import companyRoutes from 'containers/CompanyModule/CompanyRoutes'
import stockItemRoutes from 'containers/GeneralSettingModule/StockItem/StockItemRoutes'
import workdeskRoutes from 'containers/WorkdeskModule/WorkdeskRoutes'
import React, { lazy } from 'react'
import approvalPolicyRoutes from './ApprovalPolicy/ApprovalPolicyRoutes'
import autoNumberingRoutes from './AutoNumberingwDnD/AutoNumberingRoutes'
import bankProfileRoutes from './BankProfile/BankProfileRoutes'
import costCentreDefinitionRoutes from './CostCentreDefinition/CostCentreDefinitionRoutes'
import costCentrTypeRoutes from './CostCentreType/CostCentreTypeRoutes'
import creditCardTypeRoutes from './CreditCardType/CreditCardRoutes'
import creditorProfileRoutes from './CreditorProfile/CreditorProfileRoutes'
import creditorTypeRoutes from './CreditorType/CreditorTypeRoutes'
import currencyRoutes from './Currency/CurrencyRoutes'
import currencyExchangeRoutes from './CurrencyExchange/CurrencyExchangeRoutes'
import expenseClassRoutes from './ExpenseClass/ExpenseClassRoutes'
import expenseItemRoutes from './ExpenseItem/ExpenseItemRoutes'
import financialReportRoutes from './FinancialReport/FinancialReportRoutes'
import masterCOARoutes from './MasterCOA/MasterCOARoutes'
import paymentMethodRoutes from './PaymentMethod/PaymentMethodRoutes'
import policySettingsRoutes from './PolicySettings/PolicySettingsRoutes'
import stockAdjustmentReasonRoute from './StockAdjustmentReason/StockAdjustmentReasonRoute'
import taxRoutes from './TaxModule/TaxRoutes'
import uomRateRoutes from './UOMExchange/ConversionRatesRoutes'
import uomRoutes from './UOMModule/UOMRoutes'
import warehouseRoutes from './Warehouse/WarehouseRoute'
import stockCategoryForm from './StockCategory/StockCategoryRoute'
import inventoryPolicyRoutes from './InventoryPolicy/InventoryPolicyRoutes'
import transactionTypeRoutes from './TransactionType/TransactionTypeRoutes'
import bulkPurchasePriceRoutes from './BulkPurchasePrice/BulkPurchasePriceRoutes'
import supplierCategoryRoutes from './SupplierCategory/SupplierCategoryRoutes'
import itemRoutes from './PurchaseItem/PurchaseItemRoutes'
import itemCategoryRoutes from './PurchaseClass/PurchaseClassRoutes'
import generalApprovalRoutes from './ApprovalPolicy/ApprovalPolicyGPRoutes'
import supplierProfileRoutes from './SupplierProfile/SupplierProfileRoutes'
import purchaseVarianceRoutes from './PurchaseItemVariancePolicy/PurchaseItemVariancePolicyRoutes'

const GeneralSettingListing = lazy(() =>
  import('./GeneralSettingListing').then(module => ({
    default: module.GeneralSettingListing,
  }))
)

const GeneralSettingSubmenu = lazy(() =>
  import('./GeneralSettingSubmenu').then(module => ({
    default: module.GeneralSettingSubmenu,
  }))
)

const generalSettingRoutes = [
  {
    props: { exact: true, path: '/general-setting' },
    component: <GeneralSettingSubmenu />,
  },
  {
    props: { exact: true, path: '/:mode/general-setting' },
    component: <GeneralSettingListing />,
  },
  ...masterCOARoutes,
  ...approvalPolicyRoutes,
  ...bankProfileRoutes,
  ...currencyRoutes,
  ...currencyExchangeRoutes,
  ...companyRoutes,
  ...autoNumberingRoutes,
  ...taxRoutes,
  ...costCentreDefinitionRoutes,
  ...creditCardTypeRoutes,
  ...paymentMethodRoutes,
  ...workdeskRoutes,
  ...expenseClassRoutes,
  ...expenseItemRoutes,
  ...policySettingsRoutes,
  ...creditorTypeRoutes,
  ...creditorProfileRoutes,
  ...financialReportRoutes,
  ...costCentrTypeRoutes,
  ...uomRoutes,
  ...uomRateRoutes,
  ...warehouseRoutes,
  ...stockItemRoutes,
  ...stockAdjustmentReasonRoute,
  ...stockCategoryForm,
  ...inventoryPolicyRoutes,
  ...transactionTypeRoutes,
  // General Purchase Routes
  ...bulkPurchasePriceRoutes,
  ...supplierCategoryRoutes,
  ...itemRoutes,
  ...generalApprovalRoutes,
  ...itemCategoryRoutes,
  ...supplierProfileRoutes,
  ...purchaseVarianceRoutes,
  // ...generalPurchasePolicyRoutes,
]

export default generalSettingRoutes
