export const CompanyTemplateForm = [
  {
    header: {
      header: 'CompanyName',
      width: 40,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'RegistrationNo',
      width: 30,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'CompanyTax',
      width: 20,
    },
    dataType: 'companytax',
  },
  {
    header: {
      header: 'BaseCurrency',
      width: 15,
    },
    dataType: 'BaseCurrency',
  },
  {
    header: {
      header: 'ContactNo',
      width: 17,
    },
    dataType: 'number',
  },
  {
    header: {
      header: 'Address',
      width: 40,
    },
    dataType: 'number',
  },
  {
    header: {
      header: 'Country',
      width: 20,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'State',
      width: 20,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'City',
      width: 20,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'PostCode',
      width: 17,
    },
    dataType: 'text',
  },
  {
    header: {
      header: 'Active',
      width: 15,
    },
    dataType: 'TrueOrFalse',
  },
]
