import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import { amtStr4Dec } from '../../../helpers/StringNumberFunction/numFormatter';

export const Table2PR = (props: any) => {
  const { data, docType } = props;

  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) fontSize = 6;
  else fontSize = 10;

  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  };

  const thright = {
    width: '10%',
    borderBottom: '0.5px  solid',
    textAlign: 'right' as 'right',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  };

  const thcenter = {
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  };

  const thnumber = {
    width: '5%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  };

  const thcenternarrow = {
    width: '10%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  };

  const tdright = {
    width: '10%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
  };

  const tdleft = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
  };

  const tdleftstretch = {
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
  };

  // For PO
  const amount = data?.POItem?.reduce(
    (total, amountValue) => (total = total + amountValue.BaseAmt),
    0,
  );

  const taxAmount = data?.POItem?.reduce(
    (total, amountValue) => (total = total + amountValue.TaxAmt),
    0,
  );

  const totalAmount = data?.POItem?.reduce(
    (total, amountValue) =>
      (total =
        total +
        amountValue?.OrderedQty * (amountValue.BaseAmt + amountValue?.TaxAmt)),
    0,
  );

  // For PR
  let totalQty = data?.PRItem?.reduce((total, currentValue) => {
    total = total + parseFloat(currentValue?.Quantity);

    return total;
  }, 0);

  return (
    <>
      {docType !== 'purchase-requisition' && (
        <div style={{ marginTop: '20px' }}>
          <table
            style={{
              width: '100%',
              fontSize,
              // borderCollapse: 'collapse',
              borderSpacing: '0 1em',
            }}
          >
            <tr>
              <th style={thnumber}>No</th>
              <th style={thleft}>
                Item <br /> Description
              </th>
              <th style={thcenternarrow}>
                Cost
                <br />
                Centre
              </th>
              <th style={thcenternarrow}>UOM</th>
              <th style={thcenternarrow}>Qty</th>
              <th style={thright}>
                Unit Price <br /> {'(RM)'}
              </th>
              <th style={thright}>
                Base Amt <br /> {'(RM)'}
              </th>
              <th style={thright}>
                Tax Amt <br /> {'(RM)'}
              </th>
              <th style={thright}>
                Total Amt <br /> {'(RM)'}
              </th>
            </tr>

            {data?.POItem?.map((v, i) => (
              <>
                <tr>
                  <td style={{ textAlign: 'center' }}>{i + 1}</td>
                  <td style={{ textAlign: 'left' }}>
                    {v?.Item?.Name}
                    <br />
                    {v?.Item?.Description}
                  </td>
                  <td style={{ textAlign: 'center' }}>{v?.CostCentre?.Name}</td>
                  <td style={{ textAlign: 'center' }}>{v?.UOM?.Code}</td>
                  <td style={{ textAlign: 'right' }}>
                    {amtStr4Dec(v?.OrderedQty)}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {amtStr4Dec(v?.UnitPrice)}
                  </td>
                  <td style={{ textAlign: 'right' }}>{amtStr(v?.BaseAmt)}</td>
                  <td style={{ textAlign: 'right' }}>{amtStr(v?.TaxAmt)}</td>
                  <td style={{ textAlign: 'right' }}>
                    {amtStr(v?.OrderedQty * (v?.BaseAmt + v?.TaxAmt))}
                  </td>
                </tr>
              </>
            ))}
          </table>

          <div style={{ marginTop: '50px' }}>
            <table
              style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
            >
              <tr>
                <td style={tdleftstretch}>{`Total Amount (RM):`}</td>

                <td style={tdright}>{amtStr(amount)}</td>
                <td style={tdright}>{amtStr(taxAmount)}</td>
                <td style={tdright}>{amtStr(totalAmount)}</td>
              </tr>
            </table>
          </div>
        </div>
      )}

      {/* PR Item Table */}
      {docType === 'purchase-requisition' && (
        <div style={{ marginTop: '20px' }}>
          <table
            style={{
              width: '100%',
              fontSize,
              // borderCollapse: 'collapse',
              borderSpacing: '0 1em',
            }}
          >
            <tr>
              <th style={thnumber}>No</th>
              <th style={thleft}>
                Item <br /> Description
              </th>
              <th style={thcenternarrow}>
                Cost
                <br />
                Centre
              </th>
              <th style={thcenternarrow}>UOM</th>
              <th style={thcenternarrow}>Qty</th>
            </tr>

            {data?.PRItem?.map((v, i) => (
              <>
                <tr>
                  <td style={{ textAlign: 'center' }}>{i + 1}</td>
                  <td style={{ textAlign: 'left' }}>
                    {v?.Item?.Name}
                    <br />
                    {v?.Item?.Description}
                  </td>
                  <td style={{ textAlign: 'center' }}>{v?.CostCentre?.Name}</td>
                  <td style={{ textAlign: 'center' }}>{v?.UOM?.Code}</td>
                  <td style={{ textAlign: 'right' }}>
                    {amtStr4Dec(v?.Quantity)}
                  </td>
                </tr>
              </>
            ))}
          </table>

          <div style={{ marginTop: '50px' }}>
            <table
              style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
            >
              <tr>
                <td style={tdleft}>{`Total Item Requested:`}</td>

                <td style={tdright}>{amtStr4Dec(totalQty)}</td>
              </tr>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default Table2PR;
