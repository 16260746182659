import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { CopyrightFooter } from 'components/Footer/Copyright'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AmountType,
  GetDataSourceSettingsDocument,
  useDeleteDataSourceSettingsMutation,
  useGetDataSourceSettingsQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { toProperCase } from 'helpers/stringConverter'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const DataSourceSettingsListing = (props: any) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    loading: DataSourceSettingsLoading,
    error: DataSourceSettingsError,
    data: { getDataSourceSettings } = {
      getDataSourceSettings: [],
    },
  } = useGetDataSourceSettingsQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByDesc: 'createdTs',
    },
  })

  useEffect(() => {
    if (getDataSourceSettings && getDataSourceSettings?.length > 0) {
      setOriginalListing(getDataSourceSettings)
    }
  }, [getDataSourceSettings])

  const [
    deleteDataSourceSettings,
    { loading: deleteItemLoading, error: deleteItemError },
  ] = useDeleteDataSourceSettingsMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
    },
  })

  const handleDelete = data => {
    deleteDataSourceSettings({
      variables: {
        input: {
          DataSourceSettingsID: menu?.obj?.DataSourceSettingsID,
        },
        // JournalProcessingItemID
      },
      refetchQueries: [
        {
          query: GetDataSourceSettingsDocument,
          variables: { orderByDesc: 'createdTs' },
        },
      ],
    })
    setOpenDeleteDialog(false)
  }

  const amountType = type => {
    return type === AmountType.Closing
      ? 'Closing'
      : type === AmountType.Opening
      ? 'Opening'
      : type === AmountType.Transaction
      ? 'Transaction'
      : type === AmountType.YearClosing
      ? 'Year Closing'
      : type === AmountType.YearOpening
      ? 'Year Opening'
      : ''
  }

  return (
    <>
      {DataSourceSettingsLoading && <Loading />}
      {deleteItemLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-ledger/financial-reporting`)
        }} //<- back button action
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Financial Reporting' },
          { name: 'Data Source Settings', current: true },
        ]}
      />

      <SearchHeader
        title="Data Source Settings Listing"
        value={`${filteredList?.length}`}
        search
        fixed
        onChangeAction={e => {
          handleSearch(e?.target?.value, ['Name', 'Code', 'Description'])
        }}
        onCloseAction={() => handleSearch('', [])}
      />

      <ContentWrapper singleDynamicInfo float>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList?.map((el, index) => {
              return (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        <span className="mdDesc date-width">{el?.Code}</span>
                        <span className="flex-space mdLabel">{el?.Name}</span>
                        <span className="mdLabel">
                          {toProperCase(el?.ColumnSource)}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc flex-space">
                          {`Last updated on ${formatDate(el?.modTs)}`}
                        </span>
                        <span className="mdLabel ">{`${amountType(
                          el.ColumnValue
                        )}`}</span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      aria-controls="menu-list"
                      aria-haspopup="true"
                      onClick={e => {
                        handleClick(e, el?.DataSourceSettingsID, index, el)
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          )}
        </List>
        <FloatButton
          onClick={() =>
            history.push(
              `/general-ledger/financial-reporting/data-source-settings/add`
            )
          }
        />
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <>
          <MenuItem
            onClick={() => {
              history.push({
                pathname: `/general-ledger/financial-reporting/data-source-settings/${menu?.obj.DataSourceSettingsID}/edit`,
                state: menu?.obj,
              })
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem onClick={() => setOpenDeleteDialog(true)}>
            <span className="">Delete</span>
          </MenuItem>
        </>
      </Menu>

      {/* DELETE DIALOG */}
      <CommonDialog
        fullWidth={true}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <div
                    className="title"
                    style={{
                      color: '#ff9800',
                      marginBottom: '2px',
                      fontSize: '14px',
                    }}
                  >
                    Data Source Settings
                  </div>
                </div>
                <div>
                  {/* <div className="dialog-dynamic-content">
                    <div className="session"> */}
                  <div className="infoline-content">
                    <>
                      <div className="mdLabel">
                        {formatDate(menu?.obj?.createdTs)}
                      </div>
                      <div className="flex-space"></div>
                      <div className="xsTitle rightText">Delete </div>
                    </>
                  </div>
                </div>
              </div>
            ),
          },

          body: () => (
            <div className="content-wrap full">
              <span className="mdDesc full">Confirm to delete?</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setOpenDeleteDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleDelete(menu?.obj?.DataSourceSettingsID),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
