import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import Fuse from 'fuse.js'
import {
  ApprovalStatus,
  useGetCompanyNameQuery,
  useGetRecurringJournalbyStatusLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { RecurringEntryList } from './RecurringList/RecurringEntryList'
import { RecurringPostingList } from './RecurringList/RecurringPostingList'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'
import { Tune } from '@material-ui/icons'

export const RecurringJournalMain = (props: any) => {
  useEffect(() => {
    document.title = 'GL-Recurring Journal'
  }, [])

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadRecurringJournal,
    {
      loading: RecurringJournalbyStatusLoading,
      error: RecurringJournalbyStatusError,
      data: { getRecurringJournalbyStatus } = {
        getRecurringJournalbyStatus: [],
      },
    },
  ] = useGetRecurringJournalbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getRecurringJournalbyStatus }) => {
      setOriginalListing(
        getRecurringJournalbyStatus
        //   , v => {
        //   v['DocDateFormatted'] = formatDate(v.DocDate)
        // }
      )
    },
  })

  useEffect(() => {
    if (listStatus === 'ACTIVE') {
      loadRecurringJournal({
        variables: {
          CompanyID: CompanyID,
          StatusArr: [listStatus],
        },
      })
    } else {
      loadRecurringJournal({
        variables: {
          CompanyID: CompanyID,
          StatusArr: [listStatus, ApprovalStatus.Cancelled],
        },
      })
    }
  }, [listStatus])

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  // useEffect(() => {
  //   if (
  //     (getRecurringJournalbyStatus &&
  //       getRecurringJournalbyStatus?.length > 0) ||
  //     getRecurringJournalbyStatus?.length === 0
  //   ) {
  //     setOriginalListing(
  //       getRecurringJournalbyStatus
  //       //   v => {
  //       //   v['DocDateFormatted'] = formatDate(v.DocDate)
  //       // }
  //     )
  //   }
  // }, [getRecurringJournalbyStatus])

  useEffect(() => {
    if (getSearch?.name && !!originalList) {
      const keys = [
        'DocNo',
        'Description',
        amtNumStr('RecurringJournalItem.DocAmt'),
      ]
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch?.name)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch?.name + '', keys)
      }
    }
  }, [getSearch?.name, originalList])

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Transaction Date',
      key: 'TransactionDate',
    },
  })

  return (
    <>
      {UserLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {RecurringJournalbyStatusLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/general-ledger/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }} //<- back button action
        smTitle="General Ledger"
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Home Journal' },
          { name: 'Recurring Journal', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'DocNo',
              'Description',
              amtNumStr('RecurringJournalItem.DocAmt'),
            ])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          isDefaultValue={!!getSearch}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
        />
        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>
      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') &&
        !RecurringJournalbyStatusLoading && (
          <RecurringEntryList
            filteredList={filteredList}
            listLoading={RecurringJournalbyStatusLoading}
            handleFilterDate={handleFilterDate}
            listStatus={listStatus}
            setOriginalListing={setOriginalListing}
            userList={getUsersByAccountAndSoftware}
            fetchMore={loadRecurringJournal}
          />
        )}
      {listStatus === 'SUBMIT' && !RecurringJournalbyStatusLoading && (
        <RecurringPostingList
          filteredList={filteredList}
          listLoading={RecurringJournalbyStatusLoading}
          handleFilterDate={handleFilterDate}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}`)
        }}
      />
    </>
  )
}
