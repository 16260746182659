import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import {
  checkDueDate,
  formatDate,
  formatTime,
} from 'helpers/StringNumberFunction/FormatDate'
import React, { Fragment, useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useARDeleteMutation } from '../Hooks/useARDeleteMutation'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { ApprovalStatus, useGetDocNumTitleQuery } from 'generated/graphql'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'

export const AREntryList = (props: any) => {
  const {
    arSubmenu,
    filteredList,
    userList,
    listStatus,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    loadData,
    handleFilterDate,
  } = props

  let history = useHistory()
  const { CompanyID }: any = useParams()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  const GreyTooltip = withStyles({
    tooltip: { color: 'white', backgroundColor: '#9e9e9e' },
  })(Tooltip)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  const { handleDelete, mutationLoading, deleteCalled } = useARDeleteMutation({
    arSubmenu: arSubmenu,
    loadData: loadData,
    CompanyID: CompanyID,
  })

  const [deleteDialog, setOpenDeleteDialog] = useState(false)

  let ref, title, keyID, ID, clickText, refTable
  switch (arSubmenu) {
    case 'advance':
      title = 'Advance'
      ref = { AdvanceID: menu?.ID }
      keyID = 'AdvanceID'
      ID = menu?.obj?.AdvanceID
      refTable = 'AR_Advance'
      clickText = true
      break
    case 'invoice':
      title = 'Invoice'
      ref = { InvoiceID: menu?.ID }
      keyID = 'InvoiceID'
      ID = menu?.obj?.InvoiceID
      refTable = 'AR_Invoice'
      clickText = true
      break
    case 'refund':
      title = 'Refund'
      ref = { RefundID: menu?.ID }
      keyID = 'RefundID'
      ID = menu?.obj?.RefundID
      refTable = 'AR_Refund'
      clickText = true
      break
    case 'debit-note':
      title = 'Debit Note'
      ref = { DebitNoteID: menu?.ID }
      keyID = 'DebitNoteID'
      ID = menu?.obj?.DebitNoteID
      refTable = 'AR_DebitNote'
      clickText = true
      break
    case 'official-receipt':
      title = 'Receipt'
      ref = { ReceiptID: menu?.ID }
      keyID = 'ReceiptID'
      ID = menu?.obj?.ReceiptID
      refTable = 'AR_OfficialReceipt'
      clickText = true
      break
    case 'credit-note':
      title = 'Credit Note'
      ref = { CreditNoteID: menu?.ID }
      keyID = 'CreditNoteID'
      ID = menu?.obj?.CreditNoteID
      refTable = 'AR_CreditNote'
      clickText = true
      break
  }

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: refTable,
    },
  })

  const docNumChecker = getDocumentNumberHeader?.length === 0

  return (
    <>
      {docNumHeaderLoading && <Loading />}
      {mutationLoading && <Loading />}
      <ContentWrapper float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.filter(handleFilterDate)
              ?.map((el, index) => {
                return (
                  <ListItem
                    key={index}
                    style={{
                      paddingBottom: listStatus === 'COMPLETED' ? '25px' : null,
                    }}
                  >
                    <ListItemText
                      style={{
                        textDecoration:
                          el?.ApprovalStatus === 'CANCELLED' && 'line-through',
                      }}
                      primary={
                        <>
                          <span className="desc date-width">
                            {formatDate(el?.DocDate)}
                          </span>
                          <span
                            className={`mdLabel flex-space
                            ${
                              clickText &&
                              listStatus === ApprovalStatus.Completed
                                ? 'click-text'
                                : ''
                            }`}
                            onClick={() => {
                              if (arSubmenu === 'advance') {
                                return history.push({
                                  pathname: `/account-receivable/${CompanyID}/advance/${el?.AdvanceID}/preview`,
                                  state: { ...el, item: el?.ARAdvanceItem },
                                })
                              } else if (arSubmenu === 'credit-note') {
                                return history.push({
                                  pathname: `/account-receivable/${CompanyID}/credit-note/${el?.CreditNoteID}/preview`,
                                  state: {
                                    ...el,
                                    allocation: el?.Allocation,
                                    mainCreditOrDebit: 'Credit',
                                  },
                                })
                              } else if (arSubmenu === 'invoice') {
                                return history.push({
                                  pathname: `/account-receivable/${CompanyID}/invoice/${el?.InvoiceID}/preview`,
                                  state: {
                                    ...el,
                                    item: el?.ARInvoiceItem,
                                  },
                                })
                              } else if (arSubmenu === 'refund') {
                                return history.push({
                                  pathname: `/account-receivable/${CompanyID}/refund/${el?.RefundID}/preview`,
                                  state: {
                                    ...el,
                                    allocation: el?.Allocation,
                                  },
                                })
                              } else if (arSubmenu === 'official-receipt') {
                                return history.push({
                                  pathname: `/account-receivable/${CompanyID}/official-receipt/${el?.ReceiptID}/preview`,
                                  state: {
                                    ...el,
                                    allocation: el?.Allocation,
                                  },
                                })
                              } else if (arSubmenu === 'credit-note') {
                                return history.push({
                                  pathname: `/account-receivable/${CompanyID}/credit-note/${el?.CreditNoteID}/preview`,
                                  state: {
                                    ...el,
                                    allocation: el?.Allocation,
                                  },
                                })
                              } else if (arSubmenu === 'debit-note') {
                                return history.push({
                                  pathname: `/account-receivable/${CompanyID}/debit-note/${el?.DebitNoteID}/preview`,
                                  state: {
                                    ...el,
                                    allocation: el?.Allocation,
                                  },
                                })
                              }
                            }}
                          >
                            {el?.DocNo || el?.RefNo}
                          </span>
                          <IconText
                            font="highlight-text xxTitle"
                            parentStyle={{ color: '#ff9800' }}
                            children={amtStr(el?.DocAmt)}
                          />
                        </>
                      }
                      secondary={
                        <Grid container className="desc flex-space text-noflow">
                          {listStatus === 'COMPLETED' && (
                            <Grid
                              spacing={1}
                              container
                              className="table-content"
                            >
                              <Grid item xs={8}>
                                <span style={{ fontSize: '10px' }}>
                                  {el?.Allocation?.map(
                                    alloc =>
                                      alloc?.[`${alloc?.CreditOrDebit}DocNo`]
                                  )?.join(', ') ?? '-'}
                                </span>
                              </Grid>
                              <Grid item xs={4}>
                                <IconText
                                  parentClassName="c-grey"
                                  parentStyle={{ justifyContent: 'end' }}
                                  font="c-grey xxTitle"
                                  children={amtStr(el?.BalanceAmt)}
                                />
                              </Grid>
                            </Grid>
                          )}
                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={!!el?.DueDate ? 9 : 12}>
                              <span style={{ fontSize: '10px' }}>
                                {el?.DebtorAccount?.DebtorName}
                              </span>
                            </Grid>
                            {!!el?.DueDate && (
                              <Grid item xs={3} style={{ textAlign: 'end' }}>
                                <span
                                  className="desc"
                                  style={{
                                    color: checkDueDate(el?.DueDate)
                                      ? 'red'
                                      : null,
                                  }}
                                >
                                  {formatDate(el?.DueDate)}
                                </span>
                              </Grid>
                            )}
                          </Grid>

                          <Grid spacing={1} container className="table-content">
                            <Grid item xs={12}>
                              <div className="icon-text">
                                <GreyTooltip
                                  disableFocusListener
                                  title={
                                    <Fragment>
                                      <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                          <Avatar
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                            }}
                                          >
                                            <PersonIcon
                                              style={{ fontSize: 'small' }}
                                            />
                                          </Avatar>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                          <div style={{ fontSize: '12px' }}>
                                            {listStatus === 'ACTIVE'
                                              ? el?.ProfileInfo?.name
                                              : userList?.find(
                                                  x => x?.ID === el?.approvedBy
                                                )?.name}
                                          </div>
                                          <div style={{ fontSize: '10px' }}>
                                            {`${formatDate(
                                              el?.approvedTs ??
                                                new Date(
                                                  el?.createdTs
                                                ).toISOString()
                                            )} ${formatTime(
                                              el?.modTs ??
                                                new Date(
                                                  el?.createdTs
                                                ).toISOString()
                                            )}`}
                                            {/* {formatDate(
                                            listStatus === 'ACTIVE'
                                              ? new Date(
                                                  el?.createdTs
                                                ).toISOString()
                                              : el?.approvedTs
                                          )}
                                          &nbsp;
                                          {formatTime(
                                            listStatus === 'ACTIVE'
                                              ? new Date(
                                                  el?.createdTs
                                                ).toISOString()
                                              : el?.modTs
                                          )} */}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Fragment>
                                  }
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <img
                                    src={SubmitterLog}
                                    style={{
                                      width: '12px',
                                      marginRight: '3px',
                                    }}
                                  />
                                </GreyTooltip>
                                <span className="desc text-noflow">
                                  {el?.Description}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    />
                    <ListItemSecondaryAction>
                      {listStatus === 'ACTIVE' || listStatus === 'REJECTED' ? (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => handleClick(e, el?.[keyID], index, el)}
                          style={
                            listStatus === 'ACTIVE' || listStatus === 'REJECTED'
                              ? {}
                              : { verticalAlign: '30px' }
                          }
                        >
                          <MoreVert />
                        </IconButton>
                      ) : listStatus === 'COMPLETED' &&
                        (arSubmenu === 'advance' ||
                          arSubmenu === 'credit-note' ||
                          arSubmenu === 'official-receipt') ? (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => handleClick(e, el?.[keyID], index, el)}
                          style={
                            listStatus === 'ACTIVE'
                              ? {}
                              : { verticalAlign: '30px' }
                          }
                        >
                          <MoreVert />
                        </IconButton>
                      ) : (
                        <IconButton edge="end" aria-label="arrow">
                          <KeyboardArrowRight
                            onClick={() =>
                              history.push({
                                pathname: `/account-receivable/${CompanyID}/${arSubmenu}/${el?.[keyID]}/detail`,
                                state: el,
                              })
                            }
                          />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            disabled={handlePermDisabled({
              permEnum: createPermissionCheck,
            })}
            onClick={() => {
              if (docNumChecker) {
                setOpenSnackBar(true)
                setSnackBarMsg(SystemMsgs.errorNumberingStructure())
              } else {
                history.push(
                  `/account-receivable/${CompanyID}/${arSubmenu}/add`
                )
              }
            }}
          />
        )}
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {listStatus === 'ACTIVE' ? (
          <>
            <MenuItem
              disabled={
                !CompanyID
                  ? true
                  : handlePermDisabled({
                      permEnum: updatePermissionCheck,
                    })
              }
              onClick={() => {
                history.push({
                  pathname: `/account-receivable/${CompanyID}/${arSubmenu}/${menu?.ID}/edit`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">Edit</span>
            </MenuItem>
            <MenuItem
              disabled={handlePermDisabled({
                permEnum: deletePermissionCheck,
              })}
              onClick={() => {
                setOpenDeleteDialog(true)
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </>
        ) : listStatus === 'COMPLETED' &&
          (arSubmenu === 'advance' ||
            arSubmenu === 'credit-note' ||
            arSubmenu === 'official-receipt') ? (
          <>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/account-receivable/${CompanyID}/${arSubmenu}/${ID}/detail`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">View</span>
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: `/account-receivable/${CompanyID}/${arSubmenu}/${ID}/allocation`,
                  state: menu?.obj,
                })
              }}
            >
              <span className="">Allocation</span>
            </MenuItem>
          </>
        ) : listStatus === 'REJECTED' ? (
          <>
            <MenuItem
              disabled={
                !CompanyID
                  ? true
                  : handlePermDisabled({
                      permEnum: updatePermissionCheck,
                    })
              }
              onClick={() => {
                history.push({
                  pathname: `/account-receivable/${CompanyID}/${arSubmenu}/${menu?.ID}/edit`,
                  state: { ...menu?.obj, mode: 'resubmit' },
                })
              }}
            >
              <span className="">Resubmit</span>
            </MenuItem>
          </>
        ) : null}
      </Menu>

      <DeleteDialog
        ID={ref}
        openDeleteDialog={deleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        title={title}
        menu={menu?.obj}
        handleDelete={handleDelete}
      />
    </>
  )
}
