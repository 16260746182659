import React, { useContext, useEffect, useState } from 'react'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { useHistory } from 'react-router'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import {
  RecordStatus,
  useGetAssignedCompanyQuery,
  useGetCompanyLazyQuery,
  useGetCurrencyQuery,
} from 'generated/graphql'
import Fuse from 'fuse.js'
import AppContext from 'containers/App/Store/AppContext'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import ExcelIcon from '@account-root/account-react/src/assets/icons/excel.svg'
import DownloadIcon from '@material-ui/icons/GetApp'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput'
import { CompanyTemplateForm } from './CompanyTemplateForm'

export const CompanyListing = (props: any) => {
  const getSearch = localStorage?.getItem('companyFilter')

  let history = useHistory()
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const [parentCompany] = useState<string>('000-000-000')
  const { mode, createAccess }: any = props

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }

  const ICBackPath = () => {
    history.push(`/inventory-control/general-setting`)
  }

  const setLocalAndPath = data => {
    history.push({
      pathname:
        mode === 'general-ledger'
          ? `/general-ledger/${data}/company-setting`
          : mode === 'cash-book'
          ? `/cash-book/${data}/company-setting`
          : mode === 'account-receivable'
          ? `/account-receivable/${data}/company-setting`
          : mode === 'general-purchase'
          ? `/general-purchase/${data}/company-setting`
          : mode === 'inventory-control'
          ? `/inventory-control/${data}/company-setting/numbering-structure`
          : mode === 'company-setup'
          ? `/company-setup/${data}/profile`
          : `/account-payable/${data}/company-setting`,
    })
    localStorage.setItem('latestCompany', data)
  }

  const [
    fetchChildCompany,
    {
      loading: ChildCompanyLoading,
      error: ChildCompanyError,
      data: { getCompany } = { getCompany: [] },
    },
  ] = useGetCompanyLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  const {
    loading: currencyLoading,
    error: currencyError,
    data: { getCurrency } = { getCurrency: [] },
  } = useGetCurrencyQuery({
    fetchPolicy: 'network-only',
    variables: {},
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (!!createAccess) {
      fetchChildCompany({
        variables: {
          orderByAsc: 'Name',
        },
      })
    } else {
      fetchChildCompany({
        variables: {
          orderByAsc: 'Name',
          RecordStatus: RecordStatus.Active,
        },
      })
    }
  }, [parentCompany, createAccess])

  useEffect(() => {
    if (getCompany && getCompany?.length > 0 && user?.superUser) {
      let activeList = getCompany?.filter(el => el?.RecordStatus === 'ACTIVE')

      let inactiveList = getCompany?.filter(
        el => el?.RecordStatus === 'INACTIVE'
      )
      setOriginalListing([...activeList, ...inactiveList])
    }
  }, [getCompany])

  useEffect(() => {
    if (
      getAssignedCompanyByUser &&
      getAssignedCompanyByUser?.length > 0 &&
      !user.superUser
    ) {
      setOriginalListing(getAssignedCompanyByUser)
    }
  }, [getAssignedCompanyByUser])

  let newFilteredList = filteredList?.filter(
    v => v?.ParentCompanyID === parentCompany || parentCompany === '000-000-000'
  )

  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['Name', 'CompanyRegNo']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys)
      }
    }
  }, [getSearch, originalList])
  return (
    <>
      {ChildCompanyLoading && <Loading />}
      <MainHeader
        // IF BURGER OPTION
        mainBtn={mode === 'inventory-control' ? 'back' : 'menu'}
        onClick={mode === 'inventory-control' ? ICBackPath : handleDrawer}
        smTitle={
          mode === 'general-ledger'
            ? 'General Ledger'
            : mode === 'cash-book'
            ? 'Cash Book'
            : mode === 'account-receivable'
            ? 'Account Receivable'
            : mode === 'inventory-control'
            ? 'Inventory Control'
            : mode === 'company-setup'
            ? 'Company Setup'
            : mode === 'general-purchase'
            ? 'General Purchase'
            : 'Account Payable'
        }
        title={user?.companyName}
        routeSegments={[
          {
            name:
              mode === 'inventory-control'
                ? 'Document Numbering'
                : 'Company Setup',
            current: true,
          },
        ]}
      />
      <div className="search-filter with-advanced-filter">
        <SearchHeader
          title="Search here.."
          search
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Name', 'CompanyRegNo'])
            localStorage.setItem('companyFilter', e.target.value)
          }}
          onCloseAction={() => handleSearch('', [])}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch ? getSearch : ''}
        />
      </div>

      <ContentWrapper float>
        {createAccess && (
          <div className="message-alert text-noflow">
            <div className="flex-space">
              <IconText
                icon={
                  <img
                    src={ExcelIcon}
                    style={{ width: '19px', paddingRight: '5px' }}
                  />
                }
                font="mdDesc"
                children={
                  <>
                    <span style={{ fontWeight: 'bold' }}>Upload </span>{' '}
                    <span>Excel Template</span>
                  </>
                }
              ></IconText>
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  minWidth: '0px',
                  width: '32px',
                  height: '24px',
                  margin: '-2px 0px -2px 0px',
                }}
                onClick={() => {
                  setOpenFileUpload(true)
                }}
              >
                <DownloadIcon
                  style={{
                    width: '17px',
                    height: '17px',
                  }}
                />
              </Button>
            </div>
          </div>
        )}
        <List className="core-list">
          {newFilteredList === undefined ||
          newFilteredList?.length === 0 ||
          newFilteredList.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            newFilteredList.map((el, index) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => setLocalAndPath(el.CompanyID)}
                >
                  <ListItemText
                    primary={
                      <>
                        <span
                          className="xsTitle"
                          style={{
                            color:
                              el?.RecordStatus === 'INACTIVE'
                                ? '#BDBDBD'
                                : null,
                          }}
                        >
                          {el.Name}
                        </span>
                      </>
                    }
                    secondary={
                      <span
                        className="desc"
                        style={{
                          color:
                            el?.RecordStatus === 'INACTIVE' ? '#BDBDBD' : null,
                        }}
                      >
                        {el.CompanyRegNo}
                      </span>
                    }
                  />

                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="arrow">
                      {((el?.RecordStatus === 'ACTIVE' && !createAccess) ||
                        createAccess) && (
                        <KeyboardArrowRight
                          onClick={() => setLocalAndPath(el.CompanyID)}
                        />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          )}
        </List>
        {createAccess && (
          <FloatButton
            copyright
            onClick={() =>
              history.push(
                mode === 'company-setup'
                  ? `/company-setup/add`
                  : `/${mode}/company/add`
              )
            }
            disabled={
              !!user?.maxActiveCompany
                ? getCompany?.length >= user?.maxActiveCompany
                : false
            }
          />
        )}
      </ContentWrapper>

      <ExcelInput
        showFooter={false}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          history.push({
            // pathname: `/general-ledger/general-setting/master-coa/${accType}/validation`,
            state: {
              excelData: currentData?.map(x => {
                return {
                  Code: `${x?.Code}`,
                  ParentCode:
                    x?.ParentCode === null ? null : `${x?.ParentCode}`,
                  Name: `${x?.Name}`,
                  Level: Number(x?.Level),
                  AccountType: `${x?.AccountType}`,
                  IsControl:
                    x?.IsControl === 'true' ||
                    x?.IsControl === 'TRUE' ||
                    x?.IsControl === 'True'
                      ? true
                      : x?.IsControl === 'false' ||
                        x?.IsControl === 'FALSE' ||
                        x?.IsControl === 'False'
                      ? false
                      : x?.IsControl,
                  IsLastNode:
                    x?.IsLastNode === 'true' ||
                    x?.IsLastNode === 'TRUE' ||
                    x?.IsLastNode === 'True'
                      ? true
                      : x?.IsLastNode === 'false' ||
                        x?.IsLastNode === 'FALSE' ||
                        x?.IsLastNode === 'False'
                      ? false
                      : x?.IsLastNode,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
            },
          })
          setOpenFileUpload(false)
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        downloadButton={true}
        onDownloadTemplate={() => {
          onDownloadTemplate({
            name: `Company Setup SpreadSheet`,
            workSheetName: `Company List`,
            headerSetup: CompanyTemplateForm,
            isHeaderLocked: true,
            defaultHeaderFillStyle: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            },
            defaultHeaderBorderStyle: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            listType: {
              hasCountry: true,
              hasNationality: true,
            },
            extraListData: [
              {
                data: getCurrency?.map(x => x?.Code),
                worksheetName: 'BaseCurrency',
                dataType: 'BaseCurrency',
              },
              {
                data: [
                  'No Tax',
                  'GST Registration No.',
                  'SST Registration No.',
                ],
                worksheetName: 'companytax',
                dataType: 'companytax',
              },
            ],
          })
        }}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false)
          },
        }}
      />
    </>
  )
}
