import React, { lazy } from 'react'

const AutoNumberingListing = lazy(() =>
  import('./AutoNumberingListing').then(module => ({
    default: module.AutoNumberingListing,
  }))
)

const AutoNumberingForm = lazy(() =>
  import('./AutoNumberingForm').then(module => ({
    default: module.AutoNumberingForm,
  }))
)

const GPAutoNumberingForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/CompanyModule/NumberStructureModule/AutoNumbering/AutoNumberingForm'
  ).then(module => ({
    default: module.AutoNumberingForm,
  }))
)

const autoNumberingRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: '/general-ledger/numbering-structure/:CompanyID/recurring-journal',
  //     // path: '/general-setting/auto-numbering',
  //   },
  //   component: <AutoNumberingListing />,
  // },

  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/numbering-structure/journal-processing',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="journal-processing"
        pageMode="general-ledger"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/numbering-structure/external-journal',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="external-journal"
        pageMode="general-ledger"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/numbering-structure/recurring-journal',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="recurring-journal"
        pageMode="general-ledger"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/:CompanyID/company-setting/numbering-structure/audit-adjustment',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="audit-adjustment"
        pageMode="general-ledger"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/company-setting/numbering-structure/payment',
    },
    component: (
      <AutoNumberingForm mode="add" DocType="paymentCb" pageMode="cash-book" />
    ),
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/company-setting/numbering-structure/receipt',
    },
    component: (
      <AutoNumberingForm mode="add" DocType="receipt" pageMode="cash-book" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/company-setting/numbering-structure/bank-transfer',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="bank-transfer"
        pageMode="cash-book"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/numbering-structure/advance',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="advance"
        pageMode="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/numbering-structure/invoice',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="invoice"
        pageMode="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/numbering-structure/credit-note',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="credit-note"
        pageMode="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/numbering-structure/creditor-credit-note',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="creditor-credit-note"
        pageMode="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/numbering-structure/debit-note',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="debit-note"
        pageMode="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/numbering-structure/payment',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="paymentAp"
        pageMode="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/company-setting/numbering-structure/refund',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="refund"
        pageMode="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/numbering-structure/invoice',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="invoice"
        pageMode="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/numbering-structure/credit-note',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="credit-note"
        pageMode="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/numbering-structure/debit-note',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="debit-note"
        pageMode="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/numbering-structure/official-receipt',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="official-receipt"
        pageMode="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/numbering-structure/advance',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="advance"
        pageMode="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/numbering-structure/refund',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="refund"
        pageMode="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/company-setting/numbering-structure/interest',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="interest"
        pageMode="account-receivable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:CompanyID/company-setting/numbering-structure/stock-receipt',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="stock-receipt"
        pageMode="inventory-control"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:CompanyID/company-setting/numbering-structure/stock-issue',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="stock-issue"
        pageMode="inventory-control"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:CompanyID/company-setting/numbering-structure/stock-transfer',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="stock-transfer"
        pageMode="inventory-control"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:CompanyID/company-setting/numbering-structure/cycle-counting',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="cycle-counting"
        pageMode="inventory-control"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:CompanyID/company-setting/numbering-structure/stock-adjustment',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="stock-adjustment"
        pageMode="inventory-control"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:CompanyID/company-setting/numbering-structure/stock-cost-adjustment',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="stock-cost-adjustment"
        pageMode="inventory-control"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:CompanyID/company-setting/numbering-structure/stock-requisition',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="stock-requisition"
        pageMode="inventory-control"
      />
    ),
  },

  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/company-settings/numbering-structure/purchase-requisition',
    },
    component: (
      <GPAutoNumberingForm
        mode="add"
        DocType="purchase-requisition"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/company-settings/numbering-structure/request-quotation',
    },
    component: (
      <GPAutoNumberingForm
        mode="add"
        DocType="request-quotation"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/company-settings/numbering-structure/purchase-order',
    },
    component: (
      <GPAutoNumberingForm
        mode="add"
        DocType="purchase-order"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/company-settings/numbering-structure/delivery-order',
    },
    component: (
      <GPAutoNumberingForm
        mode="add"
        DocType="delivery-order"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/company-settings/numbering-structure/good-return-note',
    },
    component: (
      <GPAutoNumberingForm
        mode="add"
        DocType="good-return-note"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/company-settings/numbering-structure/refund',
    },
    component: (
      <GPAutoNumberingForm
        mode="add"
        DocType="refund"
        pageMode="general-purchases"
      />
    ),
  },
]

export default autoNumberingRoutes
