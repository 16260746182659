import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  Divider,
  Typography,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import PersonIcon from '@material-ui/icons/Person'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab'
import { ApprovalStatus, useGetSubmitterNameLazyQuery } from 'generated/graphql'
import { toProperCase } from 'helpers/stringConverter'
import {
  dateFormat,
  formatTime24Hour,
} from 'helpers/StringNumberFunction/FormatDate'
import React, { FC, useEffect } from 'react'

export interface ApprovalLogProps {
  data: any
  setWorkFlow: any
  openWorkFlow: boolean
  moduleName: string
}

export const ApprovalLogDialog = (props: ApprovalLogProps) => {
  const { data, setWorkFlow, openWorkFlow, moduleName } = props

  const [
    loadSubmitterName,
    {
      data: { getUser } = {
        getUser: {},
      },
    },
  ] = useGetSubmitterNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  useEffect(() => {
    if (openWorkFlow && data?.WorkFlow) {
      loadSubmitterName({
        variables: {
          ID: data?.createdBy,
        },
      })
    }
  }, [openWorkFlow, data])

  const CustomTimelineItem = withStyles({
    missingOppositeContent: {
      '&:before': {
        display: 'none',
      },
    },
  })(TimelineItem)

  return (
    <CommonDialog
      fullWidth={true}
      open={openWorkFlow}
      onClose={() => setWorkFlow(false)}
      sections={{
        header: {
          dynamic: (
            <div className="">
              <div className="dialog-dynamic-content">
                <div className="session">
                  <div className="flex session">
                    <div className="title flex-space">{moduleName}</div>
                  </div>
                </div>
              </div>
              <div className="infoline-content">
                <div className="xsTitle flex-space">
                  {data?.ToBankAccountDetail?.BankName ||
                    data?.DocNo ||
                    data?.RefNo}
                </div>
                <div className=" xsTitle rightText">Approval Log </div>{' '}
              </div>
            </div>
          ),
        },
        body: () => (
          <>
            {data?.WorkFlow === null || data?.WorkFlow === undefined ? (
              <ContentWrapper
                noMargin
                style={{
                  padding: '4px 6px 7px',
                  // marginTop: isDesktop ? '0px' : null,
                  ...(isDesktop
                    ? {
                        marginTop: '0px',
                        overflow: 'initial',
                        width: '99%',
                        right: '3px',
                      }
                    : null),
                }}
              >
                <CardContents
                  contentStyle={{ padding: '4px 12px' }}
                  style={{
                    boxShadow:
                      '0 3px 1px -2px rgb(0 0 0 / 0%), 0 1px 10px 2px rgb(0 0 0 / 5%), 0 0px 5px 0 rgb(0 0 0 / 10%)',
                  }}
                >
                  <div
                    className="content-wrap full"
                    style={{
                      margin: '0px',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {`Submitter`}
                    </Typography>
                    <PersonIcon style={{ fontSize: 'small' }} />
                    <Typography variant="caption">
                      {' '}
                      {getUser?.name}{' '}
                    </Typography>{' '}
                    <br />
                    <EventIcon
                      style={{
                        fontSize: 'small',
                        paddingRight: '8px',
                        marginBottom: '-3px',
                      }}
                    />
                    <Typography variant="caption">
                      {dateFormat(data?.createdTs)}
                      &nbsp;&nbsp;
                      {formatTime24Hour(data?.createdTs)}
                    </Typography>{' '}
                  </div>
                </CardContents>
              </ContentWrapper>
            ) : (
              data?.WorkFlow?.map((el, index) => (
                <>
                  <ContentWrapper
                    noMargin
                    style={{
                      padding: '4px 6px 7px',
                      // marginTop: isDesktop ? '0px' : null,
                      ...(isDesktop
                        ? {
                            marginTop: '0px',
                            overflow: 'initial',
                            width: '99%',
                            right: '3px',
                          }
                        : null),
                    }}
                  >
                    <CardContents
                      contentStyle={{ padding: '4px 12px 0px' }}
                      style={{
                        boxShadow:
                          '0 3px 1px -2px rgb(0 0 0 / 0%), 0 1px 10px 2px rgb(0 0 0 / 5%), 0 0px 5px 0 rgb(0 0 0 / 10%)',
                      }}
                    >
                      <div
                        className="content-wrap full"
                        style={{
                          margin: '0px',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                          }}
                        >
                          {`Submitter`}
                        </Typography>
                        <PersonIcon style={{ fontSize: 'small' }} />
                        <Typography variant="caption">
                          {' '}
                          {el?.SubmitterDetail?.name ?? getUser?.name}{' '}
                        </Typography>{' '}
                        <br />
                        <EventIcon
                          style={{
                            fontSize: 'small',
                            paddingRight: '8px',
                            marginBottom: '-3px',
                          }}
                        />
                        <Typography variant="caption">
                          {dateFormat(el?.SubmitDate ?? data?.createdTs)}
                          &nbsp;&nbsp;
                          {formatTime24Hour(el?.SubmitDate ?? data?.createdTs)}
                        </Typography>{' '}
                        <Divider
                          orientation="horizontal"
                          variant="fullWidth"
                          style={{
                            color: 'grey',
                            backgroundColor: 'grey',
                            marginTop: '10px',
                            width: '115%',
                            marginLeft: '-15px',
                          }}
                        />
                        <Timeline className="timeline">
                          {el?.WorkFlowStep.sort(
                            (a, b) => a.StepNo - b.StepNo
                          ).map((x, index) => (
                            <CustomTimelineItem>
                              <TimelineSeparator>
                                <TimelineDot
                                  style={{
                                    backgroundColor:
                                      x?.ApprovalStatus ===
                                      ApprovalStatus.Pending
                                        ? 'grey'
                                        : '#ff9800',
                                  }}
                                />
                                {index !== el?.WorkFlowStep?.length - 1 ? (
                                  <TimelineConnector />
                                ) : null}
                              </TimelineSeparator>
                              <TimelineContent>
                                <Typography variant="subtitle1">
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {`Level ${x.StepNo}`}
                                  </span>{' '}
                                  &nbsp;
                                  <span
                                    style={
                                      x?.ApprovalStatus === 'PENDING'
                                        ? {
                                            color: '#ffb11f',
                                            fontSize: '12px',
                                          }
                                        : x?.ApprovalStatus === 'REJECTED'
                                        ? {
                                            color: 'red',
                                            fontSize: '12px',
                                          }
                                        : {
                                            color: 'green',
                                            fontSize: '12px',
                                          }
                                    }
                                  >
                                    {toProperCase(x?.ApprovalStatus)}
                                  </span>
                                </Typography>
                                {x?.WorkFlowApprovedStep?.length > 0 ? (
                                  <>
                                    <PersonIcon
                                      style={{
                                        fontSize: 'small',
                                        marginBottom: '-2px',
                                        paddingRight: '5px',
                                      }}
                                    />
                                    <Typography variant="caption">
                                      {' '}
                                      {
                                        x?.WorkFlowApprovedStep[0]
                                          ?.ApproverDetail?.name
                                      }{' '}
                                    </Typography>{' '}
                                    <br />
                                    <Typography variant="caption">
                                      {' '}
                                      {dateFormat(
                                        x?.WorkFlowApprovedStep[0]?.createdTs
                                      ) === 'NA'
                                        ? ''
                                        : dateFormat(
                                            x?.WorkFlowApprovedStep[0]
                                              ?.createdTs
                                          )}
                                      &nbsp;&nbsp;
                                      {formatTime24Hour(
                                        x?.WorkFlowApprovedStep[0]?.createdTs
                                      ) === 'NA'
                                        ? ''
                                        : formatTime24Hour(
                                            x?.WorkFlowApprovedStep[0]
                                              ?.createdTs
                                          )}{' '}
                                    </Typography>{' '}
                                    <br />
                                    {x?.ApprovalStatus === 'REJECTED' ? (
                                      <>
                                        <Typography variant="caption">
                                          {
                                            x?.WorkFlowApprovedStep[0]
                                              ?.StatusRemarks
                                          }{' '}
                                        </Typography>{' '}
                                      </>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    <PersonIcon
                                      style={{
                                        fontSize: 'small',
                                        marginBottom: '-2px',
                                        paddingRight: '5px',
                                      }}
                                    />
                                    <Typography
                                      variant="caption"
                                      style={{ fontSize: '12px' }}
                                    >
                                      {' '}
                                      {x?.RoleDetail?.name}{' '}
                                    </Typography>
                                  </>
                                )}
                              </TimelineContent>
                            </CustomTimelineItem>
                          ))}
                        </Timeline>
                      </div>
                    </CardContents>
                  </ContentWrapper>
                </>
              ))
            )}
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Close',
              props: {
                onClick: () => {
                  setWorkFlow(false)
                },
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}
