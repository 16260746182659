import {
  useGetPaymentLazyQuery,
  useGetReceiptLazyQuery,
  useGetBankTransferLazyQuery,
} from 'generated/graphql'
import { useEffect } from 'react'

export const useCBQueries: any = ({
  accountType,
  docType,
  CompanyID,
  BankAccountID,
}) => {
  //----------CB Queries----------//
  const [
    fetchPayment,
    {
      loading: PaymentLoading,
      called: PaymentCalled,
      data: { getPayment } = { getPayment: [] },
    },
  ] = useGetPaymentLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchReceipt,
    {
      loading: ReceiptLoading,
      called: ReceiptCalled,
      data: { getReceipt } = { getReceipt: [] },
    },
  ] = useGetReceiptLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchBankTransfer,
    {
      loading: BankTransferLoading,
      called: BankTransferCalled,
      data: { getBankTransfer } = { getBankTransfer: [] },
    },
  ] = useGetBankTransferLazyQuery({
    fetchPolicy: 'network-only',
  })

  let loading, called, listData, subMenuName, primaryKey

  switch (accountType) {
    case 'cash-book':
      switch (docType) {
        case 'payment':
          loading = PaymentLoading
          called = PaymentCalled
          listData = getPayment
          subMenuName = 'Payment'
          primaryKey = 'PaymentID'
          break

        case 'receipt':
          loading = ReceiptLoading
          called = ReceiptCalled
          listData = getReceipt
          subMenuName = 'Receipt'
          primaryKey = 'ReceiptID'
          break

        case 'bank-transfer':
          loading = BankTransferLoading
          called = BankTransferCalled
          listData = getBankTransfer
          subMenuName = 'Bank Transfer'
          primaryKey = 'BankTransferID'
          break
      }
      break
  }

  useEffect(() => {
    switch (accountType) {
      case 'cash-book':
        switch (docType) {
          case 'payment':
            fetchPayment({
              variables: {
                CompanyID: CompanyID,
                BankAccountID: BankAccountID,
              },
            })
            break

          case 'receipt':
            fetchReceipt({
              variables: {
                CompanyID: CompanyID,
                BankAccountID: BankAccountID,
              },
            })
            break

          case 'bank-transfer':
            fetchBankTransfer({
              variables: {
                CompanyID: CompanyID,
                BankAccountID: BankAccountID,
              },
            })
            break
        }
        break
    }
  }, [accountType, docType])

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
  }
}
