import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Mic, Stop } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'
import { Controller } from 'react-hook-form'

export const VoiceTextField = ({
  mounted,
  label,
  name,
  required,
  value: audioInput,
  setValue: setAudioInput,
  controllerProps,
  customOnchange,
  handleCustomOnChange,
  record,
  setRecord,
  helperTextProps,
  control,
  register,
  clearErrors,
  defaultValue,
}: any) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition()
  useEffect(() => {
    if (!!transcript || transcript !== '') {
      clearErrors(name)
      setAudioInput(name, transcript)
    }
  }, [transcript])

  useEffect(() => {
    if (record) {
      resetTranscript()
      SpeechRecognition.startListening({ continuous: true })
    } else {
      SpeechRecognition.stopListening()
    }
  }, [record])

  if (mounted) {
    return (
      <Controller
        render={({ onChange, value }) => (
          <TextField
            margin="normal"
            style={{ width: '100%' }}
            id="stardard-basic"
            label={label}
            {...helperTextProps}
            multiline
            required={required}
            value={value}
            defaultValue={defaultValue}
            onChange={e => {
              onChange(e)
              if (customOnchange) {
                handleCustomOnChange
              }
            }}
            // defaultValue={defaultValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    style={{
                      paddingRight: '0px',
                    }}
                    onClick={() => {
                      if (listening) {
                        setRecord(false)
                      } else {
                        setRecord(true)
                      }
                    }}
                  >
                    {listening ? (
                      <div style={{ color: 'red' }}>
                        <Stop />
                      </div>
                    ) : (
                      <Mic />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        name={name}
        control={control}
        ref={register}
        fullWidth
      />
    )
  } else return null
}
