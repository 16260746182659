import theme from '@ifca-root/react-component/src/assets/theme'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { CardExpansionHeader } from 'components/CardExpansion/CardExpansionHeader'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  GetJournalProcessingbyStatusDocument,
  usePostJournalProcessingMutation,
  useRejectJournalProcessingMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { JournalDetailContent } from '../JournalDetail/JournalDetailContent'
import { JournalDetailItemContent } from '../JournalDetail/JournalDetailItemContent'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { useForm } from 'react-hook-form'

export const JournalPostingList = (props: any) => {
  const {
    list,
    filteredList,
    listLoading,
    listStatus,
    postIDs,
    setPostIDs,
    userList,
    setOriginalListing,
    handleFilterDate,
  } = props

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { handleSubmit, register, errors, control, clearErrors } = useForm({
    mode: 'onSubmit',
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [openDialog, setOpenDialog] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [clickReject, setIsReject] = useState(false)
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  })

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    postJournalProcessing,
    {
      loading: postJournalProcessingLoading,
      called: postJournalProcessingCalled,
      error: postJournalProcessingError,
    },
  ] = usePostJournalProcessingMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
    },
  })

  const [
    rejectJournalProcessing,
    {
      loading: rejectJournalProcessingLoading,
      called: rejectJournalProcessingCalled,
      error: rejectJournalProcessingError,
    },
  ] = useRejectJournalProcessingMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  // handle checkbox for all employee
  const handleSelectAll = e => {
    let temp = postIDs
    if (e.target.checked) {
      filteredList.map(v => {
        temp.add(v?.JournalProcessingID)
      })
    } else {
      temp.clear()
    }
    setPostIDs(new Set(temp))
  }

  // handle checkbox for specific entityCoa
  const handleCheckBoxChange = (e: any, postID, index: number) => {
    let temp = postIDs
    if (!temp.has(postID)) {
      temp.add(postID)
    } else {
      temp.delete(postID)
    }

    setPostIDs(new Set(temp))
  }

  let listPostIDs: any = Array.from(postIDs || [])

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()

  let checkedData = []
  listPostIDs.map(ID => {
    filteredList.map(el => {
      if (el?.JournalProcessingID === ID) {
        checkedData.push(...el?.JournalProcessingItem)
      }
    })
  })
  const docAmtReducer = (total, curVal) => total + curVal?.DocAmt

  const positiveAmt = checkedData
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = checkedData
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  /* -------------------------------------------- */
  /*                    SUBMIT                   */
  /* -------------------------------------------- */
  const onSubmitApprove = () => {
    postJournalProcessing({
      variables: {
        JournalProcessingIDs: listPostIDs,
        CompanyID,
      },
      refetchQueries: [
        {
          query: GetJournalProcessingbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const onSubmitReject = () => {
    rejectJournalProcessing({
      variables: {
        JournalProcessingIDs: listPostIDs,
        input: rejectDialogInput,
      },
      refetchQueries: [
        {
          query: GetJournalProcessingbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: ['SUBMIT'],
          },
        },
      ],
    })
  }

  useEffect(() => {
    if (clickReject) {
      onSubmitReject()
    }
  }, [clickReject])

  useEffect(() => {
    if ((list && list?.length > 0) || list?.length === 0) {
      setOriginalListing(list, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    }
  }, [list])

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  return (
    <>
      {postJournalProcessingLoading && <Loading />}
      {rejectJournalProcessingLoading && <Loading />}
      {listLoading && <Loading />}

      <ContentWrapper footer style={{ marginTop: isDesktop ? '70px' : '-7px' }}>
        {listStatus === 'SUBMIT' && filteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={handleSelectAll}
              color="primary"
              value={''}
              checked={postIDs?.size === filteredList?.length}
              style={{ marginLeft: '-5px', marginTop: '-5px' }}
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum:
                  AcctPermission.GeneralLedgerJournalProcessingApproveReject,
              })}
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}

        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.filter(handleFilterDate)
              .map((el, index) => {
                return (
                  <CardExpansion
                    summary={
                      <ListItem
                        key={index}
                        style={
                          {
                            // paddingBottom: '22px',
                          }
                        }
                      >
                        {listStatus === 'SUBMIT' ? (
                          <FormControl
                            component="fieldset"
                            style={{ width: '16px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={el.JournalProcessingID}
                                  onChange={e => {
                                    handleCheckBoxChange(
                                      e,
                                      el?.JournalProcessingID,
                                      index
                                    )
                                  }}
                                  onClick={e => e.stopPropagation()}
                                  checked={postIDs?.has(
                                    el?.JournalProcessingID
                                  )}
                                  color="primary"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '-5px',
                                    // marginTop: '-15px',
                                  }}
                                  disabled={handlePermDisabled({
                                    companyID: CompanyID,
                                    permEnum:
                                      AcctPermission.GeneralLedgerJournalProcessingApproveReject,
                                  })}
                                />
                              }
                              label=""
                              name="check"
                            />
                          </FormControl>
                        ) : null}

                        <ListItemText
                          style={{
                            marginLeft: listStatus === 'SUBMIT' ? '25px' : null,
                          }}
                          primary={
                            <>
                              <span
                                className={`xsTitle flex-space click-text`}
                                onClick={() => {
                                  history.push({
                                    pathname: `/general-ledger/${CompanyID}/journal-processing/${el?.JournalProcessingID}/preview`,
                                    state: {
                                      ...el,
                                      mode: 'detail',
                                    },
                                  })
                                }}
                              >
                                {el?.DocNo ?? el?.RefNo}
                              </span>
                              <span
                                className="desc"
                                style={{
                                  whiteSpace: 'break-spaces',
                                  lineBreak: 'auto',
                                }}
                              ></span>
                              <span
                                className="desc"
                                style={{
                                  color: '#FF0000',
                                }}
                              >
                                {formatDate(el?.DocDate)}
                              </span>
                            </>
                          }
                          secondary={
                            <>
                              <Grid
                                spacing={1}
                                container
                                className="table-content"
                              >
                                <Grid
                                  item
                                  xs={7}
                                  style={{
                                    position: 'absolute',
                                    contain: 'content',
                                  }}
                                >
                                  <div
                                    className="icon-text"
                                    // style={{ marginBottom: '-12px' }}
                                    // style={{ whiteSpace: 'normal' }}
                                  >
                                    <GreyTooltip
                                      disableFocusListener
                                      title={
                                        <React.Fragment>
                                          <Grid
                                            container
                                            wrap="nowrap"
                                            spacing={2}
                                          >
                                            <Grid item>
                                              <Avatar
                                                style={{
                                                  width: '20px',
                                                  height: '20px',
                                                }}
                                              >
                                                <PersonIcon
                                                  style={{
                                                    fontSize: 'small',
                                                  }}
                                                />
                                              </Avatar>
                                            </Grid>
                                            <Grid item xs zeroMinWidth>
                                              <div style={{ fontSize: '12px' }}>
                                                {userList?.find(
                                                  x => x?.ID === el?.submittedBy
                                                )?.name ??
                                                  userList?.find(
                                                    x =>
                                                      x?.ID ===
                                                        el?.submittedBy ??
                                                      el?.modBy
                                                  )?.name}
                                              </div>

                                              <div style={{ fontSize: '10px' }}>
                                                {formatDate(el?.modTs)}
                                                &nbsp;
                                                {formatTime(el?.modTs)}
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </React.Fragment>
                                      }
                                      arrow
                                      enterTouchDelay={0}
                                    >
                                      <img
                                        src={SubmitterLog}
                                        style={{
                                          width: '12px',
                                          marginRight: '3px',
                                        }}
                                      />
                                    </GreyTooltip>
                                    <span
                                      className="desc text-overflow"
                                      style={{
                                        marginBottom: '-3px',
                                      }}
                                    >
                                      {el?.Description}
                                    </span>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{
                                    marginLeft: 'auto',
                                    flexBasis: 'auto',
                                    boxSizing: 'content-box',
                                  }}
                                >
                                  <span className="xxTitle">
                                    <span className="c-orange">
                                      <span style={{ fontSize: '12px' }}>
                                        {amtStr(
                                          el?.JournalProcessingItem?.filter(
                                            item => item?.DocAmt > 0
                                          )?.reduce((x, y) => x + y?.DocAmt, 0)
                                        )}
                                      </span>
                                    </span>
                                  </span>
                                </Grid>
                              </Grid>
                            </>
                          }
                        />
                      </ListItem>
                    }
                  >
                    {
                      <>
                        <JournalDetailContent
                          listEl={el}
                          documentListing={el?.Attachment}
                          listStatus={'SUBMIT'}
                          userList={userList}
                          mode={'expansion'}
                        />
                        <CardExpansionHeader title={'Journal Detail'} />
                        <JournalDetailItemContent
                          listItem={el?.JournalProcessingItem}
                        />
                      </>
                    }
                  </CardExpansion>
                )
              })
          )}
        </List>
      </ContentWrapper>

      <SubmitterDialog
        data={menu2?.obj}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Journal Processing'}
      />

      {listStatus === 'SUBMIT' ? (
        <AccountFooter
          options={[
            {
              name: 'Reject',
              onClick: () => {
                setOpenRejectDialog(true)
              },
              color: 'secondary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.GeneralLedgerJournalProcessingApproveReject,
                  }),
              },
            },
            {
              name: 'Approve',
              onClick: () => {
                onSubmitApprove()
              },
              color: 'primary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.GeneralLedgerJournalProcessingApproveReject,
                  }),
              },
            },
          ]}
        />
      ) : null}

      <RejectDialog
        data={filteredList}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        setRejectDialogInput={setRejectDialogInput}
        setIsReject={setIsReject}
      />

      <TotalAmountFooter
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />
    </>
  )
}
