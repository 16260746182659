import React, { lazy } from 'react'
import { PaymentDetail } from './PaymentDetail/PaymentDetail'
import { PaymentForm } from './PaymentForm/PaymentForm'
import { PaymentItemForm } from './PaymentForm/PaymentItemForm'
import { PaymentListingGS } from './PaymentList/OldList/PaymentListingGS'
import { PaymentMain } from './PaymentMain'
import PDFPreviewCB from '../Hooks/PDFPreviewCB'

// const RoutineJournalListing = lazy(() =>
//   import('./PaymentListing').then(module => ({
//     default: module.PaymentListing,
//   }))
// )

// const PaymentListing = lazy(() =>
//   import('./PaymentPostingList').then(module => ({
//     default: module.PaymentListing,
//   }))
// )

const AdvancedFilter = lazy(() =>
  import('../../../components/Filter/AdvancedFilter').then(module => ({
    default: module.AdvancedFilter,
  }))
)

const paymentRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/payment/filter',
    },
    component: (
      <AdvancedFilter
        mode="payment"
        app="cash-book"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/cash-book/:CompanyID/submenu/:BankAccountID/payment',
  //   },
  //   component: <PaymentListing />,
  // },

  // {
  //   props: {
  //     exact: true,
  //     path: '/cash-book/:CompanyID/submenu/:BankAccountID/payment',
  //   },
  //   component: <PaymentListing />,
  // },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/payment',
    },
    component: <PaymentMain />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:PaymentID/detail',
    },
    component: <PaymentDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/detail/:PaymentID/workdesk',
    },
    component: <PaymentDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/detail/:PaymentID/workdesk',
    },
    component: <PaymentDetail mode="workdesk-history" />,
  },
  {
    props: {
      exact: true,
      path: '/cash-book/general-setting/:CompanyID/payment',
    },
    component: <PaymentListingGS />,
  },

  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:formMode',
    },

    component: (
      <PaymentForm
        detailMode={'add'}
        taxAmtChangeableNeeded={true}
        type="PAYMENT_CB"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:PaymentID/:formMode',
    },
    component: (
      <PaymentForm
        detailMode={'edit'}
        taxAmtChangeableNeeded={true}
        type="PAYMENT_CB"
      />
    ),
  },
  //payment = add , paymentitem = add
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:formMode/payment-item/:detailMode',
    },
    component: <PaymentItemForm />,
  },
  //payment = add , paymentitem = edit & add
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:PaymentID/:formMode/payment-item/:detailMode',
    },
    component: <PaymentItemForm />,
  },
  //payment = add, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:formMode/payment-item/:PaymentItemID/:detailMode',
    },
    component: <PaymentItemForm />,
  },
  //payment = edit, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:PaymentID/:formMode/payment-item/:PaymentItemID/:detailMode',
    },
    component: <PaymentItemForm />,
  },
  // payment = edit, paymentitem = has been edited
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:PaymentID/:formMode',
    },
    component: <PaymentForm detailMode={'edit'} />,
  },
  // payment = edit, paymentitem = has been added
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:PaymentID/:formMode',
    },
    component: <PaymentForm detailMode={'add'} />,
  },

  /* -------------------------------------------- */
  /*              PREVIEW DETAIL PDF              */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/submenu/:BankAccountID/payment/:PaymentID/detail/preview',
    },
    component: <PDFPreviewCB accountType="cash-book" docType="payment" />,
  },
]

export default paymentRoutes
