import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { Grid, TextField } from '@material-ui/core'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetCostCentreTypeDocument,
  useCreateUpdateCostCentreTypeMutation,
  useGetCostCentreDefinitionDetailQuery,
  useGetCostCentreTypeQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { useHistory, useLocation } from 'react-router'
import { uuid } from 'uuidv4'
import * as yup from 'yup'

export interface CostCentreTypeProps {
  CostCentreTypeArr: any[]
}

export const CostCentreTypeForm = (props: any) => {
  const { mode } = props
  let history = useHistory()
  let location = useLocation()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  // const CostCentreTypeSchema = yup.object().shape({
  //   CostCentreTypeArr: yup.array().of(
  //     yup.object().shape({
  //       Name: CommonYupValidation.requireField(SystemMsgs.name()),
  //     })
  //   ),
  // })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    getValues,
  } = useForm<CostCentreTypeProps>({
    defaultValues: {},
    mode: 'onSubmit',
    //resolver: yupResolver(CostCentreTypeSchema),
  })

  const {
    loading: CostCentreTypeLoading,
    error: CostCentreTypeError,
    data: { getCostCentreType } = { getCostCentreType: [] },
  } = useGetCostCentreTypeQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getCostCentreType }) => {
      if (getCostCentreType?.length > 0) {
        append(getCostCentreType)
        append([
          {
            Name: getCostCentreType[0] ? getCostCentreType[0].Name : '',
            number: 1,
          },
          {
            Name: getCostCentreType[1] ? getCostCentreType[1].Name : '',
            number: 2,
          },
          {
            Name: getCostCentreType[2] ? getCostCentreType[2].Name : '',
            number: 3,
          },
          {
            Name: getCostCentreType[3] ? getCostCentreType[3].Name : '',
            number: 4,
          },
          {
            Name: getCostCentreType[4] ? getCostCentreType[4].Name : '',
            number: 5,
          },
          {
            Name: getCostCentreType[5] ? getCostCentreType[5].Name : '',
            number: 6,
          },
        ])
      } else {
        append([
          { Name: '', number: 1 },
          { Name: '', number: 2 },
          { Name: '', number: 3 },
          { Name: '', number: 4 },
          { Name: '', number: 5 },
          { Name: '', number: 6 },
        ])
      }
    },
  })

  const [
    createUpdateCostCentreType,
    {
      loading: createUpdateCostCentreTypeLoading,
      called: createUpdateCostCentreTypeCalled,
      error: createUpdateCostCentreTypeError,
    },
  ] = useCreateUpdateCostCentreTypeMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/general-setting`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const {
    loading: CostCentreDefinitionDetailLoading,
    error: CostCentreDefinitionDetailError,
    data: { getCostCentreDefinitionDetail } = {
      getCostCentreDefinitionDetail: [],
    },
  } = useGetCostCentreDefinitionDetailQuery({
    fetchPolicy: 'network-only',
  })

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'CostCentreTypeArr',
  })

  const onSubmit = data => {
    createUpdateCostCentreType({
      variables: {
        CostCentreTypeArr: data?.CostCentreTypeArr?.filter(
          x => x?.Name !== ''
        )?.map(x => {
          return {
            Name: x?.Name,
          }
        }),
      },
      refetchQueries: [
        {
          query: GetCostCentreTypeDocument,
        },
      ],
    })
  }

  return (
    <>
      {' '}
      <MainHeader
        mainBtn="close"
        onClick={() => history.push(`/general-ledger/general-setting`)}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Setting' },
          { name: 'Department Type', current: true },
        ]}
        // rightRouteSegments={[{ name: pageMode, current: true }]}
      />
      <ContentWrapper footer>
        {fields?.map((el, index) => {
          const CostCentreTypeArr = `CostCentreTypeArr[${index}]`
          return (
            !CostCentreDefinitionDetailLoading && (
              <div>
                <CardContents>
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      style={{
                        alignSelf: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: '#FF9800',
                          fontSize: 'small',
                          color: 'black',
                          borderRadius: '20px',
                          paddingTop: '1px',
                        }}
                      >
                        {index + 1}
                      </div>
                    </Grid>
                    <Grid item xs={11} style={{ paddingRight: '10px' }}>
                      <span>
                        <Controller
                          as={TextField}
                          name={`${CostCentreTypeArr}.Name`}
                          control={control}
                          // multiline={true}
                          fullWidth
                          margin="dense"
                          variant="outlined"
                          className="qty-input-new-wid"
                          defaultValue={el?.Name}
                          disabled={
                            !!getCostCentreDefinitionDetail?.find(
                              x => x?.Name === el?.Name
                            )
                          }
                          helperText={
                            errors.CostCentreTypeArr &&
                            errors.CostCentreTypeArr[index]?.Name?.message
                          }
                          error={
                            errors.CostCentreTypeArr &&
                            errors.CostCentreTypeArr[index]?.Name
                              ? true
                              : false
                          }
                          ref={register}
                          //defaultValue={editData?.name}
                        />
                      </span>
                    </Grid>
                  </Grid>
                </CardContents>
              </div>
            )
          )
        })}

        <Footer
          options={[
            {
              name: 'Save',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
