import React, { lazy } from 'react'
// import { PDFPreview } from '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPRModule/PDFPreview/PDFPreview';

const GeneralPRMain = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPRModule/GeneralPRMain'
  ).then(module => ({
    default: module.GeneralPRMain,
  }))
)

const PDFPreview = lazy(() =>
  import(
    '@account-root/procurement-react/src/components/PDFTemplate/PDFPreview'
  ).then(module => ({
    default: module.PDFPreview,
  }))
)

const GeneralPRListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPRModule/GeneralPRListing'
  ).then(module => ({
    default: module.GeneralPRListing,
  }))
)

const GeneralPRForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPRModule/GeneralPRFormv2'
  ).then(module => ({
    default: module.PurchaseRequisitionForm,
  }))
)

const GeneralPRItemForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPRModule/GeneralPRItemForm'
  ).then(module => ({
    default: module.PurchaseRequisitionItemForm,
  }))
)

const GeneralPRDetail = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralPurchaseModule/GeneralPRModule/GeneralPRDetail'
  ).then(module => ({
    default: module.GeneralPRDetail,
  }))
)

const generalPRRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/purchase-requisition',
    },
    component: <GeneralPRMain />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/purchase-requisition/:PRHeaderID/preview',
    },
    component: (
      <PDFPreview
        accountType="/"
        docType="purchase-requisition"
        backPath="purchase-requisition"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/purchase-requisition',
    },
    component: <GeneralPRListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/purchase-requisition/add',
    },
    component: <GeneralPRForm formMode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/purchase-requisition/:PRHeaderID/edit',
    },
    component: <GeneralPRForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/purchase-requisition/:PRHeaderID/submit',
    },
    component: <GeneralPRForm formMode="submit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/purchase-requisition/add/preview',
    },
    component: <GeneralPRForm formMode="submit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/:CompanyID/purchase-requisition/:PRHeaderID',
    },
    component: <GeneralPRDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/purchase-requisition/:formMode/purchase-requisition-item/:detailMode',
    },
    component: <GeneralPRItemForm />,
  },
  //payment = add , paymentitem = edit & add
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/purchase-requisition/:PRHeaderID/:formMode/purchase-requisition-item/:detailMode',
    },
    component: <GeneralPRItemForm />,
  },
  //payment = add, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/purchase-requisition/:formMode/purchase-requisition-item/:PRItemID/:detailMode',
    },
    component: <GeneralPRItemForm />,
  },
  //payment = edit, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/general-purchase/:CompanyID/purchase-requisition/:PRHeaderID/:formMode/purchase-requisition-item/:PRItemID/:detailMode',
    },
    component: <GeneralPRItemForm />,
  },
]

export default generalPRRoutes
