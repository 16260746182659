import { format, parseISO } from 'date-fns'

export const convertToMonthDayYear = UNIX_timestamp => {
  const a = new Date(UNIX_timestamp)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const year = a.getFullYear()
  const month = months[a.getMonth()]
  const date = a.getDate()
  const time = `${month}, ${date}, ${year}`
  return time
}

export const convertToMonth = UNIX_timestamp => {
  const a = new Date(UNIX_timestamp)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const year = a.getFullYear()
  const month = months[a.getMonth()]
  const date = a.getDate()
  const time = `${month || '-'}, ${date || '-'}, ${year || '-'}`
  return time
}

export const checkDueDate = (dueDate: string) => {
  var date = new Date(dueDate)
  return new Date() > date
}

export const formatDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd MMM yyyy') : 'NA'
}

export const formatDashDate = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'yyyy-MM-dd') : 'NA'
}

export const dateFormat = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'dd/MM/yyyy') : 'NA'
}

export const formatTime24Hour = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'HH:mm') : 'NA'
}

export const formatTime = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'hh:mm a') : 'NA'
}

export const getDatePlusDay = (date, addDay) => {
  let newDate = new Date(date)
  newDate.setDate(newDate.getDate() + addDay)
  return newDate
}

export const formatYear = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'yyyy') : 'NA'
}

export const formatMonthYear = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'M-yyyy') : 'NA'
}

export const formatMonth = (date: string) => {
  const result = parseISO(date)
  return date ? format(result, 'MMM yyyy') : 'NA'
}

export const formatDateOnlyWithoutTz = (date: string) => {
  const monthArr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]
  const dt = new Date(date)

  const year = dt.getUTCFullYear()
  const monthIndex = dt.getUTCMonth() // Date provides month index; not month number
  const day = dt.getUTCDate()

  function padToTwo(number) {
    return number > 9 ? number : '0' + number
  }

  return `${padToTwo(day)} ${monthArr[monthIndex]} ${year}`
}

export const formatDateWithTz = (date: string) => {
  const dt = new Date(date)
  const result = new Date(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000)
  return date ? format(result, 'dd MMM yyyy') : 'NA'
}
