import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { format } from 'date-fns'
import React from 'react'

export const Table2GL = (props: any) => {
  // const { docDate, docNo, description, dueDate, reminderAmt } = props;
  const { data, docType, getUser } = props
  // console.log(docType, 'dataa') not deleting this cause want to check the data 11/8/2023

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 10
  }
  ///// style border table
  const thright = {
    width: '10%',
    textAlign: 'right' as 'right',
  }

  let itemName

  switch (docType) {
    case 'journal-processing':
      itemName = 'JournalProcessingItem'
      break
    case 'recurring-journal':
      itemName = 'RecurringJournalItem'
      break
    case 'audit-adjustment':
      itemName = 'AuditAdjustmentItem'
      break
  }

  return (
    <>
      <div>
        <table
          style={{
            margin: '10px',
            width: '100%',
            fontSize,
          }}
        >
          <tr>
            <th
              style={{ fontWeight: 'normal', textAlign: 'left', width: '33%' }}
            >
              <div
                style={{
                  border: '0.5px solid',
                  marginRight: '10px',
                }}
              >
                <span>
                  <b>Prepared By:</b>

                  <br />
                  <br />
                  {getUser
                    ?.filter(v => v?.ID === data?.createdBy)
                    .map(v => {
                      return v?.name
                    })}
                  <br />
                  <b>Date: </b>
                  {format(new Date(data?.createdTs), 'dd/MM/yyyy')}
                </span>
              </div>
            </th>
            {!!data?.submittedBy ? (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                }}
              >
                <div
                  style={{
                    border: '0.5px solid',
                  }}
                >
                  <span>
                    <b>Submitted By:</b>

                    <br />
                    <br />
                    {getUser
                      ?.filter(v => v?.ID === data?.submittedBy)
                      .map(v => {
                        return v?.name
                      })}
                    <br />
                    <b>Date: </b>
                    {format(new Date(data?.submittedTs), 'dd/MM/yyyy')}
                  </span>
                </div>
              </th>
            ) : (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                }}
              ></th>
            )}
            {!!data?.approvedBy ? (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                }}
              >
                <div
                  style={{
                    border: '0.5px solid',
                    marginLeft: '10px',
                    marginRight: '15px',
                  }}
                >
                  <span>
                    <b>Approved By:</b>

                    <br />
                    <br />
                    {getUser
                      ?.filter(v => v?.ID === data?.approvedBy)
                      .map(v => {
                        return v?.name
                      })}
                    <br />
                    <b>Date: </b>
                    {format(new Date(data?.approvedTs), 'dd/MM/yyyy')}
                  </span>
                </div>
              </th>
            ) : (
              <th
                style={{
                  fontWeight: 'normal',
                  textAlign: 'left',
                  width: '33%',
                  marginLeft: '15px',
                }}
              ></th>
            )}
          </tr>
        </table>
      </div>
    </>
  )
}

export default Table2GL
