import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { format } from 'date-fns'
import React from 'react'

export const TableCBPV = (props: any) => {
  // const { docDate, docNo, description, dueDate, reminderAmt } = props;
  const { data, docType, item } = props
  // console.log(docType, 'dataa') not deleting this cause want to check the data 11/8/2023

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()
  console.log(data)
  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 10
  }
  ///// style border table
  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }
  const thleftnarrow = {
    width: '10%',
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }

  const thcenter = {
    width: '10%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  }

  const tdright = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
  }

  const tdleft = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
  }

  const tdcenteramt = {
    textAlign: 'center' as 'center',
  }

  return (
    <>
      <div style={{ marginTop: '20px' }}>
        <table
          style={{
            width: '100%',
            fontSize,
            // borderCollapse: 'collapse',
            borderSpacing: '0 1em',
          }}
        >
          <tr>
            <th style={thleftnarrow}>Doc Date</th>
            <th style={thleftnarrow}>Doc No</th>
            <th style={thleft}>Remark</th>
            <th style={thcenter}>Tax Rate</th>
            <th style={thcenter}>
              Base Amt
              <br />
              <span>(RM)</span>
            </th>
            <th style={thcenter}>
              Tax Amt
              <br />
              <span>(RM)</span>
            </th>
            <th style={thcenter}>
              Total Amt
              <br />
              <span>(RM)</span>
            </th>
          </tr>
          {item?.map((v, i) => (
            <>
              <tr>
                <td style={{ textAlign: 'left' }}>
                  {v?.DocDate
                    ? format(
                        new Date(v?.DocDate ?? v?.DebitDocDate),
                        'dd/MM/yyyy'
                      )
                    : 'NA'}
                </td>
                <td style={{ textAlign: 'left' }}>{v?.DocNo ?? 'NA'}</td>
                <td style={{ textAlign: 'left' }}>{v?.Remark ?? 'NA'}</td>
                <td style={tdcenteramt}>{`${v?.TaxRate}%`}</td>
                <td style={tdcenteramt}>{amtStr(v?.Amount)}</td>
                <td style={tdcenteramt}>{amtStr(v?.TaxAmt)}</td>
                <td style={tdcenteramt}>{amtStr(v?.DocAmt)}</td>
              </tr>
            </>
          ))}
        </table>
      </div>
      <div>
        <table style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}>
          <tr>
            <td style={tdleft}>{`Total Amount (RM)`}</td>

            <td style={tdright}>{amtStr(data?.DocAmt)}</td>
          </tr>
        </table>
      </div>
    </>
  )
}

export default TableCBPV
