import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { APDebitNoteAllocation } from './APDebitNoteAllocation/APDebitNoteAllocation'
import { APDebitNoteDetail } from './APDebitNoteDetail/APDebitNoteDetail'
import { APDebitNoteStateForm } from './APDebitNoteForm/APDebitNoteStateForm'
import { APDebitNoteMain } from './APDebitNoteMain'
import { APMain } from '../APMain'
import { APDetail } from '../APDetail/APDetail'

const debitNoteRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/debit-note/filter',
    },
    component: (
      <AdvancedFilter
        mode="debit-note"
        app="account-payable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/debit-note',
    },
    // component: (
    //   <APDebitNoteMain accountType="account-payable" docType="debitNote" />
    // ),

    component: (
      <APMain
        apSubmenu="debit-note"
        routeSegments="Debit Note"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/debit-note/:DocumentID/detail',
    },
    component: (
      <APDetail
        apSubmenu="debit-note"
        routeSegments="Debit Note"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                ALLOCATION                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/debit-note/:DebitNoteID/allocation',
    },
    component: <APDebitNoteAllocation />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/debit-note/:formMode`,
    },
    component: (
      <APDebitNoteStateForm
        accountType="account-payable"
        docType="debit-note"
        routeSegments="Debit Note"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/debit-note/:DebitNoteID/edit`,
    },
    component: (
      <APDebitNoteStateForm
        accountType="account-payable"
        docType="debit-note"
        routeSegments="Debit Note"
        formMode="edit"
      />
    ),
  },
]

export default debitNoteRoutes
