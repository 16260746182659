import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { Delete, SubdirectoryArrowRightOutlined } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import AddBoxIcon from '@material-ui/icons/AddBox'
import InfoIcon from '@material-ui/icons/Info'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import { CopyrightFooter } from 'components/Footer/Copyright'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetCostCentreDefinitionListingDocument,
  useCreateCostCentreDefinitionMutation,
  useDeleteCostCentreDefinitionMutation,
  useGetCostCentreDefinitionLazyQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import AddIcon from '@material-ui/icons/Add'
import { uuid } from 'uuidv4'
import { CustomTreeItem } from '../MasterCOA/RoleTreeStyle'
import { TreeView } from '@material-ui/lab'

export interface CostCentreDefinitionProps {
  title: string
  HierarchyItems: any
}

export const CostCentreDefinitionFormv2 = (props: any) => {
  const { mode } = props
  const { CostCentreDefinitionHeaderID }: any = useParams()
  let location = useLocation()
  const editData: any = location?.state
  let history = useHistory()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { state }: any = useLocation()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  console.log('editData', editData)

  // // Company Hierarchy v2
  const [level, setLevel] = useState(0)
  // const [openSnackBar, setOpenSnackBar] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(true)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [treeCCDefinition, setTreeCCDefinition] = useState<any>([])
  const [currentCompanyID, setCurrentCompanyID] = useState(
    state?.Company?.CompanyID || state?.CompanyID
  )
  const [pageMode, setPageMode] = useState('')
  const pageAction = state?.getCostCentreDefinitionListing ? 'Edit' : 'New'
  let cCDArray = []
  const [currentCompanyName, setCurrentCompanyName] = useState('')

  const CostCentreDefinitionFormSchema = yup.object().shape({
    title: CommonYupValidation.requireField(SystemMsgs.title()),
  })

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    setError,
    getValues,
    watch,
  } = useForm<CostCentreDefinitionProps>({
    defaultValues: {
      title: editData?.Title,
    },
    shouldUnregister: false, // unmount input state will be remained
    resolver: yupResolver(CostCentreDefinitionFormSchema),
  })
  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'HierarchyItems',
  })

  // // get CCD Listing
  const [
    loadCostCentreDefinition,
    {
      data: { getCostCentreDefinition } = {
        getCostCentreDefinition: {},
      },
      loading: getCostCentreDefinitionLoading,
    },
  ] = useGetCostCentreDefinitionLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getCostCentreDefinition }) => {
      const header = getCostCentreDefinition?.CostCentreDefinitionHeader
      const details = getCostCentreDefinition?.CostCentreDefinitionDetail

      //setValue('title', header?.Title)
      let ccTree = []
      if (details?.length > 0) {
        details?.map((e, index) => {
          ccTree.push({
            CostCentreDefinitionDetailID: e?.CostCentreDefinitionDetailID,
            ParentCostCentreDefinitionDetailID:
              e?.ParentCostCentreDefinitionDetailID,
            Name: e?.Name,
            Level: e?.Level,
          })
        })
        append(ccTree)
      }
    },
  })

  const [
    createCostCentreDefinition,
    { loading: mutationLoading, error: mutationError },
  ] = useCreateCostCentreDefinitionMutation({
    onError: error => {
      // snackBar(error.message)
    },
    onCompleted: data => {
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setOpenSnackBar(true)
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/general-setting/cost-centre-definition`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const [
    deleteCostCentreDefinition,
    { loading: deleteLoading, error: deleteError },
  ] = useDeleteCostCentreDefinitionMutation({
    onError: error => {
      // snackBar(error.message)
    },
    onCompleted: data => {
      setSnackBarMsg(SystemMsgs.deleteRecord())
      setOpenSnackBar(true)
      setTimeout(() => {
        history.push({
          pathname: `/general-ledger/general-setting/cost-centre-definition`,
          state: { success: true, msgMode: 'delete' },
        })
      }, 500)
    },
  })

  useEffect(() => {
    if (mode === 'Edit') {
      loadCostCentreDefinition({
        variables: { CostCentreDefinitionHeaderID },
      })
    } else {
      prepend({
        CostCentreDefinitionDetailID: uuid(),
        ParentCostCentreDefinitionDetailID: null,
        Name: '',
        Level: 1,
      })
    }
  }, [mode])

  //insert
  const onSubmit = data => {
    const costCentreDefinitionList = []
    console.log('datassss', data)

    for (var i = 0; i < data?.HierarchyItems?.length; i++) {
      costCentreDefinitionList.push({
        CostCentreDefinitionDetailID:
          data?.HierarchyItems[i]?.CostCentreDefinitionDetailID,
        ParentCostCentreDefinitionDetailID:
          data?.HierarchyItems[i]?.ParentCostCentreDefinitionDetailID,
        Name: data?.HierarchyItems[i]?.Name,
        Level: data?.HierarchyItems[i]?.Level,
      })
    }
    console.log('costCentreDefinitionList', costCentreDefinitionList)

    createCostCentreDefinition({
      variables: {
        CostCentreDefinitionHeaderInput: {
          ...(mode === 'Edit'
            ? {
                CostCentreDefinitionHeaderID:
                  getCostCentreDefinition?.CostCentreDefinitionHeader
                    ?.CostCentreDefinitionHeaderID,
              }
            : null),
          Title: data?.title,
        },
        CostCentreDefinitionDetailInput: costCentreDefinitionList?.map(
          (x, index) => {
            return {
              CostCentreDefinitionDetailID: x?.CostCentreDefinitionDetailID,
              ParentCostCentreDefinitionDetailID:
                x?.ParentCostCentreDefinitionDetailID,
              Level: x?.Level,
              Name: x?.Name,
              IsLastNode:
                costCentreDefinitionList?.filter(
                  el =>
                    el?.ParentCostCentreDefinitionDetailID ===
                    x?.CostCentreDefinitionDetailID
                )?.length > 0
                  ? false
                  : true,
            }
          }
        ),
      },
      refetchQueries: [{ query: GetCostCentreDefinitionListingDocument }],
    })
  }

  // delete
  const deleteCCDef = () => {
    deleteCostCentreDefinition({
      variables: {
        CostCentreDefinitionHeaderID: CostCentreDefinitionHeaderID,
      },
      refetchQueries: [
        {
          query: GetCostCentreDefinitionListingDocument,
        },
      ],
    })
  }

  const TheCostCentreType = [
    { id: 1, Name: 'Company', value: 'Company' },
    { id: 2, Name: 'Region', value: 'Region' },
    { id: 3, Name: 'Department', value: 'Department' },
    { id: 4, Name: 'Division', value: 'Division' },
    { id: 5, Name: 'Team', value: 'Team' },
    { id: 6, Name: 'Placeholder', value: 'Placeholder' },
  ]

  console.log('Data in fields: ', fields)
  console.log(mode, 'MODE')
  console.log(watch('HierarchyItems'), 'HierarchyItems')

  useEffect(() => {
    if (fields?.length > 0) {
      let initialArr = []

      let level = fields.map(v => v.Level).sort((a, b) => b - a)[0]

      initializeLevel(1, fields, initialArr, level, level)

      setTreeCCDefinition(initialArr)
    }
    console.log('UPDATED FIELD', fields)
  }, [fields])

  const initializeLevel = (level, data, arr, maxLevel, prtID) => {
    const dataToPush = data
      ?.filter(v => {
        if (v.ParentCostCentreDefinitionDetailID !== null) {
          return (
            v.Level === level && v.ParentCostCentreDefinitionDetailID === prtID
          )
        } else {
          return v.Level === level
        }
      })
      .map(x => {
        return {
          Name: x?.Name,
          CostCentreDefinitionDetailID: x?.CostCentreDefinitionDetailID,
          ParentCostCentreDefinitionDetailID:
            x?.ParentCostCentreDefinitionDetailID,
          Level: x?.Level,

          child: [],
        }
      })

    arr.push(...dataToPush)

    if (level < maxLevel) {
      arr.map(k => {
        initializeLevel(
          level + 1,
          data,
          k.child,
          maxLevel,
          k.CostCentreDefinitionDetailID
        )
      })
    }
  }

  const removeCCDef = CCID => {
    fields?.map((x, index) => {
      if (x?.CostCentreDefinitionDetailID === CCID) {
        remove(index)
      }
    })
  }

  const getCCDIndex = CCID => {
    const dataID = fields.findIndex(
      el => el.CostCentreDefinitionDetailID === CCID
    )
    return dataID
  }

  const renderTree = (nodes, index) => (
    <CustomTreeItem
      key={nodes?.CostCentreDefinitionDetailID}
      nodeId={nodes?.CostCentreDefinitionDetailID}
      label={
        <>
          <Box
            p={0}
            pr={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingRight:
                nodes?.CostCentreDefinitionDetailID === null ? '46px' : null,
            }}
          >
            {nodes?.Level === 1 ? null : (
              <SubdirectoryArrowRightOutlined
                fontSize="inherit"
                style={{
                  fontSize: '25px',
                  marginTop: '-12px',
                  //   paddingLeft:
                  //     nodes?.Level ===
                  //     getCostCentreDefinitionAsgmt[0]?.CostCentreDefinitionHeader
                  //       ?.CostCentreDefinitionDetail?.length
                  //       ? '25px'
                  //       : null,
                }}
              />
            )}
            <span style={{ marginRight: '6px' }}>
              <AddBoxIcon
                style={{
                  color: theme.palette.primary.main,
                }}
                onClick={() => {
                  append({
                    CostCentreDefinitionDetailID: uuid(),
                    ParentCostCentreDefinitionDetailID:
                      nodes?.CostCentreDefinitionDetailID,
                    Name: '',
                    Level: nodes?.Level + 1,
                  })
                }}
              />
            </span>

            <span
              style={{
                width: '100%',
                paddingTop: '5px',
              }}
            >
              <Controller
                ref={register}
                name={`HierarchyItems[${getCCDIndex(
                  nodes?.CostCentreDefinitionDetailID
                )}].Name`}
                control={control}
                defaultValue={nodes?.Name}
                InputProps={{
                  disabled: nodes?.Level === 0 ? true : false,
                }}
                onChangeName="onValueChange"
                select
                multiline={true}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    select
                    name={`HierarchyItems[${fields.findIndex(
                      el =>
                        el.CostCentreDefinitionDetailID ===
                        nodes?.CostCentreDefinitionDetailID
                    )}].Name`}
                    ref={register}
                    defaultValue={nodes?.Name}
                    className="qty-input-new"
                    // className='shift-input'
                    size="small"
                    style={
                      {
                        // display: nodes?.Level === 0 ? 'none' : null,
                      }
                    }
                    variant="outlined"
                    InputProps={{
                      disabled: nodes?.Level === 0 ? true : false,
                    }}
                    onChange={e => {
                      setValue(
                        `HierarchyItems[${getCCDIndex(
                          nodes?.CostCentreDefinitionDetailID
                        )}].Name`,
                        e.target.value
                      )
                      // watch(`HierarchyItems[${index}].name`)
                    }}
                  >
                    {TheCostCentreType?.map((el, index) => (
                      <MenuItem key={index} value={el.Name}>
                        {`${el.Name}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Controller
                // as={<TextField />}
                inputRef={register}
                name={`HierarchyItems[${getCCDIndex(
                  nodes?.CostCentreDefinitionDetailID
                )}].Level`}
                control={control}
                defaultValue={nodes?.Level}
                InputProps={{
                  disabled: nodes?.Level === 0 ? true : false,
                }}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    name={`HierarchyItems[${fields.findIndex(
                      el =>
                        el.CostCentreDefinitionDetailID ===
                        nodes?.CostCentreDefinitionDetailID
                    )}].Level`}
                    ref={register}
                    defaultValue={nodes?.Level}
                    style={{ display: 'none' }}
                    variant="filled"
                    InputProps={{
                      disabled: nodes?.Level === 0 ? true : false,
                    }}
                    onChange={e => {
                      setValue(
                        `HierarchyItems[${getCCDIndex(
                          nodes?.CostCentreDefinitionDetailID
                        )}].Level`,
                        e.target.value
                      )
                    }}
                  ></TextField>
                )}
              />
              <Controller
                // as={<TextField />}
                inputRef={register}
                name={`HierarchyItems[${getCCDIndex(
                  nodes?.CostCentreDefinitionDetailID
                )}].CostCentreDefinitionDetailID`}
                control={control}
                defaultValue={nodes?.CostCentreDefinitionDetailID}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    name={`HierarchyItems[${fields.findIndex(
                      el =>
                        el.CostCentreDefinitionDetailID ===
                        nodes?.CostCentreDefinitionDetailID
                    )}].CostCentreDefinitionDetailID`}
                    ref={register}
                    defaultValue={nodes?.CostCentreDefinitionDetailID}
                    style={{ display: 'none' }}
                    variant="filled"
                    onChange={e => {
                      setValue(
                        `HierarchyItems[${getCCDIndex(
                          nodes?.CostCentreDefinitionDetailID
                        )}].CostCentreDefinitionDetailID`,
                        e.target.value
                      )
                    }}
                  ></TextField>
                )}
              />
              <Controller
                // as={<TextField />}
                inputRef={register}
                name={`HierarchyItems[${getCCDIndex(
                  nodes?.CostCentreDefinitionDetailID
                )}].ParentCostCentreDefinitionDetailID`}
                control={control}
                defaultValue={nodes?.ParentCostCentreDefinitionDetailID}
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    name={`HierarchyItems[${fields.findIndex(
                      el =>
                        el.CostCentreDefinitionDetailID ===
                        nodes?.CostCentreDefinitionDetailID
                    )}].ParentCostCentreDefinitionDetailID`}
                    ref={register}
                    defaultValue={nodes?.ParentCostCentreDefinitionDetailID}
                    style={{ display: 'none' }}
                    variant="filled"
                    onChange={e => {
                      setValue(
                        `HierarchyItems[${getCCDIndex(
                          nodes?.CostCentreDefinitionDetailID
                        )}].ParentCostCentreDefinitionDetailID`,
                        e.target.value
                      )
                    }}
                  ></TextField>
                )}
              />
            </span>

            <Typography variant="caption" color="inherit">
              {!fields?.find(
                x =>
                  x?.ParentCostCentreDefinitionDetailID ===
                  nodes?.CostCentreDefinitionDetailID
              ) && nodes?.Level > 1 ? (
                <IconButton
                  className="delete-icon "
                  onClick={() => {
                    removeCCDef(nodes?.CostCentreDefinitionDetailID)
                  }}
                >
                  <Box
                    borderRadius="borderRadius"
                    style={{ backgroundColor: 'red' }}
                    width="20px"
                    height="20px"
                    marginTop="-5px"
                  >
                    <DeleteIcon
                      style={{
                        color: 'white',
                        padding: 0,
                        fontSize: '16px',
                        zIndex: 5,
                        paddingBottom: '4px',
                      }}
                      fontSize="small"
                      // onClick={e => {
                      //   remove(index)
                      // }}
                    />
                  </Box>
                </IconButton>
              ) : (
                <Box
                  borderRadius="borderRadius"
                  style={{ backgroundColor: 'white' }}
                  width="43px"
                  height="20px"
                ></Box>
              )}
            </Typography>
          </Box>
        </>
      }
    >
      {Array.isArray(nodes.child)
        ? nodes.child.map((nodes, index) => renderTree(nodes, index))
        : null}
    </CustomTreeItem>
  )

  return (
    <>
      {getCostCentreDefinitionLoading && <Loading />}
      {mutationLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/general-ledger/general-setting/cost-centre-definition`)
        }
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Setting' },
          { name: 'General Setting' },
          { name: 'Department Definition', current: true },
        ]}
        // rightRouteSegments={[{ name: pageMode, current: true }]}
        rightRouteSegments={[
          { name: mode === 'Add' ? 'New' : mode === 'Edit' ? 'Edit' : null },
        ]}
      />

      <ContentWrapper footer>
        <CardContents>
          <Controller
            as={TextField}
            name="title"
            label="Title"
            required
            control={control}
            fullWidth
            defaultValue={
              getCostCentreDefinition?.CostCentreDefinitionHeader?.Title ?? ''
            }
            helperText={errors?.title?.message}
            error={errors?.title ? true : false}
            ref={register}
          />
        </CardContents>
        <div>
          <div className="message-alert text-noflow">
            <div style={{ width: '100%' }}>
              <Grid container>
                <Grid item xs={'auto'} style={{ marginTop: '6.5px' }}>
                  <span className={`desc icon-text text-noflow`}>
                    <InfoIcon style={{ color: 'orange' }} />
                  </span>
                </Grid>
                <Grid item xs={11}>
                  <div
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {' '}
                    <span
                      className={`desc text-noflow`}
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        className="desc text-noflow"
                        style={{
                          fontSize: '12px',
                          marginRight: '3px',
                          width: '31px',
                        }}
                      >
                        Click
                      </span>
                      {
                        <span>
                          <AddBoxIcon
                            style={{
                              color: theme.palette.primary.main,
                              marginBottom: '-7px',
                            }}
                          />
                          {/* <AddBoxIcon
                            style={{ color: '#2f53e6', marginBottom: '-7px' }}
                          /> */}
                        </span>
                      }
                      <span
                        className="desc text-noflow"
                        style={{ fontSize: '12px' }}
                      >
                        {''} to add the{' '}
                        <span
                          className="c-darkblack xxTitle text-noflow"
                          style={{ fontSize: '12px' }}
                        >
                          next level
                        </span>{' '}
                        of Department Definition
                      </span>
                    </span>
                  </div>

                  <div>
                    {' '}
                    <span
                      className={`desc text-noflow`}
                      style={{
                        color: 'black',
                        //paddingLeft: '19px',
                        paddingTop: '10px',
                      }}
                    >
                      <span
                        className="desc text-noflow"
                        style={{
                          fontSize: '12px',
                          marginRight: '6px',
                          width: '31px',
                        }}
                      >
                        Click
                      </span>
                      {
                        <span
                          style={{
                            backgroundColor: '#FF1B40',
                            borderRadius: '3px',
                            width: '20px',
                            height: '20px',
                            alignItems: 'center',
                            padding: '2px 1.5px 2px 0.5px',
                          }}
                        >
                          <DeleteIcon
                            style={{
                              color: 'white',
                              fontSize: '15px',
                              zIndex: 5,
                              paddingLeft: '1.5px',
                              //paddingTop: '1.5px',
                              marginBottom: '-4px',
                            }}
                            fontSize="small"
                          />
                        </span>
                      }
                      <span
                        className="desc text-noflow"
                        style={{ fontSize: '12px', paddingLeft: '3px' }}
                      >
                        {''} to delete the{' '}
                        <span
                          className="c-darkblack xxTitle text-noflow"
                          style={{ fontSize: '12px' }}
                        >
                          current level
                        </span>{' '}
                        of Department Definition
                      </span>
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <CardContents
            section={{
              header: {
                title: 'Hierarchy',
                // onClickAction: () => [
                //   append({
                //     Name: '',
                //     Level: fields?.length + 1,
                //   }),
                // ],
                // icon: (
                //   <AddBoxIcon
                //     style={{
                //       color: theme.palette.primary.main,
                //       marginBottom: '-7px',
                //     }}
                //   />
                // ),
              },
            }}
          >
            {fields?.length === 0 && treeCCDefinition?.length === 0 ? (
              <Grid container justify="center">
                <EmptyList
                  title="No Record found"
                  subtitle="Add a new record now."
                />
              </Grid>
            ) : (
              <>
                <TreeView
                  className="tree-view-custom"
                  expanded={fields?.map(x => x?.CostCentreDefinitionDetailID)}
                  // defaultCollapseIcon={<MinusSquare />}
                  //defaultExpandIcon={<PlusSquare />}
                  // defaultEndIcon={<DotIcon />}
                  multiSelect
                  // onNodeToggle={handleToggle}
                  // expanded={expanded}
                >
                  {treeCCDefinition.map((el, index) => {
                    console.log('test', el)
                    return renderTree(el, index)
                  })}
                </TreeView>
              </>
            )}
          </CardContents>
        </div>
      </ContentWrapper>
      {mode === 'Add' ? (
        <Footer
          options={[
            {
              name: 'Save',
              onClick: () => handleSubmit(onSubmit)(),
              color: 'primary',
            },
          ]}
        />
      ) : (
        <Footer
          options={[
            {
              name: 'Delete',
              onClick: () => deleteCCDef(),
              color: 'secondary',
            },
            {
              name: 'Save',
              onClick: () => handleSubmit(onSubmit)(),
              color: 'primary',
            },
          ]}
        />
      )}
    </>
  )
}
