import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { CopyrightFooter } from 'components/Footer/Copyright'
import AppContext from 'containers/App/Store/AppContext'
import {
  AcctPermission,
  useGetCompanyNameQuery,
  useGetWarehouseNameQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'

export const ICDigitalReportSubmenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { WarehouseID }: any = useParams()
  const { handlePermHidden } = usePermissionChecker()

  // ACCOUNTX API CALLS
  const {
    loading: WarehouseLoading,
    error: WarehouseError,
    data: { getWarehouse } = { getWarehouse: [] },
  } = useGetWarehouseNameQuery({
    variables: { WarehouseID },
    fetchPolicy: 'network-only',
  })

  const submenuList = [
    {
      path: `/inventory-control/${WarehouseID}/digital-report/stock-ledger-by-location-report/parameters`,
      title: `Stock Ledger`,
      permission:
        AcctPermission.InventoryControlDigitalReportsStockLedgerByLocationView,
    },
    {
      path: `/inventory-control/${WarehouseID}/digital-report/stock-balance-by-location-report/parameters`,
      title: `Stock Balance`,
      permission:
        AcctPermission.InventoryControlDigitalReportsStockBalanceByLocationView,
    },
  ]

  return (
    <>
      {WarehouseLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/inventory-control/${WarehouseID}`)}
        smTitle={'Inventory Control'}
        title={getWarehouse[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Digital Reports', current: true },
        ]}
      />
      <ContentWrapper float>
        <List className="core-list">
          {submenuList.map((el, index) => {
            if (
              handlePermHidden({
                // companyID: CompanyID,
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem key={index} onClick={() => history.push(el?.path)}>
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={<span className="xsTitle">{el?.title}</span>}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: el.path,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
