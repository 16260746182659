import { yupResolver } from '@hookform/resolvers'
import { GET_LOCAL_BANK } from 'containers/AccountModule/GraphQL/AccountQuery'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SubscriptionClient } from 'SubscriptionClient'
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import { AddressAutoComponent } from 'components/AddressAutocomplete/AddressComponent'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'

import {
  CreditorStatus,
  CreditorType,
  GetCreditorAccountDocument,
  useCreateCreditorAccountMutation,
  useGetCreditorTypeQuery,
  useGetTaxSchemeQuery,
  useUpdateCreditorAccountWithItemMutation,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useAddressAutoComplete } from 'helpers/Hooks/useAddressAutoComplete'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
interface CreditorProfileProps {
  companyRegNo: string
  companyName: string
  taxIdentificationNo: string
  creditorType: string
  phoneNo: number
  email: string
  creditTerms: string
  tax: string
  region: string
  SWIFTCode: any
  PayeeName: string
  GSTRegNo: string
  remark: string
  bankAccNo: string
  bankName: string
  Contact: any
  Director: any
}

export const CreditorProfileForm = (props: any) => {
  const { mode }: any = props
  const { creditorType, CreditorAccountID }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [country, setCountry] = useState('')
  const [, setState] = useState('')
  const [, setCity] = useState('')
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openDirectorDialog, setOpenDirectorDialog] = useState<boolean>(false)
  const [contactData, setContactData] = useState([])
  const [directorData, setDirectorData] = useState([])
  const [checkContactError, setCheckContactError] = useState([])
  const [checkDirectorError, setCheckDirectorError] = useState([])
  const [submitMode, setSubmitMode] = useState<string>()
  const [openExitConf, setOpenExitConf] = useState(null)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  let location = useLocation()
  const editData = location?.state as any

  const CreditorProfileSchema = yup.object().shape({
    companyRegNo: CommonYupValidation.requireField(SystemMsgs.companyRegNo()),
    creditorType: CommonYupValidation.requireField(SystemMsgs.creditorType()),
    companyName: CommonYupValidation.requireField(SystemMsgs.companyName()),
    creditTerms: CommonYupValidation.requireField(SystemMsgs.creditTerms()),
    // taxIdentificationNo: CommonYupValidation.requireField(
    //   SystemMsgs.taxIdentificationNo()
    // ),
  })

  let typeOfCreditor = type => {
    let temp
    switch (type) {
      case 'supplier':
        temp = CreditorType.Supplier
        break
      case 'contractor':
        temp = CreditorType.Contractor
        break
      case 'consultant':
        temp = CreditorType.Consultant
        break
      case 'agent':
        temp = CreditorType.Agent
        break
    }
    return temp
  }

  let creditorName = type => {
    let temp
    switch (type) {
      case 'supplier':
        temp = 'Supplier'
        break
      case 'contractor':
        temp = 'Contractor'
        break
      case 'consultant':
        temp = 'Consultant'
        break
      case 'agent':
        temp = 'Agent'
        break
    }
    return temp
  }

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
    formState: { isSubmitted, isDirty },
  } = useForm<CreditorProfileProps>({
    mode: 'onSubmit',
    resolver: yupResolver(CreditorProfileSchema),
  })

  // const hasChanges = () => {
  //   const hasInfo =
  //     (watch('companyRegNo') !== '' && watch('companyRegNo') !== undefined) ||
  //     (watch('companyName') !== '' && watch('companyName') !== undefined) ||
  //     (watch('contactNo') !== '' && watch('contactNo') !== undefined) ||
  //     (watch('creditTerms') !== '' && watch('creditTerms') !== undefined) ||
  //     (watch('tax') !== '' && watch('tax') !== undefined) ||
  //     (watch('region') !== '' && watch('region') !== undefined) ||
  //     (watch('GSTRegNo') !== '' && watch('GSTRegNo') !== undefined) ||
  //     (watch('remark') !== '' && watch('remark') !== undefined) ||
  //     (watch('bankName') !== '' && watch('bankName') !== undefined) ||
  //     (watch('bankAccNo') !== '' && watch('bankAccNo') !== undefined) ||
  //     (watch('phoneNo') !== null && watch('phoneNo') !== undefined) ||
  //     (watch('creditorType') !== '' && watch('creditorType') !== undefined) ||
  //     (watch('creditorStatus') !== '' &&
  //       watch('creditorStatus') !== undefined) ||
  //     (watch('email') !== '' && watch('email') !== undefined)

  //   let prevData = {
  //     companyRegNo: editData?.CompanyRegNo,
  //     companyName: editData?.CompanyName,
  //     contactNo: editData?.ContactNo,
  //     creditTerms: editData?.CreditTerm,
  //     taxSchemeID: editData?.TaxSchemeID,
  //     region: editData?.Region,
  //     GSTRegNo: editData?.GSTRegNo,
  //     remark: editData?.Remark,
  //     bankName: editData?.BankName,
  //     bankAccountNo: editData?.BankAccountNo,
  //     creditorTypeEnum: editData?.CreditorTypeEnum,
  //     creditorStatus: editData?.CreditorStatus,
  //     email: editData?.Email,
  //   }
  //   let currentData = {
  //     companyRegNo: watch('companyRegNo'),
  //     companyName: watch('companyName'),
  //     contactNo: watch('contactNo'),
  //     creditTerms: watch('creditTerms'),
  //     taxSchemeID: watch('TaxSchemeID'),
  //     region: watch('region'),
  //     GSTRegNo: watch('GSTRegNo'),
  //     remark: watch('remark'),
  //     bankName: watch('bankName'),
  //     bankAccountNo: watch('bankAccNo'),
  //     creditorTypeEnum: watch('creditorType'),
  //     creditorStatus: watch('creditorStatus'),
  //     email: watch('email'),
  //   }
  //   if (mode === 'edit') {
  //     if (JSON.stringify(prevData) === JSON.stringify(currentData)) return false
  //     else return true
  //   } else {
  //     if (hasInfo === true) return true
  //     else return false
  //   }
  // }

  useEffect(() => {
    if (mode === 'edit' && editData) {
      // setValue('creditorType', editData.CreditorTypeID)
      // setValue('tax', editData.TaxSchemeID)
      // setValue('bankName', e?.target?.value)
      const Contact = editData.CreditorContact?.map((el, index) => {
        return {
          name: el?.Name,
          phoneNo: el?.ContactNo,
          email: el?.Email,
          designation: el?.Designation,
        }
      })
      const Director = editData.CreditorDirector?.map((el, index) => {
        return {
          identityNo: el?.IdentityNo,
          identityType: el?.IdentityType,
          name: el?.Name,
          phoneNo: el?.ContactNo,
          email: el?.Email,
        }
      })

      contactData.push(...Contact)
      directorData.push(...Director)

      let array_of_address = [editData?.Address?.address] || []
      setQuery(array_of_address.join(', '))
      initializeEditValue({
        address: array_of_address.join(', '),
        //address: editData?.Address?.address,
        country: editData?.Address?.country,
        state: editData?.Address?.state,
        city: editData?.Address?.city,
        postCode: editData?.Address?.postCode,
      })
    }
  }, [mode, editData])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'HierarchyItems',
  })

  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    query,
    autoCompleteRef,
    state,
    setQuery,
    initializeEditValue,
  } = useAddressAutoComplete()

  const {
    loading: creditorTypeLoading,
    error: creditorTypeError,
    data: { getCreditorType } = { getCreditorType: [] },
  } = useGetCreditorTypeQuery({
    fetchPolicy: 'network-only',
    variables: { CreditorType: typeOfCreditor(creditorType) },
  })

  const {
    loading: taxLoading,
    error: taxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: {
      AccTaxClass: 'INPUT',
      orderByAsc: 'Code',
    },
    onCompleted: () => {},
  })

  // const {
  //   loading: bankProfileLoading,
  //   error: bankProfileError,
  //   data: { getBankProfile } = { getBankProfile: [] },
  // } = useGetBankProfileQuery({
  //   fetchPolicy: 'network-only',
  // })
  const {
    loading: localBankLoading,
    error: localBankError,
    data: { getLocalBank } = { getLocalBank: [] },
  } = useQuery(GET_LOCAL_BANK, {
    client: SubscriptionClient,
    variables: { orderByAsc: 'name' },
  })

  const [
    createCreditorAccount,
    { loading: createLoading, error: createError },
  ] = useCreateCreditorAccountMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-payable/general-setting/creditor-profile/${creditorType}`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const [
    updateCreditorAccount,
    { loading: updateLoading, error: updateError },
  ] = useUpdateCreditorAccountWithItemMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/detail`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
    },
  })

  const IdentityType = [
    { type: 'IDENTITY_CARD', name: 'Identity Card' },
    { type: 'MILITARY_CARD', name: 'Military Card' },
    { type: 'PASSPORT', name: 'Passport' },
    { type: 'BUSINESS_LICENSE', name: 'Business License' },
  ]

  const onCreateContact = data => {
    if (checkContact(watch('Contact'))?.length === 0) {
      contactData.push({
        name: data.contactName,
        phoneNo: data.mobileNo,
        email: data.emailAddress,
        designation: data.designation,
      })
    }
  }
  const onUpdateContact = (data, index) => {
    if (checkContact(watch('Contact'))?.length === 0) {
      contactData[index].name = data.contactName
      contactData[index].phoneNo = data.mobileNo
      contactData[index].email = data.emailAddress
      contactData[index].designation = data.designation
    }
  }
  const onDeleteContact = index => {
    contactData.splice(index, 1)
  }
  const onCreateDirector = data => {
    if (checkDirector(watch('Director'))?.length === 0) {
      directorData.push({
        identityNo: data.identityNo,
        identityType: data.identityType,
        name: data.contactName,
        phoneNo: data.mobileNo,
        email: data.emailAddress,
      })
    }
  }
  const onDeleteDirector = index => {
    directorData.splice(index, 1)
  }

  const onUpdateDirector = (index, data) => {
    if (checkDirector(watch('Director'))?.length === 0) {
      directorData[index].identityNo = data.identityNo
      directorData[index].identityType = data.identityType
      directorData[index].name = data.contactName
      directorData[index].phoneNo = data.mobileNo
      directorData[index].email = data.emailAddress
    }
  }

  const onSubmit = (data, state, contact, director) => {
    if (
      state.address &&
      state.state.name &&
      state.city.name &&
      state.country.name &&
      state.postcode.postcode
    ) {
      if (mode === 'add') {
        createCreditorAccount({
          variables: {
            input: {
              CreditorTypeID: data?.creditorType,
              CompanyRegNo: data?.companyRegNo,
              TaxIdentificationNo: data?.taxIdentificationNo,
              ContactNo: data?.phoneNo,
              CompanyName: data?.companyName,
              Email:
                data?.email === '' || data?.email === null ? null : data?.email,
              Address: {
                address: state.address,
                state: state.state.name,
                city: state.city.name,
                country: state.country.name,
                postCode: state.postcode.postcode,
              },
              CreditTerm: parseFloat(amtNumStr(data?.creditTerms)),
              TaxSchemeID: data?.tax,
              Region: data?.region,
              GSTRegNo: data?.GSTRegNo,
              Remark: data?.remark,
              BankName: data?.bankName,
              SWIFTCode: data?.SWIFTCode,
              PayeeName: data?.PayeeName,
              BankAccountNo: data?.bankAccNo,
              CreditorTypeEnum: getCreditorType?.find(
                x => x?.CreditorTypeID === data?.creditorType
              ).CreditorType,
              CreditorStatus: CreditorStatus.Active,
            },
            contactItem: contact?.map(y => {
              return {
                Name: y?.name,
                ContactNo: y?.phoneNo,
                Designation: y?.designation,
                Email: y?.email,
              }
            }),
            directorItem: director?.map(y => {
              return {
                IdentityNo: y?.identityNo,
                IdentityType: y?.identityType,
                Name: y?.name,
                ContactNo: y?.phoneNo,
                Email: y?.email,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetCreditorAccountDocument,
            },
          ],
        })
      } else if (mode === 'edit') {
        updateCreditorAccount({
          variables: {
            input: {
              CreditorAccountID: CreditorAccountID,
              CreditorTypeID: data?.creditorType,
              CompanyRegNo: data?.companyRegNo,
              TaxIdentificationNo: data?.taxIdentificationNo,
              ContactNo: data?.phoneNo,
              CompanyName: data?.companyName,
              Email:
                data?.email === '' || data?.email === null ? null : data?.email,
              Address: {
                address: state.address,
                state: state.state.name,
                city: state.city.name,
                country: state.country.name,
                postCode: state.postcode.postcode,
              },
              CreditTerm: parseFloat(amtNumStr(data?.creditTerms)),
              TaxSchemeID: data?.tax,
              Region: data?.region,
              GSTRegNo: data?.GSTRegNo,
              Remark: data?.remark,
              BankName: data?.bankName,
              BankAccountNo: data?.bankAccNo,
              SWIFTCode: data?.SWIFTCode,
              PayeeName: data?.PayeeName,
              CreditorTypeEnum: getCreditorType?.find(
                x => x?.CreditorTypeID === data?.creditorType
              ).CreditorType,
              CreditorStatus: CreditorStatus.Active,
            },
            contactItem: contact?.map(y => {
              return {
                Name: y?.name,
                ContactNo: y?.phoneNo,
                Designation: y?.designation,
                Email: y?.email,
              }
            }),
            directorItem: director?.map(y => {
              return {
                IdentityNo: y?.identityNo,
                IdentityType: y?.identityType,
                Name: y?.name,
                ContactNo: y?.phoneNo,
                Email: y?.email,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetCreditorAccountDocument,
              variables: { CreditorAccountID: CreditorAccountID },
            },
          ],
        })
      }
    }
  }

  const checkContact = data => {
    let errorData = []

    if (data.contactName === undefined || data.contactName === '') {
      errorData.push({
        error: 'contactName',
      })
    }
    // if (data.mobileNo === undefined || data.mobileNo === '') {
    //   errorData.push({
    //     error: 'mobileNo',
    //   })
    // }
    // if (data.emailAddress === undefined || data.emailAddress === '') {
    //   errorData.push({
    //     error: 'emailAddress',
    //   })
    // }
    if (data.designation === undefined || data.designation === '') {
      errorData.push({
        error: 'designation',
      })
    }
    // if (validateEmail(data.emailAddress) === false ) {
    //   errorData.push({
    //     error: 'invalidEmailAddress',
    //   })
    // }

    setCheckContactError(errorData)
    return errorData
  }
  const checkDirector = data => {
    let errorData = []

    // if (data.identityNo === undefined || data.identityNo === '') {
    //   errorData.push({
    //     error: 'identityNo',
    //   })
    // }
    // if (data.identityType === undefined || data.identityType === '') {
    //   errorData.push({
    //     error: 'identityType',
    //   })
    // }
    // if (data.contactName === undefined || data.contactName === '') {
    //   errorData.push({
    //     error: 'contactName',
    //   })
    // }
    // if (data.mobileNo === undefined || data.mobileNo === '') {
    //   errorData.push({
    //     error: 'mobileNo',
    //   })
    // }
    // if (data.emailAddress === undefined || data.emailAddress === '') {
    //   errorData.push({
    //     error: 'emailAddress',
    //   })
    // }
    // if (validateEmail(data.emailAddress) === false) {
    //   errorData.push({
    //     error: 'invalidEmailAddress',
    //   })
    // }
    setCheckDirectorError(errorData)
    return errorData
  }

  const validateEmail = email => {
    if (
      email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true
    } else {
      return false
    }
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: mode,
    })

  return (
    <>
      {taxLoading && <Loading />}
      {createLoading && <Loading />}
      {updateLoading && <Loading />}
      {localBankLoading && <Loading />}
      {creditorTypeLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          {
            hasChanges() === true
              ? setOpenExitConf(true)
              : mode === 'add'
              ? history.push(
                  `/account-payable/general-setting/creditor-profile/${creditorType}`
                )
              : history.push({
                  pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/detail`,
                })
          }
        }} //<- back button action
        smTitle={'Account Payable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Setting' },
          { name: 'Creditor Profile' },
          { name: 'Creditor Profiles', current: true },
        ]}
        rightRouteSegments={[
          { name: mode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      <ContentWrapper footer>
        <CardContents
          section={{
            header: { title: `${creditorName(creditorType)} Details` },
          }}
        >
          <Controller
            style={{ textOverflow: 'ellipsis' }}
            as={TextField}
            id="standard-basic"
            name="companyRegNo"
            label="Company Reg. No."
            required
            control={control}
            fullWidth
            className="left"
            margin="dense"
            helperText={errors?.companyRegNo?.message}
            error={errors?.companyRegNo ? true : false}
            ref={register}
            InputLabelProps={{ style: {} }}
            defaultValue={editData?.CompanyRegNo}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="taxIdentificationNo"
            label="Tax Id. No."
            // required
            autoComplete="off"
            control={control}
            fullWidth
            className="right"
            margin="dense"
            // helperText={errors?.taxIdentificationNo?.message}
            // error={errors?.taxIdentificationNo ? true : false}
            ref={register}
            defaultValue={editData?.TaxIdentificationNo}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="companyName"
            label="Company Name"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.companyName?.message}
            error={errors?.companyName ? true : false}
            ref={register}
            defaultValue={editData?.CompanyName}
          />

          <Controller
            render={({ onChange, value }) => (
              <TextField
                select
                name="creditorType"
                label="Creditor Type"
                onChange={e => {
                  setValue('creditorType', e?.target?.value)
                }}
                helperText={errors?.creditorType?.message}
                error={errors?.creditorType ? true : false}
                defaultValue={editData?.CreditorTypeID}
                required
              >
                {getCreditorType
                  ?.sort((a, b) => {
                    return a?.Description?.localeCompare(b?.Description)
                  })
                  ?.map((el, index) => (
                    <MenuItem
                      key={index}
                      value={el.CreditorTypeID}
                      style={{ whiteSpace: 'normal' }}
                    >
                      <span className="text-noflow">{el.Description}</span>
                    </MenuItem>
                  ))}
              </TextField>
            )}
            onChange={e => {
              setValue('creditorType', e?.target?.value)
            }}
            name="creditorType"
            label="Creditor Type"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            ref={register}
            select
            defaultValue={editData?.CreditorTypeID}
            required
          />

          <Controller
            as={
              <PhoneNumInput
                country={'my'}
                specialLabel="Phone No."
                fullWidth
              />
            }
            name="phoneNo"
            type="tel"
            marginTop
            marginBottom
            // helperText={errors?.phoneNo?.message}
            // error={errors?.phoneNo ? true : false}
            margin="normal"
            control={control}
            ref={register}
            defaultValue={editData?.ContactNo}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="email"
            label="Email Address"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={
              watch('email') === '' ||
              watch('email') === null ||
              watch('email') === undefined
                ? null
                : validateEmail(watch('email')) === false
                ? 'Email must be a valid email'
                : null
            }
            error={
              watch('email') === '' ||
              watch('email') === null ||
              watch('email') === undefined
                ? false
                : validateEmail(watch('email')) === false
                ? true
                : false
            }
            ref={register}
            defaultValue={
              editData?.Email === null ? undefined : editData?.Email
            }
          />

          <Controller
            as={
              <NumberFormat
                allowNegative={true}
                customInput={TextField}
                autoComplete="off"
                isNumericString
                label="Credit Terms"
                margin="dense"
              />
            }
            id="standard-basic"
            name="creditTerms"
            label="Credit Terms"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.creditTerms?.message}
            error={errors?.creditTerms ? true : false}
            ref={register}
            defaultValue={editData?.CreditTerm}
            className="left"
          />

          <Controller
            render={({ onChange, value }) => (
              <TextField
                select
                name="tax"
                label="Tax"
                className="right"
                style={{ marginTop: '5px' }}
                onChange={e => {
                  setValue('tax', e?.target?.value)
                }}
                // helperText={errors?.tax?.message}
                // error={errors?.tax ? true : false}
                defaultValue={editData?.TaxSchemeID}
              >
                {getTaxScheme?.map((el, index) => (
                  <MenuItem key={index} value={el.TaxSchemeID}>
                    {`${el.Code}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="tax"
            label="Tax"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            ref={register}
            select
            onChange={e => {
              setValue('tax', e?.target?.value)
            }}
            className="right"
            defaultValue={editData?.TaxSchemeID}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="region"
            label="Region"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            // helperText={errors?.region?.message}
            // error={errors?.region ? true : false}
            ref={register}
            defaultValue={editData?.Region}
            className="left"
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="GSTRegNo"
            label="GST Registration No."
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            // helperText={errors?.GSTRegNo?.message}
            // error={errors?.GSTRegNo ? true : false}
            ref={register}
            defaultValue={editData?.GSTRegNo}
            className="right"
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="remark"
            label="Remark"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            // helperText={errors?.remark?.message}
            // error={errors?.remark ? true : false}
            ref={register}
            defaultValue={editData?.Remark}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="bankAccNo"
            label="Bank Account No."
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            // helperText={errors?.bankAccNo?.message}
            // error={errors?.bankAccNo ? true : false}
            ref={register}
            defaultValue={editData?.BankAccountNo}
            className="left"
          />

          <Controller
            render={({ onChange, value }) => (
              <TextField
                style={{ marginTop: '5px' }}
                select
                name="bankName"
                label="Bank Name"
                className="right"
                onChange={e => {
                  setValue('bankName', e?.target?.value)
                }}
                // helperText={errors?.bankName?.message}
                // error={errors?.bankName ? true : false}
                defaultValue={editData?.BankName}
              >
                {getLocalBank?.map((el, index) => (
                  <MenuItem key={index} value={el?.name}>
                    {`${el?.name} | ${el?.code}`}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="bankName"
            className="right"
            label="Bank Name"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            ref={register}
            select
            onChange={e => {
              setValue('bankName', e?.target?.value)
            }}
            defaultValue={editData?.BankName}
          />

          <Controller
            as={TextField}
            name="SWIFTCode"
            label="SWIFT Code"
            autoComplete="off"
            className="left"
            margin="dense"
            multiline={true}
            fullWidth
            ref={register}
            control={control}
            // required
            // helperText={errors?.SWIFTCode?.message}
            // error={errors?.SWIFTCode ? true : false}
            defaultValue={editData?.SWIFTCode}
          ></Controller>

          <Controller
            as={TextField}
            id="standard-basic"
            name="PayeeName"
            label="Payee Name"
            autoComplete="off"
            className="right"
            control={control}
            fullWidth
            // required
            margin="dense"
            ref={register}
            // helperText={errors?.PayeeName?.message}
            // error={errors?.PayeeName ? true : false}
            defaultValue={editData?.PayeeName}
          />
        </CardContents>
        <CardContents section={{ header: { title: 'Address Info' } }}>
          <AddressAutoComponent
            getCountry={getCountry}
            getStatebyCountry={getStatebyCountry}
            getCitybyState={getCitybyState}
            getPostcodebyCity={getPostcodebyCity}
            handleCountryOnChange={handleCountryOnChange}
            handleStateOnChange={handleStateOnChange}
            handleCityOnChange={handleCityOnChange}
            handlePostCodeOnChange={handlePostCodeOnChange}
            handleGoogleOnChange={handleGoogleOnChange}
            autoCompleteRef={autoCompleteRef}
            state={state}
            country={country}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            formType={mode}
            isSubmitted={isSubmitted}
          />
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Contact Details',
              onClickAction: () => {
                resetMenu()
                setOpenDialog(true)
              },
              icon: (
                <AddIcon
                  htmlColor="white"
                  fontSize="small"
                  style={{
                    width: '20px',
                    height: '20px',
                    margin: '0',
                    background: theme.palette.primary.main,
                    borderRadius: '3px',
                    color: 'rgba(224,234,254,100)',
                    marginTop: '10px',
                    marginRight: '10px',
                  }}
                />
              ),
            },
          }}
        >
          {contactData?.length === 0 ? (
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmptyList
                title="No Contact Record(s)"
                subtitle="Click on the plus button to add records"
              />
            </div>
          ) : (
            contactData?.map((el, index) => {
              return (
                <List style={{ width: '100%', paddingBottom: '0px' }}>
                  <ListItem
                    key={index}
                    onClick={() =>
                      history.push({
                        pathname: el.path,
                      })
                    }
                    style={{ marginLeft: '-5px', marginTop: '-15px' }}
                  >
                    <ListItemText
                      primary={
                        <span className="desc xsTitle">
                          {el.name} ({el.designation})
                        </span>
                      }
                      secondary={
                        <span className="desc">
                          {!!el?.phoneNo ? (
                            <span>
                              <PhoneIcon
                                style={{
                                  fontSize: '10px',
                                  color: 'black',
                                  marginBottom: '-1px',
                                  marginRight: '3px',
                                }}
                              />

                              <span
                                className={` primary-click-text`}
                                onClick={e => {
                                  window.open(`tel:${el.phoneNo}`)
                                }}
                              >
                                +{el.phoneNo}
                              </span>
                            </span>
                          ) : null}
                          {!!el?.email ? (
                            <>
                              {` | `}
                              <span>
                                <EmailIcon
                                  style={{
                                    fontSize: '12px',
                                    color: 'black',
                                    marginBottom: '-3px',
                                    marginRight: '3px',
                                  }}
                                />
                                <span
                                  className={` primary-click-text`}
                                  onClick={e => {
                                    window.open(`mailto:${el.email}`)
                                  }}
                                >
                                  {el.email}
                                </span>
                              </span>
                            </>
                          ) : null}
                        </span>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={e => {
                          handleClick(e, el.id, index, el)
                          setOpenDialog(true)
                        }}
                      >
                        <CreateIcon
                          htmlColor="white"
                          fontSize="small"
                          style={{
                            width: '20px',
                            height: '20px',
                            margin: '-7px',
                            background: theme.palette.primary.main,
                            borderRadius: '3px',
                            color: 'rgba(224,234,254,100)',
                          }}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              )
            })
          )}
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Director Details',
              onClickAction: () => {
                resetMenu()
                setOpenDirectorDialog(true)
              },
              icon: (
                <AddIcon
                  htmlColor="white"
                  fontSize="small"
                  style={{
                    width: '20px',
                    height: '20px',
                    margin: '0',
                    background: theme.palette.primary.main,
                    borderRadius: '3px',
                    color: 'rgba(224,234,254,100)',
                    marginTop: '10px',
                    marginRight: '10px',
                  }}
                />
              ),
            },
          }}
        >
          {directorData?.length === 0 ? (
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmptyList
                title="No Director Record(s)"
                subtitle="Click on the plus button to add records"
              />
            </div>
          ) : (
            directorData.map((el, index) => {
              return (
                <List style={{ width: '100%', paddingBottom: '0px' }}>
                  <ListItem
                    key={index}
                    onClick={() =>
                      history.push({
                        pathname: el.path,
                      })
                    }
                    style={{ marginLeft: '-5px', marginTop: '-15px' }}
                  >
                    <ListItemText
                      primary={<span className="desc xsTitle">{el.name}</span>}
                      secondary={
                        <span className="desc">
                          <span>
                            <PhoneIcon
                              style={{
                                fontSize: '10px',
                                color: 'black',
                                marginBottom: '-1px',
                                marginRight: '3px',
                              }}
                            />

                            <span
                              className={` primary-click-text`}
                              onClick={e => {
                                window.open(`tel:${el.phoneNo}`)
                              }}
                            >
                              +{el.phoneNo}
                            </span>
                          </span>
                          {` | `}
                          <span>
                            <EmailIcon
                              style={{
                                fontSize: '12px',
                                color: 'black',
                                marginBottom: '-3px',
                                marginRight: '3px',
                              }}
                            />
                            <span
                              className={` primary-click-text`}
                              onClick={e => {
                                window.open(`mailto:${el.email}`)
                              }}
                            >
                              {el.email}
                            </span>
                          </span>
                        </span>
                      }
                    />

                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={e => {
                          handleClick(e, el.id, index, el)
                          setOpenDirectorDialog(true)
                        }}
                      >
                        <CreateIcon
                          htmlColor="white"
                          fontSize="small"
                          style={{
                            width: '20px',
                            height: '20px',
                            margin: '-7px',
                            background: theme.palette.primary.main,
                            borderRadius: '3px',
                            color: 'rgba(224,234,254,100)',
                          }}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              )
            })
          )}
        </CardContents>
      </ContentWrapper>

      <Footer
        copyright
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {
              if (
                watch('email') === '' ||
                watch('email') === null ||
                watch('email') === undefined
              ) {
                handleSubmit(data =>
                  onSubmit(data, state, contactData, directorData)
                )()
              } else if (validateEmail(watch('email')) === true) {
                handleSubmit(data =>
                  onSubmit(data, state, contactData, directorData)
                )()
              }
            },
          },
        ]}
      />

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
          setCheckContactError([])
        }}
        sections={{
          header: {
            title: 'Contact Details',
            rightText: !!menu?.obj ? 'Edit' : 'New',
            infoLine: menu?.obj?.name,
          },
          body: () => (
            <CardContents>
              <Controller
                as={TextField}
                name="Contact.contactName"
                label="Name"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={
                  (watch('Contact.contactName') === '' ||
                    watch('Contact.contactName') === undefined) &&
                  checkContactError?.find(x => x?.error === 'contactName')
                    ? 'Name is required'
                    : null
                }
                error={
                  (watch('Contact.contactName') === '' ||
                    watch('Contact.contactName') === undefined) &&
                  checkContactError?.find(x => x?.error === 'contactName')
                    ? true
                    : false
                }
                ref={register}
                defaultValue={menu?.obj?.name || ''}
              />

              <Controller
                as={TextField}
                name="Contact.designation"
                label="Designation"
                required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                helperText={
                  (watch('Contact.designation') === '' ||
                    watch('Contact.designation') === undefined) &&
                  checkContactError?.find(x => x?.error === 'designation')
                    ? 'Designation is required'
                    : null
                }
                error={
                  (watch('Contact.designation') === '' ||
                    watch('Contact.designation') === undefined) &&
                  checkContactError?.find(x => x?.error === 'designation')
                    ? true
                    : false
                }
                ref={register}
                defaultValue={menu?.obj?.designation || ''}
              />

              <Controller
                as={
                  <PhoneNumInput
                    country={'my'}
                    specialLabel="Mobile No."
                    fullWidth
                  />
                }
                name="Contact.mobileNo"
                type="tel"
                marginTop
                marginBottom
                // helperText={
                //   (watch('Contact.mobileNo') === '' ||
                //     watch('Contact.mobileNo') === undefined) &&
                //   checkContactError?.find(x => x?.error === 'mobileNo')
                //     ? 'Mobile No is required'
                //     : null
                // }
                // error={
                //   (watch('Contact.mobileNo') === '' ||
                //     watch('Contact.mobileNo') === undefined) &&
                //   checkContactError?.find(x => x?.error === 'mobileNo')
                //     ? true
                //     : false
                // }
                margin="normal"
                control={control}
                ref={register}
                defaultValue={menu?.obj?.phoneNo || ''}
              />

              <Controller
                as={TextField}
                name="Contact.emailAddress"
                label="Email Address"
                // required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                type="email"
                helperText={
                  // (watch('Contact.emailAddress') === '' ||
                  //   watch('Contact.emailAddress') === undefined) &&
                  // checkContactError?.find(x => x?.error === 'emailAddress')
                  //   ? 'Email Address is required'
                  //   :
                  !!watch('Contact.emailAddress') &&
                  validateEmail(watch('Contact.emailAddress')) === false
                    ? 'Email must be a valid email'
                    : null
                }
                error={
                  // (watch('Contact.emailAddress') === '' ||
                  //   watch('Contact.emailAddress') === undefined) &&
                  // checkContactError?.find(x => x?.error === 'emailAddress')
                  //   ? true
                  //   :
                  !!watch('Contact.emailAddress') &&
                  validateEmail(watch('Contact.emailAddress')) === false
                    ? true
                    : false
                }
                ref={register}
                defaultValue={menu?.obj?.email || ''}
              />
            </CardContents>
          ),
          leftFooterIcon: menu?.obj ? (
            <span style={{ width: '100%' }}>
              <IconButton
                edge="start"
                style={{
                  color: 'black',
                  marginTop: '-20px',
                  marginBottom: '-15px',
                }}
                onClick={e => {
                  onDeleteContact(menu?.index)
                  setOpenDialog(false)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null,
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    isDirty ? setOpenExitConf(true) : setCheckContactError([])
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    menu?.obj
                      ? onUpdateContact(watch('Contact'), menu?.index)
                      : onCreateContact(watch('Contact'))
                    checkContact(watch('Contact'))?.length === 0
                      ? setOpenDialog(false)
                      : setOpenDialog(true)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <CommonDialog
        fullWidth={true}
        open={openDirectorDialog}
        onClose={() => {
          setOpenDirectorDialog(false)
          setCheckDirectorError([])
        }}
        sections={{
          header: {
            title: 'Director Details',
            rightText: !!menu?.obj ? 'Edit' : 'New',
            infoLine: menu?.obj?.name,
          },
          body: () => (
            <CardContents>
              <Controller
                as={TextField}
                id="standard-basic"
                name="Director.identityNo"
                label="Identity No"
                // required
                autoComplete="off"
                control={control}
                fullWidth
                margin="dense"
                // helperText={
                //   (watch('Director.identityNo') === '' ||
                //     watch('Director.identityNo') === undefined) &&
                //   checkDirectorError?.find(x => x?.error === 'identityNo')
                //     ? 'Identity No is required'
                //     : null
                // }
                // error={
                //   (watch('Director.identityNo') === '' ||
                //     watch('Director.identityNo') === undefined) &&
                //   checkDirectorError?.find(x => x?.error === 'identityNo')
                //     ? true
                //     : false
                // }
                ref={register}
                defaultValue={menu?.obj?.identityNo || ''}
                className="left"
              />
              <Controller
                render={({ onChange, value }) => (
                  <TextField
                    select
                    name="Director.identityType"
                    label="Identity Type"
                    className="right"
                    style={{ marginTop: '5px' }}
                    value={value}
                    onChange={e => {
                      setValue('Director.identityType', e?.target?.value)
                    }}
                    // helperText={
                    //   (watch('Director.identityType') === '' ||
                    //     watch('Director.identityType') === undefined) &&
                    //   checkDirectorError?.find(x => x?.error === 'identityType')
                    //     ? 'Identity Type is required'
                    //     : null
                    // }
                    // error={
                    //   (watch('Director.identityType') === '' ||
                    //     watch('Director.identityType') === undefined) &&
                    //   checkDirectorError?.find(x => x?.error === 'identityType')
                    //     ? true
                    //     : false
                    // }
                  >
                    {IdentityType?.map((el, index) => (
                      <MenuItem key={index} value={el.type}>
                        {`${el.name}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                name="Director.identityType"
                label="Identity Type"
                autoComplete="off"
                onChange={e =>
                  setValue('Director.identityType', e?.target?.value)
                }
                control={control}
                multiline={true}
                fullWidth
                ref={register()}
                select
                className="right"
                defaultValue={menu?.obj?.identityType || null}
              />
              <Controller
                as={TextField}
                name="Director.contactName"
                label="Name"
                // required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                // helperText={
                //   (watch('Director.contactName') === '' ||
                //     watch('Director.contactName') === undefined) &&
                //   checkDirectorError?.find(x => x?.error === 'contactName')
                //     ? 'Name is required'
                //     : null
                // }
                // error={
                //   (watch('Director.contactName') === '' ||
                //     watch('Director.contactName') === undefined) &&
                //   checkDirectorError?.find(x => x?.error === 'contactName')
                //     ? true
                //     : false
                // }
                ref={register}
                defaultValue={menu?.obj?.name || ''}
              />

              <Controller
                as={
                  <PhoneNumInput
                    country={'my'}
                    specialLabel="Mobile No."
                    fullWidth
                  />
                }
                name="Director.mobileNo"
                type="tel"
                marginTop
                marginBottom
                // helperText={
                //   (watch('Director.mobileNo') === '' ||
                //     watch('Director.mobileNo') === undefined) &&
                //   checkDirectorError?.find(x => x?.error === 'mobileNo')
                //     ? 'Mobile No is required'
                //     : null
                // }
                // error={
                //   (watch('Director.mobileNo') === '' ||
                //     watch('Director.mobileNo') === undefined) &&
                //   checkDirectorError?.find(x => x?.error === 'mobileNo')
                //     ? true
                //     : false
                // }
                margin="normal"
                control={control}
                ref={register}
                defaultValue={menu?.obj?.phoneNo || ''}
              />

              <Controller
                as={TextField}
                name="Director.emailAddress"
                label="Email Address"
                // required
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                // helperText={
                //   (watch('Director.emailAddress') === '' ||
                //     watch('Director.emailAddress') === undefined) &&
                //   checkDirectorError?.find(x => x?.error === 'emailAddress')
                //     ? 'Email Address is required'
                //     : !!watch('Director.emailAddress') &&
                //       validateEmail(watch('Director.emailAddress')) === false
                //     ? 'Email must be a valid email'
                //     : null
                // }
                // error={
                //   (watch('Director.emailAddress') === '' ||
                //     watch('Director.emailAddress') === undefined) &&
                //   checkDirectorError?.find(x => x?.error === 'emailAddress')
                //     ? true
                //     : !!watch('Director.emailAddress') &&
                //       validateEmail(watch('Director.emailAddress')) === false
                //     ? true
                //     : false
                // }
                ref={register}
                defaultValue={menu?.obj?.email || ''}
              />
            </CardContents>
          ),
          leftFooterIcon: menu?.obj ? (
            <span style={{ width: '100%' }}>
              <IconButton
                edge="start"
                style={{
                  color: 'black',
                  marginTop: '-20px',
                  marginBottom: '-15px',
                }}
                onClick={e => {
                  onDeleteDirector(menu?.index)
                  setOpenDirectorDialog(false)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null,
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    isDirty ? setOpenExitConf(true) : setCheckDirectorError([])
                    setOpenDirectorDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    menu?.obj
                      ? onUpdateDirector(menu?.index, watch('Director'))
                      : onCreateDirector(watch('Director'))
                    checkDirector(watch('Director'))?.length === 0
                      ? setOpenDirectorDialog(false)
                      : setOpenDirectorDialog(true)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          setOpenExitConf(false)
          mode === 'add'
            ? history.push(
                `/account-payable/general-setting/creditor-profile/${creditorType}`
              )
            : history.push({
                pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${CreditorAccountID}/detail`,
              })
        }}
      />
    </>
  )
}
