import React from 'react'
import { ICDigitalReportSubmenu } from './ICDigitalReportSubmenu'
import { StockBalanceByLocationParamsForm } from './Parameters/StockBalanceByLocationParamsForm'
import { StockBalanceByLocationReport } from './GeneratedReport/StockBalanceByLocationReport'
import { StockLedgerByLocationParamsForm } from './Parameters/StockLedgerByLocationParamsForm'
import { StockLedgerByLocationReport } from './GeneratedReport/StockLedgerByLocationReport'

const icDigitalReportRoute = [
  {
    props: {
      exact: true,
      path: '/inventory-control/:WarehouseID/digital-report',
    },
    component: <ICDigitalReportSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/digital-report/stock-ledger-by-location-report/parameters',
    },
    component: <StockLedgerByLocationParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/digital-report/stock-ledger-by-location-report/generated',
    },
    component: <StockLedgerByLocationReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/digital-report/stock-balance-by-location-report/parameters',
    },
    component: <StockBalanceByLocationParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/inventory-control/:WarehouseID/digital-report/stock-balance-by-location-report/generated',
    },
    component: <StockBalanceByLocationReport />,
  },
]

export default icDigitalReportRoute
