import User from '@ifca-root/react-component/src/assets/hrIcons/employee.svg'
import CompanyActive from '@ifca-root/react-component/src/assets/icons/company-w.svg'
import GeneralSettingActive from '@ifca-root/react-component/src/assets/icons/general-setting-w.svg'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import DigitalReports from 'assets/icons/CB/digital-report.svg'
import NumberingStructureIcon from 'assets/icons/GL/CompanySettings/numbering.svg'
import ApprovalIcon from 'assets/icons/GL/GeneralSettings/approval-policy.svg'
import AccessSecurity from 'assets/icons/Sidemenu/access-security.svg'
import CompanySetting from 'assets/icons/Sidemenu/company-setting.svg'
import GeneralSettingIcon from 'assets/icons/Sidemenu/general-setting.svg'
import Home from 'assets/icons/Sidemenu/home.svg'
import Logout from 'assets/icons/Sidemenu/log-out.svg'
import HomeActive from 'assets/icons/home-w.svg'
import Profile from 'assets/icons/profile.svg'
import AppContext from 'containers/App/Store/AppContext'
import { useLoggedInUserProfileQuery } from 'generated/graphql'
import { useBroadcastChannel } from 'helpers/Hooks/useBroadcastChannel'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'

export const drawerWidth = 240
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  })
)

interface Props {
  window?: () => Window
  open: boolean
  variant: any
  onCloseDrawer: any
}

export default function LayoutDrawer(props: Props) {
  const { window, open, onCloseDrawer, variant, ...rest } = props
  const classes = useStyles()
  const container =
    window !== undefined ? () => window().document.body : undefined
  let history = useHistory()
  const CompanyID = localStorage.getItem('latestCompany')
  const WarehouseID = localStorage.getItem('latestWarehouse')
  const { globalState }: any = useContext(AppContext as any)
  const { signOut } = useBroadcastChannel()

  let location = useLocation()
  const userData: any = location?.state
  const user =
    userData?.__typename === 'UserResponse'
      ? userData
      : JSON?.parse(localStorage?.getItem('loggedInUser'))

  const mode: any = history?.location?.pathname

  let isHome = false,
    isSetting = false

  const { loading, data } = useLoggedInUserProfileQuery()

  const [previewFile, setPreviewFile] = useState(null)

  useEffect(() => {
    // setUserData(data?.loggedInUserProfile)
    setPreviewFile(data?.loggedInUserProfile?.avatar)
  }, [data?.loggedInUserProfile])

  if (mode === '/' || mode === '/home' || mode === '/alternate') {
    isHome = true
  }
  if (
    globalState?.isPathSysAdmin ||
    mode === '/access-security' ||
    mode === '/general-setting' ||
    mode === '/company'
  ) {
    isSetting = true
  }

  const pageMode = reconsRoute(mode)

  const home = {
    name: 'Home',
    path: `/home`,
    icon: Home,
    iconSelected: HomeActive,
    permission: undefined,
    deployed: true,
  }

  const homeCompanySetup = {
    name: 'Company Setup',
    path: '/company-setup',
    icon: CompanySetting,
    iconSelected: CompanyActive,
    permission: undefined,
    deployed: user?.superUser ? true : false,
  }

  const homeAccessSecurity = {
    name: 'Access Security',
    path: '/access-security',
    icon: AccessSecurity,
    iconSelected: AccessSecurity,
    deployed: user?.superUser ? true : false,
  }

  const homeToSubmenu = {
    name: 'Home',
    path: `/${pageMode}/${CompanyID}`,
    icon: Home,
    iconSelected: HomeActive,
    permission: undefined,
    deployed: true,
  }

  const IChomeToSubmenu = {
    name: 'Home',
    path: `/${pageMode}/${WarehouseID}`,
    icon: Home,
    iconSelected: HomeActive,
    permission: undefined,
    deployed: true,
  }

  const logout = {
    name: 'Sign Out',
    path: '/logout',
    icon: Logout,
    iconSelected: Logout,
    permission: undefined,
    deployed: true,
  }

  const accessSecurity = {
    name: 'Access Security',
    path: `/${pageMode}/access-security`,
    icon: AccessSecurity,
    // icon: User,
    iconSelected: User,
    permission: undefined,
    deployed: user?.superUser ? true : false,
  }

  const generalSetting = {
    name: 'General Settings',
    path: `/${pageMode}/general-setting`,
    icon: GeneralSettingIcon,
    iconSelected: GeneralSettingActive,
    permission: undefined,
    deployed: user?.superUser ? true : false,
  }
  const financialReport = {
    name: 'Financial Reports',
    path: `/${pageMode}/financial-reporting`,
    icon: ApprovalIcon,
    iconSelected: GeneralSettingActive,
    permission: undefined,
    deployed: true,
  }

  const companySetting = {
    name: 'Company Settings',
    path: `/${pageMode}/company`,
    icon: CompanySetting,
    iconSelected: CompanyActive,
    permission: undefined,
    deployed: user?.superUser ? true : false,
  }

  const documentNumber = {
    name: 'Document Numbering',
    path: `/${pageMode}/company`,
    icon: NumberingStructureIcon,
    iconSelected: CompanyActive,
    permission: undefined,
    deployed: user?.superUser ? true : false,
  }

  const digitalReports = {
    name: 'Digital Reports',
    path: `/${pageMode}/submenu/digital-report`,
    icon: DigitalReports,
    iconSelected: DigitalReports,
    permission: undefined,
    deployed: true,
  }

  const glExportCB = {
    name: `GL Export`,
    // path: `disabled`,
    path: `/cash-book/${CompanyID}/gl-export`,
    icon: DigitalReports,
    iconSelected: DigitalReports,
    permission: undefined,
    deployed: true,
    // permission:
  }

  const navigations =
    pageMode === 'general-ledger'
      ? [
          homeToSubmenu,
          accessSecurity,
          generalSetting,
          companySetting,
          financialReport,
          logout,
        ]
      : pageMode === 'cash-book'
      ? [
          homeToSubmenu,
          generalSetting,
          companySetting,
          digitalReports,
          glExportCB,
          logout,
        ]
      : pageMode === 'inventory-control'
      ? [IChomeToSubmenu, generalSetting, logout]
      : pageMode === 'account-receivable' || pageMode === 'account-payable'
      ? [homeToSubmenu, generalSetting, companySetting, logout]
      : pageMode === 'general-purchase'
      ? [homeToSubmenu, generalSetting, companySetting, logout]
      : [home, homeAccessSecurity, homeCompanySetup, logout]

  const authenticationMenu = [
    {
      label: 'Authentication',
      child: [
        { label: 'Login ', path: '/login' },
        { label: 'Forgot Password ', path: '/forgot' },
        { label: 'Error ', path: '/authentication/404' },
      ],
    },
  ]

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRedirectProfile = () => {
    if (!!onCloseDrawer) {
      onCloseDrawer()
    }
    setAnchorEl(null)
    history.push('/profile')
  }

  const drawer = (
    <div className="route-container">
      <List disablePadding>
        {navigations.map((v, index) => {
          if (v?.deployed)
            return (
              <ListItem
                button
                key={index}
                dense
                activeClassName={'active'}
                divider
                component={NavLink}
                to={v?.path}
                disabled={v?.path !== 'disabled' ? false : true}
                onClick={() => {
                  localStorage.removeItem('companyFilter')
                  if (!!onCloseDrawer) {
                    onCloseDrawer()
                  }
                  if (v.name === 'Logout') {
                    signOut(user?.ID, CompanyID)
                    sessionStorage.clear()
                    sessionStorage.removeItem('tokenKey')
                    // localStorage.removeItem('loggedInUser')
                    // localForage.removeItem('permission')
                    // history.push('/login')
                  }
                }}
              >
                <ListItemIcon>
                  <img className="icon-svg" src={v.icon} alt="drawer-icon" />
                  {/* <img
                className="icon-svg"
                src={v.iconSelected}
                alt="drawer-icon"
              /> */}
                </ListItemIcon>
                <ListItemText primary={v.name} />
              </ListItem>
            )
        })}
      </List>
    </div>
  )

  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">
        {previewFile ? (
          <img src={previewFile} alt="" className="avatar" />
        ) : (
          <Avatar alt="avatar" className="avatar" />
        )}
      </div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <span className="smTitle">
                {user?.name &&
                  user?.userName &&
                  `${user?.name} (${user?.userName})`}
              </span>
            }
            secondary={<span className="desc">{user?.email}</span>}
          />
          {/* {isHome ? null : ( */}
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="simple-menu"
              onClick={handleProfileClick}
            >
              <ExpandMore />
            </IconButton>
          </ListItemSecondaryAction>
          {/* )} */}
        </ListItem>
      </List>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="drawer-submenu"
      >
        <MenuItem className="drawer-dropdown" onClick={handleRedirectProfile}>
          <img className="icon-dropdown-svg" src={Profile} alt="" /> Profile
        </MenuItem>
        <MenuItem
          className="drawer-dropdown"
          onClick={() => {
            // userLogout()
            signOut(user?.ID, CompanyID)
            sessionStorage.clear()
            sessionStorage.removeItem('tokenKey')
            // localStorage.removeItem('loggedInUser')
            // localForage.removeItem('permission')
            // history.push('/login')
          }}
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" /> Logout
        </MenuItem>
      </Menu>
    </div>
  )

  // Better open performance on mobile.
  return (
    <>
      {loading && <Loading />}
      <nav className={classes.drawer} aria-label="main menu">
        <Drawer
          {...rest}
          container={container}
          variant={variant}
          anchor={'left'}
          open={open}
          onClose={onCloseDrawer}
          className="drawer"
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {userProfile}
          {drawer}
        </Drawer>
      </nav>
    </>
  )
}

const mode = [
  'general-ledger',
  'cash-book',
  'account-receivable',
  'account-payable',
  'inventory-control',
  'system-admin',
  'workdesk',
  'general-purchase',
]

export const isIn = (route: string, module: string) => {
  return route.match(module)
}

export const reconsRoute = (route: string) => {
  let genRoute = mode.filter(x => isIn(route, x))
  return genRoute.length > 0 ? genRoute[genRoute.length - 1] : genRoute
}
