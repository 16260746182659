import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import { useGetCompanyNameQuery } from 'generated/graphql'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const ARDebtorAgingSummaryByDocDateReport = () => {
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const editData = location?.state as any

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: CompanyID,
    },
    {
      name: 'docdate',
      value: new Date(editData?.DocDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'debtortypeid',
      value: editData?.DebtorTypeID,
    },
    {
      name: 'debtoraccountid',
      value: editData?.DebtorAccountID,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      {CompanyLoading && <Loading />}

      <MainHeader
        onClick={() =>
          history.push(`/account-receivable/${CompanyID}/digital-report`)
        }
        mainBtn="close"
        smTitle="Account Receivable"
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Debtor Aging Summary By Document Date', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('DebtorAgingSummaryByDocDate', parameters),
          host: accountxReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
