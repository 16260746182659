import AppContext from '@account-root/account-react/src/containers/App/Store/AppContext';
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import React, { useContext } from 'react';
import { amtStr4Dec } from '../../../helpers/StringNumberFunction/numFormatter';

export const TableDOItem = (props: any) => {
  const { data, docType } = props;

  const { globalState, dispatch }: any = useContext(AppContext as any);
  // const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
  //   defaultMatches: true,
  // });

  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) {
    fontSize = 6;
  } else {
    fontSize = 10;
  }

  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  };

  const thright = {
    width: '10%',
    borderBottom: '0.5px  solid',
    textAlign: 'right' as 'right',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  };

  const thcenter = {
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  };

  const thdocnum = {
    width: '15%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  };

  const thcenternarrow = {
    width: '10%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  };

  const tdright = {
    width: '10%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
  };

  const tdleft = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
  };

  const tdleftstretch = {
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
  };

  // Reducer for GRTN

  // Reducer for DO / GRN

  return (
    <>
      {/* ====== GRN Table ======== */}
      {docType === 'delivery-order' && (
        <div style={{ marginTop: '20px' }}>
          <table
            style={{
              width: '100%',
              fontSize,
              // borderCollapse: 'collapse',
              borderSpacing: '0 1em',
            }}
          >
            <tr>
              <th style={thdocnum}>PO No</th>
              <th style={thleft}>
                Item <br /> Description
              </th>
              <th style={thcenternarrow}>UOM</th>
              <th style={thcenternarrow}>
                Order
                <br />
                Qty
              </th>
              <th style={thcenternarrow}>
                Received
                <br />
                Qty
              </th>
              <th style={thcenternarrow}>
                Received Amt <br /> {'(RM)'}
              </th>
            </tr>

            {data?.DOItem?.map((v, i) => (
              <>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    {v?.POItem?.POHeader?.DocNo}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {v?.POItem?.Item?.Name}
                    <br />
                    {v?.POItem?.Remarks}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {v?.POItem?.UOM?.Name}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {amtStr4Dec(v?.POItem?.OrderedQty)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {amtStr4Dec(v?.AcceptedQty)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {amtStr(v?.AcceptedAmt)}
                  </td>
                </tr>
              </>
            ))}
          </table>

          <div style={{ marginTop: '50px' }}>
            <table
              style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
            >
              <tr>
                <td style={tdleftstretch}>{`Total`}</td>
                {/* 
                <td style={tdright}>{amtStr(amount)}</td>
                <td style={tdright}>{amtStr(taxAmount)}</td>
                <td style={tdright}>{amtStr(totalAmount)}</td> */}
              </tr>
            </table>
          </div>
        </div>
      )}

      {/* ======== GRTN Table =========== */}
      {docType === 'good-return-note' && (
        <div style={{ marginTop: '20px' }}>
          <table
            style={{
              width: '100%',
              fontSize,
              // borderCollapse: 'collapse',
              borderSpacing: '0 1em',
            }}
          >
            <tr>
              <th style={thdocnum}>GRN No</th>
              <th style={thleft}>
                Item <br /> Description
              </th>
              <th style={thcenternarrow}>UOM</th>
              <th style={thcenternarrow}>
                Reeeived
                <br />
                Qty
              </th>
              <th style={thcenternarrow}>
                Returned
                <br />
                Qty
              </th>
              <th style={thcenternarrow}>
                Returned Amt <br /> {'(RM)'}
              </th>
            </tr>

            {data?.GRTNHeaderItem?.map((v, i) => (
              <>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    {v?.DOItem?.DOHeader?.DocNo}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {v?.DOItem?.POItem?.Item?.Name}
                    <br />
                    {v?.DOItem?.DOHeader?.DoNo} | {v?.DOItem?.POItem?.Remarks}
                    <br />
                    {!!v?.Replacement ? 'Replacement' : null}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {v?.DOItem?.POItem?.UOM?.Name}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {amtStr4Dec(v?.DOItem?.AcceptedQty)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {amtStr4Dec(v?.ReturnedQty)}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {amtStr(v?.ReturnedAmt)}
                  </td>
                </tr>
              </>
            ))}
          </table>

          <div style={{ marginTop: '50px' }}>
            <table
              style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
            >
              <tr>
                <td style={tdleftstretch}>{`Total`}</td>
                {/* 
                <td style={tdright}>{amtStr(amount)}</td>
                <td style={tdright}>{amtStr(taxAmount)}</td>
                <td style={tdright}>{amtStr(totalAmount)}</td> */}
              </tr>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default TableDOItem;
