import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import {
  useGetCompanyQuery,
  useGetCostCentreQuery,
  useGetUsersByAccountAndSoftwareQuery,
  useGetBankAccountQuery,
} from 'generated/graphql'
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useCBQueries } from './Query/useCBQueries'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import HeaderGL from 'containers/GeneralLedgerModule/Hooks/PDFTemplate/HeaderGL'
import TableCBV from './PDFTemplate/TableCBV'
import FooterPR from 'containers/ARModule/Hooks/PDFTemplate/FooterPR'
import TableCBPV from './PDFTemplate/TableCBPV'
import html2pdf from 'html2pdf.js/dist/html2pdf.min'
import TableCBOR from './PDFTemplate/TableCBOR'
import TableBT from './PDFTemplate/TableBT'
import FooterBT from './PDFTemplate/FooterBT'
import TableDetailBT from './PDFTemplate/TableDetailBT'

export const PDFPreviewCB = (props: any) => {
  const location: any = useLocation()
  const cbDetail: any = location?.state
  const { accountType, docType } = props
  let history = useHistory()
  const { styles } = useMobilePDFZoom()
  const {
    CompanyID,
    PaymentID,
    ReceiptID,
    BankTransferID,
    BankAccountID,
  }: any = useParams()

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: CostCentreLoading,
    error: CostCentreError,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: BankLoading,
    error: BankError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    fetchPolicy: 'network-only',
  })

  const { loading, listData, primaryKey } = useCBQueries({
    accountType,
    docType,
    CompanyID,
    BankAccountID,
  })

  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 8
  } else {
    fontSize = 12
  }

  let fontStyle
  fontStyle = {
    padding: '10px',
    fontSize: fontSize,
    textAlign: 'center',
  }

  const previewDetail: any = listData?.filter(
    v => v?.[primaryKey] === (PaymentID ?? ReceiptID ?? BankTransferID)
  )

  let routeSegments, title, smallTitle, name, DocumentID, pageLength, docName

  switch (accountType) {
    case 'cash-book':
      smallTitle = smTitle.CASH_BOOK
      name = 'DocNo'
      switch (docType) {
        case 'payment':
          DocumentID = PaymentID
          routeSegments = 'Payment'
          title = 'CASH PAYMENT VOUCHER'
          docName = 'CPV'
          name = 'DocNo'
          pageLength = Math.floor(previewDetail[0]?.PaymentItem?.length / 16)
          break
        case 'receipt':
          DocumentID = ReceiptID
          routeSegments = 'Receipt'
          title = 'CASH RECEIPT/INVOICE'
          docName = 'CRV'
          name = 'DocNo'
          break
        case 'bank-transfer':
          DocumentID = BankTransferID
          routeSegments = 'Bank Transfer'
          title = 'BANK TRANSFER VOUCHER'
          docName = 'BTV'
          name = 'DocNo'
          break
      }
      break
  }

  const onPrint = () => {
    listData
      ?.filter(x => x?.[primaryKey] === previewDetail[0]?.[primaryKey])
      ?.map((v, i) => {
        let element = document.getElementById('element-to-print-' + i)
        html2pdf()
          .set({
            filename: `${title} (${
              listData?.filter(x => x?.[primaryKey] === v?.[primaryKey])[0]?.[
                name
              ]
            }).pdf`,
          })
          .from(element)
          .save()
      })
  }

  return (
    <>
      {CostCentreLoading && <Loading />}
      {loading && <Loading />}
      {UserLoading && <Loading />}
      {companyLoading && <Loading />}
      {BankLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (previewDetail[0]?.ApprovalStatus === 'COMPLETED') {
            history.push({
              pathname: `/${accountType}/${CompanyID}/submenu/${BankAccountID}/${docType}/${DocumentID}/detail`,
              state: previewDetail[0],
            })
          } else {
            history.push({
              pathname: `/${accountType}/${CompanyID}/submenu/${BankAccountID}/${docType}/`,
              state: previewDetail[0],
            })
          }
        }}
        smTitle={smallTitle}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[
          {
            name: 'Print',
            current: true,
          },
        ]}
      />

      <ContentWrapper footer>
        {listData
          ?.filter(x => x?.[primaryKey] === DocumentID)
          ?.map((v, i) => {
            return (
              <div
                style={{ padding: '14px' }}
                key={i}
                id={'element-to-print-' + i}
              >
                <div style={styles.supercontainer}>
                  <div style={styles.container}>
                    <HeaderGL />

                    <div className="row">
                      <div>
                        <p style={fontStyle}>
                          <b>{title}</b>
                        </p>
                      </div>
                    </div>

                    <div style={{ border: '0.5px solid', margin: '10px' }}>
                      {/* Table for Document Info */}
                      {docType !== 'bank-transfer' ? (
                        <TableCBV data={previewDetail[0]} />
                      ) : (
                        <TableBT
                          data={previewDetail[0]}
                          getBankAccount={getBankAccount}
                        />
                      )}

                      {/* Table for details */}
                      {docType === 'payment' && (
                        <>
                          <TableCBPV
                            data={previewDetail[0]}
                            item={previewDetail[0]?.PaymentItem}
                          />
                        </>
                      )}
                      {docType === 'receipt' && (
                        <>
                          <TableCBOR
                            data={previewDetail[0]}
                            item={previewDetail[0]?.ReceiptItem}
                          />
                        </>
                      )}
                      {docType === 'bank-transfer' && (
                        <>
                          <TableDetailBT
                            data={previewDetail[0]}
                            getBankAccount={getBankAccount}
                          />
                        </>
                      )}
                    </div>

                    {/* Footer */}
                    {docType !== 'bank-transfer' ? (
                      <FooterPR
                        data={previewDetail[0]}
                        docType={docType}
                        getUser={getUsersByAccountAndSoftware}
                      />
                    ) : (
                      <FooterBT
                        data={previewDetail[0]}
                        docType={docType}
                        getUser={getUsersByAccountAndSoftware}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          })}

        <Footer
          options={[
            {
              name: 'Download',
              color: 'primary',
              onClick: () => {
                onPrint()
              },
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}

export default PDFPreviewCB
