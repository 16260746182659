import React, { useState } from 'react'
import watermark from 'assets/images/watermark.png'
import 'assets/styles/accountx.scss'

export const useMobilePDFZoom = () => {
  let [zoomLevel, setZoomLevel] = useState(100)
  let [opac, setOpacity] = useState(1)

  const zoomIn = () => {
    setZoomLevel((zoomLevel *= 1.5))
  }

  const zoomOut = () => {
    setZoomLevel((zoomLevel /= 1.5))
  }

  const zoomReset = () => {
    setZoomLevel(100)
  }

  const opUp = () => {
    setOpacity(opac == 1 ? (opac += 0) : (opac += 0.1))
  }

  const opDown = () => {
    setOpacity(opac == 0 ? (opac += 0) : (opac -= 0.1))
  }

  const styles = {
    body: {
      paddingTop: 40,
      paddingBottom: 20,
      paddingHorizontal: 40,
    },
    supercontainer: {
      // position: 'absolute' as 'absolute',

      marginTop: '30px',
      margin: 'auto',
      width: `85%`,
      height: `70%`,
      // borderStyle: 'solid',
      background: 'white',
      overflow: 'auto',
      paddingTop: '10px',
      top: 0,
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    a4container: {
      // position: 'absolute' as 'absolute',

      marginTop: '30px',
      margin: 'auto',
      width: `85%`,
      // borderStyle: 'solid',
      background: 'white',
      overflow: 'auto',
      paddingTop: '10px',
      top: 0,
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '10px',
      aspectRatio: '1/1.41',
    },
    watercontainer: {
      // zIndex:1,
      // position: 'absolute' as 'absolute',
      width: `${zoomLevel}%`,
      height: `${zoomLevel}%`,
      left: 0,
      top: '50px',
      opacity: `${opac}`,
      // opacity:0.1,
      // backgroundImage: `url(${watermark})`,
      backgroundRepeat: 'no-repeat',
    },
    container: {
      // position: 'absolute' as 'absolute',

      zoom: zoomLevel + '%',
      width: `${zoomLevel}%`,
      // overflow: 'auto',
      height: `${zoomLevel}%`,
      background: 'rgba(0, 1, 0, 0)',

      // backgroundImage: `url(${watermark})`,
      zIndex: -1,
    },
  }

  return {
    zoomIn,
    zoomOut,
    zoomReset,
    opUp,
    opDown,
    styles,
  }
}
