import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import Fuse from 'fuse.js'
import {
  useGetCompanyNameQuery,
  useGetJournalProcessingbyStatusLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { JournalEntryList } from './JournalList/JournalEntryList'
import { JournalPostingList } from './JournalList/JournalPostingList'
import { Tune } from '@material-ui/icons'
import { useFilterDialog } from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2'

export const JournalMain = (props: any) => {
  useEffect(() => {
    document.title = 'GL-Journal'
  }, [])

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ]

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]))
  const [openExitConf, setOpenExitConf] = useState(null)
  const [listStatus, setListStatus] = useState<string>(
    getSearch?.option ?? 'ACTIVE'
  )

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    loadJournalProcessing,
    {
      loading: JournalProcessingbyStatusLoading,
      data: { getJournalProcessingbyStatus } = {
        getJournalProcessingbyStatus: [],
      },
      fetchMore,
    },
  ] = useGetJournalProcessingbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    // variables: {
    //   CompanyID: CompanyID,
    //   StatusArr: [listStatus ?? 'ACTIVE'],
    // },
    onCompleted: ({ getJournalProcessingbyStatus }) => {
      setOriginalListing(getJournalProcessingbyStatus, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    },
  })

  useEffect(() => {
    loadJournalProcessing({
      variables: {
        CompanyID: CompanyID,
        StatusArr: [listStatus],
      },
    })
  }, [listStatus])

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  /* -------------------------------------------- */
  /*                    SEARCH                    */
  /* -------------------------------------------- */

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()

  useEffect(() => {
    if (getSearch?.name && !!originalList) {
      const keys = [
        'DocNo',
        'Description',
        'DocDateFormatted',
        amtNumStr('JournalProcessingItem.DocAmt'),
      ]
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch?.name)
      const val = result?.map(x => x.item)
      // if (val.length !== 0) {
      handleSearch(getSearch?.name + '', keys)
      // }
    }
  }, [getSearch?.name, originalList])

  /* -------------------------------------------- */
  /*                   EXIT CONF                  */
  /* -------------------------------------------- */

  const hasChanges = () => {
    if (postIDs?.size === 0) return false
    else return true
  }

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Doc Date',
      key: 'DocDate',
    },
  })

  return (
    <>
      {UserLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {JournalProcessingbyStatusLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasChanges() === true && listStatus === 'SUBMIT'
            ? setOpenExitConf(true)
            : history.push(`/general-ledger/${CompanyID}`)
          localStorage.removeItem(`searchFilter`)
        }} //<- back button action
        smTitle="General Ledger"
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Home Journal' },
          { name: 'Journal Entries', current: true },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, [
              'DocNo',
              'Description',
              amtNumStr('JournalProcessingItem.DocAmt'),
              'DocDateFormatted',
            ])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          isDefaultValue={!!getSearch}
          option={{
            icon: <Tune />,
            onClick: openFilterDialog,
          }}
        />

        {filterDialog}

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') &&
        !JournalProcessingbyStatusLoading && (
          <JournalEntryList
            filteredList={filteredList}
            handleFilterDate={handleFilterDate}
            listLoading={JournalProcessingbyStatusLoading}
            listStatus={listStatus}
            userList={getUsersByAccountAndSoftware}
            fetchMore={loadJournalProcessing}
          />
        )}
      {listStatus === 'SUBMIT' && !JournalProcessingbyStatusLoading && (
        <JournalPostingList
          list={getJournalProcessingbyStatus}
          filteredList={filteredList}
          handleFilterDate={handleFilterDate}
          listLoading={JournalProcessingbyStatusLoading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing} // pass to set new original listing
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}`)
        }}
      />
    </>
  )
}
