import React, { lazy } from 'react'
import { ARSubmenu } from '../ARSubmenu'
import { DebtorAccountForm } from './DebtorAccountForm'
import { DebtorAccountListing } from './DebtorAccountListing'
import { DebtorAccountTrxListing } from './DebtorAccountTrxListing'
import { DebtorTypeListing } from './DebtorTypeListing'
const DebtorAccountDetail = lazy(() =>
  import('./DebtorAccountDetail').then(module => ({
    default: module.DebtorProfileDetail,
  }))
)
export const debtorAccountRoutes = [
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account`,
    },
    component: <DebtorAccountListing />,
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/add`,
    },
    component: (
      <DebtorAccountForm accountType="account-receivable" mode="add" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorID/edit`,
    },
    component: (
      <DebtorAccountForm accountType="account-receivable" mode="edit" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorID/detail`,
    },
    component: (
      <DebtorAccountDetail
        accountType="account-receivable"
        creditordebtorType="debtor-account"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/account-receivable/:CompanyID/debtor-type`,
    },
    component: <DebtorTypeListing accountType="account-receivable" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID',
    },
    component: <ARSubmenu creditordebtorType="debtor-account" />,
  },

  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/invoice',
    },
    component: <DebtorAccountTrxListing docType="invoice" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/credit-note',
    },
    component: <DebtorAccountTrxListing docType="credit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/debit-note',
    },
    component: <DebtorAccountTrxListing docType="debit-note" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/advance',
    },
    component: <DebtorAccountTrxListing docType="advance" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/official-receipt',
    },
    component: <DebtorAccountTrxListing docType="official-receipt" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-receivable/:CompanyID/debtor-account/debtor-profile/:DebtorAccountID/refund',
    },
    component: <DebtorAccountTrxListing docType="refund" />,
  },
]

export default debtorAccountRoutes
