import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { amtStr, amtStr4Dec } from 'helpers/StringNumberFunction/NumFormatters'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  useGetHomeSummaryQuery,
  useDocumentListingQuery,
  useGetStockItemQuery,
  useGetStockLedgerQuery,
  useGetCompanyNameQuery,
  useStockItemWarehouseSummaryLazyQuery,
  useGetWarehouseNameQuery,
} from 'generated/graphql'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { KeyboardArrowRight, VerticalAlignTop } from '@material-ui/icons'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import e from 'cors'
import { el } from 'date-fns/locale'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'

export const StockEnquiryListing2 = (props: any) => {
  const { mode }: any = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let history = useHistory()
  const { StockItemID, WarehouseID }: any = useParams()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const [warehouseData, setWarehouseData] = useState([])
  const [totalUnit, setTotalUnit] = useState(0)

  // QUERY //

  const {
    loading,
    called,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const {
    loading: StockItemLoading,
    error: StockItemError,
    data: { getStockItem } = {
      getStockItem: [],
    },
  } = useGetStockItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      StockItemID: StockItemID,
      CompanyID: CompanyID,
    },
    onCompleted: ({ getStockItem }) => {
      if (getStockItem?.length > 0) {
        setWarehouseData(getStockItem[0]?.StockItemWarehouse)
        setTotalUnit(
          getStockItem[0]?.StockItemWarehouse?.reduce(
            (a, b) => a + b?.StockQty,
            0
          )
        )
      }
    },
  })

  const {
    loading: WarehouseNameLoading,
    error: WarehouseNameError,
    data: { getWarehouse: getWarehouseName } = { getWarehouse: [] },
  } = useGetWarehouseNameQuery({
    variables: { WarehouseID: WarehouseID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: documentLoading,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: StockItemID,
    },
  })

  // const WarehouseList = getStockItem?.[0].StockItemWarehouse?.map(
  //   (el, index) => {
  //     return {
  //       WarehouseID: el?.WarehouseID,
  //       Name: el?.Warehouse?.Name,
  //       MaximumQty: el?.MaximumQty,
  //       MinimumQty: el?.MinimumQty,
  //       ReorderLevel: el?.ReorderLevel,
  //       ReorderQty: el?.ReorderQty,
  //       StockQty: el?.StockQty,
  //     }
  //   }
  // )

  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/inventory-control/${WarehouseID}/stock-enquiry`)
        }} //<- back button action
        smTitle={'Inventory Control'}
        title={getWarehouseName[0]?.Name}
        //currency={`MYR`}
        routeSegments={[
          { name: 'IC' },
          { name: 'Stock Enquiry' },
          { name: 'Stock Enquiry', current: true },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      <DynamicSubHeader
        title={
          <>
            <span style={{ paddingTop: '-10px' }}>{getStockItem[0]?.Name}</span>
          </>
        }
        infoLine={
          <>
            <span>
              <b>{getStockItem[0]?.StockCategory?.Name}</b>
            </span>
          </>
        }
        rightText={
          <>
            <span className="xsTitle c-orange">{amtStr4Dec(totalUnit)}</span>
            <span>
              {' '}
              <b>Unit</b>
            </span>
          </>
        }
      />

      <ContentWrapper singleDynamicInfo>
        <List className="core-list">
          {warehouseData === undefined || warehouseData?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            warehouseData?.map((el, index) => {
              return (
                <ListItem
                  onClick={() => {
                    history.push({
                      pathname: `/inventory-control/${WarehouseID}/stock-enquiry/${StockItemID}/warehouse/${el?.WarehouseID}/general-ledger`,
                      state: {
                        warehouse: el,
                        stockitem: editData,
                      },
                    })
                  }}
                  // onClick={e => handleClick(e, el.StockItemID, index, el)}
                >
                  <ListItemText
                    primary={
                      <>
                        <span className="xsTitle flex-space">
                          {el?.Warehouse?.Name}{' '}
                        </span>
                        <span
                          className="xsTitle c-orange click-text"
                          onClick={() => {
                            history.push({
                              pathname: `/inventory-control/${WarehouseID}/stock-enquiry/${StockItemID}/warehouse/${el?.WarehouseID}/general-ledger`,
                              state: {
                                warehouse: el,
                                stockitem: editData,
                              },
                            })
                          }}
                        >
                          {amtStr4Dec(el?.StockQty ?? 0)}
                        </span>
                        <span
                          className="xsTitle"
                          style={{
                            paddingLeft: '2px',
                            //width: '10px'
                          }}
                        >
                          <b>Unit</b>
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc" style={{ display: 'flex' }}>
                          <span> Unit Cost :</span>
                          <span className="desc c-orange">
                            <IconText
                              font="desc c-orange"
                              children={amtStr(el?.StockAvgUnitCost)}
                            ></IconText>
                          </span>
                        </span>
                        &nbsp; | &nbsp;
                        <span className="desc" style={{ display: 'flex' }}>
                          <span>Total Cost :</span>
                          <span className="desc c-orange">
                            <IconText
                              icon={
                                <img
                                  style={{
                                    width: '12px',
                                    marginRight: '3px',
                                    marginBottom: '1.5px',
                                  }}
                                />
                              }
                              font="desc c-orange"
                              children={amtStr(
                                el?.StockAvgUnitCost * el?.StockQty
                              )}
                            ></IconText>
                          </span>
                        </span>
                      </>
                    }
                  />
                </ListItem>
              )
            })
          )}
        </List>
      </ContentWrapper>
    </>
  )
}
// import React from 'react'
// import {
//     useDocumentListingQuery,
//     useGetStockItemQuery
//   } from 'generated/graphql'

// export const StockEnquiryListing2 = (props: any) => {
//     const { mode }: any = props
//     const { StockItemID }: any = useParams()
//     const {
//         loading: StockItemLoading,
//         error: StockItemError,
//         data: { getStockItem } = {
//           getStockItem: [],
//         },
//       } = useGetStockItemQuery({
//         fetchPolicy: 'network-only',
//         variables: {
//           StockItemID: StockItemID,
//         },
//       })
//   return (
//     <div>StockEnquiryListing2</div>
//   )
// }
