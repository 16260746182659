import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import {
  RecordStatus,
  useDocumentListingQuery,
  useGetCompanyNameQuery,
  useGetUsersByAccountAndSoftwareQuery,
  UserSelection,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { APDetailContent } from './APDetailContent'
import { APItemDetailContent } from './APItemDetailExpansion'

export const APDetail = props => {
  let history = useHistory()
  let location = useLocation()
  const listData = location?.state as any
  const { CompanyID, DocumentID }: any = useParams()
  const { apSubmenu, routeSegments, accountType } = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openWorkFlow, setWorkFlow] = useState(false)

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, RecordStatus: RecordStatus.Active },
  })

  const {
    loading: userLoading,
    error: userError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: { superUserBool: UserSelection.All },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    loading: docLoading,
    error: docError,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: { refID: DocumentID },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  let itemName
  switch (apSubmenu) {
    case 'credit-note':
      itemName = 'APCreditNoteItem'
      break
    case 'creditor-credit-note':
      itemName = 'APCreditorCreditNoteItem'
      break
    case 'invoice':
      itemName = 'APInvoiceItem'
      break
    case 'debit-note':
      itemName = 'APDebitNoteItem'
      break
    default:
      break
  }

  return (
    <>
      {docLoading && <Loading />}
      {userLoading && <Loading />}
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/${accountType}/${CompanyID}/${apSubmenu}`)
        }
        smTitle={smTitle.ACCOUNT_PAYABLE}
        title={`${getCompany[0]?.Name}`}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Account Payable' },
          { name: 'AP Submenu' },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {listData?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="c-orange">{formatDate(listData?.DocDate)}</span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {listData?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <span className="desc flex-space c-orange">
              {amtStr(listData?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <APDetailContent
            apSubmenu={apSubmenu}
            listEl={listData}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>
        {(apSubmenu === 'invoice' ||
          apSubmenu === 'debit-note' ||
          apSubmenu === 'credit-note' ||
          apSubmenu === 'creditor-credit-note') && (
          <CardContents
            section={{
              header: {
                title: `${routeSegments} Detail`,
                customFontSizeClass: 'xsTitle',
              },
            }}
          >
            <APItemDetailContent listItem={listData?.[itemName]} />
          </CardContents>
        )}
        {(apSubmenu === 'creditor-credit-note' ||
          apSubmenu === 'credit-note' ||
          apSubmenu === 'payment' ||
          apSubmenu === 'refund') && (
          <>
            <CardContents
              section={{
                header: {
                  title: 'Allocation(s)',
                  // rightTitle: amtStr(allocationTotal),
                  // dollarIcon: (
                  //   <img
                  //     src={OrangeDollarIcon}
                  //     style={{
                  //       width: '15px',
                  //       height: '15px',
                  //       marginBottom: '-2px',
                  //       marginRight: '3px',
                  //       marginLeft: '3px',
                  //     }}
                  //   />
                  // ),
                },
              }}
            >
              <DetailAllocationTableContent listItem={listData?.Allocation} />
            </CardContents>
          </>
        )}
      </ContentWrapper>
      <ApprovalLogDialog
        data={listData}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={routeSegments}
      />

      <TooltipAmountFooter
        data={listData}
        module={`${apSubmenu}-approved`}
        detail={true}
      />
    </>
  )
}
