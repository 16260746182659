import { formatDashDate } from '../../helpers/StringNumberFunction/formatDate'

export const advancedFilterList = list => {
  const advancedFilter = JSON.parse(localStorage.getItem('advancedFilter'))
  let newList = list
  if (advancedFilter && Object.keys(advancedFilter)?.length > 0) {
    newList = list?.filter(e => {
      let date =
        new Date(e?.DocDate) ?? e?.createdTs ?? e?.JournalDate ?? e?.DueDate
      if (!!advancedFilter?.DocDate) {
        return e?.DocDate === formatDashDate(advancedFilter?.DocDate)
      } else if (!!advancedFilter?.toDate && !!advancedFilter?.fromDate) {
        return (
          new Date(date) >= new Date(advancedFilter?.fromDate) &&
          new Date(date) <= new Date(advancedFilter?.toDate)
        )
      } else {
        return e
      }
    })
  }
  return newList
}
