import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { CopyrightFooter } from 'components/Footer/Copyright'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetCompanyNameQuery,
  useGetDebtorAccountQuery,
} from 'generated/graphql'
import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import * as yup from 'yup'

interface ARDebtorLedgerDetailbyDocDateParamsProps {
  StartDate: Date
  EndDate: Date
  DebtorAccountID: string
  TrxType: string
}

export const ARDebtorLedgerDetailbyDocDateParamsForm = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({})

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<ARDebtorLedgerDetailbyDocDateParamsProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    variables: { orderByAsc: 'DebtorName', CompanyID },
    fetchPolicy: 'network-only',
  })

  const listDebtor = getDebtorAccount?.sort((a, b) => {
    return a.DebtorName.localeCompare(b.DebtorName)
  })
  const trxType = [
    'Advance',
    'Invoice',
    'Credit Note',
    'Debit Note',
    'Official Receipt',
  ]

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/account-receivable/${CompanyID}/digital-report/ar-debtor-ledger-detail-by-doc-date/generated`,
      state: {
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
        DebtorAccountID: data.DebtorAccountID,
        TrxType: data.TrxType,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/account-receivable/${CompanyID}/digital-report`)
        }
        smTitle={'Account Receivable'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Debtor Ledger Detail by Document Date', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={listDebtor || []}
                  getOptionLabel={option => `${option?.DebtorName}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('DebtorAccountID', newValue?.DebtorAccountID)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.DebtorName}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.DebtorAccountID?.message}
                          error={errors?.DebtorAccountID ? true : false}
                          label="Debtor Account"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Debtor Account"
            name="DebtorAccountID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.DebtorAccountID?.message}
            error={errors?.DebtorAccountID ? true : false}
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={trxType || []}
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('TrxType', newValue)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.TrxType?.message}
                          error={errors?.TrxType ? true : false}
                          label="Transaction Type"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Transaction Type"
            name="TrxType"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.TrxType?.message}
            error={errors?.TrxType ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
