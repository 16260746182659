import theme from '@ifca-root/react-component/src/assets/theme'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { CardExpansionHeader } from 'components/CardExpansion/CardExpansionHeader'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import AppContext from 'containers/App/Store/AppContext'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useEffect, useState } from 'react'
import { APDetailExpansion } from '../APDetail/APDetailExpansion'
import { APItemDetailContent } from '../APDetail/APItemDetailExpansion'
import { useAPPostMutation } from '../Hooks/useAPPostingMutation'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { useHistory } from 'react-router'

export const APPostingList = (props: any) => {
  const {
    list,
    filteredList,
    listLoading,
    listStatus,
    postIDs,
    setPostIDs,
    userList,
    setOriginalListing,
    approveRejectPermissionCheck,
    apSubmenu,
    loadData,
    CompanyID,
    handleFilterDate,
  } = props

  let history = useHistory()
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [openDialog, setOpenDialog] = useState(false)

  const { globalState, dispatch }: any = useContext(AppContext as any)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [clickReject, setIsReject] = useState(false)
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  })
  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const {
    handlePost,
    mutationLoading,
    handleReject,
    postCalled,
    setErrorDia,
    errorDia,
    errMsg,
  } = useAPPostMutation({
    apSubmenu: apSubmenu,
    loadData: loadData,
    setPostIDs: setPostIDs,
    CompanyID: CompanyID,
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  // handle checkbox for all
  const handleSelectAll = e => {
    let temp = postIDs
    if (e.target.checked) {
      filteredList.map(v => {
        temp.add(v[keyID])
      })
    } else {
      temp.clear()
    }
    setPostIDs(new Set(temp))
  }

  // handle checkbox for specific checked
  const handleCheckBoxChange = (e: any, postID, index: number) => {
    let temp = postIDs
    if (!temp.has(postID)) {
      temp.add(postID)
    } else {
      temp.delete(postID)
    }

    setPostIDs(new Set(temp))
  }

  let listPostIDs: any = Array.from(postIDs || [])

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  let postValue, rejectValue, keyID, titleItemHeader, itemName
  switch (apSubmenu) {
    case 'advance':
      postValue = {
        AdvanceIDs: listPostIDs,
        CompanyID: CompanyID,
        RefTable: 'AP_Advance',
      }
      rejectValue = { AdvanceIDs: listPostIDs, input: rejectDialogInput }
      keyID = 'AdvanceID'
      break
    case 'invoice':
      postValue = {
        InvoiceIDs: listPostIDs,
        CompanyID: CompanyID,
        RefTable: 'AP_Invoice',
      }
      rejectValue = { APInvoiceIDs: listPostIDs, input: rejectDialogInput }
      itemName = 'APInvoiceItem'
      titleItemHeader = 'Invoice Item(s)'
      keyID = 'InvoiceID'

      break
    case 'refund':
      postValue = {
        RefundIDs: listPostIDs,
        CompanyID: CompanyID,
        RefTable: 'AP_Refund',
      }
      rejectValue = { RefundIDs: listPostIDs, input: rejectDialogInput }
      keyID = 'RefundID'
      break
    case 'debit-note':
      postValue = {
        DebitNoteIDs: listPostIDs,
        CompanyID: CompanyID,
        RefTable: 'AP_DebitNote',
      }
      rejectValue = { DebitNoteIDs: listPostIDs, input: rejectDialogInput }
      itemName = 'APDebitNoteItem'
      titleItemHeader = 'Debit Note Item(s)'
      keyID = 'DebitNoteID'
      break
    case 'payment':
      postValue = {
        PaymentIDs: listPostIDs,
        CompanyID: CompanyID,
        RefTable: 'AP_Payment',
      }
      rejectValue = { PaymentIDs: listPostIDs, input: rejectDialogInput }
      keyID = 'PaymentID'
      break
    case 'credit-note':
      postValue = {
        CreditNoteIDs: listPostIDs,
        CompanyID: CompanyID,
        RefTable: 'AP_CreditNote',
      }
      rejectValue = {
        CreditNoteIDs: listPostIDs,
        input: rejectDialogInput,
      }
      titleItemHeader = 'Credit Note Item(s)'
      itemName = 'APCreditNoteItem'
      keyID = 'CreditNoteID'

      break
    case 'creditor-credit-note':
      postValue = {
        CreditorCreditNoteIDs: listPostIDs,
        CompanyID: CompanyID,
        RefTable: 'AP_CreditorCreditNote',
      }
      rejectValue = {
        CreditorCreditNoteIDs: listPostIDs,
        input: rejectDialogInput,
      }
      titleItemHeader = 'Credit Note Item(s)'
      itemName = 'APCreditorCreditNoteItem'
      keyID = 'CreditorCreditNoteID'

      break

    default:
      break
  }

  /* -------------------------------------------- */
  /*                    SUBMIT                   */
  /* -------------------------------------------- */
  const onSubmit = listPostIDs => {
    handlePost({ variables: postValue })
  }

  const onSubmitReject = listPostIDs => {
    handleReject({ variables: rejectValue })
  }

  useEffect(() => {
    if (clickReject) {
      onSubmitReject(listPostIDs)
    }
  }, [clickReject])

  useEffect(() => {
    if ((list && list?.length > 0) || list?.length === 0) {
      setOriginalListing(list, v => {
        v['DocDateFormatted'] = formatDate(v.DocDate)
      })
    }
  }, [list])

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  let checkedData = []
  listPostIDs.map(ID => {
    filteredList.map(el => {
      switch (apSubmenu) {
        case 'invoice':
          if (el?.InvoiceID === ID) {
            checkedData.push(...el?.APInvoiceItem)
          }
          break
        case 'debit-note':
          if (el?.DebitNoteID === ID) {
            checkedData.push(...el?.APDebitNoteItem)
          }
          break
        case 'credit-note':
          if (el?.CreditNoteID === ID) {
            checkedData.push(...el?.APCreditNoteItem)
          }
          break
        case 'creditor-credit-note':
          if (el?.CreditorCreditNoteID === ID) {
            checkedData.push(...el?.APCreditorCreditNoteItem)
          }
          break
        case 'payment':
          if (el?.PaymentID === ID) {
            checkedData.push(el.DocAmt)
          }
          break
        case 'refund':
          if (el?.RefundID === ID) {
            checkedData.push(el.DocAmt)
          }
          break
        case 'advance':
          if (el?.AdvanceID === ID) {
            checkedData.push(el.DocAmt)
          }
          break
        default:
          break
      }
    })
  })

  return (
    <>
      {mutationLoading && <Loading />}
      {listLoading && <Loading />}

      <ContentWrapper float style={{ marginTop: isDesktop ? '70px' : '-7px' }}>
        {listStatus === 'SUBMIT' && filteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={handleSelectAll}
              color="primary"
              value={''}
              checked={postIDs?.size === filteredList?.length}
              style={{ marginLeft: '-5px', marginTop: '-5px' }}
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum: approveRejectPermissionCheck,
              })}
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}

        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.filter(handleFilterDate)
              ?.sort((a, b) => {
                return a.DocDate.localeCompare(b.DocDate)
              })
              .map((el, index) => {
                const elAllocationTotal = el?.Allocation?.reduce(
                  (prevValue, currentValue) =>
                    prevValue + currentValue?.AllocationAmt,
                  0
                )

                return (
                  <CardExpansion
                    summary={
                      <ListItem key={index} style={{ paddingBottom: '22px' }}>
                        {listStatus === 'SUBMIT' ? (
                          <FormControl
                            component="fieldset"
                            style={{ width: '16px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={el[keyID]}
                                  onChange={e => {
                                    handleCheckBoxChange(e, el[keyID], index)
                                  }}
                                  onClick={e => e.stopPropagation()}
                                  checked={postIDs?.has(el[keyID])}
                                  color="primary"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '-5px',
                                    // marginTop: '-15px',
                                  }}
                                />
                              }
                              label=""
                              name="check"
                              disabled={handlePermDisabled({
                                companyID: CompanyID,
                                permEnum: approveRejectPermissionCheck,
                              })}
                            />
                          </FormControl>
                        ) : null}

                        <>
                          <ListItemText
                            style={{
                              marginLeft:
                                listStatus === 'SUBMIT' ? '25px' : null,
                            }}
                            primary={
                              <>
                                <span
                                  className="xsTitle flex-space click-text"
                                  onClick={() => {
                                    if (apSubmenu === 'advance') {
                                      return history.push({
                                        pathname: `/account-payable/${CompanyID}/advance/${el?.AdvanceID}/preview`,
                                        state: {
                                          ...el,
                                          item: el?.APAdvanceItem,
                                        },
                                      })
                                    } else if (apSubmenu === 'payment') {
                                      return history.push({
                                        pathname: `/account-payable/${CompanyID}/payment/${el?.PaymentID}/preview`,
                                        state: {
                                          ...el,
                                        },
                                      })
                                    } else if (apSubmenu === 'credit-note') {
                                      return history.push({
                                        pathname: `/account-payable/${CompanyID}/credit-note/${el?.CreditNoteID}/preview`,
                                        state: {
                                          ...el,
                                          allocation: el?.Allocation,
                                          mainCreditOrDebit: 'Credit',
                                        },
                                      })
                                    }
                                  }}
                                >
                                  {el?.DocNo ?? el?.RefNo}
                                </span>
                                <span
                                  className="desc"
                                  style={{
                                    whiteSpace: 'break-spaces',
                                    lineBreak: 'auto',
                                  }}
                                ></span>
                                <span
                                  className="desc"
                                  style={{
                                    color: '#FF0000',
                                  }}
                                >
                                  {formatDate(el?.DocDate)}
                                </span>
                              </>
                            }
                            secondary={
                              <>
                                <Grid
                                  spacing={1}
                                  container
                                  className="table-content"
                                  style={{ marginTop: '-10px' }}
                                >
                                  <Grid item xs={6}>
                                    <span
                                      className="desc flex-space"
                                      style={{ marginBottom: '-5px' }}
                                    >
                                      {el?.CreditorAccount?.CompanyName}
                                    </span>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    style={{
                                      textAlign: 'end',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: '10px',
                                        color: 'orange',
                                        fontWeight: 500,
                                      }}
                                    >
                                      {amtStr(el?.DocAmt)}
                                    </span>
                                  </Grid>
                                </Grid>
                                <Grid
                                  spacing={1}
                                  container
                                  className="table-content"
                                >
                                  <Grid
                                    item
                                    xs={10}
                                    style={{
                                      position: 'absolute',
                                      contain: 'content',
                                      marginTop: 'inherit',
                                      maxWidth:
                                        isDesktop && globalState.drawerOpen
                                          ? '95%'
                                          : isDesktop && !globalState.drawerOpen
                                          ? '1400px'
                                          : null,
                                    }}
                                  >
                                    <div className="icon-text">
                                      <GreyTooltip
                                        onClick={e => e.stopPropagation()}
                                        disableFocusListener
                                        title={
                                          <React.Fragment>
                                            <Grid
                                              container
                                              wrap="nowrap"
                                              spacing={2}
                                            >
                                              <Grid item>
                                                <Avatar
                                                  style={{
                                                    width: '20px',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <PersonIcon
                                                    style={{
                                                      fontSize: 'small',
                                                    }}
                                                  />
                                                </Avatar>
                                              </Grid>
                                              <Grid item xs zeroMinWidth>
                                                <div
                                                  style={{ fontSize: '12px' }}
                                                >
                                                  {userList?.find(
                                                    x =>
                                                      x?.ID === el?.submittedBy
                                                  )?.name ??
                                                    userList?.find(
                                                      x =>
                                                        x?.ID ===
                                                          el?.submittedBy ??
                                                        el?.modBy
                                                    )?.name}
                                                </div>
                                                <div
                                                  style={{ fontSize: '10px' }}
                                                >
                                                  {formatDate(el?.modTs)}
                                                  &nbsp;
                                                  {formatTime(el?.modTs)}
                                                </div>
                                              </Grid>
                                            </Grid>
                                          </React.Fragment>
                                        }
                                        arrow
                                        enterTouchDelay={0}
                                      >
                                        <div>
                                          <IconText
                                            icon={
                                              <img
                                                src={SubmitterLog}
                                                style={{
                                                  width: '12px',
                                                  marginRight: '3px',
                                                }}
                                              />
                                            }
                                            font="desc"
                                          ></IconText>
                                        </div>
                                      </GreyTooltip>
                                      <span
                                        className="desc text-overflow"
                                        style={{ marginBottom: '-3px' }}
                                      >
                                        {el?.Description}
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                              </>
                            }
                          />
                        </>
                      </ListItem>
                    }
                  >
                    {
                      <>
                        <APDetailExpansion
                          listEl={el}
                          documentListing={el?.Attachment}
                          listStatus={'SUBMIT'}
                          userList={userList}
                          docType={apSubmenu}
                          mode={'expansion'}
                          itemQueryData={el[itemName]}
                        />

                        {el?.Allocation?.length > 0 && (
                          <>
                            <CardExpansionHeader
                              title={'Allocation(s)'}
                              rightTitle={amtStr(elAllocationTotal)}
                            />

                            <DetailAllocationTableContent
                              listItem={el?.Allocation}
                            />
                          </>
                        )}

                        {el[itemName]?.length > 0 && (
                          <>
                            <CardExpansionHeader title={titleItemHeader} />
                            <APItemDetailContent listItem={el[itemName]} />
                          </>
                        )}
                      </>
                    }
                  </CardExpansion>
                )
              })
          )}
        </List>
        <div style={{ marginBottom: '100px' }}></div>
      </ContentWrapper>

      <SubmitterDialog
        data={menu2?.obj}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Account Payable'}
      />

      <TooltipAmountFooter data={checkedData} module={`${apSubmenu}-submit`} />

      {listStatus === 'SUBMIT' && (
        <AccountFooter
          options={[
            {
              name: 'Reject',
              onClick: () => {
                setOpenRejectDialog(true)
              },
              color: 'secondary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum: approveRejectPermissionCheck,
                  }),
              },
            },
            {
              name: 'Approve',
              onClick: () => {
                onSubmit(listPostIDs)
              },
              color: 'primary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum: approveRejectPermissionCheck,
                  }),
              },
            },
          ]}
        />
      )}

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      <RejectDialog
        data={filteredList}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        setRejectDialogInput={setRejectDialogInput}
        setIsReject={setIsReject}
      />
    </>
  )
}
