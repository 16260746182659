import theme from '@ifca-root/react-component/src/assets/theme';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { advancedFilterList } from '@ifca-root/react-component/src/components/Filter/AdvancedFilter';
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import SubmitterLog from '../../../assets/icons/submitter-log.svg';
import { RejectDialog } from 'components/Dialog/RejectDialog';
import { TooltipAmtFooter } from '../../../components/Footer/TooltipAmtFooter';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
  ApprovalStatus,
  useActionOnGrtnStatusMutation,
} from '../../../generated/graphql';
import { useMenuOption } from 'helpers/Hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import {
  formatDate,
  formatTime,
} from '../../../helpers/StringNumberFunction/formatDate';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { GRTNContent } from './Components/GRTNContent';

export const GeneralGRTNPostingList = (props: any) => {
  const {
    list,
    filteredList,
    listLoading,
    listStatus,
    postIDs,
    setPostIDs,
    userList,
    loadGRTN,
    setOriginalListing,
  } = props;

  const companyStatus = localStorage?.getItem('companyStatus');
  let history = useHistory();
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'));
  const { CompanyID }: any = useParams();
  let location = useLocation();
  let editData: any = location?.state;
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [clickReject, setIsReject] = useState(false);
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  });

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    actionOnGRTNStatus,
    { loading: actionGRTNLoading, error: actionGRTNError },
  ] = useActionOnGrtnStatusMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: data => {
      setSnackBarMsg(SystemMsgs.updateRecord());
      setOpenSnackBar(true);
      setTimeout(() => {
        history.push({
          pathname: `/general-purchase/${CompanyID}/good-return-note`,
          state: { success: true, msgMode: 'update' },
        });
      }, 500);
      loadGRTN({
        variables: {
          CompanyID: CompanyID,
          orderByDesc: 'DocDate',
          ApprovalStatus: ApprovalStatus.Submit,
        },
      });
    },
  });

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  // handle checkbox for all employee
  const handleSelectAll = e => {
    let temp = postIDs;
    if (e.target.checked) {
      filteredList.map(v => {
        temp.add(v?.GRTNHeaderID);
      });
    } else {
      temp.clear();
    }
    setPostIDs(new Set(temp));
  };

  // handle checkbox for specific entityCoa
  const handleCheckBoxChange = (e: any, postID, index: number) => {
    let temp = postIDs;
    if (!temp.has(postID)) {
      temp.add(postID);
    } else {
      temp.delete(postID);
    }

    setPostIDs(new Set(temp));
  };

  let listPostIDs: any = Array.from(postIDs || []);

  let checkedData = [];
  listPostIDs.map(ID => {
    filteredList.map((el, i) => {
      if (el?.GRTNHeaderID === ID) {
        checkedData.push(...el?.GRTNHeaderItem);
      }
    });
  });

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker();
  /**ACL */
  /* -------------------------------------------- */
  /*                    SUBMIT                   */
  /* -------------------------------------------- */
  const onSubmit = (listPostIDs, status) => {
    actionOnGRTNStatus({
      variables: {
        GRTNHeaderID: listPostIDs,
        ApprovalStatus: status,
        CompanyID: CompanyID,
        input: {
          RejectionDate: new Date().toISOString(),
          RejectionRemark:
            status === ApprovalStatus.Rejected
              ? rejectDialogInput?.RejectionRemark
              : null,
        },
      },
    });
  };

  useEffect(() => {
    if (clickReject) {
      onSubmit(listPostIDs, ApprovalStatus.Rejected);
    }
  }, [clickReject]);

  useEffect(() => {
    if ((list && list?.length > 0) || list?.length === 0) {
      setOriginalListing(list, v => {
        v['DocDate'] = formatDate(v.DocDate);
      });
    }
  }, [list]);

  return (
    <>
      {listLoading && <Loading />}
      {actionGRTNLoading && <Loading />}
      <ContentWrapper style={{ marginTop: isDesktop ? '70px' : '-7px' }}>
        {listStatus === 'SUBMIT' && filteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={handleSelectAll}
              color="primary"
              value={''}
              checked={postIDs?.size === filteredList?.length}
              style={{ marginLeft: '-5px', marginTop: '-5px' }}
              disabled={companyStatus === 'INACTIVE' ? true : false}
              //   disabled={handlePermDisabled({
              //     companyID: CompanyID,
              //     permEnum: AcctPermission.AccPayableAdvancesApproveReject,
              //   })}
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}

        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.sort((a, b) => {
                return a.DocDate.localeCompare(b.DocDate);
              })
              .map((el, index) => {
                return (
                  <CardExpansion
                    summary={
                      <ListItem key={index}>
                        {listStatus === 'SUBMIT' ? (
                          <FormControl
                            component="fieldset"
                            style={{ width: '16px', paddingRight: '16px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={el?.GRTNHeaderID}
                                  onChange={e => {
                                    handleCheckBoxChange(
                                      e,
                                      el?.GRTNHeaderID,
                                      index,
                                    );
                                  }}
                                  onClick={e => e.stopPropagation()}
                                  checked={postIDs?.has(el?.GRTNHeaderID)}
                                  color="primary"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '-5px',
                                    // marginTop: '-15px',
                                  }}
                                  disabled={
                                    companyStatus === 'INACTIVE' ? true : false
                                  }
                                />
                              }
                              label=""
                              name="check"
                              //   disabled={handlePermDisabled({
                              //     companyID: CompanyID,
                              //     permEnum: AcctPermission.AccPayableAdvancesApproveReject,
                              //   })}
                            />
                          </FormControl>
                        ) : null}

                        <ListItemText
                          primary={
                            <>
                              <span className="desc date-width">
                                {`${formatDate(el?.DocDate)}` ?? 'N/A'}
                              </span>
                              <span className="xsTitle flex-space">
                                {el?.DocNo ?? el?.RefNo}
                              </span>
                              <span className="desc">
                                {`Trx: ${formatDate(el?.TransactionDate)}` ??
                                  'N/A'}
                              </span>
                            </>
                          }
                          secondary={
                            <>
                              <span
                                className="desc"
                                style={{
                                  whiteSpace: 'break-spaces',
                                  lineBreak: 'auto',
                                }}
                              >
                                <Tooltip
                                  disableFocusListener
                                  title={
                                    <React.Fragment>
                                      <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                          <Avatar
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                            }}
                                          >
                                            <PersonIcon
                                              style={{ fontSize: 'small' }}
                                            />
                                          </Avatar>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                          <div style={{ fontSize: '12px' }}>
                                            {userList?.find(
                                              x => x?.ID === el?.createdBy,
                                            )?.name ??
                                              userList?.find(
                                                x =>
                                                  x?.ID === el?.createdBy ??
                                                  el?.modBy,
                                              )?.name}
                                          </div>
                                          <div style={{ fontSize: '10px' }}>
                                            {formatDate(
                                              el?.modTs ?? el?.createdTs,
                                            )}
                                            &nbsp;
                                            {formatTime(
                                              el?.modTs ?? el?.createdTs,
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </React.Fragment>
                                  }
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <div>
                                    <IconText
                                      icon={
                                        <img
                                          src={SubmitterLog}
                                          style={{
                                            width: '12px',
                                            marginRight: '3px',
                                          }}
                                        />
                                      }
                                      font="desc"
                                    ></IconText>
                                  </div>
                                </Tooltip>
                              </span>
                              <span
                                className="desc flex-space"
                                style={{ display: 'contents' }}
                              >
                                {el?.Supplier?.CompanyName}
                              </span>
                            </>
                          }
                        />
                      </ListItem>
                    }
                  >
                    {el?.GRTNHeaderItem?.length > 0 && (
                      <GRTNContent mode="submit" dataDetail={el} />
                    )}
                  </CardExpansion>
                );
              })
          )}
        </List>
        <div style={{ marginBottom: '100px' }}></div>
      </ContentWrapper>
      <TooltipAmtFooter data={checkedData} module={'grtn-submit'} />

      {listStatus === 'SUBMIT' ? (
        <AccountFooter
          options={[
            {
              name: 'Reject',
              onClick: () => {
                setOpenRejectDialog(true);
                // onSubmit(listPostIDs, ApprovalStatus.Active);
              },
              color: 'secondary',
              props: {
                disabled: postIDs.size === 0,
                //   handlePermDisabled({
                //     companyID: CompanyID,
                //     permEnum: AcctPermission.AccPayableAdvancesApproveReject,
                //   }),
              },
            },
            {
              name: 'Approve',
              onClick: () => {
                onSubmit(listPostIDs, ApprovalStatus.Completed);
              },
              color: 'primary',
              props: {
                disabled: postIDs.size === 0,
                //   handlePermDisabled({
                //     companyID: CompanyID,
                //     permEnum: AcctPermission.AccPayableAdvancesApproveReject,
                //   }),
              },
            },
          ]}
        />
      ) : null}

      <RejectDialog
        data={filteredList}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        setRejectDialogInput={setRejectDialogInput}
        setIsReject={setIsReject}
      />
    </>
  );
};
