import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { CopyrightFooter } from 'components/Footer/Copyright'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetCompanyNameQuery,
  useGetCostCentreQuery,
  useGetJournalQuery,
  useGetJournalReportingQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Autocomplete } from '@material-ui/lab'

interface GLJournalByCostCentreParamsProps {
  StartDate: Date
  EndDate: Date
  JournalNo: string
  AccountCode: string
  CostCentreCode: string
}

export const GLJournalByCostCentreParamsForm = (props: any) => {
  useEffect(() => {
    console.log('GLJournalByCostCentreParamsForm')
  }, [])
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({})

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<GLJournalByCostCentreParamsProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: JournalReportingLoading,
    error: JournalReportingError,
    data: { getJournalReporting } = { getJournalReporting: [] },
  } = useGetJournalReportingQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  console.log('getJournalReporting', getJournalReporting)

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true },
  })

  const {
    loading: CostCentreLoading,
    error: CostCentreErrors,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      //   orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/general-ledger/${CompanyID}/digital-report/gl-journal-costcentre-report/generated`,
      state: {
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
        JournalNo: data.JournalNo,
        AccountCode: data.AccountCode,
        CostCentreCode: data.CostCentreCode,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/digital-report`)
        }
        smTitle={'General Ledger'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'GL Journal By Department', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {
                console.log(date)
              }}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {
                console.log(date)
              }}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="JournalNo"
            label="Journal No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.JournalNo?.message}
            error={errors?.JournalNo ? true : false}
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getMasterCOA?.sort((a, b) => {
                      return a.Code.localeCompare(b.Code)
                    }) || []
                  }
                  getOptionLabel={option => `${option?.Code}  ${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('AccountCode', newValue?.Code)
                  }}
                  renderOption={(props, option) => {
                    return (
                      <span>
                        {props?.Code} {props?.Name}
                      </span>
                    )
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.AccountCode?.message}
                          error={errors?.AccountCode ? true : false}
                          label="Account Code"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Account Code"
            name="AccountCode"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.AccountCode?.message}
            error={errors?.AccountCode ? true : false}
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getCostCentre || []}
                  getOptionLabel={option => `${option?.Code}  ${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('CostCentreCode', newValue?.Code)
                  }}
                  renderOption={(props, option) => {
                    return (
                      <span>
                        {props?.Code} {props?.Name}
                      </span>
                    )
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.CostCentreCode?.message}
                          error={errors?.CostCentreCode ? true : false}
                          label="Department Code"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Department Code"
            name="CostCentreCode"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.CostCentreCode?.message}
            error={errors?.CostCentreCode ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
