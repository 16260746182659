import React from 'react'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns'

export const TableGL = (props: any) => {
  const { data, journalData, docType, docCode } = props
  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  return (
    <>
      <div style={{ fontSize }}>
        <div style={{ display: 'flex' }}>
          {/*////////Left side/////////*/}
          <span style={{ width: '60%' }}>
            <table>
              <tbody>
                <tr>
                  <td>Description </td>
                  <td>:</td>
                  <td>{data?.Description}</td>
                </tr>
                {(!!data?.FYear && !!data?.FPeriod) ||
                (!!data?.StartPeriod && !!data?.StartYear) ? (
                  <>
                    <tr>
                      <td>Financial Year </td>
                      <td>:</td>
                      <td>{data?.FYear ?? data?.StartYear}</td>
                    </tr>
                    <tr>
                      <td>Financial Period </td>
                      <td>:</td>
                      <td>{data?.FPeriod ?? data?.StartPeriod}</td>
                    </tr>
                  </>
                ) : null}
                {!!data?.AdjYearOrPeriod ? (
                  <>
                    <tr>
                      <td>Adjustment Type </td>
                      <td>:</td>
                      <td>{data?.AdjYearOrPeriod}</td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </span>

          {/*////////Right side/////////*/}
          <span style={{ width: '40%' }}>
            <table>
              <tbody>
                {(!!data?.DocNo || !!journalData?.JournalNo) && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Journal No. </td>
                    <td>:</td>
                    <td>
                      <b>
                        {!data?.DocNo
                          ? journalData?.JournalNo ?? 'NA'
                          : data?.DocNo}
                      </b>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Journal Date </td>
                  <td>:</td>
                  {!!data?.DocDate || !!data?.TransactionDate ? (
                    <td>
                      {format(
                        new Date(data?.DocDate ?? data?.TransactionDate),
                        'dd/MM/yyyy'
                      )}
                    </td>
                  ) : null}
                </tr>
                {!!data?.JournalType?.JournalType && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Journal Type </td>
                    <td>:</td>
                    <td>{data?.JournalType?.JournalType}</td>
                  </tr>
                )}
                <tr>
                  <td style={{ paddingRight: '20px' }}>Reference No. </td>
                  <td>:</td>
                  <td>{!data?.RefNo ? 'NA' : data?.RefNo}</td>
                </tr>

                {data?.DueDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Due Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DueDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </span>
        </div>
      </div>
    </>
  )
}

export default TableGL
