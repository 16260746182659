import {
  useGetJournalProcessingLazyQuery,
  useGetAuditAdjustmentLazyQuery,
  useGetRecurringJournalLazyQuery,
} from 'generated/graphql'
import { useEffect } from 'react'

export const useGLQueries: any = ({
  accountType,
  docType,
  CompanyID,
  DocumentID,
}) => {
  //----------GL Queries----------//
  const [
    fetchJournalProcessing,
    {
      loading: JournalProcessingLoading,
      called: JournalProcessingCalled,
      //error: JournalProcessingError,
      data: { getJournalProcessing } = { getJournalProcessing: [] },
    },
  ] = useGetJournalProcessingLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchRecurringJournal,
    {
      loading: RecurringJournalLoading,
      called: RecurringJournalCalled,
      // error: RecurringJournalError,
      data: { getRecurringJournal } = { getRecurringJournal: [] },
    },
  ] = useGetRecurringJournalLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    fetchAuditAdjustment,
    {
      loading: AuditAdjustmentLoading,
      called: AuditAdjustmentCalled,
      // error: AuditAdjustmentError,
      data: { getAuditAdjustment } = { getAuditAdjustment: [] },
    },
  ] = useGetAuditAdjustmentLazyQuery({
    fetchPolicy: 'network-only',
  })

  let loading, called, listData, subMenuName, primaryKey

  switch (accountType) {
    case 'general-ledger':
      switch (docType) {
        case 'journal-processing':
          loading = JournalProcessingLoading
          called = JournalProcessingCalled
          listData = getJournalProcessing
          subMenuName = 'Journal Entries'
          primaryKey = 'JournalProcessingID'
          break

        case 'recurring-journal':
          loading = RecurringJournalLoading
          called = RecurringJournalCalled
          listData = getRecurringJournal
          subMenuName = 'Recurring Journal Entries'
          primaryKey = 'RecurringJournalID'
          break

        case 'audit-adjustment':
          loading = AuditAdjustmentLoading
          called = AuditAdjustmentCalled
          listData = getAuditAdjustment
          subMenuName = 'Audit Adjustment Entries'
          primaryKey = 'AuditAdjustmentID'
          break
      }
      break
  }

  useEffect(() => {
    switch (accountType) {
      case 'general-ledger':
        switch (docType) {
          case 'journal-processing':
            fetchJournalProcessing({
              variables: {
                CompanyID: CompanyID,
              },
            })
            break

          case 'recurring-journal':
            fetchRecurringJournal({
              variables: {
                CompanyID: CompanyID,
              },
            })
            break

          case 'audit-adjustment':
            fetchAuditAdjustment({
              variables: {
                CompanyID: CompanyID,
              },
            })
            break
        }
        break
    }
  }, [accountType, docType])

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
  }
}
