import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import {
  useGetCompanyQuery,
  useGetCostCentreQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom'
import html2pdf from 'html2pdf.js/dist/html2pdf.min'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { useGLQueries } from './Query/useGLQueries'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import TableGL from './PDFTemplate/TableGL'
import Table2GL from './PDFTemplate/Table2GL'
import './OR.scss'
import HeaderGL from './PDFTemplate/HeaderGL'
import FooterGL from './PDFTemplate/FooterGL'

export const PDFPreviewGL = (props: any) => {
  const location: any = useLocation()
  const journalDetail: any = location?.state
  const { accountType, docType } = props
  let history = useHistory()
  const { styles } = useMobilePDFZoom()
  const [visibled, setVisibility] = useState(null)
  const {
    CompanyID,
    JournalProcessingID,
    RecurringJournalID,
    AuditAdjustmentID,
    JournalID,
  }: any = useParams()

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: CostCentreLoading,
    error: CostCentreError,
    data: { getCostCentre } = { getCostCentre: [] },
  } = useGetCostCentreQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const { loading, listData, primaryKey } = useGLQueries({
    accountType,
    docType,
    CompanyID,
  })

  const previewDetail: any = listData?.filter(
    v =>
      v?.[primaryKey] ===
      (JournalProcessingID ?? RecurringJournalID ?? AuditAdjustmentID)
  )[0]

  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 8
  } else {
    fontSize = 12
  }

  //html2pdf options
  let opt = {
    pagebreak: { mode: 'avoid-all', before: '#pagebreak' },
  }

  const [screenWidth] = useMediaQuery()
  // let watemarkStyle

  // if (screenWidth < 769) {
  //   watemarkStyle = {
  //     watermark: {
  //       position: 'absolute' as 'absolute',
  //       top: '20%',
  //       width: '80%',
  //       textAlign: 'center',
  //       opacity: `${visibled}`,
  //       fontSize: '18px',
  //       userSelect: 'none',
  //       transform: 'rotate(-45deg)',
  //     },
  //   }
  // } else {
  //   watemarkStyle = {
  //     watermark: {
  //       position: 'absolute' as 'absolute',
  //       top: '40%',
  //       width: '80%',
  //       textAlign: 'center',
  //       opacity: `${visibled}`,
  //       fontSize: '18px',
  //       userSelect: 'none',
  //       transform: 'rotate(-40deg)',
  //     },
  //   }
  // }
  let fontStyle
  fontStyle = {
    padding: '10px',
    fontSize: fontSize,
    textAlign: 'center',
  }

  let routeSegments
  let title
  let smallTitle
  let name
  let DocumentID
  let docCode
  switch (accountType) {
    case 'general-ledger':
      smallTitle = smTitle.GENERAL_LEDGER
      name = 'DocNo'
      switch (docType) {
        case 'journal-processing':
          DocumentID = JournalProcessingID
          routeSegments = 'Journal Entries'
          title = 'JOURNAL VOUCHER'
          docCode = 'JV'
          break
        case 'recurring-journal':
          DocumentID = RecurringJournalID
          routeSegments = 'Recurring Journal Entries'
          title = 'RECURRING JOURNAL'
          docCode = 'RJ'
          break
        case 'audit-adjustment':
          DocumentID = AuditAdjustmentID
          routeSegments = 'Audit Adjustment Entries'
          title = 'AUDIT ADJUSTMENT JOURNAL'
          docCode = 'AA'
          break
      }
      break
  }

  const onPrint = () => {
    listData
      ?.filter(x => x?.[primaryKey] === previewDetail?.[primaryKey])
      ?.map((v, i) => {
        let element = document.getElementById('element-to-print-' + i)
        html2pdf()
          .set({
            filename: `${title} (${listData?.filter(
              x => x?.[primaryKey] === v?.[primaryKey]
            )[0]?.[name] ?? journalDetail?.JournalNo}).pdf`,
          })
          .from(element)
          .save()
      })
  }

  return (
    <>
      {CostCentreLoading && <Loading />}
      {loading && <Loading />}
      {UserLoading && <Loading />}
      {companyLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (!!JournalID) {
            history.push({
              pathname: `/${accountType}/${CompanyID}/${docType}/${DocumentID}/${JournalID}/journal-Detail`,
              state: previewDetail,
            })
          } else if (previewDetail?.ApprovalStatus === 'COMPLETED') {
            history.push({
              pathname: `/${accountType}/${CompanyID}/${docType}/${DocumentID}/detail`,
              state: previewDetail,
            })
          } else {
            history.push({
              pathname: `/${accountType}/${CompanyID}/${docType}`,
              state: previewDetail,
            })
          }
        }}
        smTitle={smallTitle}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[
          {
            name: 'Print',
            current: true,
          },
        ]}
      />

      <ContentWrapper footer>
        {listData
          ?.filter(x => x?.[primaryKey] === previewDetail?.[primaryKey])
          ?.map((v, i) => {
            return (
              <div
                style={{ padding: '14px' }}
                key={i}
                id={'element-to-print-' + i}
              >
                <div style={styles.supercontainer}>
                  <div style={styles.container}>
                    <HeaderGL />

                    <div className="row">
                      <div>
                        <p style={fontStyle}>
                          <b>{title}</b>
                        </p>
                      </div>
                    </div>

                    <div style={{ border: '0.5px solid', margin: '10px' }}>
                      <TableGL
                        data={previewDetail}
                        docType={docType}
                        docCode={docCode}
                        journalData={journalDetail}
                      />
                      <Table2GL
                        data={previewDetail}
                        docType={docType}
                        getCostCentre={getCostCentre}
                      />
                    </div>

                    <FooterGL
                      data={previewDetail}
                      docType={docType}
                      getUser={getUsersByAccountAndSoftware}
                    />
                  </div>
                </div>
              </div>
            )
          })}

        <Footer
          options={[
            {
              name: 'Download',
              color: 'primary',
              onClick: () => {
                onPrint()
              },
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
