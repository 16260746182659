import { yupResolver } from '@hookform/resolvers'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetBillItemDocument,
  RecordStatus,
  useCreateBillItemMutation,
  useDeleteBillItemMutation,
  useGetBillItemQuery,
  useGetEntityCoaQuery,
  useGetHomeSummaryQuery,
  useGetMasterCoaQuery,
  useGetTaxSchemeQuery,
  useUpdateBillItemMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/CustomHooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import * as yup from 'yup'

export interface BillItemProps {
  Name: string
  Description: string
  TaxSchemeID: string
  MasterCOAID: string
  TaxRate: number
}

export const BillItemListing = (props: any) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()
  const [billItemDia, setBillItemDia] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [deleteType, setDeleteType] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const { CompanyID, DebtorID }: any = useParams()

  const { accountType, docType, subMenuName, smTitle, routeSegments } = props

  const BillItemSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    TaxSchemeID: CommonYupValidation.requireField('Tax Code is required'),
    MasterCOAID: CommonYupValidation.requireField(
      'GL Account Code is required'
    ),
  })

  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    errors,
    reset,
  } = useForm<BillItemProps>({
    mode: 'onSubmit',
    resolver: yupResolver(BillItemSchema),
  })
  //.........Query............//
  const {
    loading,
    called,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const {
    loading: BillItemLoading,
    error: BillItemError,
    data: { getBillItem } = { getBillItem: [] },
  } = useGetBillItemQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  useEffect(() => {
    if (getBillItem && getBillItem?.length > 0) {
      setOriginalListing(getBillItem)
    }
  }, [getBillItem])

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true },
  })
  const {
    loading: entityCOALoading,
    error: entityCOAError,
    data: { getEntityCOA } = { getEntityCOA: [] },
  } = useGetEntityCoaQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })
  const masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA
          ?.map(coa => coa?.MasterCOA)
          ?.filter(coa => coa?.IsLastNode === true && coa?.IsControl === false)
      : getMasterCOA?.filter(
          coa => coa?.IsLastNode === true && coa?.IsControl === false
        )

  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    onCompleted: () => {},
    variables: {
      RecordStatus: RecordStatus.Active,
      AccTaxClass: 'OUTPUT',
      orderByAsc: 'Code',
    },
  })

  //.........Mutation............//

  const [
    createBillItem,
    { loading: createLoading, error: createError },
  ] = useCreateBillItemMutation({
    onError: error => {},
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setBillItemDia(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
    },
  })

  const [
    updateBillItem,
    { loading: updateLoading, error: updateError },
  ] = useUpdateBillItemMutation({
    onError: error => {},
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setBillItemDia(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
    },
  })

  const [
    deleteBillItem,
    { loading: deleteBillItemLoading, error: deleteBillItemError },
  ] = useDeleteBillItemMutation({
    onError: error => {
      console.log('error')
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())
    },
  })

  const [deleteError, setDeleteError] = useState(false)

  const [TaxRate, setTaxRate] = useState(0)

  const [selectedTax, setSelectedTax] = useState(null)

  const taxEffectiveDate: any = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
  )
    .toISOString()
    .slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      // el?.AccTaxClass === 'OUTPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate &&
      el?.RecordStatus === 'ACTIVE'
  )

  const handleTaxChange = (TaxSchemeID, taxEffectiveDate) => {
    setSelectedTax(taxSchemeInput.find(x => x?.TaxSchemeID === TaxSchemeID))
    setTaxRate(
      taxSchemeInput.filter(x => x?.TaxSchemeID === TaxSchemeID)[0]
        .LatestTax === null
        ? taxSchemeInput
            .filter(x => x?.TaxSchemeID === TaxSchemeID)[0]
            ?.TaxEffective.reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
        : taxSchemeInput
            .filter(x => x?.TaxSchemeID === TaxSchemeID)[0]
            ?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            )
            .reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
    )
  }

  const onSubmit = data => {
    isEdit === false
      ? createBillItem({
          variables: {
            input: {
              Name: data.Name,
              Description: data.Description,
              TaxSchemeID: data.TaxSchemeID,
              MasterCOAID: data.MasterCOAID,
              TaxRate: TaxRate,
            },
          },
          refetchQueries: [
            {
              query: GetBillItemDocument,
              variables: { orderByAsc: 'Name' },
            },
          ],
        })
      : updateBillItem({
          variables: {
            input: {
              BillItemID: menu?.ID,
              Name: data.Name,
              Description: data.Description,
              TaxSchemeID: data.TaxSchemeID,
              MasterCOAID: data.MasterCOAID,
              TaxRate: TaxRate,
            },
          },
          refetchQueries: [
            {
              query: GetBillItemDocument,
              variables: { orderByAsc: 'Name' },
            },
          ],
        })
    clearErrors()
  }

  const InactiveReactive = status => {
    updateBillItem({
      variables: {
        input: {
          BillItemID: menu?.ID,
          Name: menu?.obj.Name,
          Description: menu?.obj.Description,
          TaxSchemeID: menu?.obj.TaxSchemeID,
          MasterCOAID: menu?.obj.MasterCOAID,
          RecordStatus: status,
          TaxRate: TaxRate,
        },
      },
      refetchQueries: [
        {
          query: GetBillItemDocument,
          variables: { orderByAsc: 'Name' },
        },
      ],
    })
  }

  const handleDelete = () => {
    deleteBillItem({
      variables: { input: { BillItemID: menu?.obj?.BillItemID } },
      refetchQueries: [
        {
          query: GetBillItemDocument,
          variables: { orderByAsc: 'Name' },
        },
      ],
    })
    setOpenDeleteDialog(false)
  }

  return (
    <>
      {loading && <Loading />}
      {masterCOALoading && <Loading />}
      {deleteBillItemLoading && <Loading />}
      {BillItemLoading && <Loading />}
      {entityCOALoading && <Loading />}
      {TaxLoading && <Loading />}
      {createLoading && <Loading />}
      {updateLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/account-receivable/general-setting`)} //<- back button action
        smTitle={'Account Receivable'}
        title={user?.companyName}
        routeSegments={[{ name: 'AR' }, { name: 'Bill Items', current: true }]}
      />

      <SearchHeader
        title="Bill Items"
        value={`${advancedFilterList(filteredList)?.length}`}
        fixed
        search
        onChangeAction={e => {
          handleSearch(e?.target?.value, ['Name', 'Description'])
        }}
        onCloseAction={() => handleSearch('', [])}
      />

      <ContentWrapper footer search>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList
              .sort((a, b) => {
                return (
                  Object.values(RecordStatus).indexOf(a?.RecordStatus) -
                  Object.values(RecordStatus).indexOf(b?.RecordStatus)
                )
              })
              ?.map((el, index) => {
                return (
                  <ListItem selected={el.RecordStatus === 'INACTIVE'}>
                    <ListItemText
                      primary={
                        <>
                          <span className="xsTitle flex-space">
                            {el?.Name}{' '}
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <Grid container>
                            <Grid item xs={12}>
                              <span className="desc ">
                                {el?.MasterCOA?.Code} | {el?.MasterCOA?.Name}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <span className="desc">
                                {el?.TaxScheme?.Code}{' '}
                                {el?.TaxScheme?.LatestTax?.Rate}%
                              </span>
                            </Grid>
                          </Grid>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="more"
                        aria-controls="menu-list"
                        aria-haspopup="true"
                        onClick={e => handleClick(e, el.BillItemID, index, el)}
                      >
                        <MoreVert />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
          )}
        </List>
        <FloatButton
          onClick={() => {
            setIsEdit(false)
            resetMenu()
            setBillItemDia(true)
          }}
        />
      </ContentWrapper>
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            // setValue('TaxSchemeID', menu?.obj?.TaxSchemeID)
            // setValue('MasterCOAID', menu?.obj?.MasterCOAID)
            setIsEdit(true)
            setBillItemDia(true)
          }}
        >
          Edit
        </MenuItem>
        {menu?.obj?.RecordStatus === RecordStatus.Active ? (
          <MenuItem onClick={() => InactiveReactive(RecordStatus.Inactive)}>
            Inactive
          </MenuItem>
        ) : (
          <MenuItem onClick={() => InactiveReactive(RecordStatus.Active)}>
            Reactive
          </MenuItem>
        )}

        <MenuItem
          onClick={() => setOpenDeleteDialog(true)}
          disabled={menu?.obj?.IsUsed ? true : false}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>
      <Box mt={1}>
        <CommonDialog
          fullWidth={true}
          open={billItemDia}
          onClose={() => {
            reset()
            setBillItemDia(false)
          }}
          sections={{
            header: {
              dynamic: (
                <div className="">
                  <div className="dialog-dynamic-content">
                    <div className="session">
                      <div className="flex session">
                        <div
                          className="dialog-title flex-space"
                          style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: 'orange',
                          }}
                        >
                          {'Bill Item'}
                        </div>
                        {isEdit === false ? (
                          <div
                            className="dialog-title right-text"
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              color: 'orange',
                            }}
                          >
                            {'New'}
                          </div>
                        ) : (
                          <div
                            className="dialog-title right-text"
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              color: 'orange',
                            }}
                          >
                            {'Edit'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ),
            },
            body: () => (
              <>
                <div className="content-container">
                  <Controller
                    as={TextField}
                    name="Name"
                    required
                    label="Name"
                    control={control}
                    ref={register}
                    defaultValue={menu?.obj?.Name}
                    helperText={errors?.Name?.message}
                    error={errors?.Name ? true : false}
                  />
                  <Controller
                    as={TextField}
                    name="Description"
                    required
                    label="Description"
                    control={control}
                    ref={register}
                    defaultValue={menu?.obj?.Description}
                    helperText={errors?.Description?.message}
                    error={errors?.Description ? true : false}
                  />

                  <Controller
                    control={control}
                    name="TaxSchemeID"
                    ref={register()}
                    defaultValue={menu?.obj?.TaxSchemeID}
                    onChange={({ value, newValue }) => {
                      return newValue?.TaxSchemeID
                    }}
                    helperText={errors?.TaxSchemeID?.message}
                    error={errors?.TaxSchemeID ? true : false}
                    render={({ onChange, onBlur, value }) => {
                      const defVal = getTaxScheme?.filter(
                        x => x?.TaxSchemeID === menu?.obj?.TaxSchemeID
                      )[0]
                      return (
                        //  {
                        // return
                        <Autocomplete
                          className="mat-new"
                          defaultValue={defVal}
                          options={
                            getTaxScheme?.sort((a, b) => {
                              return a.Code.localeCompare(b.Code)
                            }) || []
                          }
                          getOptionLabel={option => `${option?.Code}`}
                          fullWidth
                          renderOption={option => (
                            <>
                              <div>
                                <div className="">{option?.Code}</div>
                              </div>
                            </>
                          )}
                          onChange={(value, newValue: any) => {
                            handleTaxChange(
                              newValue?.TaxSchemeID,
                              taxEffectiveDate
                            )
                            onChange(newValue?.TaxSchemeID)
                          }}
                          PopperComponent={AccCodeDropdownFullWidth}
                          renderInput={(params: any) => {
                            return (
                              <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                label="Tax Code"
                                //defaultValue={defVal?.Code}
                                helperText={errors?.TaxSchemeID?.message}
                                error={errors?.TaxSchemeID ? true : false}
                                required
                              />
                            )
                          }}
                        />
                      )
                    }}
                    // }
                  />

                  <Controller
                    control={control}
                    name="MasterCOAID"
                    ref={register()}
                    defaultValue={menu?.obj?.MasterCOAID}
                    onChange={({ value, newValue }) => {
                      return newValue?.MasterCOAID
                    }}
                    helperText={errors?.MasterCOAID?.message}
                    error={errors?.MasterCOAID ? true : false}
                    render={({ onChange, onBlur, value }) => {
                      const defVal = masterCOAList?.filter(
                        x => x?.MasterCOAID === menu?.obj?.MasterCOAID
                      )[0]

                      return (
                        //  {
                        // return
                        <Autocomplete
                          className="mat-new"
                          defaultValue={defVal}
                          options={
                            masterCOAList?.sort((a, b) => {
                              return a.Code.localeCompare(b.Code)
                            }) || []
                          }
                          getOptionLabel={option =>
                            `${option?.Code} | ${option?.Name}`
                          }
                          fullWidth
                          renderOption={(props, option) => (
                            <>
                              <div>
                                <div>
                                  <span className="xsTitle">{props?.Code}</span>
                                </div>
                                <div className="desc">{props?.Name}</div>
                              </div>
                            </>
                          )}
                          onChange={(value, newValue: any) => {
                            onChange(newValue?.MasterCOAID)
                          }}
                          PopperComponent={AccCodeDropdownFullWidth}
                          renderInput={(params: any) => {
                            return (
                              <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                label="GL Account  Code"
                                //defaultValue={defVal?.Code}
                                helperText={errors?.MasterCOAID?.message}
                                error={errors?.MasterCOAID ? true : false}
                                required
                              />
                            )
                          }}
                        />
                      )
                    }}
                    // }
                  />
                </div>
              </>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => {
                      reset()
                      setBillItemDia(false)
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Confirm',
                  props: {
                    onClick: () => {
                      handleSubmit(onSubmit)()
                    },
                    variant: 'contained',
                    color: 'primary',
                    form: 'createUOM-form',
                    type: 'submit',
                  },
                },
              ],
            },
          }}
        />
      </Box>
      <CommonDialog
        fullWidth={true}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        sections={{
          header: {
            title: `Item`,
            rightText: 'Delete',
            infoLine: menu?.obj?.Name,
          },

          body: () => (
            <div className="content-wrap full">
              <span className="mdDesc full">Confirm to delete?</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setOpenDeleteDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => handleDelete(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <CommonDialog
        fullWidth={true}
        open={deleteError}
        onClose={() => setDeleteError(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="xsTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        Error!
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span className="text-noflow mdDesc">{`You are not allowed to delete this bill item as it's already been used`}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setDeleteError(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
