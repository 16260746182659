import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { format } from 'date-fns'
import React from 'react'

export const TableAPPayment = (props: any) => {
  // const { docDate, docNo, description, dueDate, reminderAmt } = props;
  const { data, docType, allocation } = props
  // console.log(docType, 'dataa') not deleting this cause want to check the data 11/8/2023

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 10
  }
  ///// style border table
  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }
  const thleftnarrow = {
    width: '15%',

    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }

  const thright = {
    textAlign: 'right' as 'right',
    backgroundColor: '#e7e6e6',
    borderBottom: '0.5px solid',
  }

  const thcenter = {
    width: '15%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  }

  const tdright = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
  }

  const tdleft = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
  }

  const tdrightamt = {
    width: '15%',
    textAlign: 'right' as 'right',
  }

  return (
    <>
      <div style={{ marginTop: '20px' }}>
        <table
          style={{
            width: '100%',
            fontSize,
            // borderCollapse: 'collapse',
            borderSpacing: '0 1em',
          }}
        >
          <tr>
            <th style={thleftnarrow}>Doc Date</th>
            <th style={thleftnarrow}>Doc No</th>
            <th style={thleft}>Description</th>

            <th style={thright}>
              Amount
              <span>(RM)</span>
            </th>
          </tr>
          {docType === 'payment' &&
            allocation?.map((v, i) => (
              <>
                <tr>
                  <td style={{ textAlign: 'left' }}>
                    {format(
                      new Date(v?.CreditDocDate ?? v?.DebitDocDate),
                      'dd/MM/yyyy'
                    )}
                  </td>
                  <td style={{ textAlign: 'left' }}>
                    {v?.CreditDocNo ?? v?.DebitDocNo ?? 'NA'}
                  </td>
                  <td style={{ textAlign: 'left' }}>{v?.Description}</td>
                  <td style={tdrightamt}>{amtStr(v?.AllocationAmt)}</td>
                </tr>
              </>
            ))}
        </table>
      </div>

      <div style={{ marginTop: '185px' }}>
        <table style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}>
          <tr>
            <td style={tdleft}>{`Total Amount (RM)`}</td>

            <td style={tdright}>{amtStr(data?.DocAmt)}</td>
          </tr>
        </table>
      </div>
    </>
  )
}

export default TableAPPayment
