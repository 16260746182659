import '@ifca-root/react-component/src/assets/styles/components/totalAmountFooter.scss'
import theme from '@ifca-root/react-component/src/assets/theme'
import { useMediaQuery } from '@material-ui/core'
import AppContext from 'containers/App/Store/AppContext'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext } from 'react'
import { useHistory } from 'react-router'

export interface TotalAmountFooterProps {
  dialogPopUp?: any
  dialogPopUp2?: any
  docAmt?: any
  totalTaxAmt?: any
  debitAmt?: any
  creditAmt?: any
  label1?: string
  label2?: string
  outstandingAmt?: any
  singleLabel?: boolean
  singleFooter?: boolean
  rightLabel?: any
  multiLabel?: boolean
  multiFooter?: boolean
  sharedFooter?: any
  isHavePath?: any
  path?: any
  detail?: boolean
  taxInfo?: boolean
  taxInfoDetail?: boolean
  balanceInfo?: boolean
  balanceAmt?: any
  TotalAllocationAmt?: any
  debitCreditInfo?: boolean
  recordSelected?: boolean
  countRecord?: any
  totalRecord?: any
}

export const TotalAmountFooter = (props: TotalAmountFooterProps) => {
  let history = useHistory()
  const {
    dialogPopUp,
    dialogPopUp2,
    docAmt,
    totalTaxAmt,
    debitAmt,
    creditAmt,
    label1,
    label2,
    outstandingAmt,
    singleLabel,
    singleFooter,
    rightLabel,
    multiLabel,
    multiFooter,
    sharedFooter,
    isHavePath,
    path,
    detail,
    taxInfo,
    taxInfoDetail,
    balanceInfo,
    balanceAmt,
    TotalAllocationAmt,
    debitCreditInfo,
    recordSelected,
    countRecord,
    totalRecord,
  } = props

  const { globalState, dispatch }: any = useContext(AppContext as any)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  return (
    <div
      className={detail ? 'totalAmountFooterDetail' : 'totalAmountFooter'}
      style={{
        width:
          isDesktop && globalState.drawerOpen
            ? 'calc(100% - 288px)'
            : !isDesktop
            ? '100%'
            : 'calc(100% - 48px)',
        marginLeft:
          isDesktop && globalState.drawerOpen
            ? '264px'
            : !isDesktop
            ? '0px'
            : '24px',
        bottom: '0px',
      }}
    >
      {taxInfo ? (
        <>
          <span
            className="xxTitle flex-space"
            style={{
              fontWeight: 500,
              marginLeft: '17px',
              color: 'white',
              fontSize: '10.5px',
              height: '9px',
              marginTop: '7px',
            }}
          >
            Tax:
            {amtStr(totalTaxAmt)}
          </span>
          <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '43px',
            }}
          >
            Total:
            <span style={{ marginLeft: '3px' }} />
            {amtStr(docAmt)}
          </span>
        </>
      ) : debitCreditInfo ? (
        <>
          <span
            style={{
              fontSize: '10.5px',
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              marginRight: '43px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
            }}
          >
            CR:
            <span style={{ marginLeft: '3px' }} />
            {creditAmt}
          </span>
          <span
            style={{
              fontSize: '10.5px',
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              marginRight: '20px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
            }}
          >
            DR:
            <span style={{ marginLeft: '3px' }} />
            {debitAmt}
          </span>
        </>
      ) : taxInfoDetail ? (
        <>
          <span
            className="xxTitle flex-space"
            style={{
              fontWeight: 500,
              marginLeft: '17px',
              color: 'white',
              fontSize: '10.5px',
              height: '9px',
              marginTop: '7px',
            }}
          >
            Tax:
            <span style={{ marginLeft: '3px' }} />
            {amtStr(totalTaxAmt)}
          </span>
          <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '20px',
            }}
          >
            Total:
            <span style={{ marginLeft: '3px' }} />
            {amtStr(docAmt)}
          </span>
        </>
      ) : balanceInfo ? (
        <>
          <span
            className="xxTitle flex-space"
            style={{
              fontWeight: 500,
              marginLeft: '17px',
              color: 'white',
              fontSize: '10.5px',
              height: '9px',
              marginTop: '7px',
            }}
          >
            Balance:
            <span style={{ marginLeft: '3px' }} />
            {amtStr(balanceAmt)}
          </span>
          <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '20px',
            }}
          >
            Total:
            <span style={{ marginLeft: '3px' }} />
            {amtStr(TotalAllocationAmt)}
          </span>
        </>
      ) : recordSelected ? (
        <>
          <span
            className="xxTitle flex-space"
            style={{
              fontWeight: 500,
              marginLeft: '17px',
              color: 'white',
              fontSize: '10.5px',
              height: '9px',
              marginTop: '7px',
            }}
          >
            No. of Records:
            <span style={{ marginLeft: '3px' }} />
            {` ${countRecord}/${totalRecord}`}
          </span>
          <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '20px',
            }}
          >
            Total:
            <span style={{ marginLeft: '3px' }} />
            {amtStr(docAmt)}
          </span>
        </>
      ) : (
        <>
          {/* <span
            style={{
              fontSize: '10px',
              //   verticalAlign: '4px',
              fontWeight: 500,
              //   marginRight: '3px',
              height: '9px',
              //   marginBottom: '2px',
              //   verticalAlign: '4px',
              marginLeft: '10px',
              marginRight: '20px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
            }}
          >
            Total
            <MonetizationOnIcon
              style={{
                fontSize: '15px',
                //   verticalAlign: '4px',
                fontWeight: 500,
                //   marginRight: '3px',
                height: '9px',
                //   marginBottom: '2px',
                //   verticalAlign: '4px',
                // marginLeft: '10px',
                // marginRight: '-3px',
                // color: 'white',
                justifyContent: 'right',
              }}
            />
            {amtStr(docAmt)}
          </span> */}
          {/* <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '43px',
            }}
          >
            Total:
           
            {amtStr(docAmt)}
          </span> */}

          {/* <span
            className="xxTitle flex-space"
            style={{
              fontWeight: 500,
              marginLeft: '17px',
              color: 'white',
              fontSize: '10.5px',
              height: '9px',
              marginTop: '7px',
            }}
          >
            Total Amount:
          </span>
          <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '20px',
            }}
          >
           
            {amtStr(docAmt)}
          </span> */}
          <span
            style={{
              fontWeight: 500,
              height: '9px',
              marginLeft: '10px',
              color: 'white',
              float: 'right',
              marginTop: '7px',
              fontSize: '10.5px',
              marginRight: '20px',
            }}
          >
            Total:
            <span style={{ marginLeft: '3px' }} />
            {amtStr(docAmt)}
          </span>
        </>
      )}
    </div>
  )
}
