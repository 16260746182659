import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { APPaymentDetail } from './APPaymentDetail/APPaymentDetail'
import { APPaymentForm } from './APPaymentForm/APPaymentForm'
import { PDFPreview } from 'containers/ARModule/Hooks/PDFPreview'
import { APMain } from '../APMain'
import { APDetail } from '../APDetail/APDetail'
import { APAllocation } from '../APAllocation/APAllocation'

const paymentRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment/filter',
    },
    component: (
      <AdvancedFilter
        mode="payment"
        app="account-payable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment',
    },
    component: (
      <APMain
        apSubmenu="payment"
        routeSegments="Payment"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment/:DocumentID/detail',
    },
    component: (
      <APDetail
        apSubmenu="payment"
        routeSegments="Payment"
        accountType="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment/:PaymentID/detail/workdesk',
    },
    component: <APPaymentDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/payment/:PaymentID/detail/workdesk-history',
    },
    component: <APPaymentDetail mode="workdesk-history" />,
  },

  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/payment/:PaymentID/preview`,
    },
    component: <PDFPreview accountType="account-payable" docType="payment" />,
  },

  /* -------------------------------------------- */
  /*                ALLOCATION                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment/:ID/allocation',
    },
    component: (
      <APAllocation
        apSubmenu="payment"
        routeSegments="Payment"
        accountType="account-payable"
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment/add',
    },
    component: <APPaymentForm formMode="add" type="PAYMENT_AP" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment/:PaymentID/edit',
    },
    component: <APPaymentForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment/:PaymentID/approve-reject',
    },
    component: <APPaymentForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/payment/:PaymentID/resubmit',
    },
    component: <APPaymentForm formMode="resubmit" />,
  },
]

export default paymentRoutes
