import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { maxHeight } from '@material-ui/system'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import { useGetCompanyNameQuery } from 'generated/graphql'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const TrialBalanceByEntityReport = () => {
  const history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const editData = location?.state as any

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
  })

  console.log(editData, 'editData')

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: CompanyID,
    },
    {
      name: 'fYear',
      value: editData?.Year,
    },
    {
      name: 'fPeriod',
      value: editData?.Period,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      <MainHeader
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/digital-report`)
        }
        mainBtn="close"
        smTitle="General Ledger"
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reporting' },
          { name: 'Trial Balance By Entity', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('TrialBalanceByEntityReport', parameters),
          host: accountxReportUrl,
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
