import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { useGetCompanyQuery } from 'generated/graphql'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'

export const HeaderGL = (props: any) => {
  const { logoPos, logoUrl, sAccountData } = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { CompanyID }: any = useParams()

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })
  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 8
  } else {
    fontSize = 12
  }

  let logoStyle, containerStyle, fontStyle
  fontStyle = {
    padding: '10px',
    fontSize: fontSize,
    textAlign: 'center',
  }

  return (
    <>
      <div style={containerStyle}>
        <div style={fontStyle}>
          <b>{getCompany[0]?.Name}</b>
          <br />
          {` (Co. No. : ${getCompany[0]?.CompanyRegNo}, `}
          {!!getCompany[0]?.GSTNo ? (
            <>GST No. : {getCompany[0]?.GSTNo}</>
          ) : (
            <>SST No. : {getCompany[0]?.SSTNo}</>
          )}
          {`)`}
          <br />
          <br />
          {getCompany[0]?.Address?.address}
          <br />
          {getCompany[0]?.Address?.country == 'Singapore'
            ? `${getCompany[0]?.Address?.country} ${getCompany[0]?.Address?.postCode}`
            : `${getCompany[0]?.Address?.postCode} ${getCompany[0]?.Address?.city}, ${getCompany[0]?.Address?.state}, ${getCompany[0]?.Address?.country}`}
          <br />
          Tel No. {getCompany[0]?.ContactNo}
          {/* Tel No. {user?.contactNo} */}
          {/* {getCompany[0]?.CompanyTax === 'GST_NO' ? (
            <>
              <br />
              GST Registration No. : {getCompany[0]?.GSTNo}
            </>
          ) : (
            <>
              <br />
              SST Registration No. : {getCompany[0]?.SSTNo}
            </>
          )} */}
        </div>
      </div>
    </>
  )
}

export default HeaderGL
