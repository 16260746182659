import CardContentsWithInput from '@ifca-root/react-component/src/components/CardList/CardContentsWithInput'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  ListItem,
  TextField,
} from '@material-ui/core'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

interface AllocationProps {
  fields: any
  watch: any
  errors: any
  register: any
  unregister?: any
  control: any
  setValue: any
  clearErrors: any
  setError: any
  allocatedObj: any
  docAmt?: number
  totalAmt?: number
  headerStyle?: any
  headerRightTitle?: any
  headerLeftTitle?: any
  hasAmount?: number
  hasCheckbox?: Boolean
}

export const FormAllocationTableContent = (props: AllocationProps) => {
  const {
    fields,
    watch,
    errors,
    register,
    unregister,
    control,
    setValue,
    clearErrors,
    setError,
    allocatedObj,
    docAmt,
    totalAmt,
    headerStyle,
    headerRightTitle,
    hasCheckbox,
    headerLeftTitle,
    // customTrigger,
  } = props

  // const triggerVar = customTrigger ? customTrigger : [fields, allocatedObj]
  useEffect(
    () => {
      fields?.map((alloc, index) => {
        register(`Allocation[${index}].AllocationID`)
        hasCheckbox && register(`Allocation[${index}].IsChecked`)
        register(`Allocation[${index}].CanAllocate`)
        register(`Allocation[${index}].CreditOrDebit`)
        register(`Allocation[${index}].DocDate`)
        register(`Allocation[${index}].DocNo`)
        register(`Allocation[${index}].Description`)
        register(`Allocation[${index}].AllocationAmt`)
        register(`Allocation[${index}].BalanceAmt`)
        register(`Allocation[${index}].${alloc?.CreditOrDebit}ID`)
        register(`Allocation[${index}].${alloc?.CreditOrDebit}RefTable`)
        register(`Allocation[${index}].${alloc?.CreditOrDebit}DocNo`)

        setValue(`Allocation[${index}].AllocationID`, alloc?.AllocationID)
        hasCheckbox &&
          setValue(
            `Allocation[${index}].IsChecked`,
            alloc?.AllocationID ? true : false
          )
        setValue(`Allocation[${index}].CanAllocate`, alloc?.CanAllocate)
        setValue(`Allocation[${index}].CreditOrDebit`, alloc?.CreditOrDebit)
        setValue(`Allocation[${index}].DocDate`, alloc?.DocDate)
        setValue(`Allocation[${index}].DocNo`, alloc?.DocNo)
        setValue(`Allocation[${index}].Description`, alloc?.Description)
        setValue(
          `Allocation[${index}].AllocationAmt`,
          Number(allocatedObj[alloc[`${alloc?.CreditOrDebit}ID`]] ?? 0)
        )
        setValue(
          `Allocation[${index}].BalanceAmt`,
          Number(alloc?.BalanceAmt ?? 0)
        )
        setValue(
          `Allocation[${index}].${alloc?.CreditOrDebit}ID`,
          alloc[`${alloc?.CreditOrDebit}ID`]
        )
        setValue(
          `Allocation[${index}].${alloc?.CreditOrDebit}RefTable`,
          alloc[`${alloc?.CreditOrDebit}RefTable`]
        )
        setValue(
          `Allocation[${index}].${alloc?.CreditOrDebit}DocNo`,
          alloc[`${alloc?.CreditOrDebit}DocNo`]
        )
      })
    },
    [fields, allocatedObj]
    // triggerVar
  )

  const calcDefaultValue = alloc => {
    if (docAmt) {
      if (docAmt - totalAmt - alloc?.BalanceAmt < 0) {
        return docAmt - totalAmt
      } else if (docAmt - totalAmt - alloc?.BalanceAmt > 0) {
        return alloc?.BalanceAmt
      } else {
        return 0
      }
    } else {
      return alloc?.BalanceAmt
    }
  }

  return (
    <CardContentsWithInput
      IsDifferentColour={true}
      //IsBold={true}
      headerDifferentColour
      section={{
        header: {
          title: headerLeftTitle ?? 'Document',
          rightTitle: headerRightTitle ?? amtStr(totalAmt),

          // docAmt: docAmt,
          // totalAmt: totalAmt,
          error: totalAmt && docAmt && totalAmt > docAmt ? true : false,
          errorMsg:
            totalAmt && docAmt && totalAmt > docAmt
              ? 'Amount inserted exceed document amount'
              : null,
          style: headerStyle
            ? headerStyle
            : {
                backgroundColor: '#e9e9e9',
                color: '#000000',
                fontWeight: '700',
              },
        },
      }}
      style={{ padding: 'initial' }}
    >
      <div className="table-wrap rm-padding">
        {fields?.length > 0 ? (
          watch('Allocation')?.map((alloc, index) => {
            const allocation = `Allocation[${index}]`

            let disableAllocation
            if (alloc?.CanAllocate === true) {
              disableAllocation = false
            } else if (alloc?.CanAllocate === false) {
              disableAllocation = true
            } else {
              disableAllocation = false
            }

            return (
              <>
                <ListItem
                  key={index}
                  style={{ paddingRight: '0px', paddingLeft: '12px' }}
                >
                  {hasCheckbox && (
                    <FormControl component="fieldset" style={{ width: '24px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={alloc?.[`${alloc?.CreditOrDebit}ID`]}
                            onChange={e => {
                              e.stopPropagation()
                              setValue(
                                `${allocation}.IsChecked`,
                                !watch(`${allocation}.IsChecked`)
                              )
                              setValue(
                                `${allocation}.AllocationAmt`,
                                watch(`${allocation}.IsChecked`)
                                  ? allocatedObj[
                                      alloc[`${alloc?.CreditOrDebit}ID`]
                                    ] ?? calcDefaultValue(alloc)
                                  : 0
                              )
                            }}
                            checked={watch(`${allocation}.IsChecked`)}
                            disabled={
                              disableAllocation ||
                              (!watch(`${allocation}.IsChecked`) &&
                                docAmt - totalAmt <= 0)
                            }
                            color="primary"
                          />
                        }
                        label=""
                        name="check"
                      />
                    </FormControl>
                  )}
                  <Grid
                    // spacing={1}
                    container
                    className="table-content"
                    key={alloc?.id}
                    style={{ padding: '8px 12px 4px 8px' }}
                  >
                    <Grid
                      item
                      xs={7}
                      style={{
                        placeSelf: 'start',
                      }}
                    >
                      <div
                        className="desc text-noflow"
                        style={{ lineBreak: 'anywhere', lineHeight: '20px' }}
                      >
                        <div>
                          <span className="mdDesc flex-space">
                            {formatDate(alloc?.DocDate)}
                          </span>

                          <span
                            className="mdDesc "
                            style={{ marginLeft: '15px', fontWeight: 700 }}
                          >
                            {alloc?.[`${alloc?.CreditOrDebit}DocNo`]}
                          </span>
                        </div>
                        <div>
                          <span
                            className="desc flex-space"
                            style={{
                              whiteSpace: 'initial',
                              display: 'inline-block',
                              color: 'grey',
                            }}
                          >
                            {alloc?.Description}
                          </span>
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      style={{
                        placeSelf: 'start',
                        marginRight: 'auto',
                        textAlign: 'right',
                        flexWrap: 'wrap',
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                    >
                      <div
                        className="desc text-noflow"
                        style={{ lineBreak: 'anywhere', lineHeight: '20px' }}
                      >
                        <div>
                          <span className="mdDesc" style={{ fontWeight: 500 }}>
                            {`O/S: ${amtStr(alloc?.BalanceAmt)}`}
                          </span>
                        </div>

                        {hasCheckbox ? (
                          watch(`${allocation}.IsChecked`) && (
                            <div>
                              <span
                                className="mdDesc alloc-amt-input"
                                style={{ height: '10px' }}
                              >
                                <Controller
                                  ref={register}
                                  name={`${allocation}.AllocationAmt`}
                                  control={control}
                                  required
                                  defaultValue={
                                    allocatedObj[
                                      alloc[`${alloc?.CreditOrDebit}ID`]
                                    ] ?? watch(`${allocation}.AllocationAmt`)
                                  }
                                  render={({ onChange, onBlur, value }) => (
                                    <NumberFormat
                                      thousandSeparator
                                      disabled={disableAllocation}
                                      allowNegative={false}
                                      defaultValue={
                                        // allocatedObj[
                                        //   alloc[`${alloc?.CreditOrDebit}ID`]
                                        // ] ??
                                        // alloc?.BalanceAmt ??
                                        // 0
                                        allocatedObj[
                                          alloc[`${alloc?.CreditOrDebit}ID`]
                                        ] ??
                                        watch(`${allocation}.AllocationAmt`)
                                      }
                                      customInput={TextField}
                                      className="p-l-10 round-input"
                                      name={`${allocation}.AllocationAmt`}
                                      required
                                      variant="outlined"
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      onValueChange={values => {
                                        let { floatValue } = values
                                        register(`${allocation}.AllocationAmt`)
                                        setValue(
                                          `${allocation}.AllocationAmt`,
                                          floatValue
                                        )
                                        if (floatValue > alloc?.BalanceAmt) {
                                          setError(
                                            `${allocation}.AllocationAmt`,
                                            {
                                              message: 'Cannot exceed balance',
                                            }
                                          )
                                        } else {
                                          clearErrors(
                                            `${allocation}.AllocationAmt`
                                          )
                                        }
                                      }}
                                      helperText={
                                        errors.Allocation &&
                                        errors.Allocation[index]?.AllocationAmt
                                          ? errors.Allocation[index]
                                              ?.AllocationAmt?.message
                                          : null
                                      }
                                      error={
                                        errors.Allocation &&
                                        errors.Allocation[index]?.AllocationAmt
                                      }
                                    />
                                  )}
                                />
                              </span>
                            </div>
                          )
                        ) : (
                          <span
                            className="mdDesc alloc-amt-input"
                            style={{ height: '10px' }}
                          >
                            <Controller
                              ref={register}
                              name={`${allocation}.AllocationAmt`}
                              control={control}
                              required
                              defaultValue={
                                allocatedObj[
                                  alloc[`${alloc?.CreditOrDebit}ID`]
                                ] ?? 0
                              }
                              render={({ onChange, onBlur, value }) => (
                                <NumberFormat
                                  thousandSeparator
                                  disabled={disableAllocation}
                                  allowNegative={false}
                                  defaultValue={
                                    allocatedObj[
                                      alloc[`${alloc?.CreditOrDebit}ID`]
                                    ] ?? 0
                                  }
                                  customInput={TextField}
                                  className="p-l-10 round-input"
                                  name={`${allocation}.AllocationAmt`}
                                  required
                                  variant="outlined"
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  onValueChange={values => {
                                    let { floatValue } = values
                                    register(`${allocation}.AllocationAmt`)
                                    setValue(
                                      `${allocation}.AllocationAmt`,
                                      floatValue
                                    )
                                    if (floatValue > alloc?.BalanceAmt) {
                                      setError(`${allocation}.AllocationAmt`, {
                                        message: 'Cannot exceed balance',
                                      })
                                    } else {
                                      clearErrors(`${allocation}.AllocationAmt`)
                                    }
                                  }}
                                  helperText={
                                    errors.Allocation &&
                                    errors.Allocation[index]?.AllocationAmt
                                      ? errors.Allocation[index]?.AllocationAmt
                                          ?.message
                                      : null
                                  }
                                  error={
                                    errors.Allocation &&
                                    errors.Allocation[index]?.AllocationAmt
                                  }
                                />
                              )}
                            />
                          </span>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
                {index !== fields?.length - 1 && (
                  <Divider
                    variant="fullWidth"
                    style={{ background: '#E4E4E4' }}
                  ></Divider>
                )}
              </>
            )
          })
        ) : (
          <EmptyList title="No Record found" />
        )}
      </div>
    </CardContentsWithInput>
  )
}
