import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { useMediaQuery } from '@material-ui/core'
import BlackDollarIcon from 'assets/icons/Money/black-dollar.svg'
import OrangeDollarIcon from 'assets/icons/Money/orange-dollar.svg'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetApAdvancebyStatusDocument,
  RecordStatus,
  useDocumentListingQuery,
  useGetApAdvanceQuery,
  useGetCompanyNameQuery,
  useGetTaxSchemeQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostApAdvanceMutation,
  UserSelection,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'

import { APAdvanceDetailContent } from './APAdvanceDetailContent'

export const APAdvanceDetail = props => {
  let history = useHistory()
  const { CompanyID, AdvanceID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { menu, handleClick: handleClick2 }: any = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  let location = useLocation()
  const editData = location?.state as any
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { handleStatusColor } = useColorStatusList()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, RecordStatus: RecordStatus.Active },
  })

  const {
    loading: APAdvanceLoading,
    error: APAdvanceError,
    data: { getAPAdvance } = {
      getAPAdvance: [],
    },
  } = useGetApAdvanceQuery({
    fetchPolicy: 'network-only',
    variables: {
      AdvanceID: AdvanceID,
    },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    loading: TaxLoading,
    error: TaxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: {
      TaxSchemeID: getAPAdvance[0]?.TaxSchemeID,
    },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: AdvanceID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const statusText = jStatus => {
    switch (jStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Submitted'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }

  const [
    postAPAdvance,
    {
      loading: postAdvanceLoading,
      called: postAdvanceCalled,
      error: postAdvanceError,
    },
  ] = usePostApAdvanceMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-payable/${CompanyID}/advances`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = (data, status) => {
    postAPAdvance({
      variables: {
        AdvanceIDs: AdvanceID,
        CompanyID,
        RefTable: 'AP_Advance',
      },
      refetchQueries: [
        {
          query: GetApAdvancebyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Completed, ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const allocationTotal = getAPAdvance[0]?.Allocation?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.AllocationAmt,
    0
  )

  return (
    <>
      {postAdvanceLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {APAdvanceLoading && <Loading />}
      {TaxLoading && <Loading />}

      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Account Payable'}
        title={`${getCompany[0]?.Name}`}
        routeSegments={[
          { name: 'Account Payable' },
          { name: 'AP Submenu' },
          { name: 'Advance', current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getAPAdvance[0]?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getAPAdvance[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {getAPAdvance[0]?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <img
              src={BlackDollarIcon}
              style={{
                width: '12px',
                marginBottom: '-2px',
                marginRight: '3px',
              }}
            />
            <span className="desc flex-space" style={{ paddingTop: '1px' }}>
              {amtStr(getAPAdvance[0]?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <APAdvanceDetailContent
            listEl={getAPAdvance[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        {getAPAdvance[0]?.Allocation?.length > 0 && (
          <>
            <CardContents
              section={{
                header: {
                  title: 'Allocation(s)',
                  rightTitle: amtStr(allocationTotal),
                  customFontSizeClass: 'xsTitle',
                  dollarIcon: (
                    <img
                      src={OrangeDollarIcon}
                      style={{
                        width: '15px',
                        height: '15px',
                        marginBottom: '-2px',
                        marginRight: '3px',
                        marginLeft: '3px',
                      }}
                    />
                  ),
                },
              }}
            >
              <DetailAllocationTableContent
                listItem={getAPAdvance[0]?.Allocation}
              />
            </CardContents>
          </>
        )}
      </ContentWrapper>
      <ApprovalLogDialog
        data={getAPAdvance[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Advance'}
      />

      {getAPAdvance[0]?.ApprovalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      ) : null}
    </>
  )
}
