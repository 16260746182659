import { AdvancedFilter } from 'components/Filter/AdvancedFilter'
import React from 'react'
import { APRefundDetail } from './APRefundDetail/APRefundDetail'
import { APRefundForm } from './APRefundForm/APRefundForm'
import { APRefundMain } from './APRefundMain'
import { APMain } from '../APMain'
import { APDetail } from '../APDetail/APDetail'
import { PDFPreview } from 'containers/ARModule/Hooks/PDFPreview'

const refundRoutes = [
  /* -------------------------------------------- */
  /*                ADVANCED FILTER               */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/refund/filter',
    },
    component: (
      <AdvancedFilter
        mode="refund"
        app="account-payable"
        hasStatus
        hasDocDate
        hasDateRange
      />
    ),
  },

  /* -------------------------------------------- */
  /*                     MAIN                     */
  /* -------------------------------------------- */
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/refund',
    },
    component: (
      <APMain
        apSubmenu="refund"
        routeSegments="Refund"
        accountType="account-payable"
      />
    ),
    // component: <APRefundMain />,
  },

  /* -------------------------------------------- */
  /*                    DETAIL                    */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/refund/:DocumentID/detail',
    },
    component: (
      <APDetail
        apSubmenu="refund"
        routeSegments="Refund"
        accountType="account-payable"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/refund/:RefundID/detail/workdesk',
    },
    component: <APRefundDetail mode="workdesk" />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/refund/:RefundID/detail/workdesk-history',
    },
    component: <APRefundDetail mode="workdesk-history" />,
  },
  {
    props: {
      exact: true,
      path: `/account-payable/:CompanyID/refund/:RefundID/preview`,
    },
    component: <PDFPreview accountType="account-payable" docType="refund" />,
  },

  /* -------------------------------------------- */
  /*                     FORM                     */
  /* -------------------------------------------- */

  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/refund/add',
    },
    component: <APRefundForm formMode="add" type="PAYMENT_AP" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/refund/:RefundID/edit',
    },
    component: <APRefundForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/refund/:RefundID/approve-reject',
    },
    component: <APRefundForm mode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/refund/:RefundID/resubmit',
    },
    component: <APRefundForm formMode="resubmit" />,
  },
]

export default refundRoutes
