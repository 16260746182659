import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Button } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import React from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
interface ShareType {
  simple: boolean
  setSimple: any
  title: string
  shareTitle: string
  URL: string
  emailShare?: boolean
  setSimpleEmail?: any
}
const dateFormat = require('dateformat')

export const htmlConverter = ({ formContent, name }) => {
  var blob = new Blob([formContent], { type: 'text/html' })
  blob['name'] = name + '.html'
  blob['lastModified'] = Date.UTC(
    dateFormat(new Date(), 'yyyy'),
    dateFormat(new Date(), 'mm'),
    dateFormat(new Date(), 'dd')
  )
  blob['lastModifiedDate'] = new Date()

  return blob
}

const ShareDialog = (props: ShareType) => {
  const {
    simple,
    setSimple,
    title,
    URL,
    shareTitle,
    emailShare,
    setSimpleEmail,
  } = props
  return (
    <CommonDialog
      fullWidth={true}
      open={simple}
      onClose={() => setSimple(false)}
      sections={{
        header: {
          dynamic: (
            <div className="">
              <div className="dialog-dynamic-content">
                <div className="session">
                  <div className="title">{title}</div>
                </div>
              </div>
            </div>
          ),
        },
        body: () => (
          <>
            <div
              className="content-container"
              style={{ justifyContent: 'space-evenly' }}
            >
              <WhatsappShareButton title={shareTitle} url={URL}>
                <WhatsappIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </WhatsappShareButton>
              <FacebookShareButton title={shareTitle} url={URL}>
                <FacebookIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </FacebookShareButton>
              <TelegramShareButton title={shareTitle} url={URL}>
                <TelegramIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </TelegramShareButton>
              <TwitterShareButton title={shareTitle} url={URL}>
                <TwitterIcon
                  style={{ marginRight: '5px' }}
                  size={40}
                  round={true}
                />
              </TwitterShareButton>

              {emailShare && (
                <Button onClick={() => setSimpleEmail(true)}>
                  <MailOutlineIcon
                    style={{ marginRight: '5px', fontSize: 40 }}
                  />
                </Button>
              )}
            </div>
          </>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setSimple(false),
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  )
}

const ConvertFormToURL = ({ formContent, name }) => {
  var contentType = 'text/html'
  const blob = new Blob([formContent], { type: contentType })
  blob['name'] = name + '.html'
  blob['lastModified'] = Date.UTC(
    dateFormat(new Date(), 'yyyy'),
    dateFormat(new Date(), 'mm'),
    dateFormat(new Date(), 'dd')
  )
  blob['lastModifiedDate'] = new Date()

  return blob
}
export { ShareDialog as default, ConvertFormToURL }
