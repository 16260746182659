import {
  AcctPermission,
  useGetApAdvancebyStatusLazyQuery,
  useGetApCreditNotebyStatusLazyQuery,
  useGetApCreditorCreditNoteByStatusLazyQuery,
  useGetApDebitNotebyStatusLazyQuery,
  useGetApInvoicebyStatusLazyQuery,
  useGetApPaymentbyStatusLazyQuery,
  useGetApRefundbyStatusLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import React, { useEffect } from 'react'

export const useAPListingData: any = ({
  accountType,
  apSubmenu,
  CompanyID,
  setOriginalListing,
}) => {
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  let listStatus = getSearch?.option ?? 'ACTIVE'

  //----------AP Queries----------//
  const {
    loading: getUsersLoading,
    error: getUsersError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const [
    loadApAdvance,
    {
      loading: ApAdvancebyStatusLoading,
      called: ApAdvanceCalled,
      data: { getAPAdvancebyStatus } = {
        getAPAdvancebyStatus: [],
      },
    },
  ] = useGetApAdvancebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPAdvancebyStatus }) => {
      if (getAPAdvancebyStatus?.length > 0) {
        setOriginalListing(getAPAdvancebyStatus)
      } else {
        setOriginalListing([])
      }
    },
  })
  const [
    loadApInvoice,
    {
      loading: ApInvoicebyStatusLoading,
      called: ApInvoiceCalled,
      data: { getAPInvoicebyStatus } = {
        getAPInvoicebyStatus: [],
      },
    },
  ] = useGetApInvoicebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPInvoicebyStatus }) => {
      if (getAPInvoicebyStatus?.length > 0) {
        setOriginalListing(getAPInvoicebyStatus)
      } else {
        setOriginalListing([])
      }
    },
  })

  const [
    loadApRefund,
    {
      loading: ApRefundbyStatusLoading,
      called: ApRefundCalled,
      data: { getAPRefundbyStatus } = {
        getAPRefundbyStatus: [],
      },
    },
  ] = useGetApRefundbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPRefundbyStatus }) => {
      if (getAPRefundbyStatus?.length > 0) {
        setOriginalListing(getAPRefundbyStatus)
      } else {
        setOriginalListing([])
      }
    },
  })
  const [
    loadApCreditNote,
    {
      loading: ApCreditNotebyStatusLoading,
      called: ApCreditNoteCalled,
      data: { getAPCreditNotebyStatus } = {
        getAPCreditNotebyStatus: [],
      },
    },
  ] = useGetApCreditNotebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditNotebyStatus }) => {
      if (getAPCreditNotebyStatus?.length > 0) {
        setOriginalListing(getAPCreditNotebyStatus)
      } else {
        setOriginalListing([])
      }
    },
  })

  const [
    loadApCreditorCreditNote,
    {
      loading: ApCreditorCreditNotebyStatusLoading,
      called: ApCreditorCreditNoteCalled,
      data: { getAPCreditorCreditNotebyStatus } = {
        getAPCreditorCreditNotebyStatus: [],
      },
    },
  ] = useGetApCreditorCreditNoteByStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPCreditorCreditNotebyStatus }) => {
      if (getAPCreditorCreditNotebyStatus?.length > 0) {
        setOriginalListing(getAPCreditorCreditNotebyStatus)
      } else {
        setOriginalListing([])
      }
    },
  })

  const [
    loadApDebitNote,
    {
      loading: ApDebitNotebyStatusLoading,
      called: ApDebitNoteCalled,
      data: { getAPDebitNotebyStatus } = {
        getAPDebitNotebyStatus: [],
      },
    },
  ] = useGetApDebitNotebyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPDebitNotebyStatus }) => {
      if (getAPDebitNotebyStatus?.length > 0) {
        setOriginalListing(getAPDebitNotebyStatus)
      } else {
        setOriginalListing([])
      }
    },
  })

  const [
    loadAPPayment,
    {
      loading: getAPPaymentbyStatusLoading,
      called: ApPaymentCalled,
      data: { getAPPaymentbyStatus } = {
        getAPPaymentbyStatus: [],
      },
    },
  ] = useGetApPaymentbyStatusLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getAPPaymentbyStatus }) => {
      if (getAPPaymentbyStatus?.length > 0 && listStatus === 'COMPLETED') {
        setOriginalListing(getAPPaymentbyStatus)
      } else if (
        getAPPaymentbyStatus?.length > 0 &&
        listStatus !== 'COMPLETED'
      ) {
        setOriginalListing(
          getAPPaymentbyStatus?.filter(x => !x?.BatchPaymentID)
        )
      } else {
        setOriginalListing([])
      }
    },
  })

  let loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
    userList = getUsersByAccountAndSoftware

  switch (apSubmenu) {
    case 'advance':
      loading = ApAdvancebyStatusLoading
      called = ApAdvanceCalled
      listData = getAPAdvancebyStatus
      subMenuName = 'Advance'
      primaryKey = 'AdvanceID'
      createPermissionCheck = AcctPermission.AccPayableAdvancesCreate
      updatePermissionCheck = AcctPermission.AccPayableAdvancesUpdate
      deletePermissionCheck = AcctPermission.AccPayableAdvancesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableAdvancesApproveReject
      break
    case 'invoice':
      loading = ApInvoicebyStatusLoading
      called = ApInvoiceCalled
      listData = getAPInvoicebyStatus
      subMenuName = 'Invoice'
      primaryKey = 'InvoiceID'
      createPermissionCheck = AcctPermission.AccPayableInvoicesCreate
      updatePermissionCheck = AcctPermission.AccPayableInvoicesUpdate
      deletePermissionCheck = AcctPermission.AccPayableInvoicesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableInvoicesApproveReject
      break
    case 'refund':
      loading = ApRefundbyStatusLoading
      called = ApRefundCalled
      listData = getAPRefundbyStatus
      subMenuName = 'ApRefund'
      primaryKey = 'RefundID'
      createPermissionCheck = AcctPermission.AccPayableRefundCreate
      updatePermissionCheck = AcctPermission.AccPayableRefundUpdate
      deletePermissionCheck = AcctPermission.AccPayableRefundDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableRefundApproveReject
      break
    case 'debit-note':
      loading = ApDebitNotebyStatusLoading
      called = ApDebitNoteCalled
      listData = getAPDebitNotebyStatus
      subMenuName = 'DebitNote'
      primaryKey = 'DebitNoteID'
      createPermissionCheck = AcctPermission.AccPayableDebitNotesCreate
      updatePermissionCheck = AcctPermission.AccPayableDebitNotesUpdate
      deletePermissionCheck = AcctPermission.AccPayableDebitNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableDebitNotesApproveReject
      break
    case 'credit-note':
      loading = ApCreditNotebyStatusLoading
      called = ApCreditNoteCalled
      listData = getAPCreditNotebyStatus
      subMenuName = 'CreditNote'
      primaryKey = 'CreditNoteID'
      createPermissionCheck = AcctPermission.AccPayableCreditNotesCreate
      updatePermissionCheck = AcctPermission.AccPayableCreditNotesUpdate
      deletePermissionCheck = AcctPermission.AccPayableCreditNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableCreditNotesApproveReject
      break
    case 'creditor-credit-note':
      loading = ApCreditorCreditNotebyStatusLoading
      called = ApCreditorCreditNoteCalled
      listData = getAPCreditorCreditNotebyStatus
      subMenuName = 'CreditorCreditNote'
      primaryKey = 'CreditorCreditNoteID'
      createPermissionCheck = AcctPermission.AccPayableCreditorCreditNotesCreate
      updatePermissionCheck = AcctPermission.AccPayableCreditorCreditNotesUpdate
      deletePermissionCheck = AcctPermission.AccPayableCreditorCreditNotesDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayableCreditorCreditNotesApproveReject
      break
    case 'payment':
      loading = getAPPaymentbyStatusLoading
      called = ApPaymentCalled
      listData = getAPPaymentbyStatus
      subMenuName = 'Payment'
      primaryKey = 'PaymentID'
      createPermissionCheck = AcctPermission.AccPayablePaymentCreate
      updatePermissionCheck = AcctPermission.AccPayablePaymentUpdate
      deletePermissionCheck = AcctPermission.AccPayablePaymentDelete
      approveRejectPermissionCheck =
        AcctPermission.AccPayablePaymentApproveReject
      break
  }

  const loadData = () => {
    switch (apSubmenu) {
      case 'advance':
        loadApAdvance({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'invoice':
        loadApInvoice({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'refund':
        loadApRefund({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'debit-note':
        loadApDebitNote({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'credit-note':
        loadApCreditNote({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'creditor-credit-note':
        loadApCreditorCreditNote({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
      case 'payment':
        loadAPPayment({
          variables: { CompanyID: CompanyID, StatusArr: [listStatus] },
        })
        break
    }
  }

  useEffect(() => {
    loadData()
  }, [apSubmenu, listStatus])

  return {
    loading,
    called,
    listData,
    subMenuName,
    primaryKey,
    userList,
    loadData,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    approveRejectPermissionCheck,
  }
}
