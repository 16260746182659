import React, { lazy } from 'react'

const BankProfileListing = lazy(() =>
  import('./BankProfileListing').then(module => ({
    default: module.BankProfileListing,
  }))
)

const bankProfileRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/general-setting/bank-profile',
    },
    component: <BankProfileListing />,
  },
]

export default bankProfileRoutes
