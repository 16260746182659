export const SystemMsgs = {
  name: () => {
    return 'Name is required'
  },
  source: () => {
    return 'Source is required'
  },
  amountType: () => {
    return 'Amount type is required'
  },
  unitPrice: () => {
    return 'Unit Price is required'
  },
  expense: () => {
    return 'Expense Class is required'
  },
  reason: () => {
    return 'Reason is required'
  },
  loginName: () => {
    return 'Login Name is required'
  },
  contactNo: () => {
    return 'Contact No. is required'
  },
  docAmt: () => {
    return 'Journal Amt is required'
  },
  mobileNo: () => {
    return 'Mobile No is required'
  },
  phoneNo: () => {
    return 'Phone No is required'
  },
  referenceNo: () => {
    return 'Reference No. is required'
  },
  description: () => {
    return 'Description is required'
  },
  designation: () => {
    return 'Designation is required'
  },
  adjYearOrPeriod: () => {
    return 'Adjustment Type is required'
  },
  accountCode: () => {
    return 'Account Code is required'
  },
  journalAmount: () => {
    return 'Journal Amount cannot be zero'
  },
  routineJournal: () => {
    return 'Routine Journal is required'
  },
  journalType: () => {
    return 'Journal Type is required'
  },
  startYear: () => {
    return 'Start Year is required'
  },
  startPeriod: () => {
    return 'Start Period is required'
  },
  noofRecurrance: () => {
    return 'No. of Recurrence is required'
  },
  currency: () => {
    return 'Currency is required'
  },
  baseCurrency: () => {
    return 'Base currency is required'
  },
  interval: () => {
    return 'Interval is required'
  },
  specialInstruction: () => {
    return 'Special instruction is required'
  },
  createNewRecord: () => {
    return 'Record added successfully.'
  },
  postNewRecord: () => {
    return 'Record(s) approved successfully.'
  },
  rejectNewRecord: () => {
    return 'Record(s) rejected successfully.'
  },
  createNewRecordFail: () => {
    return 'Failed to add new record.'
  },
  allocationRecord: () => {
    return 'Updated allocation in record.'
  },
  creditorName: () => {
    return 'Creditor Name is required'
  },
  creditorNo: () => {
    return 'Creditor No. is required'
  },
  creditorType: () => {
    return 'Creditor Type is required'
  },
  debtorType: () => {
    return 'Debtor Type is required'
  },
  creditTerms: () => {
    return 'Credit Terms is required'
  },
  creditCardType: () => {
    return 'Credit Card Type is required'
  },
  updateRecord: () => {
    return 'Record updated successfully.'
  },
  updateRecordFail: () => {
    return 'Failed to update record.'
  },
  stockAccountCode: () => {
    return 'Stock GL Account Code is required'
  },
  expenseAccountCode: () => {
    return 'Expense Account Code is required'
  },
  cancelRecord: () => {
    return 'Record cancelled successfully.'
  },
  deleteRecord: () => {
    return 'Record deleted successfully.'
  },
  deleteRecordFail: () => {
    return 'Failed to delete record.'
  },
  deleteError: () => {
    return 'Not allowed to delete the following item, as there are transactions occured already'
  },
  inactiveRecord: () => {
    return 'Record inactivated successfully.'
  },
  email: () => {
    return 'Email is required'
  },
  password: () => {
    return 'Password is required'
  },
  reinvite: () => {
    return 'Resend invitation successfully'
  },
  region: () => {
    return 'Region is required'
  },
  GSTRegNo: () => {
    return 'GST Registration No. is required'
  },
  forgotPassword: () => {
    return 'Please check your inbox for an email we just sent you with instructions on how to reset your password and log into your account.'
  },
  company: () => {
    return 'Company is required'
  },
  companyName: () => {
    return 'Company Name is required'
  },
  companyRegNo: () => {
    return 'Company Registration No. is required'
  },
  bankName: () => {
    return 'Bank Name is required'
  },
  bankCode: () => {
    return 'Bank Code is required'
  },
  bankAccNo: () => {
    return 'Bank Account No. is required'
  },
  accNo: () => {
    return 'Account No. is required'
  },
  swiftCode: () => {
    return 'Swift Code. is required'
  },
  effectiveDate: () => {
    return 'Effective date is required'
  },
  regNo: () => {
    return 'Registration No. is required'
  },
  selectCurrency: () => {
    return 'Select your preferred currency'
  },
  remark: () => {
    return 'Remark is required'
  },
  taxClass: () => {
    return 'Tax Class is required'
  },
  taxCode: () => {
    return 'Tax Code is required'
  },
  tax: () => {
    return 'Tax is required'
  },
  taxIdentificationNo: () => {
    return 'Tax Identificication No is required'
  },
  code: () => {
    return 'Code is required'
  },
  Summary: () => {
    return 'Summary is required'
  },
  type: () => {
    return ' Account Type is required'
  },
  amount: () => {
    return 'Amount cannot be zero'
  },
  exceedBalance: () => {
    return 'Amount cannot exceed balance amount'
  },
  transferTo: () => {
    return 'Transfer To is required'
  },
  transferDate: () => {
    return 'Transfer Date is required'
  },
  identityNo: () => {
    return 'Identity No. is required'
  },
  identityType: () => {
    return 'Identity Type is required'
  },

  //Standard Address fields validation
  address: () => {
    return 'Address is required'
  },
  state: () => {
    return 'State is required'
  },
  city: () => {
    return 'City is required'
  },
  postCode: () => {
    return 'Postcode is required'
  },
  country: () => {
    return 'Country is required'
  },
  userName: () => {
    return 'Username is required'
  },
  department: () => {
    return 'Department is required'
  },
  //
  //Error Messages
  loginErrorTitle: () => {
    return 'Your email or password is incorrect'
  },
  attempt: () => {
    return 'Please try again.'
  },
  noAccess: () => {
    return 'You have no access to the application'
  },
  unableUploadFile: () => {
    return 'Unable to upload file, please try again'
  },
  uploadFile: () => {
    return 'Upload a file'
  },
  errorNumberingStructure: () => {
    return 'Please setup Document Numbering.'
  },
  refresh: () => {
    return 'Failed to refresh, try again'
  },
  server: () => {
    return 'Could Not contact server, Please try again later'
  },
  fetchData: () => {
    return 'Failed to load data'
  },
  contactValidation: () => {
    return 'Enter a valid phone number with country code (e.g. + 60)'
  },

  documentNumber: () => {
    return 'Document Number is required'
  },
  documentNumberError: () => {
    return 'Document Number already in used'
  },
  duplicateDocNo: () => {
    return 'Document Number already exist'
  },
  documentDate: () => {
    return 'Document Date is required'
  },
  journalNo: () => {
    return 'Journal No. is required'
  },
  journalDate: () => {
    return 'Journal Date is required'
  },
  predicate: () => {
    return 'Predicate is required'
  },
  title: () => {
    return 'Title is required'
  },
  role: () => {
    return 'Role is required'
  },
  expenseCode: () => {
    return 'Expense Code is required'
  },
  dateSelection: () => {
    return 'Select a date'
  },
  itemNo: () => {
    return 'Item No. is required'
  },
  itemName: () => {
    return 'Item Name. is required'
  },
  uom: () => {
    return 'UOM is required'
  },
  reportingPeriod: () => {
    return 'Reporting Period is required'
  },
  reportingYear: () => {
    return 'Reporting Year is required'
  },
  costCentre: () => {
    return 'Cost Centre is required'
  },
  costCentreType: () => {
    return 'Cost Centre Type is required'
  },
  quantity: () => {
    return 'Quantity is required'
  },
  fromwarehouse: () => {
    return 'From Warehouse is required'
  },
}
