import React, { lazy } from 'react'

const GeneralApprovalPolicySubmenu = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/GeneralApprovalPolicySubmenu'
  ).then(module => ({
    default: module.GeneralApprovalPolicySubmenu,
  }))
)

const PRForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/PRForm'
  ).then(module => ({
    default: module.PRForm,
  }))
)

const RFQForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/RFQForm'
  ).then(module => ({
    default: module.PRForm,
  }))
)

const POListing = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/POListing'
  ).then(module => ({
    default: module.POListing,
  }))
)

const POForm = lazy(() =>
  import(
    '@account-root/procurement-react/src/containers/GeneralSettingModule/GeneralApprovalPolicy/ApprovalPolicy/POForm'
  ).then(module => ({
    default: module.POForm,
  }))
)

const generalApprovalRoutes = [
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/approval-policy',
    },
    component: <GeneralApprovalPolicySubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/general-purchase/general-setting/approval-policy/pr',
    },
    component: <PRForm />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/general-setting/approval-policy/rfq`,
    },
    component: <RFQForm />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/general-setting/approval-policy/po`,
    },
    component: <POListing />,
  },
  {
    props: {
      exact: true,
      path: `/general-purchase/general-setting/approval-policy/po/add`,
    },
    component: <POForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-purchase/general-setting/approval-policy/:ApprovalPolicyID/po-form/edit`,
    },
    component: <POForm mode="edit" />,
  },
]

export default generalApprovalRoutes
