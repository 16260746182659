import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  GetInventoryPolicyDocument,
  StockCostingType,
  useCreateInventoryPolicyMutation,
  useGetInventoryPolicyQuery,
  useUpdateInventoryPolicyMutation,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

export interface InventoryPolicyProps {
  StockCostingType: string
  PaymentReminder1: number
  PaymentReminder2: number
  PaymentReminder3: number
  PaymentReminder4: number
  PaymentReminder5: number
  PaymentReminder6: number
}

export const InventoryPolicyForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [openExitConf, setOpenExitConf] = useState(null)
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const InventoryPolicySchema = yup.object().shape({
    StockCostingType: CommonYupValidation.requireField(
      'Stock Costing Method is required'
    ),
    PaymentReminder1: CommonYupValidation.requireField('Required'),
    PaymentReminder2: CommonYupValidation.requireField('Required'),
    PaymentReminder3: CommonYupValidation.requireField('Required'),
    PaymentReminder4: CommonYupValidation.requireField('Required'),
    PaymentReminder5: CommonYupValidation.requireField('Required'),
    PaymentReminder6: CommonYupValidation.requireField('Required'),
  })

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const {
    handleSubmit,
    control,
    register,
    watch,
    setError,
    clearErrors,
    getValues,
    setValue,
    errors,
  } = useForm<InventoryPolicyProps>({
    mode: 'onSubmit',
    resolver: yupResolver(InventoryPolicySchema),
  })

  const StockCostingTypeList = [
    { name: 'Weighted', value: StockCostingType.Weighted },
    { name: 'FIFO (First In First Out)', value: StockCostingType.Fifo },
    { name: 'LIFO (Last In First Out)', value: StockCostingType.Lifo },
  ]

  const hasInfo =
    (watch('StockCostingType') !== null &&
      watch('StockCostingType') !== undefined) ||
    (watch('PaymentReminder1') !== null &&
      watch('PaymentReminder1') !== undefined) ||
    (watch('PaymentReminder2') !== null &&
      watch('PaymentReminder2') !== undefined) ||
    (watch('PaymentReminder3') !== null &&
      watch('PaymentReminder3') !== undefined) ||
    (watch('PaymentReminder4') !== null &&
      watch('PaymentReminder4') !== undefined) ||
    (watch('PaymentReminder5') !== null &&
      watch('PaymentReminder5') !== undefined) ||
    (watch('PaymentReminder6') !== null &&
      watch('PaymentReminder6') !== undefined)

  const {
    loading: InventoryPolicyLoading,
    error: InventoryPolicyError,
    data: { getInventoryPolicy } = {
      getInventoryPolicy: [],
    },
  } = useGetInventoryPolicyQuery({
    fetchPolicy: 'network-only',
    variables: { AccountID: user?.accountID },
  })

  const [
    CreateInventoryPolicy,
    {
      loading: CreateInventoryPolicyLoading,
      called: CreateInventoryPolicyCalled,
      error: CreateInventoryPolicyError,
    },
  ] = useCreateInventoryPolicyMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      console.log('data', data)
      setTimeout(() => {
        history.push({
          pathname: `/inventory-control/general-setting`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const [
    UpdateInventoryPolicy,
    {
      loading: UpdateInventoryPolicyLoading,
      called: UpdateInventoryPolicyCalled,
      error: UpdateInventoryPolicyError,
    },
  ] = useUpdateInventoryPolicyMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      console.log('data', data)
      setTimeout(() => {
        history.push({
          pathname: `/inventory-control/general-setting`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
    },
  })

  const onSubmit = data => {
    console.log('CHECK', data)

    if (getInventoryPolicy?.length > 0) {
      UpdateInventoryPolicy({
        variables: {
          input: {
            InventoryPolicyID: getInventoryPolicy[0]?.InventoryPolicyID,
            StockCostingType: data?.StockCostingType,
            PaymentReminder1: Number(data?.PaymentReminder1),
            PaymentReminder2: Number(data?.PaymentReminder2),
            PaymentReminder3: Number(data?.PaymentReminder3),
            PaymentReminder4: Number(data?.PaymentReminder4),
            PaymentReminder5: Number(data?.PaymentReminder5),
            PaymentReminder6: Number(data?.PaymentReminder6),
          },
        },
        refetchQueries: [
          {
            query: GetInventoryPolicyDocument,
            variables: {
              AccountID: user?.accountID,
            },
          },
        ],
      })
    } else {
      CreateInventoryPolicy({
        variables: {
          input: {
            StockCostingType: data?.StockCostingType,
            PaymentReminder1: Number(data?.PaymentReminder1),
            PaymentReminder2: Number(data?.PaymentReminder2),
            PaymentReminder3: Number(data?.PaymentReminder3),
            PaymentReminder4: Number(data?.PaymentReminder4),
            PaymentReminder5: Number(data?.PaymentReminder5),
            PaymentReminder6: Number(data?.PaymentReminder6),
          },
        },
        refetchQueries: [
          {
            query: GetInventoryPolicyDocument,
            variables: {
              AccountID: user?.accountID,
            },
          },
        ],
      })
    }
  }

  return (
    <>
      {InventoryPolicyLoading && <Loading />}
      {CreateInventoryPolicyLoading && <Loading />}
      {UpdateInventoryPolicyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          hasInfo
            ? setOpenExitConf(true)
            : history.push(`/inventory-control/general-setting`)
        }}
        smTitle={'Inventory Control'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Setting' },
          { name: 'Inventory Policy Settings', current: true },
        ]}
      />

      {!InventoryPolicyLoading && (
        <ContentWrapper float>
          <CardContents>
            <Controller
              as={
                <TextField
                  margin="dense"
                  label="Stock Costing Method"
                  required
                  SelectProps={{
                    onChange: event => {
                      setValue(
                        'StockCostingType',
                        event?.target?.value.toString()
                      )
                    },
                  }}
                  select
                >
                  {StockCostingTypeList?.map((x, index) => (
                    <MenuItem key={index} value={x?.value}>
                      <span className="text-noflow">{`${x?.name}`}</span>
                    </MenuItem>
                  ))}
                </TextField>
              }
              select
              name="StockCostingType"
              autoComplete="off"
              control={control}
              defaultValue={getInventoryPolicy[0]?.StockCostingType}
              ref={register}
              helperText={errors?.StockCostingType?.message}
              error={errors?.StockCostingType ? true : false}
            />

            <Grid
              style={{ marginBottom: '15px', color: 'darkorange' }}
              container
              className="table-content"
            >
              Aging:
            </Grid>
            <Grid spacing={1} container justify="flex-start">
              <Grid item xs={isDesktop ? 2 : 4}>
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  customInput={TextField}
                  name="PaymentReminder1"
                  autoComplete="off"
                  fullWidth
                  className="receivable-aging-form"
                  ref={register}
                  control={control}
                  multiline={isDesktop ? false : true}
                  variant="outlined"
                  focused
                  defaultValue={getInventoryPolicy[0]?.PaymentReminder1}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{
                            position: 'absolute',
                            left: '-5px',
                            top: '-9px',
                            width: '17px',
                            height: '17px',
                            borderRadius: '50%',
                            background: '#ff9800',
                            textAlign: 'center',
                            zIndex: 1,
                          }}
                        >
                          <span
                            style={{
                              color: 'white',
                            }}
                          >
                            1
                          </span>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                  helperText={errors?.PaymentReminder1?.message}
                  error={errors?.PaymentReminder1 ? true : false}
                />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 4}>
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  customInput={TextField}
                  name="PaymentReminder2"
                  autoComplete="off"
                  fullWidth
                  className="receivable-aging-form"
                  ref={register}
                  control={control}
                  multiline={isDesktop ? false : true}
                  variant="outlined"
                  focused
                  defaultValue={getInventoryPolicy[0]?.PaymentReminder2}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{
                            position: 'absolute',
                            left: '-5px',
                            top: '-9px',
                            width: '17px',
                            height: '17px',
                            borderRadius: '50%',
                            background: '#ff9800',
                            textAlign: 'center',
                            zIndex: 1,
                          }}
                        >
                          <span
                            style={{
                              color: 'white',
                            }}
                          >
                            2
                          </span>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                  helperText={errors?.PaymentReminder2?.message}
                  error={errors?.PaymentReminder2 ? true : false}
                />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 4}>
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  customInput={TextField}
                  name="PaymentReminder3"
                  autoComplete="off"
                  fullWidth
                  className="receivable-aging-form"
                  ref={register}
                  control={control}
                  multiline={isDesktop ? false : true}
                  variant="outlined"
                  focused
                  defaultValue={getInventoryPolicy[0]?.PaymentReminder3}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{
                            position: 'absolute',
                            left: '-5px',
                            top: '-9px',
                            width: '17px',
                            height: '17px',
                            borderRadius: '50%',
                            background: '#ff9800',
                            textAlign: 'center',
                            zIndex: 1,
                          }}
                        >
                          <span
                            style={{
                              color: 'white',
                            }}
                          >
                            3
                          </span>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                  helperText={errors?.PaymentReminder3?.message}
                  error={errors?.PaymentReminder3 ? true : false}
                />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 4}>
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  customInput={TextField}
                  name="PaymentReminder4"
                  autoComplete="off"
                  fullWidth
                  className="receivable-aging-form"
                  ref={register}
                  control={control}
                  multiline={isDesktop ? false : true}
                  variant="outlined"
                  focused
                  defaultValue={getInventoryPolicy[0]?.PaymentReminder4}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{
                            position: 'absolute',
                            left: '-5px',
                            top: '-9px',
                            width: '17px',
                            height: '17px',
                            borderRadius: '50%',
                            background: '#ff9800',
                            textAlign: 'center',
                            zIndex: 1,
                          }}
                        >
                          <span
                            style={{
                              color: 'white',
                            }}
                          >
                            4
                          </span>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                  helperText={errors?.PaymentReminder4?.message}
                  error={errors?.PaymentReminder4 ? true : false}
                />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 4}>
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  customInput={TextField}
                  name="PaymentReminder5"
                  autoComplete="off"
                  fullWidth
                  className="receivable-aging-form"
                  ref={register}
                  control={control}
                  multiline={isDesktop ? false : true}
                  variant="outlined"
                  focused
                  defaultValue={getInventoryPolicy[0]?.PaymentReminder5}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{
                            position: 'absolute',
                            left: '-5px',
                            top: '-9px',
                            width: '17px',
                            height: '17px',
                            borderRadius: '50%',
                            background: '#ff9800',
                            textAlign: 'center',
                            zIndex: 1,
                          }}
                        >
                          <span
                            style={{
                              color: 'white',
                            }}
                          >
                            5
                          </span>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                  helperText={errors?.PaymentReminder5?.message}
                  error={errors?.PaymentReminder5 ? true : false}
                />
              </Grid>
              <Grid item xs={isDesktop ? 2 : 4}>
                <Controller
                  as={<NumberFormat allowNegative={false} />}
                  customInput={TextField}
                  name="PaymentReminder6"
                  autoComplete="off"
                  fullWidth
                  className="receivable-aging-form"
                  ref={register}
                  control={control}
                  multiline={isDesktop ? false : true}
                  variant="outlined"
                  focused
                  defaultValue={getInventoryPolicy[0]?.PaymentReminder6}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{
                            position: 'absolute',
                            left: '-5px',
                            top: '-9px',
                            width: '17px',
                            height: '17px',
                            borderRadius: '50%',
                            background: '#ff9800',
                            textAlign: 'center',
                            zIndex: 1,
                          }}
                        >
                          <span
                            style={{
                              color: 'white',
                            }}
                          >
                            6
                          </span>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                  helperText={errors?.PaymentReminder6?.message}
                  error={errors?.PaymentReminder6 ? true : false}
                />
              </Grid>
            </Grid>
          </CardContents>
        </ContentWrapper>
      )}

      <Footer
        options={[
          {
            name: 'Save',
            onClick: () => handleSubmit(onSubmit)(),
            color: 'primary',
          },
        ]}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/inventory-control/general-setting`)
        }}
        hasInfo={hasInfo}
      />
    </>
  )
}
