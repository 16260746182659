import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { DeleteDialog } from 'components/Dialog/DeleteDialog'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  useDeleteAuditAdjustmentMutation,
  useGetDocNumTitleQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate, formatTime } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import '../AuditAdjustment.scss'

/* -------------------------------------------- */
/*            For ACTIVE and APPROVED           */
/* -------------------------------------------- */

export const AuditAdjustmentEntriesList = (props: any) => {
  const { filteredList, listLoading, listStatus, userList, fetchMore } = props

  const getSearch = localStorage?.getItem('searchFilter')
  let history = useHistory()
  const { CompanyID }: any = useParams()
  //search part -----------------------------------------

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()

  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  /** DELETE FUNCTION AND POPUP TRIGGER **/
  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  const [deleteAA, setDeleteAA] = useState(false)
  const [deleteError, setDeleteErrorPayment] = useState(false)

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'GL_AuditAdjustment',
    },
  })

  const docNumChecker = getDocumentNumberHeader?.length === 0

  const [
    deleteAuditAdjustment,
    {
      loading: deleteAuditAdjustmentLoading,
      error: deleteAuditAdjustmentError,
    },
  ] = useDeleteAuditAdjustmentMutation({
    onError: error => {
      if (error.message.includes('FK_')) {
        setErrorDia(true)
      }
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.deleteRecord())

      fetchMore({
        variables: {
          CompanyID: CompanyID,
          StatusArr: 'ACTIVE',
        },
      })
    },
  })

  const handleDelete = data => {
    deleteAuditAdjustment({
      variables: {
        AuditAdjustmentID: data,
      },
    })
    setDeleteAA(false)
  }

  return (
    <>
      {listLoading && <Loading />}
      {deleteAuditAdjustmentLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}

      <ContentWrapper advSearch float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.sort((a, b) => {
                return (
                  Object.values(ApprovalStatus).indexOf(a?.ApprovalStatus) -
                  Object.values(ApprovalStatus).indexOf(b?.ApprovalStatus)
                )
              })
              ?.map((el, index) => {
                return (
                  <ListItem>
                    <ListItemText
                      primary={
                        <>
                          {listStatus === 'COMPLETED' ? (
                            <span
                              className={`xsTitle flex-space click-text`}
                              onClick={() => {
                                history.push({
                                  pathname: `/general-ledger/${CompanyID}/audit-adjustment/${el?.AuditAdjustmentID}/preview`,
                                  state: {
                                    ...el,
                                    mode: 'detail',
                                  },
                                })
                              }}
                            >
                              {el?.DocNo ?? el?.RefNo}
                            </span>
                          ) : (
                            <span
                              className="flex-space mdLabel"
                              style={{ fontSize: '12px' }}
                            >
                              {el?.DocNo ?? el?.RefNo}
                              <span className="desc">
                                {' '}
                                {el?.AdjYearOrPeriod}
                              </span>
                            </span>
                          )}
                          {listStatus === 'COMPLETED' ? (
                            <span
                              className="desc"
                              style={{
                                fontSize: '12px',
                                color:
                                  listStatus === 'ACTIVE' ? '#FF0000' : null,
                              }}
                            >
                              <span className="">
                                {formatDate(el?.DocDate)}
                              </span>
                            </span>
                          ) : (
                            <div
                              className="desc c-orange xxTitle"
                              style={{ float: 'right' }}
                            >
                              <span style={{ fontSize: '12px' }}>
                                {amtStr(
                                  el?.AuditAdjustmentItem?.filter(
                                    item => item?.DocAmt > 0
                                  )?.reduce((x, y) => x + y?.DocAmt, 0)
                                )}
                              </span>
                            </div>
                          )}
                        </>
                      }
                      secondary={
                        <>
                          {listStatus === 'COMPLETED' ? (
                            <div className="desc flex-space">
                              <>
                                <div className="desc flex-space">
                                  <span className="desc">
                                    {el?.AdjYearOrPeriod}
                                  </span>
                                </div>
                              </>

                              <div
                                className="icon-text"
                                style={{ marginBottom: '-12px' }}
                              >
                                <GreyTooltip
                                  disableFocusListener
                                  title={
                                    <React.Fragment>
                                      <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                          <Avatar
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                            }}
                                          >
                                            <PersonIcon
                                              style={{ fontSize: 'small' }}
                                            />
                                          </Avatar>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                          <div style={{ fontSize: '12px' }}>
                                            {userList?.find(
                                              x =>
                                                x?.ID ===
                                                (listStatus === 'ACTIVE'
                                                  ? el?.createdBy
                                                  : el?.approvedBy)
                                            )?.name ??
                                              userList?.find(
                                                x =>
                                                  x?.ID ===
                                                  (listStatus === 'ACTIVE'
                                                    ? el?.createdBy
                                                    : el?.approvedBy)
                                              )?.name}
                                          </div>
                                          <div style={{ fontSize: '10px' }}>
                                            {formatDate(
                                              listStatus === 'ACTIVE'
                                                ? new Date(
                                                    el?.createdTs
                                                  ).toISOString()
                                                : el?.approvedTs
                                            )}
                                            &nbsp;
                                            {formatTime(
                                              listStatus === 'ACTIVE'
                                                ? new Date(
                                                    el?.createdTs
                                                  ).toISOString()
                                                : el?.approvedTs
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </React.Fragment>
                                  }
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <div>
                                    <IconText
                                      icon={
                                        <img
                                          src={SubmitterLog}
                                          style={{
                                            width: '12px',
                                            marginRight: '3px',
                                            marginBottom: '16px',
                                          }}
                                        />
                                      }
                                      font="desc"
                                    ></IconText>
                                  </div>
                                </GreyTooltip>

                                <span
                                  className="content-wrap full text-overflow desc"
                                  // style={{
                                  //   marginBottom: '-4px',
                                  // }}
                                >
                                  {el?.Description}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <span
                              className="desc"
                              style={{
                                whiteSpace: 'break-spaces',
                                lineBreak: 'auto',
                              }}
                            >
                              <GreyTooltip
                                disableFocusListener
                                title={
                                  <React.Fragment>
                                    <Grid container wrap="nowrap" spacing={2}>
                                      <Grid item>
                                        <Avatar
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        >
                                          <PersonIcon
                                            style={{ fontSize: 'small' }}
                                          />
                                        </Avatar>
                                      </Grid>
                                      <Grid item xs zeroMinWidth>
                                        <div style={{ fontSize: '12px' }}>
                                          {userList?.find(
                                            x =>
                                              x?.ID ===
                                              (listStatus === 'ACTIVE'
                                                ? el?.createdBy
                                                : el?.approvedBy)
                                          )?.name ??
                                            userList?.find(
                                              x =>
                                                x?.ID ===
                                                (listStatus === 'ACTIVE'
                                                  ? el?.createdBy
                                                  : el?.approvedBy)
                                            )?.name}
                                        </div>
                                        <div style={{ fontSize: '10px' }}>
                                          {formatDate(
                                            listStatus === 'ACTIVE'
                                              ? el?.createdTs
                                              : el?.approvedTs
                                          )}
                                          &nbsp;
                                          {formatTime(
                                            listStatus === 'ACTIVE'
                                              ? el?.createdTs
                                              : el?.approvedTs
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                }
                                arrow
                                enterTouchDelay={0}
                              >
                                <div>
                                  <IconText
                                    icon={
                                      <img
                                        src={SubmitterLog}
                                        style={{
                                          width: '12px',
                                          marginRight: '3px',
                                        }}
                                      />
                                    }
                                    font="desc"
                                  ></IconText>
                                </div>
                              </GreyTooltip>
                            </span>
                          )}

                          {listStatus === 'COMPLETED' ? (
                            <div
                              className="desc c-orange xxTitle"
                              style={{ float: 'right', marginTop: '-20px' }}
                            >
                              <span style={{ fontSize: '12px' }}>
                                {amtStr(
                                  el?.AuditAdjustmentItem?.filter(
                                    item => item?.DocAmt > 0
                                  )?.reduce((x, y) => x + y?.DocAmt, 0)
                                )}
                              </span>
                            </div>
                          ) : (
                            <>
                              <span
                                className="desc flex-space text-overflow"
                                style={{ marginBottom: '-5px' }}
                              >
                                {el?.Description}
                              </span>
                            </>
                          )}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      {listStatus === 'ACTIVE' || listStatus === 'REJECTED' ? (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(e, el?.AuditAdjustmentID, index, el)
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton edge="end" aria-label="arrow">
                          <KeyboardArrowRight
                            onClick={() =>
                              history.push({
                                pathname: `/general-ledger/${CompanyID}/audit-adjustment/${el?.AuditAdjustmentID}/detail`,
                                state: el,
                              })
                            }
                          />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
          )}
        </List>
        {listStatus === 'ACTIVE' && (
          <FloatButton
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerAuditAdjustmentCreate,
            })}
            onClick={() => {
              if (docNumChecker) {
                setOpenSnackBar(true)
                setSnackBarMsg(SystemMsgs.errorNumberingStructure())
              } else {
                history.push(
                  `/general-ledger/${CompanyID}/audit-adjustment/add`
                )
              }
            }}
          />
        )}
      </ContentWrapper>

      {listStatus === 'ACTIVE' ? (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerAuditAdjustmentUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/general-ledger/${CompanyID}/audit-adjustment/${menu?.obj?.AuditAdjustmentID}/edit`,
                state: menu?.obj,
              })
            }}
          >
            <span className="">Edit</span>
          </MenuItem>
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerAuditAdjustmentDelete,
            })}
            onClick={() => setDeleteAA(true)}
          >
            <span className="">Delete</span>
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem
            disabled={handlePermDisabled({
              companyID: CompanyID,
              permEnum: AcctPermission.GeneralLedgerAuditAdjustmentUpdate,
            })}
            onClick={() => {
              history.push({
                pathname: `/general-ledger/${CompanyID}/audit-adjustment/${menu?.obj?.AuditAdjustmentID}/edit`,
                state: { ...menu?.obj, mode: 'resubmit' },
              })
            }}
          >
            <span className="">Resubmit</span>
          </MenuItem>
        </Menu>
      )}

      <DeleteDialog
        ID={menu?.ID}
        openDeleteDialog={deleteAA}
        setOpenDeleteDialog={setDeleteAA}
        title={'Audit Adjustment'}
        menu={menu?.obj}
        handleDelete={handleDelete}
        passID={menu?.ID}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={
          'You are not allowed to delete this as there are transactions executed already'
        }
      />
    </>
  )
}
