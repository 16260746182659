import { AcctPermission } from 'generated/graphql'

export interface RenderPermissionTree {
  id: AcctPermission
  label: string
  children?: RenderPermissionTree[]
  layer?: string
  selected?: number
}

export const generalLedgerPerm: RenderPermissionTree = {
  id: AcctPermission.GeneralLedgerIcon,
  label: 'General Ledger',
  layer: '1',
  children: [
    {
      id: AcctPermission.GeneralLedgerOriginalBudgetView,
      label: 'Original Budget',
      children: [
        {
          id: AcctPermission.GeneralLedgerOriginalBudgetCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerOriginalBudgetUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerOriginalBudgetDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerOriginalBudgetVerified,
          label: 'Verify',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerBudgetRevisionView,
      label: 'Budget Revision',
      children: [
        {
          id: AcctPermission.GeneralLedgerBudgetRevisionCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerBudgetRevisionUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerBudgetRevisionDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerBudgetRevisionVerified,
          label: 'Verify',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerJournalProcessingView,
      label: 'Journal Entries',
      children: [
        {
          id: AcctPermission.GeneralLedgerJournalProcessingCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerJournalProcessingUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerJournalProcessingDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerJournalProcessingDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralLedgerJournalProcessingApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerExternalJournalView,
      label: 'External Journal',
      children: [
        {
          id: AcctPermission.GeneralLedgerExternalJournalImport,
          label: 'Import',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerRecurringJournalView,
      label: 'Recurring Journal',
      children: [
        {
          id: AcctPermission.GeneralLedgerRecurringJournalCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalCancel,
          label: 'Cancel',
        },
        {
          id: AcctPermission.GeneralLedgerRecurringJournalApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerAuditAdjustmentView,
      label: 'Audit Adjustment',
      children: [
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralLedgerAuditAdjustmentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralLedgerClosingMonthYearEndView,
      label: 'Month End/ Year End Closing',
      // children:[

      // ]
    },
    {
      id: AcctPermission.GeneralLedgerDigitalReportsView,
      label: 'Digital Reports',
      children: [
        {
          id: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
          label: 'GL Journal by Entity',
        },
        {
          id: AcctPermission.GeneralLedgerDigitalReportsTrialBalanceView,
          label: 'Trial Balance by Entity',
        },
        {
          id:
            AcctPermission.GeneralLedgerDigitalReportsTrialBalanceCostCentreView,
          label: 'Trial Balance by Department',
        },
        {
          id: AcctPermission.GeneralLedgerDigitalReportsGlJournalCostCentreView,
          label: 'GL Journal by Department',
        },
      ],
    },
  ],
}

export const cashBookPerm: RenderPermissionTree = {
  id: AcctPermission.CashBookIcon,
  label: 'Cash Book',
  layer: '1',
  children: [
    {
      id: AcctPermission.CashBookPaymentView,
      label: 'Payment',
      children: [
        {
          id: AcctPermission.CashBookPaymentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.CashBookPaymentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.CashBookPaymentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.CashBookPaymentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.CashBookPaymentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.CashBookReceiptView,
      label: 'Receipt',
      children: [
        {
          id: AcctPermission.CashBookReceiptCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.CashBookReceiptUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.CashBookReceiptDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.CashBookReceiptDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.CashBookReceiptApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.CashBookBankTransferView,
      label: 'Bank Transfer',
      children: [
        {
          id: AcctPermission.CashBookBankTransferCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.CashBookBankTransferUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.CashBookBankTransferDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.CashBookBankTransferDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.CashBookBankTransferApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.CashBookExternalLedgerView,
      label: 'External Ledger',
      children: [
        {
          id: AcctPermission.CashBookExternalLedgerImport,
          label: 'Import',
        },
      ],
    },
    {
      id: AcctPermission.CashBookDigitalReportView,
      label: 'Digital Reports',
      children: [
        {
          id: AcctPermission.CashBookDigitalReportCashLedgerView,
          label: 'Cash Ledger',
        },
        {
          id: AcctPermission.CashBookDigitalReportCashInOutFlowView,
          label: 'Cash Inflow VS Outflow',
        },
      ],
    },
  ],
}

export const accPayablePerm: RenderPermissionTree = {
  id: AcctPermission.AccPayableIcon,
  label: 'Account Payable',
  layer: '1',
  children: [
    {
      id: AcctPermission.AccPayableCreditorProfileView,
      label: 'Creditor Account',
      children: [
        {
          id: AcctPermission.AccPayableCreditorProfileUpdate,
          label: 'Update',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableAdvancesView,
      label: 'Advance',
      children: [
        {
          id: AcctPermission.AccPayableAdvancesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableAdvancesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableAdvancesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableAdvancesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableAdvancesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccPayableAdvancesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableInvoicesView,
      label: 'Invoice',
      children: [
        {
          id: AcctPermission.AccPayableInvoicesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableInvoicesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableInvoicesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableInvoicesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableInvoicesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableCreditNotesView,
      label: 'Credit Note',
      children: [
        {
          id: AcctPermission.AccPayableCreditNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableCreditNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableCreditNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableCreditNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableCreditNotesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccPayableCreditNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableCreditorCreditNotesView,
      label: 'Credit Note from Creditor',
      children: [
        {
          id: AcctPermission.AccPayableCreditorCreditNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccPayableCreditorCreditNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableDebitNotesView,
      label: 'Debit Note',
      children: [
        {
          id: AcctPermission.AccPayableDebitNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableDebitNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableDebitNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableDebitNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableDebitNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayablePaymentView,
      label: 'Payment',
      children: [
        {
          id: AcctPermission.AccPayablePaymentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayablePaymentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayablePaymentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayablePaymentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayablePaymentAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccPayablePaymentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableBatchPaymentView,
      label: 'Batch Payment',
      children: [
        {
          id: AcctPermission.AccPayableBatchPaymentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableBatchPaymentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableBatchPaymentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableBatchPaymentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableBatchPaymentPaid,
          label: 'Pay',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableRefundView,
      label: 'Refund',
      children: [
        {
          id: AcctPermission.AccPayableRefundCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccPayableRefundUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccPayableRefundDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccPayableRefundDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccPayableRefundApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccPayableDigitalReportView,
      label: 'Digital Reports',
      children: [
        {
          id: AcctPermission.AccPayableDigitalReportOverduePayableView,
          label: 'Overdue Payable',
        },
        {
          id: AcctPermission.AccPayableDigitalReportCreditorLedgerByDocDateView,
          label: 'Creditor Ledger Detail by Document Date',
        },
        {
          id: AcctPermission.AccPayableDigitalReportCreditorAgingByDocDateView,
          label: 'Creditor Aging Detail by Document Date',
        },
      ],
    },
  ],
}

export const accReceivablePerm: RenderPermissionTree = {
  id: AcctPermission.AccReceivableIcon,
  label: 'Account Receivable',
  layer: '1',
  children: [
    {
      id: AcctPermission.AccReceivableDebtorAccView,
      label: 'Debtor Account',
      children: [
        {
          id: AcctPermission.AccReceivableDebtorAccCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableDebtorAccUpdate,
          label: 'Update',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableInvoicesView,
      label: 'Invoices',
      children: [
        {
          id: AcctPermission.AccReceivableInvoicesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableInvoicesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableInvoicesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableInvoicesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableInvoicesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableCreditNotesView,
      label: 'Credit Note',
      children: [
        {
          id: AcctPermission.AccReceivableCreditNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccReceivableCreditNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableDebitNotesView,
      label: 'Debit Note',
      children: [
        {
          id: AcctPermission.AccReceivableDebitNotesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableDebitNotesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableDebitNotesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableDebitNotesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableDebitNotesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableAdvancesView,
      label: 'Advance',
      children: [
        {
          id: AcctPermission.AccReceivableAdvancesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableAdvancesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableAdvancesDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableAdvancesDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableAdvancesAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccReceivableAdvancesApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableReceiptsView,
      label: 'Official Receipt',
      children: [
        {
          id: AcctPermission.AccReceivableReceiptsCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableReceiptsUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableReceiptsDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableReceiptsDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableReceiptsAllocation,
          label: 'Allocation',
        },
        {
          id: AcctPermission.AccReceivableReceiptsApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableRefundView,
      label: 'Refund',
      children: [
        {
          id: AcctPermission.AccReceivableRefundCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.AccReceivableRefundUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.AccReceivableRefundDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.AccReceivableRefundDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.AccReceivableRefundApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.AccReceivableDigitalReportsView,
      label: 'Digital Reports',
    },
  ],
}

export const inventoryControlPerm: RenderPermissionTree = {
  id: AcctPermission.InventoryControlIcon,
  label: 'Inventory Control',
  layer: '1',
  children: [
    {
      id: AcctPermission.InventoryControlStockRequisitionView,
      label: 'Stock Requisition',
      children: [
        {
          id: AcctPermission.InventoryControlStockRequisitionCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.InventoryControlStockRequisitionCancel,
          label: 'Cancel',
        },
      ],
    },
    {
      id: AcctPermission.InventoryControlStockIssueView,
      label: 'Stock Issue',
      children: [
        {
          id: AcctPermission.InventoryControlStockIssueCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockIssueUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockIssueDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockIssueDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockIssueApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },

    {
      id: AcctPermission.InventoryControlStockReceiptView,
      label: 'Stock Receipt',
      children: [
        {
          id: AcctPermission.InventoryControlStockReceiptCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockReceiptUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockReceiptDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockReceiptDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockReceiptApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },

    {
      id: AcctPermission.InventoryControlStockTransferView,
      label: 'Stock Transfer',
      children: [
        {
          id: AcctPermission.InventoryControlStockTransferCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockTransferUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockTransferDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockTransferDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockTransferApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.InventoryControlStockAdjustmentView,
      label: 'Stock Qty Adjustment',
      children: [
        {
          id: AcctPermission.InventoryControlStockAdjustmentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockAdjustmentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockAdjustmentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockAdjustmentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockAdjustmentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.InventoryControlStockCostAdjustmentView,
      label: 'Stock Cost Adjustment',
      children: [
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.InventoryControlStockCostAdjustmentApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },

    {
      id: AcctPermission.InventoryControlCycleCountingView,
      label: 'Cycle Count',
      children: [
        {
          id: AcctPermission.InventoryControlCycleCountingCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.InventoryControlCycleCountingUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.InventoryControlCycleCountingDraft,
          label: 'Draft',
        },
        {
          id: AcctPermission.InventoryControlCycleCountingDelete,
          label: 'Delete',
        },
      ],
    },

    {
      id: AcctPermission.InventoryControlStockEnquiryView,
      label: 'Stock Enquiry',
    },
    {
      id: AcctPermission.InventoryControlDigitalReportsView,
      label: 'Digital Report',
      children: [
        {
          id:
            AcctPermission.InventoryControlDigitalReportsStockLedgerByLocationView,
          label: 'Stock Ledger',
        },
        {
          id:
            AcctPermission.InventoryControlDigitalReportsStockBalanceByLocationView,
          label: 'Stock Balance',
        },
      ],
    },
  ],
}

export const generalPurchasePerm: RenderPermissionTree = {
  id: AcctPermission.GeneralPurchaseIcon,
  label: 'General Purchase',
  children: [
    {
      id: AcctPermission.GeneralPurchaseScheduledPurchasesView,
      label: 'Scheduled Purchase',
      children: [
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseScheduledPurchasesDelete,
          label: 'Delete',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchasePurchaseRequisitionView,
      label: 'Purchase Requisition',
      children: [
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchasePurchaseRequisitionCancel,
          label: 'Cancel',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseRequestForQuotationView,
      label: 'Request for Quotation',
      children: [
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesView,
          label: 'RFQ Invitees',
          children: [
            {
              id:
                AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesCreate,
              label: 'Create',
            },
            {
              id:
                AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesUninvite,
              label: 'Uninvite',
            },
            {
              id:
                AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqView,
              label: 'Quoted RFQ',
              children: [
                {
                  id:
                    AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate,
                  label: 'Create',
                },
                {
                  id:
                    AcctPermission.GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate,
                  label: 'Update',
                },
              ],
            },
          ],
        },
        {
          id:
            AcctPermission.GeneralPurchaseRequestForQuotationEvaluationSelectionView,
          label: 'Evaluation & Selection',
          children: [
            {
              id:
                AcctPermission.GeneralPurchaseRequestForQuotationEvaluationSelectionCreate,
              label: 'Create',
            },
          ],
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseGeneratePoFromPrView,
      label: 'Generate PO from PR',
      children: [
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchaseGeneratePoFromPrCancel,
          label: 'Cancel',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseDirectPoView,
      label: 'Direct PO',
      children: [
        {
          id: AcctPermission.GeneralPurchaseDirectPoCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoApproveReject,
          label: 'Approve/Reject',
        },
        {
          id: AcctPermission.GeneralPurchaseDirectPoCancel,
          label: 'Cancel',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseGoodReceiveNoteView,
      label: 'Good Receive Note',
      children: [
        {
          id: AcctPermission.GeneralPurchaseGoodReceiveNoteCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReceiveNoteUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReceiveNoteDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReceiveNoteApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseGoodReturnNoteView,
      label: 'Good Return Note',
      children: [
        {
          id: AcctPermission.GeneralPurchaseGoodReturnNoteCreate,
          label: 'Create',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReturnNoteUpdate,
          label: 'Update',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReturnNoteDelete,
          label: 'Delete',
        },
        {
          id: AcctPermission.GeneralPurchaseGoodReturnNoteApproveReject,
          label: 'Approve/Reject',
        },
      ],
    },
    {
      id: AcctPermission.GeneralPurchaseDigitalReportsView,
      label: 'Digital Reports',
    },
  ],
}
