import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetBankAccountLazyQuery,
  useGetCompanyQuery,
  useGetPaymentLazyQuery,
  useGetReceiptLazyQuery,
} from 'generated/graphql'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

interface CashLedgerListingParamsFormProps {
  StartDate: Date
  EndDate: Date
  DocNo: string
  CompanyID: string
  BankAccountID: string
}

export const CashLedgerListingParamsForm = (props: any) => {
  useEffect(() => {
    console.log('CashLedgerListingParamsForm')
  }, [])
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  // const { CompanyID, BankAccountID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const [comp, setComp] = useState(undefined)
  const [bankAcc, setBankAcc] = useState(undefined)
  const [selectedDocNo, setSelectedDocNo] = useState()
  const ParamsSchema = yup.object().shape({
    CompanyID: CommonYupValidation.requireField('Company is required'),
    StartDate: CommonYupValidation.requireField('Start Date is required'),
    EndDate: CommonYupValidation.requireField('End Date is required'),
  })

  const user = localStorage.getItem('latestCompany')
  const [docNoList, setDocNoList] = useState([])

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<CashLedgerListingParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  })

  const [
    loadBankAccount,
    {
      called: bankAccountCalled,
      loading: bankAccountLoading,
      data: { getBankAccount } = { getBankAccount: [] },
    },
  ] = useGetBankAccountLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [
    loadReceipt,
    {
      loading: receiptLoading,
      error: receiptError,
      data: { getReceipt } = { getReceipt: [] },
    },
  ] = useGetReceiptLazyQuery({
    fetchPolicy: 'network-only',

    onCompleted: ({ getReceipt }) => {
      docNoList.push(...getReceipt)
    },
  })

  const [
    loadPayment,
    {
      loading: paymentLoading,
      error: paymentError,
      data: { getPayment } = { getPayment: [] },
    },
  ] = useGetPaymentLazyQuery({
    fetchPolicy: 'network-only',

    onCompleted: ({ getPayment }) => {
      docNoList.push(...getPayment)
    },
  })

  const onSubmit = (data, status) => {
    console.log('data', data)
    history.push({
      pathname: `/cash-book/submenu/digital-report/cash-ledger-report/generated`,
      state: {
        CompanyID: data?.CompanyID,
        StartDate: new Date(data.StartDate),
        EndDate: new Date(data.EndDate),
        BankAccountID: data?.BankAccountID,
        DocNo: data.DocNo,
      },
    })
  }

  return (
    <>
      {companyLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      {receiptLoading && <Loading />}
      {paymentLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/cash-book/submenu/digital-report`)}
        smTitle={smTitle?.CASH_BOOK}
        title={'IFCA AccountX Dev Team'}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Cash Ledger Listing', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <Controller
            render={({ value, onChange }) => {
              const defVal = getCompany?.filter(x => x?.CompanyID === user)[0]
                ?.Name

              return (
                <Autocomplete
                  options={getCompany || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('CompanyID', newValue?.CompanyID)
                    setComp(newValue?.CompanyID)
                    loadBankAccount({
                      variables: { CompanyID: newValue?.CompanyID },
                    })
                    setValue('BankAccountID', undefined)
                    setBankAcc(undefined)
                    setValue('DocNo', undefined)
                    setSelectedDocNo(undefined)
                    setDocNoList([])
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.Name}</span>
                  }}
                  defaultValue={defVal}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.CompanyID?.message}
                          error={errors?.CompanyID ? true : false}
                          label="Company"
                          style={{ width: '100%' }}
                          margin="normal"
                          required
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Company"
            name="CompanyID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.CompanyID?.message}
            error={errors?.CompanyID ? true : false}
            required
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Date"
              control={control}
              onChange={(date: Date | null) => {
                console.log(date)
              }}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Date"
              control={control}
              onChange={(date: Date | null) => {
                console.log(date)
              }}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getBankAccount || []}
                  getOptionLabel={option => `${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('BankAccountID', newValue?.BankAccountID)
                    setBankAcc(newValue)
                    setDocNoList([])
                    loadReceipt({
                      variables: {
                        CompanyID: comp,
                        BankAccountID: newValue?.BankAccountID,
                      },
                    })
                    loadPayment({
                      variables: {
                        CompanyID: comp,
                        BankAccountID: newValue?.BankAccountID,
                      },
                    })
                    setValue('DocNo', undefined)
                    setSelectedDocNo(undefined)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.Code}</span>
                  }}
                  value={bankAcc}
                  key={bankAcc}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.BankAccountID?.message}
                          error={errors?.BankAccountID ? true : false}
                          label="Bank"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Bank"
            name="BankAccountID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.BankAccountID?.message}
            error={errors?.BankAccountID ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="DocNo"
            label="Document No"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.DocNo?.message}
            error={errors?.DocNo ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
