import React, { lazy } from 'react'

const BankAccountListing = lazy(() =>
  import('./BankAccountListing').then(module => ({
    default: module.BankAccountListing,
  }))
)

const BankAccountForm = lazy(() =>
  import('./BankAccountForm').then(module => ({
    default: module.BankAccountForm,
  }))
)

const BankAccountDetails = lazy(() =>
  import('./BankAccountDetails').then(module => ({
    default: module.BankAccountDetails,
  }))
)

const bankAccountRoutes = [
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/company-setting/bank-account/add',
    },
    component: <BankAccountForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/company-setting/bank-account/:BankAccountID/edit',
    },
    component: <BankAccountForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/cash-book/:CompanyID/company-setting/bank-account',
    },
    component: <BankAccountListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/cash-book/:CompanyID/company-setting/bank-account/:BankAccountID/details',
    },
    component: <BankAccountDetails />,
  },
]

export default bankAccountRoutes
