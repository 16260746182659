import React from 'react'
import { APDigitalReportSubmenu } from './APDigitalReportSubmenu'
import { APOverduePayableParamsForm } from './Parameters/APOverduePayableParamsForm'
import { APCreditorLedgerDetailbyDocDateParamsForm } from './Parameters/APCreditorDetailLedgerByDocDateParamsForm'
import { APCreditorAgingDetailbyDocDateParamsForm } from './Parameters/APCreditorAgingDetailbyDocDateParamsForm'
import { APOverduePayableReport } from './GeneratedReport/APOverduePayableReport'
import { APCreditorAgingByDocDateReport } from './GeneratedReport/APCreditorAgingByDocDateReport'
import { APCreditorLedgerDetailByDocDateReport } from './GeneratedReport/APCreditorLedgerDetailByDocDateReport'
import { APCreditorAgingSummarybyTrxDateParamsForm } from './Parameters/APCreditorAgingSummarybyTrxDateParamsForms'
import { APCreditorAgingSummaryByTrxDateReport } from './GeneratedReport/APCreditorAgingSummaryReport'
import { APCreditorControlSummaryForms } from './Parameters/APCreditorControlSummaryForms'
import { APCreditorControlSummaryReport } from './GeneratedReport/APCreditorControlSummaryReport'
import { APCreditorControlDetailForms } from './Parameters/APCreditorControlDetailForms '
import { APCreditorControlDetailReport } from './GeneratedReport/APCreditorControlDetailReport'
import { APCreditorAgingSummarybyDocDateParamsForm } from './Parameters/APCreditorAgingSummaryByDocDateParamsForm'
import { APCreditorAgingSummaryByDocDateReport } from './GeneratedReport/APCreditorAgingSummaryByDocDateReport'
const apDigitalReportRoutes = [
  {
    props: {
      exact: true,
      path: '/account-payable/:CompanyID/digital-report',
    },
    component: <APDigitalReportSubmenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-overdue-payable/generated',
    },
    component: <APOverduePayableReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-aging-docdate/generated',
    },
    component: <APCreditorAgingByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-ledger-detail-docdate/generated',
    },
    component: <APCreditorLedgerDetailByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-aging-summary-trx-date/generated',
    },
    component: <APCreditorAgingSummaryByTrxDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-aging-summary-doc-date/generated',
    },
    component: <APCreditorAgingSummaryByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-overdue-payable/parameters',
    },
    component: <APOverduePayableParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-ledger-detail-by-doc-date/parameters',
    },
    component: <APCreditorLedgerDetailbyDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-aging-detail-by-doc-date/parameters',
    },
    component: <APCreditorAgingDetailbyDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-aging-summary-trx-date/parameters',
    },
    component: <APCreditorAgingSummarybyTrxDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-aging-summary-doc-date/parameters',
    },
    component: <APCreditorAgingSummarybyDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-control-summary/parameters',
    },
    component: <APCreditorControlSummaryForms />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-control-summary/generated',
    },
    component: <APCreditorControlSummaryReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-control-detail/parameters',
    },
    component: <APCreditorControlDetailForms />,
  },
  {
    props: {
      exact: true,
      path:
        '/account-payable/:CompanyID/digital-report/ap-creditor-control-detail/generated',
    },
    component: <APCreditorControlDetailReport />,
  },
]

export default apDigitalReportRoutes
