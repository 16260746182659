import React from 'react'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns'

export const TableCBV = (props: any) => {
  const { data, pageLength } = props
  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  return (
    <>
      <div style={{ fontSize }}>
        <div style={{ display: 'flex' }}>
          {/* Left Column */}
          <span style={{ width: '60%' }}>
            <table style={{ marginTop: '5px' }}>
              <tr>
                <td>
                  <b>{data?.PayeeName ?? data?.ReceivedFrom}</b>
                </td>
              </tr>
              <tr></tr>
              <tr></tr>
              <tr>
                <td>{data?.Description}</td>
              </tr>
            </table>
          </span>

          {/* Right Column */}
          <span style={{ width: '40%' }}>
            <table>
              <>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Document No. </td>
                  <td>:</td>
                  <td>
                    <b>{data?.DocNo}</b>
                  </td>
                </tr>
                {data?.DocDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Document Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DocDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}
                <tr>
                  <td style={{ paddingRight: '20px' }}>Reference No. </td>
                  <td>:</td>
                  <td>{!data?.RefNo ? 'NA' : data?.RefNo}</td>
                </tr>

                {data?.PaymentMethod?.Name && (
                  <>
                    <tr>
                      <td>Payment Method </td>
                      <td>:</td>
                      <td>{data?.PaymentMethod?.Name}</td>
                      <tr></tr>
                    </tr>
                  </>
                )}

                {data?.BankAccount?.AccountNo && (
                  <>
                    <tr>
                      <td>Bank Code </td>
                      <td>:</td>
                      <td>{data?.BankAccount?.Code}</td>
                      <tr></tr>
                    </tr>
                    <tr>
                      <td>Bank A/C No. </td>
                      <td>:</td>
                      <td>{data?.BankAccount?.AccountNo}</td>
                      <tr></tr>
                    </tr>
                  </>
                )}

                {/* <tr>
                  <td>Page No. </td>
                  <td>:</td>
                  <td>NA</td>
                </tr> */}

                {data?.DueDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Due Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DueDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}
              </>
            </table>
          </span>
        </div>
      </div>
    </>
  )
}

export default TableCBV
