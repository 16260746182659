import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import {
  useGetBankAccountQuery,
  useGetCompanyNameQuery,
  useGetLedgerQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'

export const ExternalLedgerDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, BankAccountID, LedgerID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: bankAccountLoading,
    error: bankAccountError,
    data: { getBankAccount } = { getBankAccount: [] },
  } = useGetBankAccountQuery({
    variables: { BankAccountID: BankAccountID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: ledgerLoading,
    error: ledgerError,
    data: { getLedger } = { getLedger: [] },
  } = useGetLedgerQuery({
    fetchPolicy: 'network-only',
    variables: { LedgerID: LedgerID },
  })

  return (
    <>
      {ledgerLoading && <Loading />}
      {bankAccountLoading && <Loading />}
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/cash-book/${CompanyID}/submenu/${BankAccountID}/external-ledger`
          )
        }
        smTitle="Cash Book"
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Cash Book' },
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'External Ledger', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getBankAccount[0]?.BankProfile?.Name}
            </span>
            <span
              className="xsTitle"
              style={{ color: '#ff9800', float: 'right' }}
            >
              {getBankAccount[0]?.AccountNo}
            </span>
          </>
        }
        infoLine={
          <>
            <span className="xxTitle" style={{ paddingRight: '10px' }}>
              {formatDate(getLedger[0]?.createdTs)}
            </span>
            <span className="xxTitle">
              {getLedger[0]?.DocNo ?? getLedger[0]?.RefNo}
            </span>
          </>
        }
      />

      <ContentWrapper multiDynamicInfo>
        <CardContents>
          <div className="content-wrap ">
            <div className="desc" style={{ color: 'grey' }}>
              Document Date
            </div>
            <div className="mdDesc">{formatDate(getLedger[0]?.DocDate)}</div>
          </div>

          <div className="content-wrap ">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <div className="mdDesc">{getLedger[0]?.RefNo}</div>
          </div>

          <div className="content-wrap ">
            <div className="desc" style={{ color: 'grey' }}>
              Transaction Date
            </div>
            <div className="mdDesc">
              {formatDate(getLedger[0]?.TransactionDate)}
            </div>
          </div>

          <div className="content-wrap ">
            <div className="desc" style={{ color: 'grey' }}>
              Payee/Receiver Name
            </div>
            <div className="mdDesc">{getLedger[0]?.PayeeName}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="mdDesc text-noflow">
              {' '}
              {getLedger[0]?.Description}{' '}
            </div>
          </div>

          {/* <div className="content-wrap full">
            <div className="desc">Exchange Rate</div>
            <div className="mdDesc">{getLedger[0]?.ExchangeRate}</div>
          </div> */}
          <div className="content-wrap">
            <div className="desc">Base Amount</div>
            <div className="mdDesc">{amtStr(getLedger[0]?.BaseAmt)}</div>
          </div>
          <div className="content-wrap">
            <div className="desc">Total Amount</div>
            <div className="mdDesc">{amtStr(getLedger[0]?.DocAmt)}</div>
          </div>
        </CardContents>
      </ContentWrapper>
    </>
  )
}
