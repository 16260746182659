import React from 'react';
// import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns';
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import theme from '@ifca-root/react-component/src/assets/theme';

export const TableGRN = (props: any) => {
  const { data, name, getAllCompany, docType } = props;
  // const [width] = useMediaQuery()
  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) {
    fontSize = 7;
  } else {
    fontSize = 10;
  }

  return (
    <>
      {/* GRN table */}
      {docType === 'delivery-order' && (
        <div style={{ fontSize }}>
          <div style={{ display: 'flex' }}>
            {/* Left Column */}
            <span style={{ width: '60%' }}>
              <table style={{ marginTop: '5px' }}>
                <tr>
                  <td>
                    <b>{data?.Supplier?.CompanyName}</b>
                  </td>
                </tr>
                <tr>
                  {data?.Supplier?.Address?.address}
                  <br />
                  {data?.Supplier?.Address?.postCode}{' '}
                  {data?.Supplier?.Address?.city}
                  <br />
                  {data?.Supplier?.Address?.state}
                  <br />
                  {data?.Supplier?.Address?.country}
                  <br />
                </tr>
              </table>

              <table style={{ marginTop: '5px' }}>
                <tr>
                  <td>GST No.</td>
                  <td>:</td>
                  <td>{data?.Supplier?.GSTRegNo ?? 'NA'}</td>
                </tr>
              </table>

              <table style={{ marginTop: '5px' }}>
                <tr>
                  <td>Attn. </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact?.Name ?? 'NA'}</td>
                </tr>
                <tr>
                  <td>Tel No. </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact?.ContactNo ?? 'NA'}</td>
                </tr>
                <tr>
                  <td>Email </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact?.Email ?? 'NA'}</td>
                </tr>
              </table>
            </span>

            {/* Right Column */}
            <span style={{ width: '40%' }}>
              <table>
                <>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>GRN No. </td>
                    <td>:</td>
                    <td>
                      <b>{data?.DocNo}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>GRN Date</td>
                    <td>:</td>
                    <td>{data?.DocDate}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>DO No. </td>
                    <td>:</td>
                    <td>{data?.DoNo}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>DO Date </td>
                    <td>:</td>
                    <td>{data?.TransactionDate}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Delivery Location</td>
                    <td>:</td>
                    <td>{name}</td>
                  </tr>
                </>
              </table>
            </span>
          </div>
        </div>
      )}

      {/* GRTN table */}
      {docType === 'good-return-note' && (
        <div style={{ fontSize }}>
          <div style={{ display: 'flex' }}>
            {/* Left Column */}
            <span style={{ width: '60%' }}>
              <table style={{ marginTop: '5px' }}>
                <tr>
                  <td>
                    <b>{data?.Supplier?.CompanyName}</b>
                  </td>
                </tr>
                <tr>
                  {data?.Supplier?.Address?.address}
                  <br />
                  {data?.Supplier?.Address?.postCode}{' '}
                  {data?.Supplier?.Address?.city}
                  <br />
                  {data?.Supplier?.Address?.state}
                  <br />
                  {data?.Supplier?.Address?.country}
                  <br />
                </tr>
              </table>

              <table style={{ marginTop: '5px' }}>
                <tr>
                  <td>GST No.</td>
                  <td>:</td>
                  <td>{data?.Supplier?.GSTRegNo ?? 'NA'}</td>
                </tr>
              </table>

              <table style={{ marginTop: '5px' }}>
                <tr>
                  <td>Attn. </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact?.Name ?? 'NA'}</td>
                </tr>
                <tr>
                  <td>Tel No. </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact?.ContactNo ?? 'NA'}</td>
                </tr>
                <tr>
                  <td>Email </td>
                  <td>:</td>
                  <td>{data?.Supplier?.CreditorContact?.Email ?? 'NA'}</td>
                </tr>
              </table>
            </span>

            {/* Right Column */}
            <span style={{ width: '40%' }}>
              <table>
                <>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>GRTN No. </td>
                    <td>:</td>
                    <td>
                      <b>{data?.DocNo}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>GRTN Date</td>
                    <td>:</td>
                    <td>{data?.DocDate}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>DO No. </td>
                    <td>:</td>
                    <td>{data?.RefNo}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Return Date </td>
                    <td>:</td>
                    <td>{data?.TraansactionDate}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Return Location</td>
                    <td>:</td>
                    <td>{name}</td>
                  </tr>
                </>
              </table>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default TableGRN;
