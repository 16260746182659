import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { default as React, useEffect } from 'react'
import '../AuditAdjustment.scss'
import PrintIcon from '@material-ui/icons/Visibility'
import { useHistory, useParams } from 'react-router'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
}

export const AuditAdjustmentDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()

  useEffect(() => {
    console.log('AuditAdjustmentDetailContent')
  }, [])
  const { listEl, documentListing, listStatus, userList, mode } = props

  return (
    <>
      {listStatus === 'COMPLETED' ? (
        <div className="content-wrap left">
          <div className="desc" style={{ color: 'grey' }}>
            Adjustment Type
          </div>
          <div className="mdDesc"> {listEl?.AdjYearOrPeriod} </div>
        </div>
      ) : listStatus === 'SUBMIT' ? (
        <div className="content-wrap left">
          <div className="desc" style={{ color: 'grey' }}>
            Reference No
          </div>
          <div className="mdDesc"> {listEl?.RefNo} </div>
        </div>
      ) : null}

      <div className="content-wrap right">
        {/* <div className="desc" style={{ color: 'grey' }}>
          {'Preview Document'}
        </div> */}
        <span
          style={{ float: 'right' }}
          onClick={() => {
            history.push({
              pathname: `/general-ledger/${CompanyID}/audit-adjustment/${listEl?.AuditAdjustmentID}/preview`,
              state: {
                ...listEl,
                mode: 'detail',
              },
            })
          }}
        >
          <PrintIcon
            style={{
              fontSize: 'medium',
              padding: '0px 10px 2px 0px',
            }}
          />
        </span>
      </div>

      {listStatus === 'COMPLETED' ? (
        <div className="content-wrap full ">
          <div className="desc " style={{ color: 'grey' }}>
            Reference No.
          </div>
          <div className="mdDesc text-noflow"> {listEl?.RefNo} </div>
        </div>
      ) : null}

      <div className="content-wrap full">
        <div className="desc" style={{ color: 'grey' }}>
          Description
        </div>
        <div className="mdDesc text-noflow">
          {' '}
          {listEl?.Description ?? listEl?.Remark}{' '}
        </div>
      </div>

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}
