import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { CopyrightFooter } from 'components/Footer/Copyright'
import {
  useGetCycleCountQuery,
  useGetStockIssueQuery,
  useGetUsersByAccountAndSoftwareQuery,
  useGetWarehouseNameQuery,
  UserSelection,
} from 'generated/graphql'
import React from 'react'
import { useHistory, useParams } from 'react-router'

import { CycleCountDetailContent } from './CycleCountDetailContent'
import { CycleCountDetailItemContent } from './CycleCountDetailItemContent'

export const CycleCountView = (props: any) => {
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let history = useHistory()
  const { CycleCountID, CompanyID, WarehouseID }: any = useParams()

  // QUERY //

  const {
    loading: CycleCountLoading,
    error: CycleCountError,
    data: { getCycleCount } = { getCycleCount: [] },
  } = useGetCycleCountQuery({
    fetchPolicy: 'network-only',
    variables: {
      CycleCountID: CycleCountID,
    },
  })

  const {
    loading: WarehouseNameLoading,
    error: WarehouseNameError,
    data: { getWarehouse: getWarehouseName } = { getWarehouse: [] },
  } = useGetWarehouseNameQuery({
    variables: { WarehouseID: WarehouseID },
    fetchPolicy: 'network-only',
  })

  const value = props.value

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  return (
    <>
      {CycleCountLoading && <Loading />}
      {getUsersByAccountAndSoftwareLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push({
            pathname: `/inventory-control/${WarehouseID}/cycle-count/`,
          })
        }}
        smTitle="Inventory Control"
        title={getWarehouseName[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Inventory Control' },
          { name: 'Cycle Count Detail', current: true },
        ]}
      />

      {/* <DynamicSubHeader
        title={
          <>
            <span className="xsTitle">
              {getBankAccount[0]?.BankProfile?.Name}
            </span>
          </>
        }
        infoLine={
          <>
            <span className="xxTitle">{'Account No.'}&nbsp;:&nbsp;</span>
            <span className="xxTitle">{getBankAccount[0]?.AccountNo}</span>
          </>
        }
      /> */}

      <ContentWrapper
        //   multiDynamicInfo
        float
        footer
      >
        <CardContents
          section={{
            header: {
              title: 'Cycle Count Details',
            },
          }}
        >
          <CycleCountDetailContent
            listEl={getCycleCount[0]}
            userList={getUsersByAccountAndSoftware}
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Cycle Count Item Details',
            },
          }}
        >
          <CycleCountDetailItemContent
            listItem={getCycleCount[0]?.CycleCountItem}
          />
        </CardContents>
      </ContentWrapper>
    </>
  )
}
