import React from 'react'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns'

export const TablePR = (props: any) => {
  const { data, accountType, creditorOrDebtor, name } = props
  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 7
  } else {
    fontSize = 10
  }

  return (
    <>
      <div style={{ fontSize }}>
        <div style={{ display: 'flex' }}>
          {/* Left Column */}
          <span style={{ width: '60%' }}>
            <table style={{ marginTop: '5px' }}>
              <tr>
                <td>
                  <b>{name}</b>
                </td>
              </tr>
            </table>

            {creditorOrDebtor?.Address ? (
              <table>
                {creditorOrDebtor?.Address?.country === 'Singapore' ? (
                  <>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.address}</td>
                    </tr>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.country}</td>
                    </tr>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.postCode}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.address}</td>
                    </tr>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.city}</td>
                    </tr>
                    <tr>
                      <td>
                        {creditorOrDebtor?.Address?.postCode}{' '}
                        {creditorOrDebtor?.Address?.state}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>{creditorOrDebtor?.Address?.country}</td>
                    </tr>
                  </>
                )}
              </table>
            ) : null}

            {!!creditorOrDebtor?.GSTRegNo ? (
              <table>
                <tr>
                  <td>GST No. </td>
                  <td>:</td>
                  <td>{creditorOrDebtor?.GSTRegNo}</td>
                </tr>
              </table>
            ) : null}
            {(creditorOrDebtor?.OfficeNo || creditorOrDebtor?.ContactNo) && (
              <table style={{ textAlign: 'left' }}>
                <tr>
                  <td style={{ paddingRight: '50px' }}>Tel No. </td>
                  <td>:</td>
                  <td>
                    +{creditorOrDebtor?.OfficeNo ?? creditorOrDebtor?.ContactNo}
                  </td>
                </tr>
              </table>
            )}

            {data?.BankAccount && (
              <table style={{ textAlign: 'left' }}>
                <tr>
                  <td style={{ paddingRight: '16px' }}>Bank Info. </td>
                  <td>:</td>
                  <td>
                    {creditorOrDebtor?.BankName ?? 'NA'} |{' '}
                    {creditorOrDebtor?.BankAccountNo ?? 'NA'}
                  </td>
                  <tr></tr>
                </tr>
              </table>
            )}

            {(!!creditorOrDebtor?.CreditorContact ||
              !!creditorOrDebtor?.DebtorContact) && (
              <table style={{ textAlign: 'left', marginTop: '5px' }}>
                <tr></tr>
                <tr>
                  <td style={{ paddingRight: '16px' }}>Attn. </td>
                  <td>:</td>
                  <td>
                    {creditorOrDebtor?.CreditorContact?.Name ??
                      creditorOrDebtor?.DebtorContact[0]?.Name}
                  </td>
                  <tr></tr>
                </tr>
                <tr>
                  <td style={{ paddingRight: '16px' }}>Tel No. </td>
                  <td>:</td>
                  <td>
                    {creditorOrDebtor?.CreditorContact?.ContactNo ??
                      creditorOrDebtor?.DebtorContact[0]?.ContactNo}
                  </td>
                  <tr></tr>
                </tr>
                <tr>
                  <td style={{ paddingRight: '16px' }}>Email. </td>
                  <td>:</td>
                  <td>
                    {creditorOrDebtor?.CreditorContact?.Email ??
                      creditorOrDebtor?.DebtorContact[0]?.Email}
                  </td>
                  <tr></tr>
                </tr>
              </table>
            )}

            {data?.Description && (
              <table style={{ textAlign: 'left', marginTop: '5px' }}>
                <tr></tr>
                <tr>
                  <td style={{ paddingRight: '16px' }}>Description </td>
                  <td>:</td>
                  <td>{data?.Description ?? 'NA'}</td>
                  <tr></tr>
                </tr>
              </table>
            )}
          </span>

          {/* Right Column */}
          <span style={{ paddingTop: '5px', width: '40%' }}>
            <table>
              <>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Document No. </td>
                  <td>:</td>
                  <td>
                    <b>{data?.DocNo}</b>
                  </td>
                </tr>
                {data?.DocDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Document Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DocDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}
                <tr>
                  <td style={{ paddingRight: '20px' }}>Reference No. </td>
                  <td>:</td>
                  <td>{!data?.RefNo ? 'NA' : data?.RefNo}</td>
                </tr>

                {data?.PaymentMethod?.Name && (
                  <>
                    <tr>
                      <td>Payment Method </td>
                      <td>:</td>
                      <td>{data?.PaymentMethod?.Name}</td>
                      <tr></tr>
                    </tr>
                  </>
                )}

                {data?.BankAccount && (
                  <>
                    <tr>
                      <td>Bank Code </td>
                      <td>:</td>
                      <td>{data?.BankAccount?.Code}</td>
                      <tr></tr>
                    </tr>
                    <tr>
                      <td>Bank A/C No. </td>
                      <td>:</td>
                      <td>{data?.BankAccount?.AccountNo}</td>
                      <tr></tr>
                    </tr>
                  </>
                )}

                {data?.DueDate && (
                  <tr>
                    <td style={{ paddingRight: '20px' }}>Due Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DueDate), 'dd/MM/yyyy')}</td>
                  </tr>
                )}
              </>
            </table>
          </span>
        </div>
      </div>
    </>
  )
}

export default TablePR
