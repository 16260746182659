import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { format } from 'date-fns'
import React from 'react'

export const TableDetailBT = (props: any) => {
  // const { docDate, docNo, description, dueDate, reminderAmt } = props;
  const { data, getBankAccount } = props
  // console.log(docType, 'dataa') not deleting this cause want to check the data 11/8/2023

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()
  console.log(data)
  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 10
  }
  ///// style border table
  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }
  const thleftnarrow = {
    width: '15%',
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }
  const thcenter = {
    width: '15%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  }

  const tdleft = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
  }

  const tdright = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
  }

  const tdcenteramt = {
    textAlign: 'center' as 'center',
  }

  return (
    <>
      <div style={{ marginTop: '20px' }}>
        <table
          style={{
            width: '100%',
            fontSize,
            // borderCollapse: 'collapse',
            borderSpacing: '0 1em',
          }}
        >
          <tr>
            <th style={thleftnarrow}>Bank Code</th>
            <th style={thleftnarrow}>Bank A/C No</th>
            <th style={thleft}>Transfer To</th>
            <th style={thcenter}>
              Total Amt
              <br />
              <span>(RM)</span>
            </th>
          </tr>
          <tr>
            <td style={{ textAlign: 'left' }}>
              {
                getBankAccount?.filter(
                  v => v?.BankAccountID === data?.ToBankAccountID
                )[0]?.Code
              }
            </td>
            <td style={{ textAlign: 'left' }}>
              {data?.ToBankAccountDetail?.AccountNo}
            </td>
            <td style={{ textAlign: 'left' }}>
              {data?.ToBankAccountDetail?.BankName}
            </td>
            <td style={tdcenteramt}>{amtStr(data?.Amount)}</td>
          </tr>
        </table>
      </div>
      <div style={{ marginTop: '520px' }}>
        <table style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}>
          <tr>
            <td style={tdleft}>{`Total Amount (RM)`}</td>

            <td style={tdright}>{amtStr(data?.Amount)}</td>
          </tr>
        </table>
      </div>
    </>
  )
}

export default TableDetailBT
