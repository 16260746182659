import theme from '@ifca-root/react-component/src/assets/theme'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import { List, ListItem, ListItemText, useMediaQuery } from '@material-ui/core'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useCreateImportBankReconciliationMutation,
  useGetCheckingValidationBankReconQuery,
  useGetCompanyNameQuery,
  useUpdateImportBankReconciliationMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { BankReconExcelInput } from './BankReconcilationDialogFrom'

export const BankReconciliationExcelValidation = (props: any) => {
  const { mode } = props
  let location = useLocation()
  const passedData: any = location?.state
  const excelData = passedData.excelData
  const fileName = passedData.fileTitle
  let history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()
  const { accType }: any = useParams()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [validatedData, setValidatedData] = useState<any>({
    ErrorData: [],
    ValidData: [],
  })
  const [openDialog, setOpenDialog] = useState(false)
  const [errorNumberingStructure, setErrorNumberingStructure] = useState(false)
  const [date, setDate] = useState(passedData?.date)
  const [description, setDescription] = useState(passedData?.description)
  const [IsExcelMatch, setIsExcelMatch] = useState(passedData?.IsExcelMatch)
  const [StatementBalance, setStatementBalance] = useState(
    passedData?.StatementBalance
  )

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: checkingValidationBankReconLoading,
    error: getCheckingValidationBankReconError,
    data: { getCheckingValidationBankRecon } = {
      getCheckingValidationBankRecon: [],
    },
  } = useGetCheckingValidationBankReconQuery({
    fetchPolicy: 'network-only',
    variables: {
      BankReconciliationItemImportInput: passedData.excelData,
      CompanyID: CompanyID,
      BankAccountID: BankAccountID,
    },
    onCompleted: ({ getCheckingValidationBankRecon }) => {
      let ErrorData: any = []
      let ValidData: any = []

      getCheckingValidationBankRecon?.map(x => {
        if (x['ErrorList']?.length > 0)
          ErrorData.push({
            DocNo: x['DocNo'],
            DocumentDate: x['DocumentDate'],
            DocAmt: x['DocAmt'],
            Remarks: x['Remarks'],
            RowNo: x['RowNo'],
            ErrorList: x['ErrorList'],
          })
        else
          ValidData.push({
            DocNo: x['DocNo'],
            DocumentDate: x['DocumentDate'],
            DocAmt: x['DocAmt'],
            Remarks: x['Remarks'],
            RowNo: x['RowNo'],
            ErrorList: x['ErrorList'],
          })
      })

      setValidatedData({ ErrorData: ErrorData, ValidData: ValidData })
    },
  })

  const [
    createImportBankReconciliation,
    { data: createImportBankReconciliationData },
  ] = useCreateImportBankReconciliationMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenDialog(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-reconciliation`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const [
    updateImportBankReconciliation,
    { data: updateImportBankReconciliationData },
  ] = useUpdateImportBankReconciliationMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenDialog(false)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-reconciliation`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
    },
  })

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    //setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const onSubmit = passedData => {
    if (!!passedData?.DefValue) {
      updateImportBankReconciliation({
        variables: {
          BankReconciliationID: passedData?.DefValue?.BankReconciliationID,
          BankReconciliationItemImportInput: passedData?.excelData,
          CompanyID: CompanyID,
          BankAccountID: BankAccountID,
          Date: date,
          Description: description,
          StatementBalance: StatementBalance,
          IsExcelMatch: IsExcelMatch,
        },
      })
    } else {
      createImportBankReconciliation({
        variables: {
          BankReconciliationItemImportInput: passedData?.excelData,
          CompanyID: CompanyID,
          BankAccountID: BankAccountID,
          Date: date,
          Description: description,
          StatementBalance: StatementBalance,
          IsExcelMatch: IsExcelMatch,
        },
      })
    }
  }

  return (
    <>
      {checkingValidationBankReconLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(
            `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-reconciliation`
          )
        }
        smTitle={'Cash Book'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Bank Reconciliation', current: true },
        ]}
      />

      <DynamicSubHeader
        style={{
          marginTop: '-2px',
        }}
        title={'Bank Reconciliation Excel Upload'}
        rightText={<span className="c-orange">Validation</span>}
      />
      <List className="search-header fixed-search-header with-dynamic">
        <ListItem>
          <ListItemText
            primary={
              <>
                <span className="xsTitle flex-space">{fileName}</span>
              </>
            }
          />
        </ListItem>
      </List>

      <ContentWrapper
        style={{ marginTop: isDesktop ? '140px' : '75px' }}
        float
        footer
      >
        {/* Problem List */}
        {validatedData.ErrorData?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Problem{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {validatedData.ErrorData?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {validatedData.ErrorData?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div style={{ width: '100%' }}>
                          <span className="mdDesc flex-space ">
                            {formatDate(el.DocumentDate)}{' '}
                          </span>
                          <span className="xsTitle  c-orange">{el.DocNo}</span>
                          <span
                            className={
                              el?.DocAmt >= 0
                                ? 'mdDesc c-orange'
                                : 'mdDesc c-red'
                            }
                            style={{ float: 'right' }}
                          >
                            {amtStr(el?.DocAmt)}
                          </span>
                        </div>
                        <div style={{ width: '100%' }}>
                          <span className="desc">{el.Remarks}</span>
                        </div>
                        {/* <div style={{ width: '100%' }}>
                          <span className="desc">{el.ExchangeRate}</span>
                        </div> */}
                      </>
                    }
                    secondary={
                      <>
                        <div>
                          <div
                            className="xsTitle text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            Row No: {el.RowNo}
                          </div>
                          <div
                            className="xsTitle text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            {el.ErrorList?.map((x, index) => {
                              if (index == el.ErrorList?.length - 1)
                                return `${x}`
                              else return `${x}, `
                            })}
                          </div>
                        </div>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}

        {/* Validation List */}
        {validatedData.ValidData?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Validated{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {validatedData.ValidData?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {validatedData.ValidData?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div style={{ width: '100%' }}>
                          <span className="mdDesc">
                            {formatDate(el.DocumentDate)}{' '}
                            <span className="xsTitle  c-orange">
                              {el.DocNo}
                            </span>
                          </span>
                          <span
                            className={el?.DocAmt >= 0 ? 'c-orange' : 'c-red'}
                            style={{ float: 'right' }}
                          >
                            {amtStr(el?.DocAmt)}
                          </span>
                        </div>
                        <div style={{ width: '100%' }}>
                          <span className="desc">{el.Remarks}</span>
                        </div>
                        {/* <div style={{ width: '100%' }}>
                          <span className="desc">{el.ExchangeRate}</span>
                        </div> */}
                      </>
                    }
                    // secondary={
                    //   <>
                    //     <span className="desc">
                    //       <div className="text ">{el.Code}</div>
                    //       <div className="fw-medium"> {el.ParentCode}</div>
                    //       <div className="fw-medium"> {el.Name}</div>
                    //       <div className="fw-medium"> {el.Level}</div>
                    //       <div className="fw-medium"> {el.AccountType}</div>
                    //       <div className="fw-medium"> {el.IsControl}</div>
                    //       <div className="fw-medium"> {el.IsLastNode}</div>
                    //     </span>
                    //   </>
                    // }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </ContentWrapper>

      <BankReconExcelInput
        showDupErrors={false}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        showFooter={false}
        date={date}
        setDate={setDate}
        description={description}
        setDescription={setDescription}
        IsExcelMatch={IsExcelMatch}
        setIsExcelMatch={setIsExcelMatch}
        StatementBalance={StatementBalance}
        setStatementBalance={setStatementBalance}
        reUpload={true}
        editValue={passedData?.DefValue}
        onUploadTemplate={() => {
          // onSubmit(currentData)
          history.push({
            pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-reconciliation/validation`,
            state: {
              excelData: currentData?.map(x => {
                let DocumentDate = new Date(x?.DocumentDate)
                DocumentDate.setHours(0, 0, 0)
                return {
                  DocNo: x?.DocumentNo === null ? null : `${x?.DocumentNo}`,
                  DocumentDate:
                    x?.DocumentDate === null ? null : new Date(DocumentDate),

                  DocAmt: x?.Amount === null ? null : Number(x?.Amount),
                  Remarks: x?.Remarks === null ? null : `${x?.Remarks}`,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
              date: date,
              description: description,
              StatementBalance: StatementBalance,
              IsExcelMatch: IsExcelMatch,
              DefValue: passedData?.DefValue,
            },
          })
          setOpenFileUpload(false)
          localStorage.removeItem('advancedFilter')
          localStorage.removeItem('searchFilter')
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        onDownloadTemplate={onDownloadTemplate}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: 'test',
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false)
          },
        }}
      />
      <Footer
        copyright
        options={
          validatedData.ErrorData?.length === 0 &&
          validatedData.ValidData?.length > 0
            ? [
                {
                  onClick: () => {
                    setOpenDialog(true)
                  },
                  name: 'Confirm Upload',
                  color: 'primary',
                },
              ]
            : [
                {
                  name: 'Re-upload',
                  color: 'primary',
                  onClick: () => {
                    resetFileUpload()
                    setFileTitle('')
                    setCurrentData([])
                    setOpenFileUpload(true)
                  },
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange flex-space"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Upload Confirmation
                  </span>
                </div>
              </div>
            ),
          },
          body: () => (
            <div>
              <span>{'Are you sure to upload?'}</span>
            </div>
          ),

          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    onSubmit(passedData)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <CommonDialog
        fullWidth={true}
        open={errorNumberingStructure}
        onClose={() => {
          setErrorNumberingStructure(false)
        }}
        sections={{
          header: {
            dynamic: (
              // <div className="">
              //   <div className="dialog-dynamic-content">
              //     <span
              //       className="title c-orange flex-space"
              //       style={{
              //         fontSize: '13px',
              //         fontWeight: 'bold',
              //       }}
              //     >
              //       Error!
              //     </span>
              //   </div>
              // </div>

              <>
                <span className="xsTitle flex-space" style={{ color: 'red' }}>
                  Error!
                </span>
              </>
            ),
          },
          body: () => (
            <div>
              <span>
                {'Document Numbering for External Journal is not created.'}
              </span>
            </div>
          ),

          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setErrorNumberingStructure(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
