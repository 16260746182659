import React, { useContext } from 'react'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { useHistory, useParams } from 'react-router'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  InputBase,
} from '@material-ui/core'

const user = JSON.parse(localStorage.getItem('loggedInUser')) //user session

export const DigitalReportsListing = (props: any) => {
  const { CompanyID, BankAccountID }: any = useParams()
  let history = useHistory()
  return (
    <>
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/cash-book/${CompanyID}/submenu/${BankAccountID}/`)
        }
        smTitle={user?.name}
        title={user?.companyName}
        routeSegments={[
          { name: 'Journal Management' },
          { name: 'Digital Reports', current: true }, //submodules
        ]}
        // rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />
      <ContentWrapper>
        <List className="core-list">
          {/* {getQuery === undefined || getQuery?.length === 0 ? ( */}
          <EmptyList title="No Record found" subtitle="Add a new record now." />
          {/* ) : null} */}
          <div>DigitalReportsListing</div>
        </List>
      </ContentWrapper>
    </>
  )
}
