import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetJournalProcessingbyStatusDocument,
  useDocumentListingQuery,
  useGetCompanyNameQuery,
  useGetJournalProcessingQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostJournalProcessingMutation,
  UserSelection,
  useUpdateJournalProcessingMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { default as React, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import '../Journal.scss'
import { JournalDetailContent } from './JournalDetailContent'
import { JournalDetailItemContent } from './JournalDetailItemContent'

export const JournalDetail = (props: any) => {
  let history = useHistory()
  const { mode } = props
  const { CompanyID, JournalProcessingID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  let location = useLocation()
  const editData = location?.state as any
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()
  const [audioInput, setAudioInput] = useState('')
  const [record, setRecord] = useState(false)
  const [text, setText] = useState(mode === 'add' ? '' : editData?.remark)
  const [singleRemark, setSingleRemark] = useState(null)
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  let itemData = []
  itemData = editData?.JournalProcessingItem

  const docAmtReducer = (total, curVal) => total + curVal?.DocAmt

  const positiveAmt = itemData
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = itemData
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()
  const {
    loading: JournalLoading,
    error: JournalError,
    data: { getJournalProcessing } = { getJournalProcessing: [] },
  } = useGetJournalProcessingQuery({
    variables: { JournalProcessingID: JournalProcessingID },
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    loading: docLoading,
    error: docError,
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: JournalProcessingID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const [
    updateJournalProcessing,
    {
      loading: updateJournalProcessingLoading,
      error: updateJournalProcessingError,
    },
  ] = useUpdateJournalProcessingMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      console.log('data', data)
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      mode === 'workdesk'
        ? setTimeout(() => {
            history.push({
              pathname: `/workdesk/general-ledger`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
        : setTimeout(() => {
            history.push({
              pathname: `/general-ledger/${CompanyID}/journal-processing`,
              state: { success: true, msgMode: 'update' },
            })
          }, 500)
    },
  })

  // const JourItem = getJournalProcessing[0]?.JournalProcessingItem

  // const onSubmit = (data, status) => {
  //   updateJournalProcessing({
  //     variables: {
  //       input: {
  //         JournalProcessingID: getJournalProcessing[0]?.JournalProcessingID,
  //         CompanyID: CompanyID,
  //         DocDate: getJournalProcessing[0]?.DocDate,
  //         FYear: getJournalProcessing[0]?.FYear,
  //         FPeriod: getJournalProcessing[0]?.FPeriod,
  //         JournalTypeID: getJournalProcessing[0]?.JournalTypeID,
  //         RefNo: getJournalProcessing[0]?.RefNo,
  //         Description: getJournalProcessing[0]?.Description,
  //         ApprovalStatus: status,
  //         RejectionRemark: data?.rejectionRemark,
  //         Attachment: files,
  //       },
  //       itemInput: JourItem?.map(x => {
  //         return {
  //           MasterCOAID: x.MasterCOAID,
  //           CostCentreID: x.CostCentreID,
  //           DocAmt: x.DocAmt,
  //         }
  //       }),
  //     },
  //     refetchQueries: [
  //       {
  //         query: GetJournalProcessingDocument,
  //       },
  //       {
  //         query: DocumentListingDocument,
  //         variables: {
  //           refTable: 'S_Attachment',
  //           refID: JournalProcessingID,
  //         },
  //       },
  //     ],
  //   })
  // }

  const RecordStatusText = pStatus => {
    switch (pStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Approved'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }

  const rejectFooterOption = {
    name: 'Reject',
    onClick: () => {
      {
        setOpenRejectDialog(true)
      }
    },
    color: 'secondary',

    props: {
      type: 'submit',
    },
  }

  const approveFooterOption = {
    name: 'Approve',
    onClick: () => {
      handleSubmit(data => onSubmit(ApprovalStatus.Approved, 'APPROVED'))()
    },
    color: 'primary',

    props: {
      type: 'submit',
    },
  }
  const footerOptions: any[] = [rejectFooterOption, approveFooterOption]

  const [
    postJournalProcessing,
    {
      loading: postJournalProcessingLoading,
      called: postJournalProcessingCalled,
      error: postJournalProcessingError,
    },
  ] = usePostJournalProcessingMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        mode === 'workdesk'
          ? setTimeout(() => {
              history.push({
                pathname: `/workdesk/general-ledger`,
                state: { success: true, msgMode: 'update' },
              })
            }, 500)
          : setTimeout(() => {
              history.push({
                pathname: `/general-ledger/${CompanyID}/journal-processing`,
                state: { success: true, msgMode: 'update' },
              })
            }, 500)
        // history.push({
        //   pathname: `/general-ledger/${CompanyID}/journal-posting`,
        //   state: { success: true, msgMode: 'create' },
        // })
      }, 500)
    },
  })

  // let listJournalIDs = Array.from(journalIDs || [])

  const onSubmit = (data, status) => {
    postJournalProcessing({
      variables: {
        JournalProcessingIDs: JournalProcessingID,
        CompanyID,
      },
      refetchQueries: [
        {
          query: GetJournalProcessingbyStatusDocument,
          variables: { CompanyID },
        },
      ],
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {JournalLoading && <Loading />}
      {updateJournalProcessingLoading && <Loading />}
      {postJournalProcessingLoading && <Loading />}
      {getUsersByAccountAndSoftwareLoading && <Loading />}
      {docLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-ledger/${CompanyID}/journal-processing`)
        }}
        smTitle="General Ledger"
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'Journal Processing' },
          { name: 'Journal Entries', current: true },
        ]}
        rightRouteSegments={[{ name: `Approved`, current: true }]}
      />

      <DynamicSubHeader
        title={
          <span className="c-orange">
            <span style={{ fontWeight: 'bold' }}>
              {getJournalProcessing[0]?.DocNo ?? getJournalProcessing[0]?.RefNo}
            </span>
          </span>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getJournalProcessing[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {getJournalProcessing[0]?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc ">
            <span className="desc c-orange">
              {amtStr(
                getJournalProcessing[0]?.JournalProcessingItem?.filter(
                  item => item?.DocAmt > 0
                )?.reduce((x, y) => x + y?.DocAmt, 0)
              )}
            </span>
          </span>
        }
      />
      <ContentWrapper multiDynamicInfo float footer>
        <CardContents>
          <JournalDetailContent
            listEl={getJournalProcessing[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Journal Detail',
            },
          }}
        >
          <JournalDetailItemContent
            listItem={getJournalProcessing[0]?.JournalProcessingItem}
          />
        </CardContents>
      </ContentWrapper>
      {mode === 'workdesk' ? <Footer options={[...footerOptions]} /> : ''}
      <TotalAmountFooter
        detail={true}
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />

      {getJournalProcessing[0]?.ApprovalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              // props: { disabled: newFilteredList?.length === 0 },
            },
          ]}
        />
      ) : null}

      <RejectDialog
        data={getJournalProcessing[0]}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        audioInput={audioInput}
        record={record}
        setRecord={setRecord}
        setValue={setValue}
        errors={errors}
        register={register}
        control={control}
        clearErrors={clearErrors}
        setText={setText}
        handleSingleRemark={handleSingleRemark}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
    </>
  )
}
