import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { Menu, MenuItem, TextField, useMediaQuery } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { TooltipAmountFooter } from 'components/Footer/TooltipAmountFooter'
import { ItemDialog } from 'containers/APModule/components/ItemDialog'
import { ItemDetail } from 'containers/ARAPModule/Components/Detail/ItemDetail'
import {
  findDuplicates,
  statusInput,
} from 'containers/ARAPModule/Function/DocumentChecker'
import { dueDateChecker } from 'containers/ARAPModule/Helper/DateCalculation'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocDateValidationAfter,
  DocDateValidationBefore,
} from 'containers/CashBookModule/DocDateValidation'
import {
  AcctPermission,
  DocumentListingDocument,
  GetApDebitNotebyStatusDocument,
  RecordStatus,
  useCreateApDebitNoteMutation,
  useDocumentListingLazyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreLazyQuery,
  useGetCreditorAccountCompanyAssignmentQuery,
  useGetDocNumTitleQuery,
  useGetDocumentDateValidationQuery,
  useGetExpenseItemLazyQuery,
  useGetMasterCoaQuery,
  useGetTaxEffectiveDateQuery,
  useGetTaxSchemeQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateApDebitNoteMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'

interface DebitNoteProps {
  DocDate: string
  TrxDate: string
  DocAmt: number
  Remark: string
  Description: string
  approval: string
  user: string
  CreditorAccountID: string
  CreditDebitID: string
  ExpenseItemID: string
  RefNo: string
  CompanyID: string
  DebitNoteID: string
  TaxRate: number
  TaxSchemeID: string
  DocNo: string
}

export const APDebitNoteStateForm = (props: any) => {
  const { formMode } = props
  const { CompanyID, DebitNoteID }: any = useParams()
  const [taxEffectiveList, setTaxEffectiveList] = useState<any>()
  const [detailMode, setDetailMode] = useState('')

  /* -------------------------------------------- */
  /*                   VARIABLES                  */
  /* -------------------------------------------- */

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */
  const [selectedTax, setSelectedTax] = useState(null)

  const { setOpenSnackBar, setSnackBarMsg } = useContext(SnackBarContext) as any

  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [onSub, setSub] = useState(false)

  const [initDocs, setInitDocs] = useState([])
  useEffect(() => {
    if (editData?.Attachment) {
      setInitDocs(editData?.Attachment)
    }
  }, [editData])
  const [debitNoteItemData, setDebitNoteItemData] = useState([])

  const [allocationData, setAllocationData] = useState([])
  const [edit, setEdit] = useState(false)

  const [singleRemark, setSingleRemark] = useState(null)
  const [audioInput, setAudioInput] = useState('')
  const [record, setRecord] = useState(false)
  const [docNoChecker, setDocNoChecker] = useState(false)
  const [text, setText] = useState(editData?.Remark ?? '')
  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }
  //For Default Department
  const [defaultCostCentre, loadDefaultCostCentre] = useState(null)

  useEffect(() => {
    if (DebitNoteID && editData?.Remark) {
      setText(editData?.Remark)
      handleSingleRemark(DebitNoteID && editData?.Remark)
      setAudioInput(DebitNoteID && editData?.Remark)
    }
  }, [editData])

  /* -------------------------------------------- */
  /*                   USE FORM                   */
  /* -------------------------------------------- */
  // checking the document number setup

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = { getDocumentNumberHeader: [] },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RefTable: 'AP_DebitNote' },
    onCompleted: ({ getDocumentNumberHeader }) => {
      if (
        (getDocumentNumberHeader?.length > 0 &&
          getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)) ||
        getDocumentNumberHeader?.find(x => x?.IsAutoDocNo === true)
      ) {
        setDocNoChecker(true)
      }
    },
  })

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const DebitNoteFormSchema = yup.object().shape({
    CreditorAccountID: CommonYupValidation.requireField(
      'Creditor Name is required'
    ),
    DocDate: yup.string().required('Doc Date is Required'),
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    RefNo: yup.string().required('Reference No is Required'),
    ...(docNoChecker === false
      ? { DocNo: yup.string().required('Document No. is Required') }
      : null),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
    unregister,
  } = useForm<DebitNoteProps>({
    defaultValues: {
      RefNo: editData ? editData?.RefNo : '',
      DocDate: editData ? editData?.DocDate : new Date(),
      Remark: editData ? editData?.Remark : '',
      Description: editData ? editData?.Description : '',
      CreditorAccountID: editData ? editData?.CreditorAccountID : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(DebitNoteFormSchema),
  })

  const {
    anchorEl,
    menu,
    handleClick,
    handleClose,
    resetMenu,
  } = useMenuOption()

  // Upload Document
  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  // Company Name
  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RecordStatus: RecordStatus.Active },
    onCompleted: ({ getCompany }) => {
      loadDefaultCostCentre(getCompany[0]?.DefaultCostCentre)
    },
  })

  const {
    loading: masterCOALoading,
    error: masterCOAError,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
  })

  // Tax
  const {
    loading: taxLoading,
    error: taxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: docDateValidationLoading,
    error: docDateValidationError,
    data: { getDocumentDateValidation } = { getDocumentDateValidation: [] },
  } = useGetDocumentDateValidationQuery({
    fetchPolicy: 'network-only',
  })

  // Creditor Account Company Assignment//
  const {
    loading: credAcctCompAssgtLoading,
    error: credAcctCompAssgtError,
    data: { getCreditorAccountCompanyAssignment } = {
      getCreditorAccountCompanyAssignment: [],
    },
  } = useGetCreditorAccountCompanyAssignmentQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  let creditorAcc = getCreditorAccountCompanyAssignment?.map(x => {
    return x?.CreditorAccount
  })

  const [term, setTerm] = useState(
    mode === 'edit' ? editData?.CreditorAccount?.CreditTerm : 0
  )

  //CostCentre
  const [
    fetchCostCentre,
    {
      loading: CostCentreLoading,
      error: costCentreErrors,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      orderByAsc: 'CostCentreID',
      IsLastNode: true,
    },
  })

  // Item
  const [
    fetchExpenseItem,
    {
      loading: ExpenseItemLoading,
      error: ExpenseItemError,
      data: { getItemByCompany } = { getItemByCompany: [] },
    },
  ] = useGetExpenseItemLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: taxEffectiveDateLoading,
    error: taxEffectiveDateError,
    data: { getTaxEffectiveDate } = { getTaxEffectiveDate: [] },
  } = useGetTaxEffectiveDateQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (getTaxEffectiveDate?.length > 0) {
        let temp = getTaxEffectiveDate?.filter(
          tax =>
            Number(new Date(tax?.Date)) <
              DocDateValidationAfter(
                getDocumentDateValidation?.map(x => x?.MonthsAfter)
              ) &&
            Number(new Date(tax?.Date)) >
              DocDateValidationBefore(
                getDocumentDateValidation?.map(x => x?.MonthsBefore)
              )
        )
        setTaxEffectiveList(temp)
      }
    },
  })

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
  // call fetchDocuments to get existing attachment from db
  useEffect(() => {
    if (
      formMode === 'edit' ||
      formMode === 'approve-reject' ||
      formMode === 'resubmit'
    )
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: DebitNoteID,
          },
        })
      }
  }, [formMode])

  // applies after navigating out from item form, where "Attachments" is passed as "files" in location.state
  useEffect(() => {
    if (editData?.files) {
      setFiles(editData?.files)
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [editData])

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const [
    createAPDebitNote,
    {
      loading: createAPDebitNoteLoading,
      called: createAPDebitNoteCalled,
      error: createAPDebitNoteError,
    },
  ] = useCreateApDebitNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({ pathname: `/account-payable/${CompanyID}/debit-note` })
      }, 500)
    },
  })

  const [
    updateAPDebitNote,
    {
      loading: updateAPDebitNoteLoading,
      called: updateAPDebitNoteCalled,
      error: updateAPDebitNoteError,
    },
  ] = useUpdateApDebitNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-payable/${CompanyID}/debit-note`,
        })
      }, 500)
    },
  })

  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */

  // To calculate Total Amount of Credit Note
  let totalAmount = debitNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.DocAmt),
    0
  )
  // To calculate Total Amount of Credit Note
  let totalTaxAmt = debitNoteItemData?.reduce(
    (total, currentValue) => (total = total + currentValue.TaxAmt),
    0
  )

  // get amount before tax
  let amtBeforeTax = totalAmount - totalTaxAmt
  let calcTotal = items => {
    return items?.reduce(
      (total, currentValue) => (total = total + currentValue.DocAmt),
      0
    )
  }

  /////////////////////////////////////////////////

  //checking for existing docNo
  const watchDocNo = watch('DocNo')

  const existingDocNo = creditorAcc?.find(
    x => x?.CreditorAccountID === watch('CreditorAccountID')
  )?.DocNoList

  /* -------------------------------------------- */
  /*                USE EFFECT                    */
  /* -------------------------------------------- */

  useEffect(() => {
    if (DebitNoteID && editData?.Remark) {
      setValue('Remark', editData?.Remark)
    }
  }, [editData])

  useEffect(() => {
    if (editData) {
      localStorage.getItem('attachment')
    }
  }, [])

  //   this is where i get the data for item when editing the form

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const debitNoteItems = editData?.APDebitNoteItem?.map(el => {
        return {
          Sequence: el?.Sequence,
          DebitNoteItemID: el?.DebitNoteItemID,
          ExpenseItemName: el?.ExpenseItem?.ItemName,
          ExpenseItemID: el?.ExpenseItemID,
          CostCentreCode: el?.CostCentre?.Code,
          CostCentreID: el?.CostCentreID,
          UnitPrice: el?.UnitPrice,
          Quantity: el?.Quantity,
          UOMID: el?.UOMID,
          Amount: el?.Amount,
          TaxSchemeID: el?.TaxSchemeID,
          TaxCode: el?.TaxScheme?.Code,
          TaxAmt: el?.TaxAmt,
          TaxRate: el?.TaxRate,
          Remark: el?.Remark,
          DocAmt: el?.DocAmt,
        }
      })

      debitNoteItemData.push(...debitNoteItems)
    }
  }, [formMode, editData])

  /* -------------------------------------------- */
  /*                   DELETE                     */
  /* -------------------------------------------- */

  const onDeleteDebitNoteItem = (delInd: number) => {
    debitNoteItemData.splice(delInd, 1)
  }

  /* ------------------------------------------- */
  /*                ON SUBMIT                    */
  /* ------------------------------------------- */
  // updated tax effective date

  const updateNewDNItem = (newDate, tED) => {
    const arr = []
    debitNoteItemData?.map(x => {
      let newTax =
        getTaxEffectiveDate?.filter(
          j =>
            j?.TaxSchemeID === x?.TaxSchemeID &&
            new Date(j?.Date) <= new Date(newDate)
        )?.length > 0
          ? getTaxEffectiveDate
              ?.filter(
                j =>
                  j?.TaxSchemeID === x?.TaxSchemeID &&
                  new Date(j?.Date) <= new Date(newDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })
          : null

      if (newTax !== null) {
        arr.push({
          DebitNoteItemID: x?.DebitNoteItemID,
          ExpenseItemID: x?.ExpenseItemID,
          RevenueCode: x?.RevenueCode,
          RevenueName: x?.RevenueName,
          Amount: x?.Amount,
          TaxSchemeID: x?.ExpenseItem?.TaxSchemeID,
          TaxCode: x?.TaxCode,
          TaxRate: newTax?.Rate,
          TaxAmt: x?.Amount * (newTax?.Rate / 100),
          DocAmt: x?.Amount + x?.Amount * (newTax?.Rate / 100),
          CostCentreID: x?.CostCentreID,
          CostCentreCode: x?.CostCentreCode,
          Remark: x?.Remark,
        })
      }
    })
  }

  const onSubmit = (data, status) => {
    if (formMode === 'add') {
      if (
        getDocumentNumberHeader
          ?.filter(x => x?.RefTable === 'AP_DebitNote')
          ?.find(y => y?.SampleOutput === '') &&
        docNoChecker === true
      ) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.errorNumberingStructure())
      } else if (
        findDuplicates(existingDocNo, watchDocNo, formMode, editData?.DocNo)
      ) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.duplicateDocNo())
      } else {
        //add the data with GraphQL mutation
        createAPDebitNote({
          variables: {
            input: {
              DebitNoteID: DebitNoteID,
              CompanyID: CompanyID,
              DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
              TransactionDate: formatDashDate(
                new Date(data?.TrxDate)?.toISOString()
              ),
              DocAmt: totalAmount ?? 0,
              ...(docNoChecker === false ? { DocNo: data.DocNo } : null),
              CreditorAccountID: data?.CreditorAccountID,
              ExpenseItemID: data?.ExpenseItemID,
              Remark: data?.Remark,
              Description: data?.Description,
              RefNo: data?.RefNo,
              CreditTerm: term,
              DueDate: new Date(
                dueDateChecker(data?.DocDate, term)
              ).toISOString(),
              ApprovalStatus: statusInput(status),
              Attachment: files,
            },
            itemInput: debitNoteItemData?.map((x, i) => {
              return {
                ExpenseItemID: x?.ExpenseItemID,
                Amount: parseFloat(x?.Amount),
                UnitPrice: parseInt(x?.UnitPrice),
                Quantity: parseInt(x?.Quantity),
                UOMID: x?.UOMID,
                TaxSchemeID: x?.TaxSchemeID,
                TaxRate: parseFloat(x?.TaxRate),
                TaxAmt: parseFloat(x?.TaxAmt),
                DocAmt: parseFloat(x?.DocAmt),
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                Sequence: x?.Sequence ?? i + 1,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetApDebitNotebyStatusDocument,
              variables: {
                CompanyID: CompanyID,
                StatusArr: ['ACTIVE'],
              },
            },
            {
              query: DocumentListingDocument,
              variables: {
                refTable: 'S_Attachment',
                refID: editData?.DebitNoteID,
              },
            },
          ],
        })
      }
    }

    //update the data
    else if (formMode !== 'add') {
      if (
        findDuplicates(existingDocNo, watchDocNo, formMode, editData?.DocNo)
      ) {
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.duplicateDocNo())
      }
      updateAPDebitNote({
        variables: {
          input: {
            DebitNoteID: DebitNoteID,
            CompanyID: CompanyID,
            DocDate: formatDashDate(new Date(data?.DocDate)?.toISOString()),
            TransactionDate: formatDashDate(
              new Date(data?.TrxDate)?.toISOString()
            ),
            DocAmt: totalAmount ?? 0,
            ...(docNoChecker === false ? { DocNo: data.DocNo } : null),
            CreditorAccountID: data?.CreditorAccountID,
            ExpenseItemID: data?.ExpenseItemID,
            Remark: data?.Remark,
            Description: data?.Description,
            RefNo: data?.RefNo,
            CreditTerm: term,
            DueDate: new Date(
              dueDateChecker(data?.DocDate, term)
            ).toISOString(),
            ApprovalStatus: statusInput(status),
            Attachment: files,
          },
          itemInput: debitNoteItemData?.map((x, index) => {
            return {
              ExpenseItemID: x?.ExpenseItemID,
              Amount: parseFloat(x?.Amount),
              TaxSchemeID: x?.TaxSchemeID,
              TaxRate: parseFloat(x?.TaxRate),
              TaxAmt: parseFloat(x?.TaxAmt),
              DocAmt: parseFloat(x?.DocAmt),
              UnitPrice: parseFloat(x?.UnitPrice),
              Quantity: parseInt(x?.Quantity),
              UOMID: x?.UOMID,
              CostCentreID: x?.CostCentreID,
              Remark: x?.Remark,
              Sequence: x?.Sequence ?? index + 1,
            }
          }),
        },
        refetchQueries: [
          {
            query: GetApDebitNotebyStatusDocument,
            variables: {
              CompanyID: CompanyID,
              StatusArr: ['ACTIVE'],
            },
          },
          {
            query: DocumentListingDocument,
            variables: {
              refTable: 'S_Attachment',
              refID: editData?.DebitNoteID,
            },
          },
        ],
      })
    }
  }

  const createUpdateCalled = editData
    ? updateAPDebitNoteCalled
    : createAPDebitNoteCalled

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()

  /* -------------------------------------------- */
  /*                    FOOTER                    */
  /* -------------------------------------------- */
  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled: handlePermDisabled({
      companyID: CompanyID,
      permEnum: AcctPermission.AccPayableDebitNotesDraft,
    })
      ? true
      : false,
  }
  const submitFooterOption = {
    name: 'Submit',
    onClick: e => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
    },
    color: 'primary',
    props: {
      type: 'submit',
    },
    disabled:
      calcTotal(debitNoteItemData) === 0 ||
      calcTotal(debitNoteItemData) === undefined
        ? true
        : false,
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,
      // for item
      itemSuffixID: 'DebitNoteItemID',
      itemTableName: 'APDebitNoteItem',
      // for attachment
      initFiles: initDocs,
      currFiles: files?.map(file => file?.name),
    })

  return (
    <>
      {taxLoading && <Loading />}
      {companyLoading && <Loading />}
      {documentLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {ExpenseItemLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      {taxEffectiveDateLoading && <Loading />}
      {docDateValidationLoading && <Loading />}
      {credAcctCompAssgtLoading && <Loading />}
      {createAPDebitNoteLoading && <Loading />}
      {updateAPDebitNoteLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          {
            if (hasChanges() === true) {
              setOpenExitConf(true)
            } else {
              history.push({
                pathname: `/account-payable/${CompanyID}/debit-note`,
              })
            }
          }
        }}
        smTitle={'Account Payable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Account Payable Main Menu' },
          { name: 'Account Payable Submenu' },
          { name: 'Debit Note', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : formMode === 'edit'
                ? 'Edit'
                : 'Draft',
            current: true,
          },
        ]}
      />
      <ContentWrapper float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Date"
              control={control}
              onChange={(date: Date | null) => {
                //date
              }}
              onAccept={(date: Date | null) => {
                updateNewDNItem(date, taxEffectiveList)
              }}
              format="dd/MM/yyyy"
              value={watch(formMode === 'add' ? new Date() : editData?.DocDate)}
              margin="dense"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData ? editData?.DocDate : new Date()}
              showTodayButton
              className={'left'}
              style={{
                paddingTop: '10px',
              }}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxDate"
              required
              label="Transaction Date"
              control={control}
              format="dd/MM/yyyy"
              margin="normal"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              showTodayButton
              className="right"
              value={new Date()}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
            />
          </MuiPickersUtilsProvider>

          {docNoChecker === false ||
          getDocumentNumberHeader[0]?.IsAutoDocNo === false ? (
            <Controller
              as={TextField}
              name="DocNo"
              label="Document No"
              margin="dense"
              required
              id="standard-basic"
              defaultValue={editData?.DocNo}
              autoComplete="off"
              control={control}
              className="left"
              ref={register}
              helperText={errors?.DocNo?.message}
              error={errors?.DocNo ? true : false}
            />
          ) : null}

          <Controller
            as={TextField}
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            required
            autoComplete="off"
            control={control}
            className={
              docNoChecker === false ||
              getDocumentNumberHeader[0]?.IsAutoDocNo === false
                ? 'right'
                : ''
            }
            fullWidth={
              docNoChecker === false ||
              getDocumentNumberHeader[0]?.IsAutoDocNo === false
                ? true
                : false
            }
            margin="dense"
            ref={register}
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            defaultValue={editData ? editData?.RefNo : ''}
            disabled={mode == 'approve-reject'}
          />

          {!credAcctCompAssgtLoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = creditorAcc?.filter(
                  x => x?.CreditorAccountID === editData?.CreditorAccountID
                )[0]

                return (
                  <Autocomplete
                    options={
                      creditorAcc?.sort((a, b) => {
                        return a.CompanyName.localeCompare(b.CompanyName)
                      }) || []
                    }
                    getOptionLabel={option => `${option?.CompanyName}`}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      setValue('CreditorAccountID', newValue?.CreditorAccountID)
                      clearErrors('CreditorAccountID')
                      setTerm(newValue?.CreditTerm)
                    }}
                    renderOption={(props, option) => {
                      return <span>{props?.CompanyName}</span>
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CreditorAccountID?.message}
                            error={errors?.CreditorAccountID ? true : false}
                            label="Creditor Name"
                            style={{ width: '100%' }}
                            margin="dense"
                            required
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Creditor Name"
              name="CreditorAccountID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="dense"
              ref={register}
              helperText={errors?.CreditorAccountID?.message}
              error={errors?.CreditorAccountID ? true : false}
              defaultValue={editData?.CreditorAccountID}
              required
              disabled={mode === 'approve-reject'}
            />
          )}

          <Controller
            as={TextField}
            id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            defaultValue={editData?.Description}
            ref={register}
            disabled={mode === 'approve-reject'}
          />

          <VoiceTextField
            name="Remark"
            mounted={true}
            label="Remark"
            value={audioInput}
            record={record}
            setRecord={setRecord}
            setValue={setValue}
            helperTextProps={{
              helperText: errors?.Remark?.message,
              error: errors?.Remark ? true : false,
            }}
            defaultValue={getValues('Remark')}
            customOnchange
            register={register}
            control={control}
            clearErrors={clearErrors}
            handleCustomOnChange={e => {
              setText(e.target.value)
              handleSingleRemark(e.target.value)
            }}
          />
          <div style={{ width: '100%', marginTop: '24px' }}>
            <FileUploadInput
              placeholder={previewFiles.length === 0 ? 'Attachment' : null}
              label={previewFiles.length > 0 ? 'Attachment' : null}
              name="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              accept={
                '.png, .jpg, .jpeg, .gif, .mp4, .avi, .mkv, .mov, .flv, .3gp, application/msword, application/pdf, application/vnd.ms-excel, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              }
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                      mediaType={
                        files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                      }
                    />
                  ))}
                </>
              }
            />
          </div>
        </CardContents>

        <ItemDetail
          titleLabel={'Debit Note'}
          itemData={debitNoteItemData}
          setOpenDialog={setOpenItemDialog}
          fetchExpenseItem={fetchExpenseItem}
          fetchCostCentre={fetchCostCentre}
          resetMenu={resetMenu}
          setSelectedTax={setSelectedTax}
          setDetailMode={setDetailMode}
          formMode={formMode}
          detailMode={detailMode}
          getItemByCompany={getItemByCompany}
          getCostCentre={getCostCentre}
          handleClick={handleClick}
          keyItemID={'DebitNoteItemID'}
        />
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setEdit(true)
            setOpenItemDialog(true)
            setDetailMode('edit')
            fetchExpenseItem()
            fetchCostCentre()
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteDebitNoteItem(menu?.index)
          }}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>

      {!ExpenseItemLoading && (
        <ItemDialog
          companyID={CompanyID}
          title={'Debit Note Detail'}
          fromAP={true}
          openItemDialog={openItemDialog}
          setOpenItemDialog={setOpenItemDialog}
          watch={watch}
          menu={menu}
          ExpenseItemLoading={ExpenseItemLoading}
          fetchExpenseItem={fetchExpenseItem}
          getItemByCompany={getItemByCompany}
          //CostCentreLoading={CostCentreLoading}
          //fetchCostCentre={fetchCostCentre}
          //getCostCentre={getCostCentre}
          itemData={debitNoteItemData}
          selectedTax={selectedTax}
          setSelectedTax={setSelectedTax}
          detailMode={detailMode}
          resetMenu={resetMenu}
          primaryItemKey="DebitNoteItemID"
          defaultCostCentre={defaultCostCentre}
          getMasterCOA={getMasterCOA}
          masterCOALoading={masterCOALoading}
        />
      )}

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/account-payable/${CompanyID}/debit-note`)
          localStorage.removeItem('debitNote')
          localStorage.removeItem('debitNoteAllocation')
          localStorage.removeItem('attachment')
        }}
      />
      {footerOptions?.length > 0 ? (
        <Footer options={[...footerOptions]} />
      ) : null}

      <TooltipAmountFooter
        data={debitNoteItemData}
        module={'debit-note-draft'}
      />
    </>
  )
}
