import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { default as useUploadDocument } from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import { useMediaQuery } from '@material-ui/core'
import OrangeDollarIcon from 'assets/icons/Money/orange-dollar.svg'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import { DetailAllocationTableContent } from 'components/Table/DetailAllocationTableContent'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  ApprovalStatus,
  GetArRefundbyStatusDocument,
  useDocumentListingQuery,
  useGetArRefundQuery,
  useGetCompanyNameQuery,
  useGetUsersByAccountAndSoftwareQuery,
  usePostArRefundMutation,
  UserSelection,
} from 'generated/graphql'
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { ARRefundDetailContent } from './ARRefundDetailContent'

export const ARRefundDetail = props => {
  useEffect(() => {
    console.log('ARRefundDetail')
  }, [])
  let history = useHistory()
  const { CompanyID, RefundID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { menu, handleClick: handleClick2 }: any = useMenuOption()
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  let location = useLocation()
  const editData = location?.state as any
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { handleStatusColor } = useColorStatusList()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    clearErrors,
  } = useForm<any>()

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: ARRefundLoading,
    error: ARRefundError,
    data: { getARRefund } = {
      getARRefund: [],
    },
  } = useGetArRefundQuery({
    fetchPolicy: 'network-only',
    variables: {
      RefundID: RefundID,
    },
  })

  const {
    loading: getUsersByAccountAndSoftwareLoading,
    error: getUsersByAccountAndSoftwareError,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
    variables: {
      superUserBool: UserSelection.All,
    },
  })

  const {
    files,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
  } = useUploadDocument()

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: RefundID,
    },
    onCompleted: data => {
      handleEditUpload(data?.DocumentListing)
      previewFiles.push(...data?.DocumentListing?.map(x => x?.fileURL))
    },
  })

  const statusText = jStatus => {
    switch (jStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Submitted'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }

  const [
    postARRefund,
    {
      loading: postRefundLoading,
      called: postRefundCalled,
      error: postRefundError,
    },
  ] = usePostArRefundMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-receivable/${CompanyID}/refund`,
          state: { success: true, msgMode: 'create' },
        })
      }, 500)
    },
  })

  const onSubmit = (data, status) => {
    postARRefund({
      variables: {
        RefundIDs: RefundID,
        CompanyID,
        RefTable: 'AR_Refund',
      },
      refetchQueries: [
        {
          query: GetArRefundbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Completed, ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const allocationTotal = getARRefund[0]?.Allocation?.reduce(
    (prevValue, currentValue) => prevValue + currentValue?.AllocationAmt,
    0
  )
  return (
    <>
      {postRefundLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {ARRefundLoading && <Loading />}

      <MainHeader
        onClick={() => history.goBack()}
        mainBtn="back"
        smTitle={'Account Receivable'}
        title={`${getCompany[0]?.Name}`}
        routeSegments={[
          { name: 'Account Receivable' },
          { name: 'AR Submenu' },
          { name: 'Refund Posting', current: true },
        ]}
        currency={user?.companyCurrencyCode}
      />

      <DynamicSubHeader
        title={
          <>
            <span className="xsTitle" style={{ color: '#ff9800' }}>
              {getARRefund[0]?.DocNo}
            </span>
          </>
        }
        rightText={
          <span className="c-orange">
            {formatDate(getARRefund[0]?.DocDate)}
          </span>
        }
        infoLine={
          <>
            <div
              className="text-overflow"
              style={{ fontSize: '11px', fontWeight: 'bold', width: '270px' }}
            >
              {getARRefund[0]?.Description}
            </div>
          </>
        }
        rightInfoLine={
          <span className="desc flex-space">
            <img
              src={OrangeDollarIcon}
              style={{
                width: '12px',
                marginBottom: '-2px',
                marginRight: '3px',
              }}
            />
            <span className="desc flex-space c-orange">
              {amtStr(getARRefund[0]?.DocAmt)}
            </span>
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <CardContents>
          <ARRefundDetailContent
            listEl={getARRefund[0]}
            documentListing={DocumentListing}
            listStatus={'COMPLETED'}
            userList={getUsersByAccountAndSoftware}
            mode={'detail'}
          />
        </CardContents>

        {getARRefund[0]?.Allocation?.length > 0 && (
          <>
            <CardContents
              section={{
                header: {
                  title: 'Allocation(s)',
                  rightTitle: amtStr(allocationTotal),
                  customFontSizeClass: 'xsTitle',
                  dollarIcon: (
                    <img
                      src={OrangeDollarIcon}
                      style={{
                        width: '15px',
                        height: '15px',
                        marginBottom: '-2px',
                        marginRight: '3px',
                        marginLeft: '3px',
                      }}
                    />
                  ),
                },
              }}
            >
              <DetailAllocationTableContent
                listItem={getARRefund[0]?.Allocation}
              />
            </CardContents>
          </>
        )}
      </ContentWrapper>
      <ApprovalLogDialog
        data={getARRefund[0]}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Refund'}
      />

      {getARRefund[0]?.ApprovalStatus === 'SUBMIT' ? (
        <Footer
          options={[
            {
              name: 'Post',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      ) : null}
    </>
  )
}
