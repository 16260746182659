import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'
import RepeatIcon from '@material-ui/icons/Repeat'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router'
import { RecordStatus, useGetUomQuery } from 'generated/graphql'
import '../UOMExchange/UOM.scss'
import { CopyrightFooter } from 'components/Footer/Copyright'

export interface ConversionForm {
  fromUomID: string
  toUomID: string
  fromAmt: number
  toAmt: number
}

export const ConversionRateListing = props => {
  const { anchorEl, menu, handleClick, handleClose } = props
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [hardDeleteForm, setHardDeleteForm] = useState(false)

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  //   const {
  //     loading,
  //     error,
  //     data: { getUomExchangeData } = { getUomExchangeData: [] },
  //   } = useGetUomExchangeDataQuery({
  //     fetchPolicy: 'network-only',
  //   });

  const {
    loading: uomLoading,
    error: uomError,
    data: { getUOM } = { getUOM: [] },
  } = useGetUomQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'name',
      RecordStatus: RecordStatus.Active,
    },
  })

  //   const [
  //     createUOMExchange,
  //     { loading: mutationLoading, error: mutationError },
  //   ] = useCreateUomExchangeMutation({
  //     onError: error => {
  //       setErrMsg(error.message.substr(15, error.message.length - 1));
  //       setErrorDia(true);
  //     },
  //     onCompleted: () => {
  //       setOpenSnackBar(true);
  //       setSnackBarMsg(SystemMsgs.createNewRecord());
  //     },
  //     refetchQueries: [
  //       {
  //         query: GetUomDocument,
  //         variables: {
  //           orderByAsc: 'name',
  //         },
  //       },
  //       {
  //         query: GetUomExchangeDataDocument,
  //         variables: {
  //           orderByAsc: 'name',
  //         },
  //       },
  //     ],
  //   });

  //   const [
  //     updateUOMExchange,
  //     { loading: updateMutationLoading, error: updateMutationError },
  //   ] = useUpdateUomExchangeMutation({
  //     onError: error => {
  //     },
  //     onCompleted: () => {
  //       setOpenSnackBar(true);
  //       setSnackBarMsg(SystemMsgs.updateRecord());
  //       //history.go(0);
  //     },
  //     refetchQueries: [
  //       {
  //         query: GetUomDocument,
  //         variables: {
  //           orderByAsc: 'name',
  //         },
  //       },
  //       {
  //         query: GetUomExchangeDataDocument,
  //         variables: {
  //           orderByAsc: 'name',
  //         },
  //       },
  //     ],
  //   });

  //   let filteredUOMExchange = getUomExchangeData.filter(
  //     selected => selected?.uomDetails?.ID == menu?.obj?.uomDetails?.ID,
  //   );

  //   ////// DELETE //////
  //   const [
  //     deleteUOMExchange,
  //     {
  //       loading: mutationDeleteUOMExchangeLoading,
  //       error: deleteUOMExchangeError,
  //     },
  //   ] = useDeleteUomExchangeMutation({
  //     onError: error => {
  //       console.log('ERROR', error);
  //     },
  //     onCompleted: data => {
  //       setHardDeleteForm(false);
  //       setOpenSnackBar(true);
  //       setSnackBarMsg(SystemMsgs.deleteRecord());
  //     },
  //     refetchQueries: [
  //       {
  //         query: GetUomDocument,
  //         variables: {
  //           orderByAsc: 'name',
  //         },
  //       },
  //       {
  //         query: GetUomExchangeDataDocument,
  //         variables: {
  //           orderByAsc: 'name',
  //         },
  //       },
  //     ],
  //   });

  //   const onSubmit = data => {
  //     data.fromAmt = parseFloat(data.fromAmt);
  //     data.toAmt = parseFloat(data.toAmt);
  //     if (isEdit) {

  //       //Comment out for testing
  //       updateUOMExchange({
  //         variables: {
  //           input: {
  //             ID: menu?.obj?.ID,
  //             fromUomID: data.fromUomID,
  //             toUomID: data.toUomID,
  //             fromAmt: data.fromAmt,
  //             toAmt: data.toAmt,
  //             exchangeRate: parseFloat(data.toAmt) / parseFloat(data.fromAmt),
  //           },
  //         },
  //       });
  //     } else {
  //       console.log(data, 'Create Mutation');
  //       //Comment out for testing
  //       createUOMExchange({
  //         variables: {
  //           input: {
  //             fromUomID: data.fromUomID,
  //             toUomID: data.toUomID,
  //             fromAmt: parseFloat(data.fromAmt),
  //             toAmt: parseFloat(data.toAmt),
  //             exchangeRate: parseFloat(data.toAmt) / parseFloat(data.fromAmt),
  //           },
  //         },
  //       });
  //     }
  //   };

  const { handleSubmit, register, errors, control, reset, setValue } = useForm<
    ConversionForm
  >({
    mode: 'all',
    // defaultValues: {
    //   fromAmt: menu?.obj?.uomDetails[0]?.fromAmt,
    //   toAmt: menu?.obj?.uomDetails[0]?.toAmt,
    //   //   fromUomID: isEdit ? filteredUOMExchange[0]?.fromUomID : '',
    //   //   toUomID: isEdit ? filteredUOMExchange[0]?.toUomID : '',
    // },
  })

  ////// Add and Edit UOM Conversion //////
  const fromAmount = props => {
    return (
      <Grid item xs={4}>
        <Controller
          name="fromAmt"
          className="conversion-input"
          variant="outlined"
          control={control}
          ref={register}
          // defaultValue={isEdit ? filteredUOMExchange[0]?.fromAmt : 1}
          render={({ onChange, onBlur, value }) => (
            <NumberFormat
              id="standard-select-customer"
              className="conversion-input"
              variant="outlined"
              thousandSeparator
              customInput={TextField}
              fullWidth
              allowNegative={false}
              fixedDecimalScale
              decimalScale={4}
              defaultValue={
                isEdit ? parseFloat(menu?.obj?.uomDetails[0]?.fromAmt) : 0
              }
              // onChange={e => {
              //   setToAmt(e.target.value);
              // }}
              isNumericString
              onValueChange={values => {
                let { floatValue } = values
                onChange(
                  (value = values.floatValue),
                  register(`fromAmt`),
                  setValue(`fromAmt`, floatValue)
                )
              }}
            />
          )}
        />
      </Grid>
    )
  }

  const toAmount = props => {
    return (
      <Grid item xs={4}>
        <Controller
          className="conversion-input"
          variant="outlined"
          name="toAmt"
          control={control}
          ref={register}
          // defaultValue={isEdit ? filteredUOMExchange[0]?.toAmt : 1}
          render={({ onChange, onBlur, value }) => (
            <NumberFormat
              id="standard-select-customer"
              className="conversion-input"
              variant="outlined"
              thousandSeparator
              customInput={TextField}
              fullWidth
              fixedDecimalScale
              decimalScale={4}
              allowNegative={false}
              defaultValue={
                isEdit ? parseFloat(menu?.obj?.uomDetails[0]?.toAmt) : 0
              }
              // onChange={e => {
              //   setToAmt(e.target.value);
              // }}
              isNumericString
              onValueChange={values => {
                let { floatValue } = values
                onChange(
                  (value = values.floatValue),
                  register(`toAmt`),
                  setValue(`toAmt`, floatValue)
                )
              }}
            />
          )}
        />
      </Grid>
    )
  }

  const fromUom = (data, props) => {
    return (
      <Grid item xs={7}>
        <Controller
          as={
            <TextField
              select

              // onChange={e => {
              //   setToUom(e.target.value);
              // }}
            >
              {data.map((el, index) => (
                <MenuItem key={index} value={el?.ID}>
                  {el?.name}
                </MenuItem>
              ))}
            </TextField>
          }
          name="fromUomID"
          defaultValue={isEdit ? menu?.obj?.fromUomID : ''}
          disabled={isEdit}
          fullWidth
          variant="outlined"
          className="conversion-dropdown-input"
          ref={register}
          select
          autoComplete="off"
          control={control}
        />
      </Grid>
    )
  }

  const toUom = (data, props) => {
    return (
      <Grid item xs={7}>
        <Controller
          as={
            <TextField
              select
              // onChange={e => {
              //   setfromUom(e.target.value);
              // }}
            >
              {data.map((el, index) => (
                <MenuItem key={index} value={el?.ID}>
                  {el?.name}
                </MenuItem>
              ))}
            </TextField>
          }
          name="toUomID"
          defaultValue={isEdit ? menu?.obj?.toUomID : ''}
          disabled={isEdit}
          fullWidth
          variant="outlined"
          className="conversion-dropdown-input"
          ref={register}
          select
          autoComplete="off"
          control={control}
        />
      </Grid>
    )
  }

  return (
    <>
      {/* {loading && <Loading />}
      {uomLoading && <Loading />}
      {mutationLoading && <Loading />}
      {updateMutationLoading && <Loading />}
      {mutationDeleteUOMExchangeLoading && <Loading />} */}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/account-payable/general-setting`)}
        smTitle="Account Payable"
        title={user?.companyName}
        routeSegments={[
          { name: 'System Admin' },
          { name: 'UOM Conversion Setup', current: true },
        ]}
      />
      <ContentWrapper>
        <List className="core-list">
          {/* {getUomExchangeData?.length === 0 ? ( */}
          <EmptyList title="No Record found" subtitle="Add a new record now." />
          {/* ) : (
            getUomExchangeData?.map((el, index) => (
              <>
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <>
                        <span className="mdLabel">
                          {el?.uomName[0]?.name}
                          <RepeatIcon className="repeat-icon" />
                          {el?.uomName[1]?.name}
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc flex-space">
                          {`${el?.uomDetails[0]?.fromAmt} ${el?.uomName[0]?.code} = ${el.uomDetails[0]?.toAmt} ${el.uomName[1]?.code}`}
                        </span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      aria-controls="menu-list"
                      aria-haspopup="true"
                      onClick={e => handleClick(e, el?.ID, index, el)}
                    >
                      <MoreVert />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            ))
          )} */}
        </List>
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpen(true)
            setIsEdit(true)
          }}
        >
          <span className="mdDesc">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setHardDeleteForm(true)
          }}
        >
          <span className="mdDesc">Delete</span>
        </MenuItem>
      </Menu>

      <CommonDialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
        sections={{
          header: {
            title: isEdit ? 'Edit UOM Rate' : 'New UOM Rate',
          },
          body: () => (
            <>
              {/* <form onSubmit={handleSubmit(onSubmit)} id="conversion-form">
                <br></br>
                <Grid container justify="flex-start">
                  {fromAmount('')}
                  <Grid item xs={1}></Grid>
                  {fromUom(getUOM, '')}
                </Grid>

                <div className="conversion-icon">
                  <ImportExportIcon />
                </div>

                <Grid container justify="flex-start">
                  {toAmount('')}
                  <Grid item xs={1}></Grid>
                  {toUom(getUOM, '')}
                </Grid>
              </form> */}
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setOpen(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    // handleSubmit(onSubmit);
                    setOpen(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                  type: 'submit',
                  form: 'conversion-form',
                },
              },
            ],
          },
        }}
      />

      {/* Delete UOM Exchange */}
      <Box mt={1}>
        <CommonDialog
          fullWidth={true}
          open={hardDeleteForm}
          onClose={() => setHardDeleteForm(false)}
          sections={{
            header: {
              title: 'Delete UOM Conversion',
            },

            body: () => (
              <>
                <Grid container justify="flex-start">
                  <Grid item xs={5}>
                    <div className="content-wrap ">
                      <div className="desc">From UOM</div>
                      <div className="xsTitle">
                        {menu?.obj?.uomDetails[0]?.fromAmt}{' '}
                        {menu?.obj?.uomName[0]?.code}
                      </div>
                      <div className="xxTitle">
                        {'('}
                        {menu?.obj?.uomName[0]?.name}
                        {')'}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    <RepeatIcon className="repeat-icon" />
                  </Grid>

                  <Grid item xs={5}>
                    <div className="content-wrap">
                      <div className="desc">To UOM</div>
                      <div className="xsTitle">
                        {menu?.obj?.uomDetails[0]?.toAmt}{' '}
                        {menu?.obj?.uomName[1]?.code}
                      </div>
                      <div className="xxTitle">
                        {'('}
                        {menu?.obj?.uomName[1]?.name}
                        {')'}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
              // <Grid container justify="flex-start">
              //   <Grid item xs={5}>
              //     <div className="content-wrap left">
              //       <div className="desc">From Amount</div>
              //       <div className="xsTitle">
              //         {' '}
              //         {amtNumStr(menu?.obj?.fromAmt)}{' '}
              //       </div>
              //     </div>
              //   </Grid>
              //   <Grid item xs={1}></Grid>
              //   <Grid item xs={8}>
              //     <div className="content-wrap right">
              //       <div className="desc">From UOM</div>
              //       <div className="xsTitle"> {menu?.obj?.fromUOM?.name} </div>
              //     </div>
              //   </Grid>
              //   {/* {toUom(getUOM, '')} */}

              //   <div className="conversion-icon">
              //     <ImportExportIcon />
              //   </div>

              //   <Grid item xs={5}>
              //     <div className="content-wrap full">
              //       <div className="desc">To Amount</div>
              //       <div className="xsTitle">
              //         {' '}
              //         {amtNumStr(menu?.obj?.toAmt)}{' '}
              //       </div>
              //     </div>
              //   </Grid>
              //   <Grid item xs={1}></Grid>
              //   <Grid item xs={8}>
              //     <div className="content-wrap full">
              //       <div className="desc">To UOM</div>
              //       <div className="xsTitle"> {menu?.obj?.toUOM?.name} </div>
              //     </div>
              //   </Grid>
              // </Grid>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => setHardDeleteForm(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Confirm',
                  props: {
                    onClick: () => {
                      //   deleteUOMExchange({
                      //     variables: {
                      //       ID: menu?.obj?.uomDetails[0]?.ID,
                      //     },
                      //   });
                    },
                    variant: 'contained',
                    color: 'primary',
                    form: 'hardDeleteUOM-form',
                    type: 'submit',
                  },
                },
              ],
            },
          }}
        />
      </Box>

      {/* Dialog for dupplicate error */}
      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="xsTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        Error! Duplicate Records
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{`${errMsg}. Please enter a different UOM Conversion Rate.`}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <FloatButton
        data-qa="addButton"
        onClick={() => {
          setOpen(true)
          setIsEdit(false)
        }}
      />
    </>
  )
}
