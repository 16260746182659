import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import React from 'react'

export const TableAPPayment = (props: any) => {
  // const { docDate, docNo, description, dueDate, reminderAmt } = props;
  const { item } = props
  // console.log(docType, 'dataa') not deleting this cause want to check the data 11/8/2023

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 10
  }
  ///// style border table
  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }

  const thright = {
    width: '15%',
    textAlign: 'right' as 'right',
    backgroundColor: '#e7e6e6',
    borderBottom: '0.5px solid',
  }

  const thcenter = {
    width: '15%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
    backgroundColor: '#e7e6e6',
  }

  const tdright = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
  }

  const tdleftstretch = {
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
  }

  const tdrightamt = {
    width: '15%',
    textAlign: 'right' as 'right',
  }

  const amount = item?.reduce(
    (total, amountValue) => (total = total + amountValue.Amount),
    0
  )

  const taxAmount = item?.reduce(
    (total, amountValue) => (total = total + amountValue.TaxAmt),
    0
  )

  const totalAmount = item?.reduce(
    (total, amountValue) => (total = total + amountValue?.DocAmt),
    0
  )

  return (
    <>
      <div style={{ marginTop: '20px' }}>
        <table
          style={{
            width: '100%',
            fontSize,
            // borderCollapse: 'collapse',
            borderSpacing: '0 1em',
          }}
        >
          <tr>
            <th style={thleft}>Remark</th>
            <th style={thcenter}>
              Tax
              <br />
              Rate
            </th>
            <th style={thright}>
              Base Amt
              <br />
              (RM)
            </th>

            <th style={thright}>
              Tax Amt
              <span>(RM)</span>
            </th>

            <th style={thright}>
              Total Amt
              <span>(RM)</span>
            </th>
          </tr>
          {item?.map((v, i) => (
            <>
              <tr>
                <td style={{ textAlign: 'left' }}>{v?.Remark ?? 'NA'}</td>
                <td style={{ textAlign: 'center' }}>{`${v?.TaxRate}%`}</td>
                <td style={tdrightamt}>{amtStr(v?.Amount)}</td>
                <td style={tdrightamt}>{amtStr(v?.TaxAmt)}</td>
                <td style={tdrightamt}>{amtStr(v?.DocAmt)}</td>
              </tr>
            </>
          ))}
        </table>
      </div>

      <div style={{ marginTop: '50px' }}>
        <table style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}>
          <tr>
            <td style={tdleftstretch}>{`Total Amount (RM):`}</td>

            <td style={tdright}>{amtStr(amount)}</td>
            <td style={tdright}>{amtStr(taxAmount)}</td>
            <td style={tdright}>{amtStr(totalAmount)}</td>
          </tr>
        </table>
      </div>
    </>
  )
}

export default TableAPPayment
