import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { CopyrightFooter } from 'components/Footer/Copyright'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetAccountPeriodQuery,
  useGetWarehouseNameQuery,
  useGetCostCentreQuery,
  useGetJournalQuery,
  useGetMasterCoaQuery,
  useGetStockItemFromWarehouseLazyQuery,
  useGetWarehouseQuery,
  useGetStockItemFromWarehouseQuery,
} from 'generated/graphql'
import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Autocomplete } from '@material-ui/lab'

interface StockBalanceByLocationParamsFormProps {
  DocDate: Date
  WarehouseID: string
  StockItemID: string
}

export const StockBalanceByLocationParamsForm = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { WarehouseID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({
    DocDate: CommonYupValidation.requireField('Document Date is required'),
  })

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<StockBalanceByLocationParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: WarehouseLoading,
    error: WarehouseError,
    data: { getWarehouse } = { getWarehouse: [] },
  } = useGetWarehouseNameQuery({
    variables: { WarehouseID },
    fetchPolicy: 'network-only',
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/inventory-control/${WarehouseID}/digital-report/stock-balance-by-location-report/generated`,
      state: {
        DocDate: new Date(data.DocDate),
        WarehouseID: WarehouseID,
        StockItemID: data?.StockItemID,
      },
    })
  }

  const {
    loading: StockItemFromWarehouseLoading,
    error: StockItemFromWarehouseError,
    data: { getStockItemFromWarehouse } = { getStockItemFromWarehouse: [] },
  } = useGetStockItemFromWarehouseQuery({
    fetchPolicy: 'network-only',
    variables: {
      WarehouseID: WarehouseID,
    },
  })

  return (
    <>
      {WarehouseLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/inventory-control/${WarehouseID}/digital-report`)
        }
        smTitle={'Inventory Control'}
        title={getWarehouse[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Stock Balance', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              required
              as={KeyboardDatePicker}
              name="DocDate"
              label="Document Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('DocDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              defaultValue={editData?.DocDate ?? new Date()}
              helperText={errors?.DocDate?.message}
              error={errors?.DocDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={getStockItemFromWarehouse || []}
                  getOptionLabel={(option: any) => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('StockItemID', newValue?.StockItemID)
                  }}
                  renderOption={(props: any, option) => {
                    return <span>{props?.Name}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={errors?.StockItemID?.message}
                          error={errors?.StockItemID ? true : false}
                          label="Stock Item"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Stock Item"
            name="StockItemID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            helperText={errors?.StockItemID?.message}
            error={errors?.StockItemID ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
