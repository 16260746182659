import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  useCreateApCreditNoteMutation,
  useCreateApCreditorCreditNoteMutation,
  useCreateApDebitNoteMutation,
  useUpdateApCreditNoteMutation,
  useUpdateApCreditorCreditNoteMutation,
  useUpdateApDebitNoteMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router'

interface APCNForm {
  docType: string
  CompanyID: string
  mode?: string
}
export const useAPCreateUpdateMutation = ({
  docType,
  mode,
  CompanyID,
}: APCNForm) => {
  const { ID }: any = useParams()
  let history = useHistory()

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const path = `/account-payable/${CompanyID}/${docType}`
  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const [
    createAPCreditorCreditNote,
    {
      loading: createAPCreditorCreditNoteLoading,
      called: createAPCreditorCreditNoteCalled,
      error: createAPCreditorCreditNoteError,
    },
  ] = useCreateApCreditorCreditNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })
  const [
    updateAPCreditorCreditNote,
    {
      loading: updateAPCreditorCreditNoteLoading,
      called: updateAPCreditorCreditNoteCalled,
      error: updateAPCreditorCreditNoteError,
    },
  ] = useUpdateApCreditorCreditNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })

  const [
    createAPCreditNote,
    {
      loading: createAPCreditNoteLoading,
      called: createAPCreditNoteCalled,
      error: createAPCreditNoteError,
    },
  ] = useCreateApCreditNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })
  const [
    updateAPCreditNote,
    {
      loading: updateAPCreditNoteLoading,
      called: updateAPCreditNoteCalled,
      error: updateAPCreditNoteError,
    },
  ] = useUpdateApCreditNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: path,
        })
      }, 500)
    },
  })

  /* -------------------------------------------- */
  /*                  MUTATION                    */
  /* -------------------------------------------- */

  const [
    createAPDebitNote,
    {
      loading: createAPDebitNoteLoading,
      called: createAPDebitNoteCalled,
      error: createAPDebitNoteError,
    },
  ] = useCreateApDebitNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({ pathname: `/account-payable/${CompanyID}/debit-note` })
      }, 500)
    },
  })

  const [
    updateAPDebitNote,
    {
      loading: updateAPDebitNoteLoading,
      called: updateAPDebitNoteCalled,
      error: updateAPDebitNoteError,
    },
  ] = useUpdateApDebitNoteMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-payable/${CompanyID}/debit-note`,
        })
      }, 500)
    },
  })

  const handleMutation =
    mode === 'add' && docType === 'creditor-credit-note'
      ? createAPCreditorCreditNote
      : mode === 'add' && docType === 'credit-note'
      ? createAPCreditNote
      : mode === 'edit' && docType === 'creditor-credit-note'
      ? updateAPCreditorCreditNote
      : mode === 'edit' && docType === 'credit-note'
      ? updateAPCreditNote
      : mode === 'add' && docType === 'debit-note'
      ? createAPDebitNote
      : updateAPDebitNote

  const mutationLoading =
    mode === 'add' && docType === 'creditor-credit-note'
      ? createAPCreditNoteLoading
      : mode === 'add' && docType === 'creditor-credit-note'
      ? createAPCreditNoteLoading
      : mode === 'edit' && docType === 'creditor-credit-note'
      ? updateAPCreditorCreditNoteLoading
      : mode === 'edit' && docType === 'credit-note'
      ? updateAPCreditNoteLoading
      : mode === 'add' && docType === 'debit-note'
      ? createAPDebitNoteLoading
      : updateAPDebitNoteLoading

  const mutationCalled =
    mode === 'add' && docType === 'creditor-credit-note'
      ? createAPCreditNoteCalled
      : mode === 'add' && docType === 'creditor-credit-note'
      ? createAPCreditNoteCalled
      : mode === 'edit' && docType === 'creditor-credit-note'
      ? updateAPCreditorCreditNoteCalled
      : updateAPCreditNoteCalled

  return {
    handleMutation,
    mutationLoading,
    mutationCalled,
  }
}
