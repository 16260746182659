import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import {
  KeyboardArrowRight,
  MoreVert,
  SubdirectoryArrowRightOutlined,
} from '@material-ui/icons'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { TreeView } from '@material-ui/lab'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import { CustomTreeItem } from 'containers/GeneralSettingModule/MasterCOA/RoleTreeStyle'
import { useGetStockCategoryQuery } from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseTreeSearch } from 'helpers/Hooks/useTreeSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import { filter } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { uuid } from 'uuidv4'
import * as yup from 'yup'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'

export const StockItemTreeListing = (props: any) => {
  let history = useHistory()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const getSearch = localStorage?.getItem('searchFilter')
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [pageMode, setPageMode] = useState('')
  const [expandArray, setExpandArray] = useState([])
  const { filteredList, handleSearch, setOriginalListing } = useFuseTreeSearch()

  const StockCategoryFormSchema = yup.object().shape({
    Name: CommonYupValidation.requireField(SystemMsgs.name()),
    StockAccountCode: CommonYupValidation.requireField(
      SystemMsgs.stockAccountCode()
    ),
    ExpenseAccountCode: CommonYupValidation.requireField(
      SystemMsgs.expenseAccountCode()
    ),
  })

  const {
    loading: getStockCategoryLoading,
    data: { getStockCategory } = { getStockCategory: [] },
    // refetch: getCostCentreRefetch,
    // error: getCostCentreError,
  } = useGetStockCategoryQuery({
    variables: {
      orderByAsc: 'Name',
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getStockCategory }) => {
      if (getStockCategory?.length > 0) {
        let initialArr = []
        let level = getStockCategory.map(v => v.Level).sort((a, b) => b - a)[0]
        initializeLevel(1, getStockCategory, initialArr, level, level)
        setOriginalListing(initialArr)
        setExpandArray(getStockCategory)
      }
    },
  })

  const initializeLevel = (level, data, arr, maxLevel, prtID) => {
    const dataToPush = data
      ?.filter(v => {
        if (v.ParentStockCategoryID !== null) {
          return v.Level === level && v.ParentStockCategoryID === prtID
        } else {
          return v.Level === level
        }
      })
      .map(x => {
        return {
          Name: x?.Name,
          StockCategoryID: x?.StockCategoryID,
          ParentStockCategoryID: x?.ParentStockCategoryID,
          Level: x?.Level,
          ISLastNode: x?.ISLastNode,
          ExpenseAccountCode: x?.ExpenseAccountCode,
          ExpenseAccountCodeID: x?.ExpenseAccountCodeID,
          StockItem: x?.StockItem,
          StockAccountCode: x?.StockAccountCode,
          StockAccountCodeID: x?.StockAccountCodeID,
          child: [],
        }
      })

    arr.push(...dataToPush)

    if (level < maxLevel) {
      arr.map(k => {
        initializeLevel(level + 1, data, k.child, maxLevel, k.StockCategoryID)
      })
    }
  }

  const renderTree = (nodes, index) => (
    <CustomTreeItem
      key={nodes?.StockCategoryID}
      nodeId={nodes?.StockCategoryID}
      label={
        <>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: nodes?.Level === 0 ? null : '-15px',
            }}
            onClick={() =>
              history.push({
                pathname: `/inventory-control/general-setting/stock-item/${nodes?.StockCategoryID}`,
              })
            }
          >
            <Typography
              variant="body2"
              style={{
                fontWeight: 'inherit',
                flexGrow: 1,
                paddingTop: '5px',
                marginTop: '2px',
                paddingLeft: '20px',
                marginBottom: 'auto',
                paddingBottom:
                  nodes?.Level === 0 && filteredList[0]?.child?.length === 0
                    ? '5px'
                    : '7px',
              }}
            >
              <span className="xsTitle flex-space">{nodes.Name}</span>

              <div style={{ borderBottom: '1px solid #FF9800' }}>
                <span className="mdDesc">
                  {nodes?.StockItem?.length} Stock(s)
                </span>
              </div>
            </Typography>

            <IconButton>
              <KeyboardArrowRight
                onClick={() =>
                  history.push({
                    pathname: `/inventory-control/general-setting/stock-item/${nodes?.StockCategoryID}`,
                  })
                }
              />
            </IconButton>
          </Box>
        </>
      }
    >
      {Array.isArray(nodes.child)
        ? nodes.child.map((nodes, index) => renderTree(nodes, index))
        : null}
    </CustomTreeItem>
  )

  return (
    <>
      {getStockCategoryLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/inventory-control/general-setting/`)
          localStorage.removeItem('searchFilter')
        }}
        smTitle={'Inventory Control'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Setting' },
          //{ name: 'Company List' },
          { name: 'Stock Master File', current: true },
        ]}
        rightRouteSegments={[{ name: 'Category', current: true }]}
      />

      <SearchHeader
        title={'Stock Category'}
        value={`${getStockCategory?.length}`}
        search
        fixed
        onChangeAction={e => {
          handleSearch(e?.target?.value, [
            'Name',
            'StockItem.Name',
            'StockItem.Description',
          ])
          localStorage.setItem('searchFilter', e.target.value)
        }}
        defaultValue={getSearch ? getSearch : ''}
        onCloseAction={() => handleSearch('', [])}
        isDefaultValue={!!getSearch}
      />

      {!getStockCategoryLoading && (
        <ContentWrapper float search>
          <div>
            <CardContents>
              <TreeView
                className="tree-view-custom"
                expanded={expandArray?.map(x => x?.StockCategoryID)}
                multiSelect
              >
                {filteredList.map((el, index) => {
                  return renderTree(el, index)
                })}
              </TreeView>
            </CardContents>
          </div>
        </ContentWrapper>
      )}
    </>
  )
}
