import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import { CopyrightFooter } from 'components/Footer/Copyright'
import AppContext from 'containers/App/Store/AppContext'
import {
  useGetAccountPeriodQuery,
  useGetCompanyNameQuery,
  useGetCostCentreQuery,
  useGetJournalQuery,
  useGetMasterCoaQuery,
} from 'generated/graphql'
import React, { useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import { CommonYupValidation } from 'helpers/Form/YupValidation'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Autocomplete } from '@material-ui/lab'

interface TrailBalanceByCostCentreParamsFormProps {
  Year: Number
  Period: Number
}

export const TrailBalanceByCostCentreParamsForm = (props: any) => {
  useEffect(() => {
    console.log('TrailBalanceByCostCentreParamsForm')
  }, [])
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID }: any = useParams()
  let location = useLocation()
  const editData = location?.state as any
  const ParamsSchema = yup.object().shape({
    Year: CommonYupValidation.requireField('Year is required'),
    Period: CommonYupValidation.requireField('Period is required'),
  })

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<TrailBalanceByCostCentreParamsFormProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    variables: { CompanyID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: accountPeriodLoading,
    error: accountPeriodError,
    data: { getAccountPeriod } = { getAccountPeriod: [] },
  } = useGetAccountPeriodQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, orderByAsc: 'FYear' },
  })

  const onSubmit = (data, status) => {
    history.push({
      pathname: `/general-ledger/${CompanyID}/digital-report/trial-balance-costcentre-report/generated`,
      state: {
        Year: Number(data.Year),
        Period: Number(data.Period),
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/digital-report`)
        }
        smTitle={'General Ledger'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Trial Balance By Department', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.map(el => el?.FYear)
                      .filter((element, index) => {
                        return (
                          getAccountPeriod
                            ?.map(el => el?.FYear)
                            .indexOf(element) === index
                        )
                      })
                      .sort((a, b) => b - a) || []
                  }
                  getOptionLabel={option => `${option}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Year', newValue)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          //helperText={errors?.Year?.message}
                          error={errors?.Year ? true : false}
                          label="Year"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Year"
            name="Year"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            // helperText={errors?.Year?.message}
            error={errors?.Year ? true : false}
          />
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={
                    getAccountPeriod
                      ?.filter(x => x?.FYear === Number(watch('Year')))
                      .sort((a, b) => b.FPeriod - a.FPeriod) || []
                  }
                  getOptionLabel={option => `${option?.FPeriod}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    setValue('Period', newValue?.FPeriod)
                  }}
                  renderOption={(props, option) => {
                    return <span>{props?.FPeriod}</span>
                  }}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          //helperText={errors?.Period?.message}
                          error={errors?.Period ? true : false}
                          label="Period"
                          style={{ width: '100%' }}
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Period"
            name="Period"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="normal"
            ref={register}
            // helperText={errors?.Period?.message}
            error={errors?.Period ? true : false}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
              //   props: { disabled: getReceipt?.length === 0 },
            },
          ]}
        />
      </ContentWrapper>

      {/* FOOTER */}
    </>
  )
}
