import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar';
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import Fuse from 'fuse.js';
import {
  AcctPermission,
  ApprovalStatus,
  useGetCompanyNameQuery,
  useGetGrtnHeaderLazyQuery,
  useGetRfqHeaderLazyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from '../../../generated/graphql';
import { useFuseSearch } from 'helpers/Hooks/useSearch';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { GeneralGRTNEntryList } from './GeneralGRTNEntryList';
import { GeneralGRTNPostingList } from './GeneralGRTNPostingList';

export const GeneralGRTNMain = (props: any) => {
  let history = useHistory();
  const { mode } = props;
  const { CompanyID }: any = useParams();

  const approvalStatusEnum = [
    { name: 'Draft', ID: 'ACTIVE' },
    { name: 'Submitted', ID: 'SUBMIT' },
    { name: 'Rejected', ID: 'REJECTED' },
    { name: 'Approved', ID: 'COMPLETED' },
  ];

  const getSearch = JSON.parse(localStorage.getItem('searchFilter'));
  const [postIDs, setPostIDs] = useState<Set<any>>(new Set([]));
  const [labelState, setLabelState] = useState('All Warehouses');
  const [listStatus, setListStatus] = useState<ApprovalStatus>(
    getSearch?.option ?? 'ACTIVE',
  );

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();

  useEffect(() => {
    if (!CompanyID) {
      setLabelState('All Companies');
    }
  }, [CompanyID]);

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['Description', 'RefNo', 'DocNo', 'DocDate'];
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      };
      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(getSearch);
      const val = result?.map(x => x.item);
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys);
      }
    }
  }, [getSearch, originalList]);

  const [loadGrtn, { loading }] = useGetGrtnHeaderLazyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, orderByDesc: 'DocDate' },
    onCompleted: ({ getGRTNHeader }) => {
      if (getGRTNHeader?.length > 0) {
        setOriginalListing(getGRTNHeader);
      } else {
        setOriginalListing([]);
      }
    },
  });

  useEffect(() => {
    loadGrtn({ variables: { ApprovalStatus: listStatus } });
  }, [listStatus]);

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  });

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {loading && <Loading />}
      {UserLoading && <Loading />}
      {companyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-purchase/${CompanyID}`);
          localStorage.removeItem(`searchFilter`);
        }}
        smTitle={smTitle.GENERAL_PURCHASES}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Good Return Note', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['DocNo', 'Description', 'RefNo']);
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: listStatus ?? 'ACTIVE',
                name: e?.target?.value,
              }),
            );
          }}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', []);
          }}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setListStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(listStatus ?? 'ACTIVE')}
          searchFilter={getSearch}
        />
      </div>

      {(listStatus === 'ACTIVE' ||
        listStatus === 'COMPLETED' ||
        listStatus === 'REJECTED') &&
        !loading && (
          <GeneralGRTNEntryList
            filteredList={filteredList}
            listLoading={loading}
            listStatus={listStatus}
            loadRfq={loadGrtn}
            userList={getUsersByAccountAndSoftware}
            createPermissionCheck={
              AcctPermission.GeneralPurchaseGoodReturnNoteCreate
            }
            updatePermissionCheck={
              AcctPermission.GeneralPurchaseGoodReturnNoteUpdate
            }
            deletePermissionCheck={
              AcctPermission.GeneralPurchaseGoodReturnNoteDelete
            }
            labelState={labelState}
          />
        )}

      {listStatus === 'SUBMIT' && !loading && (
        <GeneralGRTNPostingList
          list={filteredList}
          filteredList={filteredList}
          listLoading={loading}
          listStatus={listStatus}
          postIDs={postIDs}
          setPostIDs={setPostIDs}
          loadGRTN={loadGrtn}
          userList={getUsersByAccountAndSoftware}
          setOriginalListing={setOriginalListing}
          approveRejectPermissionCheck={
            AcctPermission.GeneralPurchaseGoodReturnNoteApproveReject
          }
        />
      )}
    </>
  );
};
