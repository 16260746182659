import icDigitalReportRoute from '../Inventory Control/ICDigitalReportModule/ICDigitalReportroutes'
import icGeneralListingRoutes from './ICGeneralListing/ICGeneralListingRoutes'
// import icGeneralFormRoutes from './ICGeneralRoutes/ICGeneralFormRoutes'
import { InventoryControlSubmenu } from './InventoryControlSubmenu'
import stockEnquiryRoute from './ICRoutes/StockEnquiryRoute'
import React from 'react'
import icGeneralFormRoutes from './ICRoutes/ICGeneralFormRoutes'
import icGeneralDetailRoutes from './ICRoutes/ICGeneralDetailRoutes'
import CycleCountRoute from './ICRoutes/CycleCountRoute'

const inventoryControlRoute = [
  {
    props: { exact: true, path: '/inventory-control/:WarehouseID' },
    component: <InventoryControlSubmenu accountType="inventory-control" />,
  },

  ...stockEnquiryRoute,
  ...icGeneralListingRoutes,
  ...icGeneralDetailRoutes,
  // ...stockReceiptRoutes,
  // ...stockIssueRoutes, // comment temporary for icGeneralForm test
  // ...stockTransferRoutes,
  // ...stockAdjustmentRoutes,
  ...icGeneralFormRoutes,
  ...CycleCountRoute,
  ...icDigitalReportRoute,
]

export default inventoryControlRoute
