import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader2RowInfo from '@ifca-root/react-component/src/components/Header/DynamicSubHeader2RowInfo'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  useGetAuditAdjustmentQuery,
  useGetBankReconciliationItemLazyQuery,
  useGetCompanyNameQuery,
  useGetLedgerLazyQuery,
  useGetPaymentQuery,
  useGetReceiptQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { useHistory, useLocation, useParams } from 'react-router'
import React, { useEffect, useState } from 'react'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'

export const BankReconciliationItemDetail = (props: any) => {
  let history = useHistory()
  const { formMode } = props
  let location = useLocation()
  const editData = location?.state as any
  const {
    CompanyID,
    BankAccountID,
    BankReconciliationID,
    BankReconciliationItemID,
    LedgerID,
    backpathmode,
  }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [detailData, setDetailData] = useState(null)

  //QUERY

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const [
    loadBankStatement,
    {
      loading: BankReconciliationItemLoading,
      error: BankReconciliationItemError,
      data: { getBankReconciliationItem } = { getBankReconciliationItem: [] },
    },
  ] = useGetBankReconciliationItemLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getBankReconciliationItem }) => {
      if (getBankReconciliationItem?.length > 0) {
        setDetailData(getBankReconciliationItem[0])
      }
    },
  })

  const [
    loadLedger,
    {
      loading: ledgerLoading,
      error: ledgerError,
      data: { getLedger } = { getLedger: [] },
    },
  ] = useGetLedgerLazyQuery({
    fetchPolicy: 'network-only',
    variables: { LedgerID: LedgerID },
    onCompleted: ({ getLedger }) => {
      if (getLedger?.length > 0) {
        setDetailData(getLedger[0])
      }
    },
  })

  useEffect(() => {
    if (formMode === 'bank-statement') {
      loadBankStatement({
        variables: {
          BankReconciliationItemID: BankReconciliationItemID,
        },
      })
    } else {
      loadLedger({
        variables: {
          LedgerID: LedgerID,
        },
      })
    }
  }, [formMode])

  return (
    <>
      {CompanyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(
            `/cash-book/${CompanyID}/submenu/${BankAccountID}/bank-reconciliation/${BankReconciliationID}/${backpathmode}`
          )
        }}
        smTitle="Cash Book"
        title={getCompany[0]?.Name}
        // currency={`MYR`}
        routeSegments={[
          { name: 'Bank Reconciliation Details' },
          { name: 'Bank Reconciliation Details' },
          { name: 'Bank Reconciliation Details' },
          { name: 'Bank Reconciliation Details' },
          { name: 'Bank Reconciliation Details', current: true },
        ]}
      />

      <DynamicSubHeader2RowInfo
        title={<span style={{ color: 'orange' }}>{detailData?.DocNo}</span>} // title text
      />
      <ContentWrapper singleDynamicInfo>
        <CardContents>
          {formMode === 'cash-book-ledger' && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Document Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(detailData?.DocDate)}
                </div>
              </div>
              <div className="content-wrap right ">
                <div
                  className="desc"
                  style={{
                    fontWeight: 300,
                    width: '50%',
                  }}
                >
                  Document No.
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {detailData?.DocNo}
                </div>
              </div>
              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Description
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {detailData?.Description ?? detailData?.Remark}
                </div>
              </div>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Transaction Type
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {detailData?.TrxType}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(detailData?.DocAmt)}
                </div>
              </div>
            </>
          )}
          {formMode === 'bank-statement' && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Document Date
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {formatDate(detailData?.DocumentDate)}
                </div>
              </div>
              <div className="content-wrap right ">
                <div
                  className="desc"
                  style={{
                    fontWeight: 300,
                    width: '50%',
                  }}
                >
                  Document No.
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {detailData?.DocNo}
                </div>
              </div>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Description
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {detailData?.Remarks}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(detailData?.DocAmt)}
                </div>
              </div>
            </>
          )}
        </CardContents>
      </ContentWrapper>
    </>
  )
}
