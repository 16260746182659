import theme from '@ifca-root/react-component/src/assets/theme'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import { List, ListItem, ListItemText, useMediaQuery } from '@material-ui/core'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  DocumentType,
  useCreateExternalLedgerMutation,
  useGetCheckingValidationExtLedgerQuery,
  useGetCompanyNameQuery,
  useGetDocNumQuery,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

export const ExternalLedgerExcelValidation = (props: any) => {
  const { mode } = props
  let location = useLocation()
  const passedData: any = location?.state
  const excelData = passedData.excelData
  const fileName = passedData.fileTitle
  let history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()
  const { accType }: any = useParams()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [validatedData, setValidatedData] = useState<any>({
    ErrorData: [],
    ValidData: [],
  })
  const [openDialog, setOpenDialog] = useState(false)
  const [errorNumberingStructure, setErrorNumberingStructure] = useState(false)

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: checkingValidationExtLedgerLoading,
    error: getCheckingValidationExtLedgerError,
    data: { getCheckingValidationExtLedger } = {
      getCheckingValidationExtLedger: [],
    },
  } = useGetCheckingValidationExtLedgerQuery({
    fetchPolicy: 'network-only',
    variables: {
      ExternalLedgerImportInput: passedData.excelData,
      CompanyID: CompanyID,
    },
    onCompleted: ({ getCheckingValidationExtLedger }) => {
      let ErrorData: any = []
      let ValidData: any = []

      getCheckingValidationExtLedger?.map(x => {
        if (x['ErrorList']?.length > 0)
          ErrorData.push({
            DocDate: x['DocDate'],
            TransactionDate: x['TransactionDate'],
            BankCode: x['BankCode'],
            LedgerType: x['LedgerType'],
            DocNo: x['DocNo'],
            RefNo: x['RefNo'],
            DocAmt: x['DocAmt'],
            PayeeReceiverName: x['PayeeReceiverName'],
            Description: x['Description'],
            RowNo: x['RowNo'],
            ErrorList: x['ErrorList'],
          })
        else
          ValidData.push({
            DocDate: x['DocDate'],
            TransactionDate: x['TransactionDate'],
            BankCode: x['BankCode'],
            LedgerType: x['LedgerType'],
            DocNo: x['DocNo'],
            RefNo: x['RefNo'],
            DocAmt: x['DocAmt'],
            PayeeReceiverName: x['PayeeReceiverName'],
            Description: x['Description'],
            RowNo: x['RowNo'],
            ErrorList: x['ErrorList'],
          })
      })

      setValidatedData({ ErrorData: ErrorData, ValidData: ValidData })
    },
  })

  const [
    createExternalLedger,
    { loading: creatExternalLedgerLoading, error: createExternalLedgerError },
  ] = useCreateExternalLedgerMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      history.push({
        pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/external-ledger`,
        state: {
          success: true,
          msgMode: 'create',
        },
      })
    },
  })

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar,
    //setOpenSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const createExtLedgerExcel = data => {
    let stringifyData = JSON.stringify(data)

    createExternalLedger({
      variables: {
        ExternalLedgerImportInput: stringifyData,
        CompanyID: CompanyID,
        DocRefName: fileName,
      },
    })
  }

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <>
      {checkingValidationExtLedgerLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(
            `/cash-book/${CompanyID}/submenu/${BankAccountID}/external-ledger`
          )
        }
        smTitle={'General Ledger'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'External Ledger', current: true },
        ]}
      />

      <DynamicSubHeader
        style={{
          marginTop: '-2px',
        }}
        title={'External Ledger Excel Upload'}
        rightText={<span className="c-orange">Validation</span>}
      />
      <List className="search-header fixed-search-header with-dynamic">
        <ListItem>
          <ListItemText
            primary={
              <>
                <span className="xsTitle flex-space">{fileName}</span>
              </>
            }
          />
        </ListItem>
      </List>

      <ContentWrapper
        style={{ marginTop: isDesktop ? '140px' : '75px' }}
        float
        footer
      >
        {/* Problem List */}
        {validatedData.ErrorData?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Problem{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {validatedData.ErrorData?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {validatedData.ErrorData?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div style={{ width: '100%' }}>
                          <span className="mdDesc flex-space ">
                            {formatDate(el.DocDate)}{' '}
                          </span>
                          <span className="xsTitle  c-orange">{el.DocNo}</span>
                          <span
                            className={
                              el?.DocAmt >= 0
                                ? 'mdDesc c-orange'
                                : 'mdDesc c-red'
                            }
                            style={{ float: 'right' }}
                          >
                            {amtStr(el?.DocAmt)}
                          </span>
                        </div>
                        <div style={{ width: '100%' }}>
                          <span className="desc">
                            {el.RefNo} | {el.BankCode}
                          </span>
                          <span
                            className={
                              el?.DocAmt >= 0
                                ? 'mdDesc c-orange'
                                : 'mdDesc c-red'
                            }
                            style={{ float: 'right' }}
                          >
                            {amtStr(el?.BaseAmt)}
                          </span>
                        </div>
                        {/* <div style={{ width: '100%' }}>
                          <span className="desc">{el.ExchangeRate}</span>
                        </div> */}
                      </>
                    }
                    secondary={
                      <>
                        <div>
                          <div
                            className="xsTitle text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            Row No: {el.RowNo}
                          </div>
                          <div
                            className="xsTitle text-noflow"
                            style={{ color: 'red' }}
                          >
                            {' '}
                            {el.ErrorList?.map((x, index) => {
                              if (index == el.ErrorList?.length - 1)
                                return `${x}`
                              else return `${x}, `
                            })}
                          </div>
                        </div>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}

        {/* Validation List */}
        {validatedData.ValidData?.length > 0 && (
          <>
            <List style={{ marginBottom: '-13px' }}>
              <ListItem>
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle" style={{ marginLeft: '-9px' }}>
                        Validated{' '}
                        {
                          <>
                            (
                            <span className="highlight-text">
                              {validatedData.ValidData?.length}
                            </span>
                            )
                          </>
                        }
                      </span>
                    </>
                  }
                />
              </ListItem>
            </List>
            <List className="core-list">
              {validatedData.ValidData?.map((el, index) => (
                <ListItem key={index}>
                  <ListItemText
                    // style={{
                    //   textAlign: 'right',
                    //   marginLeft: '5px',
                    // }}
                    primary={
                      <>
                        <div style={{ width: '100%' }}>
                          <span className="mdDesc">
                            {formatDate(el.DocDate)}{' '}
                            <span className="xsTitle  c-orange">
                              {el.DocNo}
                            </span>
                          </span>
                          <span
                            className={
                              el?.DocAmt >= 0
                                ? 'mdDesc c-orange'
                                : 'mdDesc c-red'
                            }
                            style={{ float: 'right' }}
                          >
                            {amtStr(el?.DocAmt)}
                          </span>
                        </div>
                        <div style={{ width: '100%' }}>
                          <span className="desc">
                            {el.RefNo} | {el.BankCode}
                          </span>
                          <span
                            className={
                              el?.DocAmt >= 0
                                ? 'mdDesc c-orange'
                                : 'mdDesc c-red'
                            }
                            style={{ float: 'right' }}
                          >
                            {amtStr(el?.BaseAmt)}
                          </span>
                        </div>
                        {/* <div style={{ width: '100%' }}>
                          <span className="desc">{el.ExchangeRate}</span>
                        </div> */}
                      </>
                    }
                    // secondary={
                    //   <>
                    //     <span className="desc">
                    //       <div className="text ">{el.Code}</div>
                    //       <div className="fw-medium"> {el.ParentCode}</div>
                    //       <div className="fw-medium"> {el.Name}</div>
                    //       <div className="fw-medium"> {el.Level}</div>
                    //       <div className="fw-medium"> {el.AccountType}</div>
                    //       <div className="fw-medium"> {el.IsControl}</div>
                    //       <div className="fw-medium"> {el.IsLastNode}</div>
                    //     </span>
                    //   </>
                    // }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </ContentWrapper>

      <ExcelInput
        showFooter={false}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          history.push({
            pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/external-ledger/validation`,
            state: {
              excelData: currentData?.map(x => {
                let DocDate = new Date(x?.DocumentDate)
                DocDate.setHours(0, 0, 0)
                let TransactionDate = new Date(x?.TransactionDate)
                DocDate.setHours(0, 0, 0)
                return {
                  DocDate: x?.DocumentDate === null ? null : new Date(DocDate),
                  TransactionDate:
                    x?.TransactionDate === null
                      ? null
                      : new Date(TransactionDate),
                  BankCode: x?.BankCode === null ? null : `${x?.BankCode}`,
                  LedgerType:
                    x?.LedgerType === null ? null : `${x?.LedgerType}`,
                  DocNo: x?.DocumentNo === null ? null : `${x?.DocumentNo}`,
                  RefNo: x?.RefNo === null ? null : `${x?.RefNo}`,
                  DocAmt:
                    x?.DocumentAmt === null ? null : Number(x?.DocumentAmt),
                  PayeeReceiverName:
                    x['Payee/ReceiverName'] === null
                      ? null
                      : `${x['Payee/ReceiverName']}`,
                  Description:
                    x?.Description === null ? null : `${x?.Description}`,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
            },
          })
          setOpenFileUpload(false)
          resetFileUpload()
          setFileTitle('')
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        onDownloadTemplate={() => {}}
        snackBarProps={{
          openSnackBar: openSnackBar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpenSnackBar,
          onClickButton: () => {
            setOpenSnackBar(false)
          },
        }}
      />
      <Footer
        copyright
        options={
          validatedData.ErrorData?.length === 0 &&
          validatedData.ValidData?.length > 0
            ? [
                {
                  onClick: () => {
                    setOpenDialog(true)
                  },
                  name: 'Confirm Upload',
                  color: 'primary',
                },
              ]
            : [
                {
                  name: 'Re-upload',
                  color: 'primary',
                  onClick: () => {
                    setOpenFileUpload(true)
                  },
                },
              ]
        }
      />

      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            dynamic: (
              <div className="">
                <div className="dialog-dynamic-content">
                  <span
                    className="title c-orange flex-space"
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    Upload Confirmation
                  </span>
                </div>
              </div>
            ),
          },
          body: () => (
            <div>
              <span>{'Are you sure to upload?'}</span>
            </div>
          ),

          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    createExtLedgerExcel(getCheckingValidationExtLedger)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <CommonDialog
        fullWidth={true}
        open={errorNumberingStructure}
        onClose={() => {
          setErrorNumberingStructure(false)
        }}
        sections={{
          header: {
            dynamic: (
              // <div className="">
              //   <div className="dialog-dynamic-content">
              //     <span
              //       className="title c-orange flex-space"
              //       style={{
              //         fontSize: '13px',
              //         fontWeight: 'bold',
              //       }}
              //     >
              //       Error!
              //     </span>
              //   </div>
              // </div>

              <>
                <span className="xsTitle flex-space" style={{ color: 'red' }}>
                  Error!
                </span>
              </>
            ),
          },
          body: () => (
            <div>
              <span>
                {'Document Numbering for External Journal is not created.'}
              </span>
            </div>
          ),

          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => {
                    setErrorNumberingStructure(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
