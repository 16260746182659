import React, { lazy } from 'react'
import { JournalEnquiryDetail } from './JournalEnquiryDetails'

const JournalEnquiryListing = lazy(() =>
  import('./JournalEnquiryListing').then(module => ({
    default: module.JournalEnquiryListing,
  }))
)

const journalEnquiryRoutes = [
  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/journal-enquiry',
    },
    component: <JournalEnquiryListing />,
  },

  {
    props: {
      exact: true,
      path: '/general-ledger/:CompanyID/journal-enquiry/:JournalID/detail',
    },
    component: <JournalEnquiryDetail />,
  },
]

export default journalEnquiryRoutes
