import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import Adjustment from '@ifca-root/react-component/src/assets/icons/stock-adjustment.svg'
import Issue from '@ifca-root/react-component/src/assets/icons/stock-issue.svg'
import Cycle from '@ifca-root/react-component/src/assets/icons/cycle-count.svg'
import Transfer from '@ifca-root/react-component/src/assets/icons/stock-transfer.svg'
import Receipt from '@ifca-root/react-component/src/assets/icons/stock-receipt.svg'
import Enquiry from '@ifca-root/react-component/src/assets/icons/stock-enquiry.svg'
import DigitalReports from 'assets/icons/digital-reporting.svg'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import { KeyboardArrowRight } from '@material-ui/icons'
import { SwitchDialog } from 'components/Switch/SwitchDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import AppContext from 'containers/App/Store/AppContext'
import {
  AcctPermission,
  useIcSubmenuDetailQuery,
  useGetWarehouseQuery,
  useGetWarehouseNameQuery,
  useGetStockItemWarehouseQuery,
  useGetAssignedWarehouseCompanyQuery,
} from 'generated/graphql'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'

export const InventoryControlSubmenu = (props: any) => {
  const { handlePermHidden } = usePermissionChecker()
  let history = useHistory()
  const { WarehouseID }: any = useParams()
  const { globalState, dispatch }: any = useContext(AppContext as any)
  const [change, setSwitch] = useState(false)
  const [homeWarehouse, setHomeWarehouse] = useState<string>(WarehouseID)

  localStorage.setItem('latestWarehouse', WarehouseID)
  if (globalState?.mode === '') {
    dispatch({
      type: 'mode',
      payload: 'inventory-control',
    })
  }

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }

  useEffect(() => {
    document.title = `AX - Inventory Control`
  }, [])

  // QUERY //
  const {
    loading: SubmenuLoading,
    error: SubmenuError,
    data: ICSubmenuDetail,
  } = useIcSubmenuDetailQuery({
    variables: { WarehouseID: WarehouseID },
    fetchPolicy: 'network-only',
  })

  const {
    loading: WarehouseNameLoading,
    error: WarehouseNameError,
    data: { getWarehouse: getWarehouseName } = { getWarehouse: [] },
  } = useGetWarehouseNameQuery({
    variables: { WarehouseID: WarehouseID },
    fetchPolicy: 'network-only',
  })

  // const {
  //   loading: warehouseLoading,
  //   error: warehouseError,
  //   data: { getWarehouse } = {
  //     getWarehouse: [],
  //   },
  // } = useGetWarehouseQuery({
  //   fetchPolicy: 'network-only',
  //   variables: {
  //     orderByAsc: 'Name',
  //   },
  // })

  const {
    loading: warehouseCompanyLoading,
    error: warehouseCompanyError,
    data: { getAssignedWarehouseCompanyByUser } = {
      getAssignedWarehouseCompanyByUser: [],
    },
  } = useGetAssignedWarehouseCompanyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: StockItemWarehouseLoading,
    error: StockItemWarehouseError,
    data: { getStockItemWarehouse } = { getStockItemWarehouse: [] },
  } = useGetStockItemWarehouseQuery({
    fetchPolicy: 'network-only',

    variables: {
      WarehouseID: WarehouseID, /// later need to change variabled to WarehouseID
    },
  })

  let submenuList = [
    {
      path: `/inventory-control/${WarehouseID}/stock-enquiry`,
      icon: Enquiry,
      title: `Stock Enquiry`,
      label1: ``,
      data1: getStockItemWarehouse?.length || 0,
      label2: ` Stock items`,
      data2: ``,
      permission: AcctPermission.InventoryControlStockEnquiryView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-requisition`,
      icon: Adjustment,
      title: `Stock Requisition`,
      label1: `Last Requisition on`,
      data1:
        ICSubmenuDetail?.stockRequisitionInfoLine.length > 0
          ? formatDate(ICSubmenuDetail?.stockRequisitionInfoLine[0].modTs)
          : 'NA',
      label2:
        ICSubmenuDetail?.stockRequisitionInfoLine.length > 0 ? ` by` : ` `,
      data2:
        ICSubmenuDetail?.stockRequisitionInfoLine.length > 0
          ? ICSubmenuDetail?.stockRequisitionInfoLine[0]?.name
          : ` `,
      permission: AcctPermission.InventoryControlStockRequisitionView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-issue`,
      icon: Issue,
      title: `Stock Issue`,
      label1: `Last issue on`,
      data1:
        ICSubmenuDetail?.stockIssueInfoLine.length > 0
          ? formatDate(ICSubmenuDetail?.stockIssueInfoLine[0].modTs)
          : 'NA',
      label2: ICSubmenuDetail?.stockIssueInfoLine.length > 0 ? ` by` : ` `,
      data2:
        ICSubmenuDetail?.stockIssueInfoLine.length > 0
          ? ICSubmenuDetail?.stockIssueInfoLine[0]?.name
          : ` `,
      permission: AcctPermission.InventoryControlStockIssueView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-receipt`,
      icon: Receipt,
      title: `Stock Receipt`,
      label1: `Last receipt on`,
      data1:
        ICSubmenuDetail?.stockReceiptInfoLine.length > 0
          ? formatDate(ICSubmenuDetail?.stockReceiptInfoLine[0].modTs)
          : 'NA',
      label2: ICSubmenuDetail?.stockReceiptInfoLine.length > 0 ? ` by` : ` `,
      data2:
        ICSubmenuDetail?.stockReceiptInfoLine.length > 0
          ? ICSubmenuDetail?.stockReceiptInfoLine[0]?.name
          : ` `,
      permission: AcctPermission.InventoryControlStockReceiptView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-transfer`,
      icon: Transfer,
      title: `Stock Transfer`,
      label1: `Last transfer on`,
      data1:
        ICSubmenuDetail?.stockTransferInfoLine.length > 0
          ? formatDate(ICSubmenuDetail?.stockTransferInfoLine[0].modTs)
          : 'NA',
      label2: ICSubmenuDetail?.stockTransferInfoLine.length > 0 ? ` by` : ` `,
      data2:
        ICSubmenuDetail?.stockTransferInfoLine.length > 0
          ? ICSubmenuDetail?.stockTransferInfoLine[0]?.name
          : ` `,
      permission: AcctPermission.InventoryControlStockTransferView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-adjustment`,
      icon: Adjustment,
      title: `Stock Qty Adjustment`,
      label1: `Last transaction on`,
      data1:
        ICSubmenuDetail?.stockAdjustmentInfoLine.length > 0
          ? formatDate(ICSubmenuDetail?.stockAdjustmentInfoLine[0].modTs)
          : 'NA',
      label2: ICSubmenuDetail?.stockAdjustmentInfoLine.length > 0 ? ` by` : ` `,
      data2:
        ICSubmenuDetail?.stockAdjustmentInfoLine.length > 0
          ? ICSubmenuDetail?.stockAdjustmentInfoLine[0].name
          : ` `,
      permission: AcctPermission.InventoryControlStockAdjustmentView,
    },
    {
      path: `/inventory-control/${WarehouseID}/stock-cost-adjustment`,
      icon: Adjustment,
      title: `Stock Cost Adjustment`,
      label1: `Last transaction on`,
      data1:
        ICSubmenuDetail?.stockAdjustmentInfoLine.length > 0
          ? formatDate(ICSubmenuDetail?.stockAdjustmentInfoLine[0].modTs)
          : 'NA',
      label2: ICSubmenuDetail?.stockAdjustmentInfoLine.length > 0 ? ` by` : ` `,
      data2:
        ICSubmenuDetail?.stockAdjustmentInfoLine.length > 0
          ? ICSubmenuDetail?.stockAdjustmentInfoLine[0].name
          : ` `,
      permission: AcctPermission.InventoryControlStockCostAdjustmentView,
    },
    {
      path: `/inventory-control/${WarehouseID}/cycle-count`,
      icon: Cycle,
      title: `Cycle Count`,
      label1: `Last cycle on`,
      data1:
        ICSubmenuDetail?.cycleCountInfoLine.length > 0
          ? formatDate(ICSubmenuDetail?.cycleCountInfoLine[0].modTs)
          : 'NA',
      label2: ICSubmenuDetail?.cycleCountInfoLine.length > 0 ? ` by` : ` `,
      data2:
        ICSubmenuDetail?.cycleCountInfoLine.length > 0
          ? ICSubmenuDetail?.cycleCountInfoLine[0].name
          : ` `,
      permission: AcctPermission.InventoryControlCycleCountingView,
    },

    {
      path: `/inventory-control/${WarehouseID}/digital-report`,
      icon: DigitalReports,
      title: `Digital Reports`,
      label1: ``,
      data1: 2,
      label2: ` Reports available`,
      data2: ``,
      permission: AcctPermission.InventoryControlDigitalReportsView,
    },
  ]

  return (
    <>
      {SubmenuLoading && <Loading />}
      {WarehouseNameLoading && <Loading />}
      {warehouseCompanyLoading && <Loading />}
      {StockItemWarehouseLoading && <Loading />}
      <MainHeader
        mainBtn="menu"
        onClick={handleDrawer}
        smTitle={'Inventory Control'}
        title={getWarehouseName[0]?.Name}
        onClickAction={() => setSwitch(true)}
        sideBtn="company"
        //rightCustomIcon={getWarehouseName[0]?.Logo?.fileURL}
        routeSegments={[{ name: 'Main Menu', current: true }]}
      />

      <ContentWrapper float>
        <List className="core-list">
          {submenuList.map((el, index) => {
            if (
              handlePermHidden({
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem key={index} onClick={() => history.push(el?.path)}>
                  <IconItem
                    image={el?.icon}
                    imageType="svg"
                    badgeType="default"
                  />
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={
                      <Grid spacing={1} container className="table-content">
                        <Grid
                          item
                          xs={6}
                          style={{
                            textAlign: 'start',
                          }}
                        >
                          <span className="xsTitle">{el?.title}</span>
                        </Grid>
                        {el?.title === 'Stock Enquiry' && (
                          <Grid
                            item
                            xs={6}
                            style={{
                              textAlign: 'end',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                                color: 'orange',
                              }}
                            >
                              {amtStr(
                                getStockItemWarehouse?.reduce(
                                  (a, b) =>
                                    a + b?.StockAvgUnitCost * b?.StockQty,
                                  0
                                )
                              )}
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    }
                    secondary={
                      <>
                        <span className="desc">
                          <span className="desc">{el?.label1}</span>
                          &nbsp;
                          <span className="desc c-orange">{el?.data1}</span>
                          <span className="desc">{el?.label2}</span>
                          &nbsp;
                          <span className="desc c-orange">{el?.data2}</span>
                        </span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: el.path,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>

      <SwitchDialog
        open={change}
        onClose={() => setSwitch(false)}
        data={getAssignedWarehouseCompanyByUser}
        name={'Warehouse'}
        nameID={'WarehouseID'}
        currID={homeWarehouse}
        setCurrID={setHomeWarehouse}
        path={'/inventory-control'}
      />
    </>
  )
}
