import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  usePostArAdvanceMutation,
  usePostArCreditNoteMutation,
  usePostArDebitNoteMutation,
  usePostArInvoiceMutation,
  usePostArRefundMutation,
  usePostArOfficialReceiptMutation,
  useRejectArAdvanceMutation,
  useRejectArCreditNoteMutation,
  useRejectArOfficialReceiptMutation,
  useRejectArDebitNoteMutation,
  useRejectArInvoiceMutation,
  useRejectArRefundMutation,
} from 'generated/graphql'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'

interface ARPostMutation {
  arSubmenu: string
  loadData: any
  setPostIDs: any
  CompanyID: string
}

export const useARPostMutation = ({
  arSubmenu,
  loadData,
  setPostIDs,
  CompanyID,
}: ARPostMutation) => {
  let history = useHistory()

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  /* -------------------------------------------- */
  /*                POST MUTATION               */
  /* -------------------------------------------- */

  const [
    postARAdvance,
    {
      loading: postARAdvanceLoading,
      called: postARAdvanceCalled,
      error: postARAdvanceError,
    },
  ] = usePostArAdvanceMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    rejectARAdvance,
    {
      loading: rejectARAdvanceLoading,
      called: rejectARAdvanceCalled,
      error: rejectARAdvanceError,
    },
  ] = useRejectArAdvanceMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    postARInvoice,
    {
      loading: postARInvoiceLoading,
      called: postARInvoiceCalled,
      error: postARInvoiceError,
    },
  ] = usePostArInvoiceMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    rejectARInvoice,
    {
      loading: rejectARInvoiceLoading,
      called: rejectARInvoiceCalled,
      error: rejectARInvoiceError,
    },
  ] = useRejectArInvoiceMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    postARRefund,
    {
      loading: postARRefundLoading,
      called: postARRefundCalled,
      error: postARRefundError,
    },
  ] = usePostArRefundMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    rejectARRefund,
    {
      loading: rejectARRefundLoading,
      called: rejectARRefundCalled,
      error: rejectARRefundError,
    },
  ] = useRejectArRefundMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    postARDebitNote,
    {
      loading: postARDebitNoteLoading,
      called: postARDebitNoteCalled,
      error: postARDebitNoteError,
    },
  ] = usePostArDebitNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    rejectARDebitNote,
    {
      loading: rejectARDebitNoteLoading,
      called: rejectARDebitNoteCalled,
      error: rejectARDebitNoteError,
    },
  ] = useRejectArDebitNoteMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: () => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    postARCreditNote,
    {
      loading: postARCreditNoteLoading,
      called: postARCreditNoteCalled,
      error: postARCreditNoteError,
    },
  ] = usePostArCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    rejectARCreditNote,
    {
      loading: rejectARCreditNoteLoading,
      called: rejectARCreditNoteCalled,
      error: rejectARCreditNoteError,
    },
  ] = useRejectArCreditNoteMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    postAROfficialReceipt,
    {
      loading: postAROfficialReceiptLoading,
      called: postAROfficialReceiptCalled,
      error: postAROfficialReceiptError,
    },
  ] = usePostArOfficialReceiptMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const [
    rejectAROfficialReceipt,
    {
      loading: rejectAROfficialReceiptLoading,
      called: rejectAROfficialReceiptCalled,
      error: rejectAROfficialReceiptError,
    },
  ] = useRejectArOfficialReceiptMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
      loadData()
    },
  })

  const handlePost =
    arSubmenu === 'advance'
      ? postARAdvance
      : arSubmenu === 'invoice'
      ? postARInvoice
      : arSubmenu === 'refund'
      ? postARRefund
      : arSubmenu === 'debit-note'
      ? postARDebitNote
      : arSubmenu === 'credit-note'
      ? postARCreditNote
      : postAROfficialReceipt

  const handleReject =
    arSubmenu === 'advance'
      ? rejectARAdvance
      : arSubmenu === 'invoice'
      ? rejectARInvoice
      : arSubmenu === 'refund'
      ? rejectARRefund
      : arSubmenu === 'debit-note'
      ? rejectARDebitNote
      : arSubmenu === 'credit-note'
      ? rejectARCreditNote
      : rejectAROfficialReceipt

  const mutationLoading =
    arSubmenu === 'advance'
      ? postARAdvanceLoading || rejectARAdvanceLoading
      : arSubmenu === 'invoice'
      ? postARInvoiceLoading || rejectARInvoiceLoading
      : arSubmenu === 'refund'
      ? postARRefundLoading || rejectARRefundLoading
      : arSubmenu === 'debit-note'
      ? postARDebitNoteLoading || rejectARDebitNoteLoading
      : arSubmenu === 'credit-note'
      ? postARCreditNoteLoading || rejectARCreditNoteLoading
      : postAROfficialReceiptLoading || rejectAROfficialReceiptLoading

  const postCalled =
    arSubmenu === 'advance'
      ? postARAdvanceCalled || rejectARAdvanceCalled
      : arSubmenu === 'invoice'
      ? postARInvoiceCalled || rejectARInvoiceCalled
      : arSubmenu === 'refund'
      ? postARRefundCalled || rejectARRefundCalled
      : arSubmenu === 'debit-note'
      ? postARDebitNoteCalled || rejectARDebitNoteCalled
      : arSubmenu === 'credit-note'
      ? postARCreditNoteCalled || rejectARCreditNoteCalled
      : postAROfficialReceiptCalled || rejectAROfficialReceiptCalled

  return {
    handlePost,
    handleReject,
    mutationLoading,
    postCalled,
    setErrorDia,
    errorDia,
    errMsg,
  }
}
