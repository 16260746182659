import theme from '@ifca-root/react-component/src/assets/theme'
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import EventIcon from '@material-ui/icons/Event'
import { ApprovalLogDialog } from 'components/Dialog/ApprovalLogDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import Fuse from 'fuse.js'
import {
  AcctPermission,
  ApprovalStatus,
  useGetBudgetbyStatusQuery,
  useGetBudgetQuery,
  useGetCompanyNameQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router'
import React, { useEffect, useState } from 'react'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'

interface GLBudgetProps {
  Year: Date
}

export const GLBudgetRevisionListing = (props: any) => {
  useEffect(() => {})
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  const [originalBudgetStatus, setOriginalBudgetStatus] = useState<string>(
    getSearch?.option
  )
  console.log('getSearch', getSearch)

  let history = useHistory()
  let location = useLocation()
  const editData: any = location?.state

  const { CompanyID }: any = useParams()
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [Year, setYear] = useState<any>(
    !!editData ? editData?.selectedYear : new Date().getFullYear()
  )
  const [openWorkFlow, setWorkFlow] = useState(false)
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const {
    loading: BudgetLoading,
    error: BudgetError,
    data: { getBudget: getBudget } = { getBudget: [] },
  } = useGetBudgetQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, orderByAsc: 'Fyear' },
    onCompleted: ({ getBudget }) => {
      const pendingV1 = getBudget
        ?.filter(
          x =>
            x?.Version === 'Version 1' &&
            x?.ApprovalStatus === ApprovalStatus.Active
        )
        ?.map(el => el?.BudgetID)
      const filteredBudget = getBudget?.filter(
        m => !pendingV1.includes(m?.BudgetID)
      )
      let arr = []
      arr.push(
        ...filteredBudget?.filter(
          x => x.ApprovalStatus === ApprovalStatus.Active
        )
      )
      arr.push(
        ...filteredBudget?.filter(
          x =>
            x.ApprovalStatus === ApprovalStatus.Submit ||
            x.ApprovalStatus === ApprovalStatus.Approved
        )
      )
      arr.push(
        ...filteredBudget?.filter(
          x => x.ApprovalStatus === ApprovalStatus.Completed
        )
      )
      arr.push(
        ...filteredBudget?.filter(
          x => x.ApprovalStatus === ApprovalStatus.Rejected
        )
      )
      arr.push(
        ...filteredBudget?.filter(
          x => x.ApprovalStatus === ApprovalStatus.Cancelled
        )
      )

      setOriginalListing(arr)
    },
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: BudgetbyStatusLoading,
    error: BudgetbyStatusError,
    data: { getBudgetbyStatus } = { getBudgetbyStatus: [] },
  } = useGetBudgetbyStatusQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState,
  } = useForm<GLBudgetProps>({
    defaultValues: {},
    mode: 'onSubmit',
    //resolver: yupResolver(GLBudgetFormSchema),
  })

  const RecordStatusText = pStatus => {
    switch (pStatus) {
      case ApprovalStatus.Submit:
        return 'Submitted'
      case ApprovalStatus.Active:
        return 'Draft'
      case ApprovalStatus.Approved:
        return 'Submitted'
      case ApprovalStatus.Completed:
        return 'Approved'
      case ApprovalStatus.Rejected:
        return 'Rejected'
      case ApprovalStatus.Cancelled:
        return 'Cancelled'
      default:
        return ''
    }
  }
  const RecordStatusColor = pStatus => {
    switch (pStatus) {
      case 'ACTIVE':
        return ''
      case 'COMPLETED':
        return '#00bf0f'

      case 'REJECTED':
        return '#FF0000'

      case 'CANCELLED':
        return ''

      case 'CLOSED':
        return ''

      case 'DRAFT':
        return '#454545'

      case 'SUBMIT':
        return '#2f53e6'

      case 'APPROVED':
        return '#2f53e6'

      case 'PENDING':
        return '#2924d9'

      case 'PENDING_CLOSE':
        return '#2924d9'

      default:
        return ''
    }
  }

  // useEffect(() => {
  //   if (getSearch && !!originalList) {
  //     const keys = ['Version']
  //     const options = {
  //       shouldSort: true,
  //       threshold: 0.6,
  //       ignoreLocation: true,
  //       keys: keys,
  //     }

  //     const myFuse = new Fuse(originalList, options)
  //     const result = myFuse.search(getSearch)
  //     const val = result?.map(x => x.item)
  //     if (val.length !== 0) {
  //       handleSearch(getSearch + '', keys)
  //     }
  //   }
  // }, [getSearch, originalList])

  useEffect(() => {
    if (getBudgetbyStatus && getBudgetbyStatus?.length > 0) {
      setOriginalBudgetStatus(getSearch?.option ?? 'ACTIVE')
    }
  }, [getBudgetbyStatus])

  // console.log(getBudgetbyStatus, 'getBudgetbyStatus')

  // useEffect(() => {
  //   handleSearch(getSearch ?? '', ['Version', 'Fyear'])
  // }, [originalList])

  useEffect(() => {
    if (getSearch?.name && !!originalList) {
      const keys = ['Version', 'Fyear']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(`'"${getSearch?.name}"`)
      const val = result?.map(x => x.item)
      if (
        val.length !== 0 &&
        getSearch?.name !== `'` &&
        getSearch?.name !== `` &&
        getSearch?.name !== `'""` &&
        getSearch?.name !== undefined
      ) {
        handleSearch(getSearch?.name + '', keys)
      }
    }
  }, [getSearch?.name, originalList])

  //////////////////////////////////////////////////////////////////
  ////// ** ORIGINALBUDGET DATA FILTERED BY STATUS ** //////
  //////////////////////////////////////////////////////////////////

  const approvalStatusEnum = [
    { name: 'All', ID: 'ALL' },
    { name: 'Pending', ID: 'ACTIVE' },
    { name: 'Verified', ID: 'COMPLETED' },
  ]

  let newFilteredList = filteredList?.filter(
    v =>
      v?.ApprovalStatus === originalBudgetStatus ||
      originalBudgetStatus === 'ALL'
  )

  // console.log(filteredList, 'filteredList')
  console.log(newFilteredList, 'newFilteredList')
  // console.log(originalBudgetStatus, 'originalBudgetStatus')

  let color
  const handleYearStatusColor = type => {
    switch (type) {
      case 'ACTIVE':
        color = '#FF0000'
        break
      case 'COMPLETED':
        color = ''
        break
    }
    return color
  }

  const divider = ' | '

  return (
    <>
      {CompanyLoading && <Loading />}
      {BudgetLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-ledger/${CompanyID}/`)
          localStorage.removeItem('searchFilter')
        }} //<- back button action
        smTitle="General Ledger"
        title={getCompany[0]?.Name}
        currency={`MYR`}
        routeSegments={[
          { name: 'Home Journal' },
          {
            name: 'Budget Revision',
            current: true,
          },
        ]}
        //rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />

      {/* <SubHeaderAction
        style={{
          top: isDesktop ? '6.5vh' : null,
          position: isDesktop ? 'relative' : null,
        }}
        title={
          <Controller
            as={KeyboardDatePicker}
            required
            onChange={(date: Date | null) => {
              console.log(date)
            }}
            views={['year']}
            name="Year"
            control={control}
            className="left"
            format="yyyy"
            value={Year}
            inputValue={Year}
            onAccept={(date: Date | null) => {
              setYear(new Date(date).getFullYear())
            }}
            allowKeyboardControl
            autoComplete="off"
            fullWidth
            ref={register}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            keyboardIcon={<ArrowDropDownIcon />}
            margin="dense"
            inputVariant="outlined"
            InputProps={{
              style: {
                fontSize: '14px',
                //fontWeight: 'bold',
                color: 'black',
                width: '145px',
                backgroundColor: 'white',
                height: '30px',
              },
              startAdornment: (
                <EventIcon
                  style={{ fontSize: 'medium', paddingRight: '10px' }}
                />
              ),
            }}
          />
        }
        noButton
        actionTitle={
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              style={{
                height: '17px',
                width: '17px',
                paddingRight: '5px',
                fill: '#FFFFFF',
              }}
            >
              {' '}
              <path d="M 11.033203 3.2207031 C 10.958186 3.2183086 10.882062 3.2229062 10.804688 3.2382812 L 2.8046875 4.8398438 C 2.3366875 4.9338437 2 5.3433125 2 5.8203125 L 2 18.179688 C 2 18.656688 2.3366875 19.066156 2.8046875 19.160156 L 10.804688 20.761719 C 11.423687 20.885719 12 20.410297 12 19.779297 L 12 4.2207031 C 12 3.6685781 11.558326 3.2374648 11.033203 3.2207031 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 5.4375 8.296875 C 5.7495 8.296875 6.0323438 8.4854375 6.1523438 8.7734375 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.1328125 8.703125 C 8.2488125 8.455125 8.4974844 8.296875 8.7714844 8.296875 C 9.3034844 8.296875 9.6444844 8.8620312 9.3964844 9.3320312 L 8.0078125 11.966797 L 9.4140625 14.599609 C 9.6780625 15.099609 9.3179531 15.703125 8.7519531 15.703125 C 8.4599531 15.703125 8.1923125 15.533578 8.0703125 15.267578 L 7.1582031 13.287109 C 7.1162031 13.202109 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.9277344 15.296875 C 5.8117344 15.544875 5.5611094 15.703125 5.2871094 15.703125 L 5.2011719 15.703125 C 4.6621719 15.703125 4.3210312 15.123344 4.5820312 14.652344 L 6.0605469 11.994141 L 4.7480469 9.421875 C 4.4860469 8.906875 4.8595 8.296875 5.4375 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z"></path>
            </svg>
            <span>Import</span>
          </>
        }
      />

      <SearchHeader
        title={'Budget Revision Listing'}
        value={`${filteredList.length ?? 0}`}
        search
        onChangeAction={e => {
          handleSearch(e?.target?.value, ['Version'])
          localStorage.setItem('searchFilter', e.target.value)
        }}
        defaultValue={getSearch ? getSearch : ''}
        isDefaultValue={!!getSearch}
        onCloseAction={() => handleSearch('', [])}
        multiDynamicInfo
        option={{
          icon: <Tune />,
          //onClick: () => setFilter(true),
        }}
      /> */}

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Fyear', 'Version'])
            localStorage.setItem(
              'searchFilter',
              JSON.stringify({
                option: originalBudgetStatus ?? 'ACTIVE',
                name: e?.target?.value,
              })
            )
          }}
          defaultValue={getSearch?.name ? getSearch?.name : ''}
          onCloseAction={() => {
            handleSearch('', [])
          }}
          isDefaultValue={!!getSearch}
        />

        <DropdownBar
          options={approvalStatusEnum}
          setOptionID={setOriginalBudgetStatus}
          borderRadius="4px"
          defaultOption={approvalStatusEnum
            ?.map(opt => opt?.ID)
            .indexOf(originalBudgetStatus ?? 'ACTIVE')}
        />
      </div>

      <ContentWrapper float>
        <List className="core-list">
          {newFilteredList === undefined || newFilteredList?.length === 0
            ? !BudgetLoading && (
                <EmptyList
                  title="No Record found"
                  subtitle="Add a new record now."
                />
              )
            : !BudgetLoading &&
              newFilteredList.map((el, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <>
                          <span className="mdLabel">
                            <EventIcon
                              style={{
                                fontSize: 'small',
                                paddingRight: '2px',
                                marginBottom: '-1px',
                                paddingTop: '2px',
                              }}
                            />
                          </span>
                          <span
                            className=" mdLabel"
                            style={{
                              paddingRight: '3px',
                              color: handleYearStatusColor(el?.ApprovalStatus),
                            }}
                          >
                            {el?.Fyear}
                          </span>
                          <span
                            className="mdLabel"
                            style={{ paddingRight: '3px' }}
                          >
                            {divider}
                          </span>
                          <span
                            className="flex-space mdLabel"
                            style={{
                              color: handleYearStatusColor(el?.ApprovalStatus),
                            }}
                          >
                            {el?.Version}
                          </span>
                          {el.ApprovalStatus === 'COMPLETED' &&
                          el.IsDefault === true ? (
                            <span>
                              <CheckCircleIcon
                                style={{
                                  color: 'limegreen',
                                  fontSize: 'medium',
                                }}
                              />
                            </span>
                          ) : null}
                        </>
                      }
                      secondary={
                        <>
                          <span className="desc flex-space">
                            <span
                              className="fw-medium"
                              style={{
                                textDecoration:
                                  el.ApprovalStatus === 'REJECTED'
                                    ? 'line-through'
                                    : null,
                              }}
                            >
                              {`Latest updated ${formatDate(el.modTs)}.`}
                            </span>
                          </span>

                          {/* <span
                            className={`desc ${RecordStatusColor(
                              el.ApprovalStatus
                            )}`}
                            style={{
                              textDecoration: null,
                              color: RecordStatusColor(el.ApprovalStatus),
                            }}
                          >
                            {el.ApprovalStatus !== 'ACTIVE' ? (
                              <IconText
                                font="desc"
                                icon={
                                  <img
                                    src={ApprovalLog}
                                    style={{
                                      width: '12px',
                                      marginBottom: '2px',
                                      marginRight: '3px',
                                    }}
                                    onClick={e => {
                                      setWorkFlow(true)
                                      handleClick2(
                                        e,
                                        el?.WorkFlow?.WorkFlowID,
                                        index,
                                        el
                                      )
                                      console.log(
                                        'id',
                                        el?.WorkFlow?.WorkFlowID
                                      )
                                    }}
                                  />
                                }
                                children={
                                  <span
                                    style={{
                                      color: RecordStatusColor(
                                        el.ApprovalStatus
                                      ),
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {RecordStatusText(el.ApprovalStatus)}
                                  </span>
                                }
                              ></IconText>
                            ) : (
                              <span
                                style={{
                                  color: RecordStatusColor(el.ApprovalStatus),
                                  fontWeight: 'bold',
                                }}
                              >
                                {RecordStatusText(el.ApprovalStatus)}
                              </span>
                            )}
                          </span> */}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        disabled={handlePermDisabled({
                          companyID: CompanyID,
                          permEnum:
                            AcctPermission.GeneralLedgerBudgetRevisionUpdate,
                        })}
                      >
                        <KeyboardArrowRight
                          onClick={() =>
                            history.push({
                              pathname:
                                el.ApprovalStatus === 'ACTIVE'
                                  ? `/general-ledger/${CompanyID}/budget-revision/edit`
                                  : `/general-ledger/${CompanyID}/budget-revision/detail`,
                              state: el,
                            })
                          }
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
        </List>

        <FloatButton
          disabled={handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.GeneralLedgerBudgetRevisionCreate,
          })}
          copyright
          onClick={() =>
            history.push({
              pathname: `/general-ledger/${CompanyID}/budget-revision/add`,
              state: { selectedYear: Year },
            })
          }
        />
      </ContentWrapper>
      <ApprovalLogDialog
        data={menu2?.obj}
        setWorkFlow={setWorkFlow}
        openWorkFlow={openWorkFlow}
        moduleName={'Budget'}
      />
    </>
  )
}
