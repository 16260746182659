import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
  GetUsersByAccountAndSoftwareDocument,
  useGetCompanyNameQuery,
  useGetPrHeaderQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from '../../generated/graphql';
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import HeaderPR from './PRTemplate/HeaderPR';
import TablePR from './PRTemplate/TablePR';
import Table2PR from './PRTemplate/Table2PR';
import FooterPR from './FooterPR';
import TablePO from './POTemplate/TablePO';
import Table2PO from './POTemplate/Table2PO';
import HeaderPO from './POTemplate/HeaderPO';
import theme from '@ifca-root/react-component/src/assets/theme';
import { useGPQueries } from './useGPQueries';
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import TableGRN from './PRTemplate/TableGRN';
import TableDOItem from './PRTemplate/TableDOItem';
import FooterGRN from './FooterGRN';

export const PDFPreview = (_props: any) => {
  const [width] = useMediaQuery();
  const location: any = useLocation();
  // const previewDetail: any = location?.state;

  let history = useHistory();
  const { styles } = useMobilePDFZoom();
  const [visibled, setVisibility] = useState(null);
  const {
    CompanyID,
    PRHeaderID,
    POHeaderID,
    GRTNHeaderID,
    DOHeaderID,
  }: any = useParams();
  const { accountType, docType, backPath } = _props;

  let fontSize;
  if (width < 769) {
    fontSize = 6;
  } else {
    fontSize = 10;
  }

  let routeSegments;
  let title;
  let smTitle;
  switch (accountType) {
    case '/':
      smTitle = 'General Purchases';
      switch (docType) {
        // PO and PR
        case 'generate-po-from-pr':
          routeSegments = 'Purchase Order';
          title = 'PURCHASE ORDER';
          break;
        case 'direct-po':
          routeSegments = 'Purchase Order';
          title = 'PURCHASE ORDER';
          break;
        case 'centralised-po':
          routeSegments = 'Purchase Order';
          title = 'PURCHASE ORDER';
          break;
        case 'purchase-requisition':
          routeSegments = 'Purchase Requisition';
          title = 'PURCHASE REQUISITION';
          break;

        // GRTN and GRN
        case 'good-return-note':
          routeSegments = 'Goods Return Note';
          title = 'GOODS RETURN NOTE';
          break;
        case 'delivery-order':
          routeSegments = 'Goods Receive Note';
          title = 'GOODS RECEIVE NOTE';
          break;
        default:
          break;
      }
      switch (backPath) {
        case 'purchase-requisition':
          break;
        case 'direct-po':
          break;
      }
      break;
  }

  const { queryLoading, listData, primaryKey } = useGPQueries({
    docType,
    CompanyID,
  });

  const previewDetail = listData?.filter(
    v =>
      v?.[primaryKey] ===
      (PRHeaderID ?? POHeaderID ?? GRTNHeaderID ?? DOHeaderID),
  )[0];

  interface InfoTemplateRight {
    title: string;
    data: string;
  }
  const InfoTemplateRight = ({ title, data }: InfoTemplateRight) => {
    // let fontSize, customWidth;
    // if (width < 769) {
    //   fontSize = 5;
    //   customWidth = '1px';
    // } else {
    //   fontSize = 10;
    //   customWidth = '5px';
    // }
    return (
      <div style={{ paddingLeft: '20%' }}>
        <div style={{ width: '40%', display: 'inline-flex' }}>{`${title}`}</div>
        <div style={{ width: '10%', display: 'inline-flex' }}>:</div>
        <div style={{ width: '50%', display: 'inline-flex' }}>{data}</div>
      </div>
    );
  };

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  });

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  });

  const {
    loading: allCompanyLoading,
    error: allCompanyError,
    data: { getCompany: getAllCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
  });

  const {
    loading,
    error,
    data: { getPRHeader } = { getPRHeader: [] },
  } = useGetPrHeaderQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      PRHeaderID: PRHeaderID,
    },
  });

  const onPrint = () => {
    getPRHeader
      ?.filter(x => x?.CompanyID === previewDetail?.CompanyID)
      ?.map((v, i) => {
        let element = document.getElementById('prList');
        html2pdf()
          .set({
            filename: `${
              getPRHeader?.filter(x => x?.CompanyID === v?.CompanyID)[0]
                ?.Company?.Name
            }.pdf`,
          })
          .from(element)
          .save();
      });
  };

  const borderWith = {
    borderBottom: '4px solid black',
  };

  let logoStyle, containerStyle, fontStyle;
  fontStyle = {
    padding: '10px',
    textAlign: 'center',
  };

  containerStyle = {
    paddingLeft: '10px',
    paddingRight: '10px',
  };

  return (
    <>
      {queryLoading && <Loading />}
      {UserLoading && <Loading />}
      {allCompanyLoading && <Loading />}
      {companyLoading && <Loading />}
      {loading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-purchase/${CompanyID}/${backPath}`);
        }}
        smTitle={'General Purchases'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
      />

      <ContentWrapper footer>
        <div style={{ padding: '14px' }} id={'prList'}>
          <div style={styles.supercontainer}>
            <div style={styles.container}>
              {accountType === '/' && (
                <>
                  <HeaderPR getCompany={getCompany} />

                  <div className="row">
                    <div>
                      <p style={fontStyle}>
                        <b>{title}</b>
                      </p>
                    </div>
                  </div>

                  <div style={{ border: '0.5px solid', margin: '10px' }}>
                    {docType !== 'good-return-note' ||
                    docType !== 'delivery-order' ? (
                      <TablePR
                        name={previewDetail?.DeliveryLocation?.Name}
                        data={previewDetail}
                        getAllCompany={getAllCompany}
                        docType={docType}
                      />
                    ) : (
                      <TableGRN
                        name={previewDetail?.DeliveryLocation?.Name}
                        data={previewDetail}
                        getAllCompany={getAllCompany}
                        docType={docType}
                      />
                    )}

                    {docType === 'delivery-order' ||
                    docType === 'good-return-note' ? (
                      <TableDOItem data={previewDetail} docType={docType} />
                    ) : (
                      <Table2PR data={previewDetail} docType={docType} />
                    )}
                  </div>

                  {docType === 'good-return-note' ||
                  docType === 'delivery-order' ? null : (
                    <div style={{ fontSize, margin: '10px' }}>
                      Special Instruction: <br />
                      {previewDetail?.Instruction ?? 'NA'}
                    </div>
                  )}

                  {docType === 'purchase-requisition' && (
                    <div style={{ fontSize, margin: '10px' }}>
                      Note : <br />
                      1. Do not substitute or back order. Notify us immediately
                      if unable to ship. <br />
                      2. Delivery Note and Invoice must accompany all shipments.{' '}
                      <br />
                      3. The PO number must appear on invoice and all delivery
                      order. <br />
                      4. We assume no responsibility for goods delivered without
                      a Purchase Order.
                    </div>
                  )}

                  {docType === 'good-return-note' ||
                  docType === 'delivery-order' ? (
                    <FooterGRN
                      data={previewDetail}
                      docType={docType}
                      getUser={getUsersByAccountAndSoftware}
                    />
                  ) : (
                    <FooterPR
                      data={previewDetail}
                      docType={docType}
                      getUser={getUsersByAccountAndSoftware}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <Footer
          options={[
            {
              name: 'Download',
              onClick: () => {
                onPrint();
              },

              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  );
};
