import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import {
  useGetCompanyQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql'
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom'
import html2pdf from 'html2pdf.js/dist/html2pdf.min'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import FooterPR from './PDFTemplate/FooterPR'
import HeaderPR from './PDFTemplate/HeaderPR'
import HeaderOR from './PDFTemplate/ORTemplate/HeaderOR'
import BodyOR from './PDFTemplate/ORTemplate/BodyOR'
import FooterOR from './PDFTemplate/ORTemplate/FooterOR'
import Table2PR from './PDFTemplate/ARTemplate/Table2PR'
import TablePR from './PDFTemplate/TablePR'
import { useQueries } from './Query/useQueries'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import Table3PR from './PDFTemplate/ARTemplate/Table3PR'
import TableAP from './PDFTemplate/APTemplate/TableAP'
import Table2AP from './PDFTemplate/APTemplate/Table2AP'
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable'
import TableAdvance from './PDFTemplate/APTemplate/TableAdvance'
import HeaderGL from 'containers/GeneralLedgerModule/Hooks/PDFTemplate/HeaderGL'
import TableAPPayment from './PDFTemplate/APTemplate/TableAPPayment'
import TableAPItem from './PDFTemplate/APTemplate/TableAPItem'

export const PDFPreview = (props: any) => {
  const location: any = useLocation()
  // const previewDetail: any = location?.state
  const { accountType, docType } = props
  let history = useHistory()
  const { styles } = useMobilePDFZoom()
  const [visibled, setVisibility] = useState(null)
  const {
    CompanyID,
    InvoiceID,
    ReceiptID,
    CreditNoteID,
    DebitNoteID,
    PaymentID,
    AdvanceID,
    RefundID,
  }: any = useParams()

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  })

  const { loading, listData, primaryKey } = useQueries({
    accountType,
    docType,
    CompanyID,
    ReceiptID,
  })

  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 8
  } else {
    fontSize = 12
  }

  const [screenWidth] = useMediaQuery()
  let watemarkStyle

  if (screenWidth < 769) {
    watemarkStyle = {
      watermark: {
        position: 'absolute' as 'absolute',
        top: '20%',
        width: '80%',
        textAlign: 'center',
        opacity: `${visibled}`,
        fontSize: '18px',
        userSelect: 'none',
        transform: 'rotate(-45deg)',
      },
    }
  } else {
    watemarkStyle = {
      watermark: {
        position: 'absolute' as 'absolute',
        top: '40%',
        width: '80%',
        textAlign: 'center',
        opacity: `${visibled}`,
        fontSize: '18px',
        userSelect: 'none',
        transform: 'rotate(-40deg)',
      },
    }
  }
  let fontStyle
  fontStyle = {
    padding: '10px',
    fontSize: fontSize,
    textAlign: 'center',
  }

  let routeSegments
  let title
  let smallTitle
  let creditorOrDebtor
  let name
  let DocumentID
  let itemName
  switch (accountType) {
    case 'account-receivable':
      smallTitle = smTitle.ACCOUNT_RECEIVABLE
      creditorOrDebtor = 'DebtorAccount'
      name = 'DebtorName'
      switch (docType) {
        case 'official-receipt':
          DocumentID = ReceiptID
          routeSegments = 'Official Receipt'
          title = 'OFFICIAL RECEIPT'
          break
        case 'refund':
          DocumentID = RefundID
          routeSegments = 'Refund'
          title = 'REFUND VOUCHER'
          break
        case 'invoice':
          DocumentID = InvoiceID
          routeSegments = 'Invoice'
          title = 'INVOICE'
          itemName = 'ARInvoiceItem'
          break
        case 'credit-note':
          DocumentID = CreditNoteID
          routeSegments = 'Credit Note'
          title = 'CREDIT NOTE'
          itemName = 'ARCreditNoteItem'
          break
        case 'debit-note':
          DocumentID = DebitNoteID
          routeSegments = 'Debit Note'
          title = 'DEBIT NOTE'
          itemName = 'ARDebitNoteItem'
          break
      }
      break
    case 'account-payable':
      smallTitle = smTitle.ACCOUNT_PAYABLE
      creditorOrDebtor = 'CreditorAccount'
      name = 'CompanyName'
      switch (docType) {
        case 'payment':
          DocumentID = PaymentID
          routeSegments = 'Payment'
          title = 'PAYMENT VOUCHER'
          break
        case 'advance':
          DocumentID = AdvanceID
          routeSegments = 'Advance'
          title = 'ADVANCE VOUCHER'
          break
        case 'refund':
          DocumentID = RefundID
          routeSegments = 'Refund'
          title = 'REFUND VOUCHER'
          break
        case 'invoice':
          DocumentID = InvoiceID
          routeSegments = 'Invoice'
          title = 'INVOICE'
          itemName = 'APInvoiceItem'
          break
        case 'credit-note':
          DocumentID = CreditNoteID
          routeSegments = 'Credit Note'
          title = 'CREDIT NOTE'
          itemName = 'APCreditNoteItem'
          break
        case 'debit-note':
          DocumentID = DebitNoteID
          routeSegments = 'Debit Note'
          title = 'DEBIT NOTE'
          itemName = 'APDebitNoteItem'
          break
      }
      break
  }

  const previewDetail: any = listData?.filter(
    v => v?.[primaryKey] === DocumentID
  )[0]

  const onPrint = () => {
    listData
      ?.filter(x => x?.[primaryKey] === previewDetail?.[primaryKey])
      ?.map((v, i) => {
        let element = document.getElementById('element-to-print-' + i)
        html2pdf()
          .set({
            filename: `${title} (${
              listData?.filter(x => x?.[primaryKey] === v?.[primaryKey])[0]?.[
                creditorOrDebtor
              ]?.[name]
            }).pdf`,
          })
          .from(element)
          .save()
      })
  }

  return (
    <>
      {loading && <Loading />}
      {companyLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          previewDetail?.ApprovalStatus === 'COMPLETED'
            ? history.push({
                pathname: `/${accountType}/${CompanyID}/${docType}/${DocumentID}/detail`,
                state: previewDetail,
              })
            : history.push({
                pathname: `/${accountType}/${CompanyID}/${docType}`,
                state: previewDetail,
              })
        }}
        smTitle={smallTitle}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
        rightRouteSegments={[{ name: `Print`, current: true }]}
      />
      <ContentWrapper footer>
        {listData
          ?.filter(x => x?.[primaryKey] === previewDetail?.[primaryKey])
          ?.map((v, i) => {
            return (
              <div id={'element-to-print-' + i}>
                <div style={styles.supercontainer}>
                  <div style={styles.container}>
                    <HeaderGL />
                    <div style={fontStyle}>
                      <b>{title}</b>
                    </div>

                    <div style={{ border: '0.5px solid', margin: '10px' }}>
                      <TablePR
                        creditorOrDebtor={
                          previewDetail?.CreditorAccount ??
                          previewDetail?.DebtorAccount
                        }
                        data={previewDetail}
                        name={
                          previewDetail?.CreditorAccount?.CompanyName ??
                          previewDetail?.DebtorAccount?.DebtorName
                        }
                        accountType={accountType}
                      />
                      {docType === 'advance' ? (
                        <>
                          <TableAdvance
                            data={previewDetail}
                            docType={'advance'}
                          />
                        </>
                      ) : docType === 'payment' ||
                        docType === 'refund' ||
                        docType === 'official-receipt' ? (
                        <>
                          <TableAPPayment
                            data={previewDetail}
                            docType={'payment'}
                            allocation={previewDetail?.Allocation}
                          />
                        </>
                      ) : (
                        <TableAPItem item={previewDetail?.[itemName]} />
                      )}
                    </div>

                    <FooterPR
                      data={previewDetail}
                      docType={docType}
                      getUser={getUsersByAccountAndSoftware}
                    />
                  </div>
                </div>
              </div>
            )
          })}

        <Footer
          options={[
            {
              name: 'Download',
              color: 'primary',
              onClick: () => {
                onPrint()
              },
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
