import PrintIcon from '@material-ui/icons/Visibility'
import { attachmentDetailView } from 'helpers/Hooks/attachmentDetailsView'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { useHistory, useParams } from 'react-router'
import './ARDetail.scss'
import React from 'react'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  mode?: string
  arSubmenu?: string
}

export const ARDetailContent = (props: DetailProps) => {
  let history = useHistory()
  const { CompanyID }: any = useParams()
  const {
    listEl,
    documentListing,
    listStatus,
    userList,
    mode,
    arSubmenu,
  } = props

  let primaryKey
  switch (arSubmenu) {
    case 'advance':
      primaryKey = 'AdvanceID'
      break
    case 'invoice':
      primaryKey = 'InvoiceID'
      break
    case 'credit-note':
      primaryKey = 'CreditNoteID'
      break
    case 'debit-note':
      primaryKey = 'DebitNoteID'
      break
    case 'official-receipt':
      primaryKey = 'ReceiptID'
      break
    case 'refund':
      primaryKey = 'RefundID'
      break
    default:
      break
  }

  return (
    <>
      {mode === 'detail' && (
        <>
          {arSubmenu === 'invoice' ? (
            <div className="content-wrap left">
              <div className="desc" style={{ fontWeight: 300 }}>
                Date
              </div>

              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.DocDate)}
              </div>
            </div>
          ) : (
            <div className="content-wrap left">
              <div className="desc" style={{ fontWeight: 300 }}>
                Date
              </div>

              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.DocDate)}
              </div>
            </div>
          )}
          <div className="content-wrap right">
            <div className="desc" style={{ fontWeight: 300 }}>
              Transaction Date
            </div>

            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {formatDate(listEl?.TransactionDate)}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ fontWeight: 300 }}>
              Reference No.
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.RefNo}
            </div>
          </div>

          <div className="content-wrap right">
            {
              <span
                style={{ float: 'right' }}
                onClick={() => {
                  history.push({
                    pathname: `/account-receivable/${CompanyID}/${arSubmenu}/${
                      listEl?.[`${primaryKey}`]
                    }/preview`,
                    state: {
                      ...listEl,
                      mode: 'detail',
                    },
                  })
                }}
              >
                <PrintIcon
                  style={{
                    fontSize: 'medium',
                    padding: '0px 10px 2px 0px',
                  }}
                />
              </span>
            }
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Debtor Name
            </div>
            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.DebtorAccount?.DebtorName}
            </div>
          </div>

          {listEl?.PaymentMethod && (
            <>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Bank Account No
                </div>
                <div
                  className="xsTitle"
                  style={{ fontWeight: 500, whiteSpace: 'break-spaces' }}
                >
                  {`${listEl?.BankAccount?.AccountNo} (${listEl?.BankAccount?.BankProfile?.Name})`}
                </div>
              </div>

              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Payment Method
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {listEl?.PaymentMethod?.Name}
                </div>
              </div>

              {listEl?.PaymentMethod?.Name?.includes('Card') && (
                <div className="content-wrap right">
                  <div className="desc" style={{ fontWeight: 300 }}>
                    Card Type
                  </div>
                  <div className="xsTitle" style={{ fontWeight: 500 }}>
                    {listEl?.CreditCardType?.Name}
                  </div>
                </div>
              )}

              {listEl?.PaymentMethod?.Name === 'Cheque' && (
                <>
                  <div className="content-wrap right">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque No.
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {listEl?.ChequeNo}
                    </div>
                  </div>
                  <div className="content-wrap left">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque Date
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {formatDate(listEl?.ChequeDate)}
                    </div>
                  </div>

                  <div className="content-wrap right">
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Cheque Expiry Date
                    </div>
                    <div className="xsTitle" style={{ fontWeight: 500 }}>
                      {formatDate(listEl?.ChequeExpiryDate)}
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Description
            </div>
            <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
              {listEl?.Description ?? listEl?.Remark}
            </div>
          </div>

          {listEl?.Remark && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                Remark
              </div>
              <div className="xsTitle text-noflow" style={{ fontWeight: 500 }}>
                {listEl?.Remark}
              </div>
            </div>
          )}

          {listEl?.TaxScheme?.Code && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(listEl?.Amount)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Tax
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {`
        ${listEl?.TaxScheme?.Code} 
            (${listEl?.TaxRate}%)
            `}
                </div>
              </div>

              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Tax Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(listEl?.TaxAmt)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Total Amount
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {amtStr(listEl?.DocAmt)}
                </div>
              </div>
            </>
          )}

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Attachment
            </div>
            {attachmentDetailView(documentListing)}
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>
        </>
      )}

      {listStatus === 'COMPLETED' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}
    </>
  )
}
