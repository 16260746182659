import { AttachFile } from '@material-ui/icons'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { default as React } from 'react'
import { Grid } from '@material-ui/core'
import { useHistory, useParams } from 'react-router'
import PrintIcon from '@material-ui/icons/Visibility'

interface DetailProps {
  listEl: any
  listStatus: string
  userList: any
  documentListing?: any
  docType?: string
  mode?: string
  itemQueryData?: string
}

export const ARDetailExpansion = (props: DetailProps) => {
  const { listEl, documentListing, userList, docType, itemQueryData } = props

  let history = useHistory()
  const { CompanyID }: any = useParams()

  let primaryKey
  switch (docType) {
    case 'advance':
      primaryKey = 'AdvanceID'
      break
    case 'invoice':
      primaryKey = 'InvoiceID'
      break
    case 'credit-note':
      primaryKey = 'CreditNoteID'
      break
    case 'debit-note':
      primaryKey = 'DebitNoteID'
      break
    case 'official-receipt':
      primaryKey = 'ReceiptID'
      break
    default:
      break
  }

  return (
    <>
      {(docType === 'advance' ||
        docType === 'refund' ||
        docType === 'official-receipt' ||
        docType === 'invoice' ||
        docType === 'credit-note' ||
        docType === 'debit-note') && (
        <>
          <Grid container>
            <Grid item xs={10}>
              <div className="content-wrap left">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Debtor Name
                </div>
                <div className="xsTitle" style={{ fontWeight: 500 }}>
                  {listEl?.DebtorAccount?.DebtorName}
                </div>
              </div>

              <div className="content-wrap right">
                <span
                  style={{ float: 'right' }}
                  onClick={() => {
                    history.push({
                      pathname: `/account-receivable/${CompanyID}/${docType}/${
                        listEl?.[`${primaryKey}`]
                      }/preview`,
                      state: {
                        ...listEl,
                        mode: 'detail',
                      },
                    })
                  }}
                >
                  <PrintIcon
                    style={{
                      fontSize: 'medium',
                      padding: '0px 10px 2px 0px',
                    }}
                  />
                </span>
              </div>
              <Grid container>
                <Grid item xs={6}>
                  <div>
                    <div className="desc" style={{ fontWeight: 300 }}>
                      Reference No
                    </div>
                    <div
                      className="xsTitle text-noflow"
                      style={{ fontWeight: 500 }}
                    >
                      {listEl?.RefNo}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ placeSelf: 'end', textAlign: 'right' }}
                >
                  <div>
                    <div className="desc" style={{ fontWeight: 300 }}>
                      {'Transaction Date'}
                    </div>
                    <div
                      className="mdDesc text-noflow"
                      style={{ fontSize: '11px' }}
                    >
                      {formatDate(listEl?.TransactionDate)}
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div className="content-wrap full">
                <div className="desc" style={{ fontWeight: 300 }}>
                  Description
                </div>
                <div
                  className="xsTitle text-noflow"
                  style={{ fontWeight: 500 }}
                >
                  {listEl?.Description}
                </div>
              </div>

              {listEl?.Remark && (
                <div className="content-wrap full">
                  <div className="desc" style={{ fontWeight: 300 }}>
                    Remark
                  </div>
                  <div
                    className="xsTitle text-noflow"
                    style={{ fontWeight: 500 }}
                  >
                    {listEl?.Remark}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              {documentListing && documentListing?.length > 0 && (
                <>
                  <div
                    className="content-wrap right"
                    style={{
                      textAlign: 'center',
                      alignSelf: 'end',
                    }}
                  >
                    {documentListing && (
                      <>
                        <span
                          onClick={() =>
                            documentListing?.forEach(doc => {
                              window.open(doc?.fileURL, '_blank')
                            })
                          }
                          style={{
                            position: 'relative',
                            top: '1px',
                          }}
                        >
                          <AttachFile
                            className="mdDesc"
                            style={{
                              fontSize: 12,
                              color: 'orange',
                            }}
                          />
                        </span>
                        <span
                          className="mdDesc"
                          style={{
                            color: 'orange',
                            textDecoration: 'underline',
                          }}
                        >
                          {documentListing?.length}
                        </span>
                      </>
                    )}
                  </div>
                </>
              )}
            </Grid>
          </Grid>

          <div className="content-wrap left">
            {docType === 'refund' ? (
              <div className="desc" style={{ fontWeight: 300 }}>
                Refund Method
              </div>
            ) : (
              <div className="desc" style={{ fontWeight: 300 }}>
                Payment Method
              </div>
            )}

            <div className="xsTitle" style={{ fontWeight: 500 }}>
              {listEl?.PaymentMethod?.Name}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc" style={{ fontWeight: 300 }}>
              Bank Account No.
            </div>
            <div
              className="xsTitle"
              style={{ fontWeight: 500, whiteSpace: 'break-spaces' }}
            >
              {`${listEl?.BankAccount?.AccountNo} (${listEl?.BankAccount?.BankProfile?.Name})`}
            </div>
          </div>

          {listEl?.PaymentMethod?.Name?.includes('Card') && (
            <div className="content-wrap right">
              <div className="desc" style={{ fontWeight: 300 }}>
                Card Type
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {listEl?.CreditCardType?.Name}
              </div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap right">
              <div className="desc" style={{ fontWeight: 300 }}>
                Cheque No.
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {listEl?.ChequeNo}
              </div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap left">
              <div className="desc" style={{ fontWeight: 300 }}>
                Cheque Date
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.ChequeDate)}
              </div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <div className="content-wrap full">
              <div className="desc" style={{ fontWeight: 300 }}>
                Cheque Expiry Date
              </div>
              <div className="xsTitle" style={{ fontWeight: 500 }}>
                {formatDate(listEl?.ChequeExpiryDate)}
              </div>
            </div>
          )}
        </>
      )}

      {(docType === 'debit-note' ||
        docType === 'invoice' ||
        docType === 'credit-note') && (
        <>
          <Grid container>
            <Grid item xs={10}>
              <div className="content-wrap left ">
                <div className="desc" style={{ color: 'grey' }}>
                  Reference No.
                </div>
                <div className="mdDesc"> {listEl?.RefNo} </div>
              </div>
              <div className="content-wrap full">
                <div className="desc" style={{ color: 'grey' }}>
                  {'Transaction Date'}
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {formatDate(listEl?.TransactionDate)}
                </div>
              </div>
              {listEl?.Description && (
                <>
                  <div className="content-wrap full">
                    <div className="desc" style={{ color: 'grey' }}>
                      Description
                    </div>
                    <div
                      className="mdDesc text-noflow"
                      style={{ fontWeight: 500 }}
                    >
                      {listEl?.Description}
                    </div>
                  </div>
                </>
              )}
              {listEl?.Remark && (
                <div className="content-wrap full">
                  <div className="desc" style={{ color: 'grey' }}>
                    Remark
                  </div>
                  <div className="mdDesc text-noflow">{listEl?.Remark}</div>
                </div>
              )}
            </Grid>
            <Grid item xs={2}>
              {documentListing && documentListing?.length > 0 && (
                <>
                  <div
                    className="content-wrap right"
                    style={{ textAlign: 'center', alignSelf: 'end' }}
                  >
                    {documentListing && (
                      <>
                        <span
                          onClick={() =>
                            documentListing?.forEach(doc => {
                              window.open(doc?.fileURL, '_blank')
                            })
                          }
                          style={{
                            position: 'relative',
                            top: '1px',
                          }}
                        >
                          <AttachFile
                            className="mdDesc"
                            style={{
                              fontSize: 12,
                              color: 'orange',
                            }}
                          />
                        </span>
                        <span
                          className="mdDesc"
                          style={{
                            color: 'orange',
                            textDecoration: 'underline',
                          }}
                        >
                          {documentListing?.length}
                        </span>
                      </>
                    )}
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}

      <div className="content-wrap left">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted Date'}
        </div>
        <div className="mdDesc text-noflow">
          {formatDate(listEl?.submittedTs)}
        </div>
      </div>
      <div className="content-wrap right">
        <div className="desc" style={{ color: 'grey' }}>
          {'Submitted By'}
        </div>
        <div className="mdDesc text-noflow">
          {userList?.filter(user => user.ID == listEl?.submittedBy)[0]?.name}
        </div>
      </div>
    </>
  )
}
