import DateFnsUtils from '@date-io/date-fns'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  ListItem,
  MenuItem,
  TextField,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import {
  ApprovalStatus,
  RecordStatus,
  useGetCompanyNameQuery,
  useGetJournalTypeLazyQuery,
} from 'generated/graphql'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'

interface AdvanceFilterProps {
  hasStatus?: boolean
  hasDocDate?: boolean
  hasDateRange?: boolean
  hasJournalType?: boolean
  mode?: string
  app?: string
}

export const AdvancedFilter = (props: AdvanceFilterProps) => {
  const {
    hasStatus,
    hasDocDate,
    hasDateRange,
    hasJournalType,
    mode,
    app,
  } = props
  let location = useLocation()
  let history = useHistory()
  const { mode: pageMode }: any = useParams()

  let title
  switch (app) {
    case 'general-ledger':
      title = 'General Ledger'
      break
    case 'cash-book':
      title = 'Cash Book'
      break
  }

  const editData: any = location?.state
  const { CompanyID }: any = useParams()

  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'onSubmit',
  })

  //   register('statusBox')
  //   register('docDateBox')
  //   register('dateRangeBox')

  let filterName
  switch (mode) {
    case 'journal-processing':
      filterName = 'Journal Processing Filter'
      break
    case 'recurring-journal':
      filterName = 'Recurring Journal Filter'
      break
    case 'external-journal':
      filterName = 'External Journal Filter'
      break
    case 'audit-adjustment':
      filterName = 'Audit Adjustment Filter'
      break
    case 'payment':
      filterName = 'Payment Filter'
      break
    case 'receipt':
      filterName = 'Receipt Filter'
      break
    case 'external-ledger':
      filterName = 'External Ledger Filter'
      break
    case 'bank-transfer':
      filterName = 'Bank Transfer Filter'
      break
    case 'bank-reconciliation':
      filterName = 'Bank Reconciliation Filter'
      break
    default:
      filterName = 'Advanced Filter'
      break
  }

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID, RecordStatus: RecordStatus.Active },
  })

  const [
    fetchJournalType,
    {
      loading: JournalTypeLoading,
      error: JournalTypeError,
      data: { getJournalType } = { getJournalType: [] },
    },
  ] = useGetJournalTypeLazyQuery({
    fetchPolicy: 'network-only',
  })

  const initializeDate = date => {
    // const selectedDate = new Date(date).toISOString()
    // // return new Date(
    // //   date?.setHours(0, 0, 0, 0) + date?.getTimezoneOffset() * -1 * 60 * 1000
    // // )
    return formatDashDate(new Date(date).toISOString())
  }

  const onApply = data => {
    const filterJSON = JSON.stringify({
      ...(data?.docDateBox && {
        ...(data?.statusBox && { status: data?.statusField }),
        DocDate: initializeDate(data?.docDateField),
      }),
      ...(data?.journalTypeBox && {
        journalType: data?.journalTypeField,
      }),
      ...(data?.dateRangeBox && {
        fromDate: initializeDate(data?.fromDateField),
      }),
      ...(data?.dateRangeBox && {
        toDate: initializeDate(data?.toDateField),
      }),
    })
    localStorage.setItem('advancedFilter', filterJSON)
    history.goBack()
  }

  const handleCheckBoxChange = e => {
    setValue(`${e.target.value}`, e.target.checked)
  }

  const statusDropdown = [
    { Name: 'All', Code: 'ALL' },
    { Name: 'Draft', Code: ApprovalStatus.Active },
    { Name: 'Submitted', Code: ApprovalStatus.Submit },
    { Name: 'Approved', Code: ApprovalStatus.Completed },
    { Name: 'Rejected', Code: ApprovalStatus.Rejected },
  ]

  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const filter = JSON.parse(localStorage.getItem('advancedFilter'))

  const customReset = () => {
    setValue('statusField', null)
    setValue('statusBox', null)
    setValue('docDateField', null)
    setValue('docDateBox', null)
    setValue('dateRangeBox', null)
    setValue('fromDateField', null)
    setValue('toDateField', null)
    setValue('journalTypeBox', null)
    setValue('journalTypeField', null)
  }

  useEffect(() => {
    if (mode === 'external-journal') {
      fetchJournalType({
        variables: {
          CompanyID: CompanyID,
        },
      })
    }
  }, [mode])

  return (
    <>
      <MainHeader
        mainBtn="close"
        onClick={() => history.goBack()}
        smTitle={title}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Doc' },
          { name: 'List' },
          { name: `${filterName}`, current: true },
        ]}
      />

      <ContentWrapper footer>
        <CardContents
          section={{
            header: {
              title: 'Standard Filters',
            },
          }}
          contentStyle={{ paddingBottom: '12px' }}
        >
          <Grid container>
            {hasStatus && (
              <>
                <Grid item xs={12}>
                  <ListItem disableGutters>
                    <FormControlLabel
                      control={
                        <Controller
                          name={'statusBox'}
                          control={control}
                          defaultValue={filter?.status ? true : false}
                          render={props => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={e => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label={''}
                    />
                    <Controller
                      as={
                        <TextField
                          style={{ marginLeft: '-14px' }}
                          margin="dense"
                        >
                          {statusDropdown?.map((v, index) => (
                            <MenuItem value={v?.Code} key={index}>
                              {v?.Name}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                      label="Status"
                      select
                      name="statusField"
                      autoComplete="off"
                      control={control}
                      fullWidth
                      ref={register()}
                      defaultValue={filter?.status ? filter?.status : 'ALL'}
                      disabled={!watch('statusBox')}
                    />
                  </ListItem>
                </Grid>
              </>
            )}
            {hasJournalType && (
              <>
                <Grid item xs={12}>
                  <ListItem disableGutters>
                    <FormControlLabel
                      control={
                        <Controller
                          name={'journalTypeBox'}
                          control={control}
                          defaultValue={filter?.journalType ? true : false}
                          render={props => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={e => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                      label={''}
                    />
                    <Controller
                      as={
                        <TextField
                          style={{ marginLeft: '-14px' }}
                          margin="dense"
                        >
                          {getJournalType?.map((v, index) => (
                            <MenuItem value={v?.JournalType} key={index}>
                              {v?.JournalType}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                      label="Journal Type"
                      select
                      name="journalTypeField"
                      autoComplete="off"
                      control={control}
                      fullWidth
                      ref={register()}
                      defaultValue={
                        filter?.journalType ? filter?.journalType : null
                      }
                      disabled={!watch('journalTypeBox')}
                    />
                  </ListItem>
                </Grid>
              </>
            )}

            {hasDocDate && (
              <Grid item xs={12}>
                <ListItem disableGutters>
                  <FormControlLabel
                    control={
                      <Controller
                        name={'docDateBox'}
                        control={control}
                        defaultValue={filter?.DocDate ? true : false}
                        render={props => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            disabled={watch('dateRangeBox')}
                            onChange={e => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label={''}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      disabled={!watch('docDateBox')}
                      as={KeyboardDatePicker}
                      name="docDateField"
                      label="Document Date"
                      control={control}
                      onChange={(date: Date | null) => {
                        // console.log(date)
                      }}
                      format="dd MMM yyyy"
                      value={watch('docDateField')}
                      margin="normal"
                      allowKeyboardControl
                      ref={register}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      defaultValue={filter?.DocDate ? filter?.DocDate : null}
                      helperText={errors?.DocDate?.message}
                      error={errors?.DocDate ? true : false}
                      showTodayButton
                      fullWidth
                      style={{ marginLeft: '-14px' }}
                    />
                  </MuiPickersUtilsProvider>
                </ListItem>
              </Grid>
            )}

            {hasDateRange && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Controller
                        name={'dateRangeBox'}
                        control={control}
                        defaultValue={
                          filter?.fromDate && filter?.toDate ? true : false
                        }
                        render={props => (
                          <Checkbox
                            {...props}
                            style={{ padding: '26px 9px' }}
                            checked={props.value}
                            disabled={watch('docDateBox')}
                            onChange={e => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label={''}
                  />
                  {/* <ListItem disableGutters> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      disabled={!watch('dateRangeBox')}
                      as={KeyboardDatePicker}
                      name="fromDateField"
                      label="From Date"
                      control={control}
                      onChange={(date: Date | null) => {
                        // console.log(date)
                      }}
                      format="dd MMM yyyy"
                      value={watch('fromDateField')}
                      margin="normal"
                      allowKeyboardControl
                      ref={register}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      defaultValue={filter?.fromDate ? filter?.fromDate : null}
                      helperText={errors?.DocDate?.message}
                      error={errors?.DocDate ? true : false}
                      showTodayButton
                      fullWidth
                      className="left"
                      style={{ width: '44%', marginLeft: '-14px' }}
                    />
                  </MuiPickersUtilsProvider>
                  {/* </ListItem>
              <ListItem disableGutters> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      disabled={!watch('dateRangeBox')}
                      as={KeyboardDatePicker}
                      name="toDateField"
                      label="To Date"
                      control={control}
                      onChange={(date: Date | null) => {
                        // console.log(date)
                      }}
                      format="dd MMM yyyy"
                      value={watch('toDateField')}
                      margin="normal"
                      allowKeyboardControl
                      ref={register}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      defaultValue={filter?.toDate ? filter?.toDate : null}
                      helperText={errors?.DocDate?.message}
                      error={errors?.DocDate ? true : false}
                      showTodayButton
                      fullWidth
                      className="right"
                      style={{ width: '44%', marginLeft: '1%' }}
                    />
                  </MuiPickersUtilsProvider>
                  {/* </ListItem> */}
                </Grid>
              </>
            )}
          </Grid>
        </CardContents>
        <Footer
          options={[
            {
              name: 'Reset',
              onClick: () => {
                //   handleSubmit(onSubmit)
                customReset()
              },
              color: 'primary',
            },
            {
              name: 'Apply',
              onClick: () => {
                handleSubmit(onApply)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}

export const advancedFilterList = list => {
  const advancedFilter = JSON.parse(localStorage.getItem('advancedFilter'))
  let newList = list
  // console.log(list, 'list')
  if (advancedFilter && Object.keys(advancedFilter)?.length > 0) {
    newList = list
      // ?.filter(e => {
      //   if (advancedFilter?.status && advancedFilter?.status === 'ALL') {
      //     return e
      //   } else if (advancedFilter?.status && advancedFilter?.status !== 'ALL') {
      //     return e?.ApprovalStatus === advancedFilter?.status
      //   } else if (
      //     advancedFilter?.status &&
      //     advancedFilter?.status === 'SUBMIT'
      //   ) {
      //     return (
      //       e?.ApprovalStatus === ApprovalStatus.Submit ||
      //       e?.ApprovalStatus === ApprovalStatus?.Approved
      //     )
      //   } else {
      //     return e
      //   }
      // })
      // ?.filter(e => {
      //   if (advancedFilter?.journalType) {
      //     return e?.JournalType?.JournalType === advancedFilter?.journalType
      //   }
      // })
      ?.filter(e => {
        let date =
          new Date(e?.DocDate) ?? e?.createdTs ?? e?.JournalDate ?? e?.DueDate
        //console.log(e, 'everyth')
        if (!!advancedFilter?.DocDate) {
          // console.log('masuk tak 1')
          return e?.DocDate === formatDashDate(advancedFilter?.DocDate)
        } else if (!!advancedFilter?.toDate && !!advancedFilter?.fromDate) {
          // console.log('masuk tak 2')

          return (
            new Date(date) >= new Date(advancedFilter?.fromDate) &&
            new Date(date) <= new Date(advancedFilter?.toDate)
          )
        } else {
          return e
        }
      })
  }

  return newList
}
