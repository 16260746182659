import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import {
  useGetCompanyNameQuery,
  useGetExternalJournalQuery,
} from 'generated/graphql'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { useHistory, useParams } from 'react-router'
import React from 'react'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'

export const ExternalJournalDetail = (props: any) => {
  let history = useHistory()
  const { CompanyID, ExternalJournalID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    loading: ExternalJournalLoading,
    error: ExternalJournalError,
    data: { getExternalJournal } = { getExternalJournal: [] },
  } = useGetExternalJournalQuery({
    variables: { ExternalJournalID: ExternalJournalID },
  })

  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const extJournalItem = getExternalJournal?.[0]?.ExternalJournalItem

  const docAmtReducer = (accumulator, currentValue) =>
    accumulator + currentValue?.DocAmt

  const positiveAmt = extJournalItem
    ?.filter(v => v?.DocAmt > 0)
    ?.sort((a, b) => {
      return a.MasterCOA?.Code.localeCompare(b.MasterCOA?.Code)
    })

  const negativeAmt = extJournalItem
    ?.filter(v => v?.DocAmt < 0)
    ?.sort((a, b) => {
      return a.MasterCOA?.Code.localeCompare(b.MasterCOA?.Code)
    })

  const extJournalItemList = positiveAmt?.concat(negativeAmt)

  return (
    <>
      {CompanyLoading && <Loading />}
      {ExternalJournalLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(`/general-ledger/${CompanyID}/external-journal`)
        }
        smTitle="General Ledger"
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: 'Journal Management' },
          { name: 'External Journal', current: true },
        ]}
        rightRouteSegments={[{ name: 'Details', current: true }]}
      />

      <DynamicSubHeader
        title={getExternalJournal[0]?.DocNo}
        rightText={formatDate(getExternalJournal[0]?.JournalDate)}
        infoLineStyle={{ display: 'flex', flex: '1 1' }}
        infoLine={
          <span className="mdDesc flex-space">
            Number of Records:{' '}
            {getExternalJournal[0]?.ExternalJournalItem?.length}
          </span>
        }
      />
      <ContentWrapper multiDynamicInfo>
        <CardContents>
          <div className="content-wrap ">
            <div className="desc" style={{ color: 'grey' }}>
              Journal Date
            </div>
            <div className="mdDesc">
              {formatDate(getExternalJournal?.[0]?.JournalDate)}
            </div>
          </div>

          <div className="content-wrap ">
            <div className="desc" style={{ color: 'grey' }}>
              Journal Type
            </div>
            <div className="mdDesc ">
              {`${getExternalJournal[0]?.JournalType.JournalType} | 
              ${getExternalJournal[0]?.JournalType.Description}`}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="mdDesc text-noflow">
              {getExternalJournal[0]?.Description}{' '}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <div className="mdDesc">{getExternalJournal[0]?.RefNo} </div>
          </div>
        </CardContents>

        <CardContents section={{ header: { title: 'Journal Detail' } }}>
          <div className="rm-padding table-wrap">
            <List className="core-list content-wrap full" disablePadding>
              {extJournalItemList
                // ?.sort((a, b) => {
                //   return a.MasterCOA?.Code.localeCompare(b.MasterCOA?.Code)
                // })
                ?.map((el, index) => (
                  <ListItem className="table-listItem" key={index}>
                    <ListItemText
                      primary={
                        <Grid container spacing={1}>
                          <Grid item xs={1} className="fw-medium desc">
                            {index + 1}.
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            className="fw-medium desc text-noflow"
                          >
                            {`${el?.MasterCOA?.Code} | ${el?.MasterCOA?.Name}`}
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            className="fw-medium desc"
                            style={{ textAlign: 'end' }}
                          >
                            {el?.DocAmt < 0
                              ? `(${amtStr(Math.abs(el?.DocAmt))})`
                              : amtStr(el?.DocAmt)}
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <>
                          <Grid container spacing={1}>
                            <Grid item xs={1} className="fw-medium desc" />
                            <Grid
                              item
                              xs={5}
                              className="fw-medium desc"
                              style={{ color: '#858585' }}
                            >
                              {el?.CostCentre?.Name}
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={1} className="fw-medium desc" />
                            <Grid
                              item
                              xs={5}
                              className="fw-medium desc"
                              style={{ color: '#858585' }}
                            >
                              {el?.Remark}
                            </Grid>
                          </Grid>
                        </>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </div>
          <div className="footer-label" style={{ background: '#bfd3ff' }} />
        </CardContents>
        <TotalAmountFooter
          detail={true}
          debitCreditInfo={true}
          debitAmt={amtStr(positiveAmt?.reduce(docAmtReducer, 0)) ?? '0.00'}
          creditAmt={
            amtStr(Math.abs(negativeAmt?.reduce(docAmtReducer, 0))) ?? '0.00'
          }
        />
      </ContentWrapper>
    </>
  )
}
