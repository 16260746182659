import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight, Tune } from '@material-ui/icons'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import Fuse from 'fuse.js'
import {
  AcctPermission,
  useDebtorAccountTotalDueQuery,
  useGetDebtorAccountQuery,
  useGetDebtorLatestUpdatedDateQuery,
  useGetHomeSummaryQuery,
  useGetOsDebtorDebitDocLazyQuery,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/CustomHooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import {
  checkDueDate,
  formatDate,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const DebtorAccountListing = (props: any) => {
  const getSearch = localStorage?.getItem('searchFilter')
  let history = useHistory()
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const { CompanyID, DebtorAccountID }: any = useParams()
  const [totalDocAmt, setTotalDocAmt] = useState(0)
  const [debtorName, setDebtorName] = useState('')
  const [osDialog, setOsDialog] = useState(false)

  const {
    loading,
    called,
    data: {
      loggedInUserProfile,
      getAccountPermission,
      getCompany: curCompany,
      getRolePermission,
    } = {
      loggedInUserProfile: null,
      getAccountPermission: [],
      getCompany: [],
      getRolePermission: [],
    },
  } = useGetHomeSummaryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID,
    },
  })

  const {
    loading: DebtorAccountLoading,
    error: DebtorAccountError,
    data: { getDebtorAccount } = { getDebtorAccount: [] },
  } = useGetDebtorAccountQuery({
    fetchPolicy: 'network-only',

    variables: {
      DebtorAccountID: DebtorAccountID,
      CompanyID: CompanyID,
      orderByAsc: 'DebtorName',
    },
  })

  const {
    loading: DebtorLatestUpdatedDateLoading,
    called: DebtorLatestUpdatedDateCalled,
    data: { getDebtorLatestUpdatedDate } = { getDebtorLatestUpdatedDate: [] },
  } = useGetDebtorLatestUpdatedDateQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const {
    loading: DebtorAccountTotalDueLoading,
    called: DebtorAccountTotalDueCalled,
    data: { DebtorAccountTotalDue } = { DebtorAccountTotalDue: [] },
  } = useDebtorAccountTotalDueQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  const [
    loadAPCreditDoc,
    {
      loading: arDocLoading,
      error: arDocError,
      data: {
        getARInvoice,
        getARDebitNote,
        getARCreditNote,
        getAROfficialReceipt,
        getARRefund,
      } = {
        getARInvoice: [],
        getARDebitNote: [],
        getARCreditNote: [],
        getAROfficialReceipt: [],
        getARRefund: [],
      },
    },
  ] = useGetOsDebtorDebitDocLazyQuery({
    fetchPolicy: 'network-only',
  })

  let outstandingCn: any[] = []
  let outstandingDn: any[] = []
  let outstandingInvoices: any[] = []
  let outstandingReceipt: any[] = []
  let outstandingRefund: any[] = []

  outstandingInvoices = getARInvoice.filter(inv => inv.BalanceAmt > 0)
  outstandingDn = getARDebitNote.filter(dn => dn.BalanceAmt > 0)

  outstandingCn = getARCreditNote
    ?.filter(ldg => ldg.BalanceAmt > 0)
    .map(ldg => ({ ...ldg, Negative: true }))

  outstandingReceipt = getAROfficialReceipt
    ?.filter(ldg => ldg.BalanceAmt > 0)
    .map(ldg => ({ ...ldg, Negative: true }))

  outstandingRefund = getARRefund.filter(rf => rf.BalanceAmt > 0)

  const outstandingArray: any[] = [
    ...outstandingInvoices,
    ...outstandingCn.map((cnItem: any) => ({
      ...cnItem,
      BalanceAmt: -cnItem.BalanceAmt,
    })),
    ...outstandingDn,
    ...outstandingReceipt.map((receiptItem: any) => ({
      ...receiptItem,
      BalanceAmt: -receiptItem.BalanceAmt,
    })),
    ...outstandingRefund,
  ]?.sort((a, b) => (a?.DocDate > b?.DocDate ? 1 : -1))

  const handleOSDialog = associateID => {
    setOsDialog(true)
    loadAPCreditDoc({
      variables: { CompanyID: CompanyID, DebtorAccountID: associateID },
    })
  }

  useEffect(() => {
    if (getDebtorAccount && getDebtorAccount?.length > 0) {
      setOriginalListing(getDebtorAccount)
    }
  }, [getDebtorAccount])

  let newFilteredListWithTotalDocAmt = filteredList.map(subject => {
    let TotalDueAmount = DebtorAccountTotalDue.find(
      f => f?.DebtorAccountID === subject?.DebtorAccountID
    )
    let LatestUpdatedDate = getDebtorLatestUpdatedDate.find(
      e => e?.DebtorAccountID === subject?.DebtorAccountID
    )
    return { ...subject, TotalDueAmount, LatestUpdatedDate }
  })

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['DebtorName']
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      }

      const myFuse = new Fuse(originalList, options)
      const result = myFuse.search(getSearch)
      const val = result?.map(x => x.item)
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys)
      }
    }
  }, [getSearch, originalList])

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const BalAmt = outstandingArray?.reduce(
    (total, curValue) => total + curValue?.BalanceAmt,
    0
  )

  return (
    <>
      {loading && <Loading />}
      {DebtorAccountLoading && <Loading />}
      {DebtorAccountTotalDueLoading && <Loading />}
      {DebtorLatestUpdatedDateLoading && <Loading />}
      {arDocLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/account-receivable/${CompanyID}`)
          localStorage.removeItem('searchFilter')
        }} //<- back button action
        smTitle={'Account Receivable'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'AR' },
          { name: 'Debtor Accounts', current: true },
        ]}
      />

      <SearchHeader
        title="Debtor Listing"
        value={`${advancedFilterList(newFilteredListWithTotalDocAmt)?.length}`}
        fixed
        search
        onChangeAction={e => {
          handleSearch(e?.target?.value, ['DebtorName'])
          localStorage.setItem('searchFilter', e.target.value)
        }}
        defaultValue={getSearch ? getSearch : ''}
        isDefaultValue={!!getSearch}
        onCloseAction={() => handleSearch('', [])}
        option={{
          icon: <Tune />,
          onClick: () => {},
        }}
      />

      <ContentWrapper footer search>
        <List className="core-list">
          {newFilteredListWithTotalDocAmt === undefined ||
          newFilteredListWithTotalDocAmt?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            newFilteredListWithTotalDocAmt?.map((el, index) => {
              return (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        <span className="xsTitle flex-space">
                          {el.DebtorName}{' '}
                        </span>
                        <span
                          className="xsTitle c-orange click-text"
                          onClick={(e: any) => {
                            handleOSDialog(el?.DebtorAccountID)
                            setTotalDocAmt(el?.TotalAmt)
                            setDebtorName(el?.DebtorName)
                          }}
                        >
                          {el?.TotalOSAmt < 0
                            ? `(${amtStr(el?.TotalOSAmt * -1)})`
                            : amtStr(el?.TotalOSAmt)}
                        </span>
                      </>
                    }
                    secondary={
                      <span className="desc">
                        <span className="fw-medium">Total Due:</span>
                        &nbsp;
                        <span>
                          <span className="desc ">
                            {amtStr(el?.TotalDueAmount?.TotalDueAmount)}
                          </span>
                          <span> |</span>
                          &nbsp;
                          <span className="desc ">{`Last Payment Date: ${formatDate(
                            el?.LatestUpdatedDate?.LatestUpdatedDate
                          )} `}</span>
                        </span>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          history.push({
                            pathname: `/account-receivable/${CompanyID}/debtor-account/debtor-profile/${el.DebtorAccountID}`,
                          })
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })
          )}
        </List>
        <FloatButton
          onClick={() =>
            history.push(`/account-receivable/${CompanyID}/debtor-account/add`)
          }
          disabled={handlePermDisabled({
            permEnum: AcctPermission.AccReceivableDebtorAccCreate,
          })}
        />
      </ContentWrapper>
      <CommonDialog
        fullWidth={true}
        open={osDialog}
        onClose={() => setOsDialog(false)}
        sections={{
          header: {
            title: debtorName,
            infoLine: 'O/S Amount',
            rightInfoLine: (() => {
              return BalAmt < 0 ? `(${amtStr(BalAmt * -1)})` : amtStr(BalAmt)
            })(),
          },
          body: () => (
            <>
              <List className="core-list">
                {outstandingArray?.length === 0 ||
                outstandingArray === undefined ? (
                  <EmptyList title={'No Outstanding Document'} />
                ) : (
                  outstandingArray?.map((el, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <span className="desc date-width">
                              {formatDate(el?.DocDate)}
                            </span>
                            <span className="mdLabel flex-space">
                              {el?.DocNo}
                            </span>
                            <span className="xxTitle">
                              <IconText
                                font="desc xxTitle"
                                children={
                                  el?.Negative
                                    ? `-${amtStr(el?.DocAmt)}`
                                    : amtStr(el?.DocAmt)
                                }
                                childrenStyle={{
                                  color: el?.Negative ? 'red' : '',
                                }}
                              />
                            </span>
                          </>
                        }
                        secondary={
                          <>
                            {!!el?.DueDate && (
                              <span
                                className="desc date-width"
                                style={{
                                  color: checkDueDate(el?.DueDate)
                                    ? 'red'
                                    : null,
                                }}
                              >
                                {formatDate(el?.DueDate)}
                              </span>
                            )}
                            <span className="desc flex-space text-overflow">
                              {el?.Description}
                            </span>
                            <span className="xxTitle">
                              <IconText
                                font=" desc xxTitle"
                                children={amtStr(el?.BalanceAmt)}
                              />
                            </span>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                )}
              </List>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setOsDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  )
}
