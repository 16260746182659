import theme from '@ifca-root/react-component/src/assets/theme'
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import {
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import PersonIcon from '@material-ui/icons/Person'
import SubmitterLog from 'assets/icons/GeneralLedger/submitter-log.svg'
import { CardExpansionHeader } from 'components/CardExpansion/CardExpansionHeader'
import { RejectDialog } from 'components/Dialog/RejectDialog'
import { SubmitterDialog } from 'components/Dialog/SubmitterDialog'
import { advancedFilterList } from 'components/Filter/AdvancedFilter'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  GetRecurringJournalbyStatusDocument,
  useGetRecurringJournalbyStatusQuery,
  usePostRecurringJournalMutation,
  useRejectRecurringJournalMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import {
  formatDate,
  formatMonth,
  formatTime,
} from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { RecurringDetailContent } from '../RecurringDetail/RecurringDetailContent'
import { RecurringDetailItemContent } from '../RecurringDetail/RecurringDetailItemContent'

export const RecurringPostingList = (props: any) => {
  const {
    filteredList,
    listLoading,
    listStatus,
    postIDs,
    setPostIDs,
    userList,
    setOriginalListing,
    handleFilterDate,
  } = props

  let history = useHistory()
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))
  const { CompanyID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { menu: menu2, handleClick: handleClick2 }: any = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  /* -------------------------------------------- */
  /*                     STATE                    */
  /* -------------------------------------------- */

  const [openDialog, setOpenDialog] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const [clickReject, setIsReject] = useState(false)
  const [rejectDialogInput, setRejectDialogInput] = useState({
    RejectionRemark: '',
  })
  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  // const {
  //   loading,
  //   error,
  //   data: { getUsersByAccountAndSoftware } = {
  //     getUsersByAccountAndSoftware: [],
  //   },
  // } = useGetUsersByAccountAndSoftwareQuery({
  //   fetchPolicy: 'network-only',
  // })

  const {
    loading: RecurringJournalbyStatusLoading,
    error: RecurringJournalbyStatusError,
    data: { getRecurringJournalbyStatus } = {
      getRecurringJournalbyStatus: [],
    },
  } = useGetRecurringJournalbyStatusQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      // BankAccountID: BankAccountID,
      StatusArr: [
        // ApprovalStatus.Completed,
        ApprovalStatus.Submit,
      ],
    },
  })

  const [
    postRecurringJournal,
    {
      loading: postRecurringJournalLoading,
      called: postRecurringJournalCalled,
      error: postRecurringJournalError,
    },
  ] = usePostRecurringJournalMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.postNewRecord())
      setPostIDs(new Set([]))
    },
  })

  const [
    rejectRecurringJournal,
    {
      loading: rejectRecurringJournalLoading,
      called: rejectRecurringJournalCalled,
      error: rejectRecurringJournalError,
    },
  ] = useRejectRecurringJournalMutation({
    onError: error => {
      console.log('ERROR', error)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.rejectNewRecord())
      setPostIDs(new Set([]))
    },
  })

  // const {
  //   loading: CompanyLoading,
  //   error: CompanyError,
  //   data: { getCompany } = { getCompany: [] },
  // } = useGetCompanyNameQuery({
  //   fetchPolicy: 'network-only',
  //   variables: { CompanyID },
  // })
  /* -------------------------------------------- */
  /*                   FUNCTION                   */
  /* -------------------------------------------- */
  // handle checkbox for all employee
  const handleSelectAll = e => {
    let temp = postIDs
    if (e.target.checked) {
      filteredList.map(v => {
        temp.add(v?.RecurringJournalID)
      })
    } else {
      temp.clear()
    }
    setPostIDs(new Set(temp))
  }

  // handle checkbox for specific entityCoa
  const handleCheckBoxChange = (e: any, postID, index: number) => {
    let temp = postIDs
    if (!temp.has(postID)) {
      temp.add(postID)
    } else {
      temp.delete(postID)
    }

    setPostIDs(new Set(temp))
  }

  let listPostIDs: any = Array.from(postIDs || [])

  let checkedData = []
  listPostIDs.map(ID => {
    filteredList.map(el => {
      if (el?.RecurringJournalID === ID) {
        checkedData.push(...el?.RecurringJournalItem)
      }
    })
  })
  const docAmtReducer = (total, curVal) => total + curVal?.DocAmt

  const positiveAmt = checkedData
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = checkedData
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  /* -------------------------------------------- */
  /*                    SUBMIT                   */
  /* -------------------------------------------- */
  const onSubmitApprove = () => {
    postRecurringJournal({
      variables: {
        RecurringJournalIDs: listPostIDs,
        CompanyID,
      },
      refetchQueries: [
        {
          query: GetRecurringJournalbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  const onSubmitReject = () => {
    rejectRecurringJournal({
      variables: {
        RecurringJournalIDs: listPostIDs,
        input: rejectDialogInput,
      },
      refetchQueries: [
        {
          query: GetRecurringJournalbyStatusDocument,
          variables: {
            CompanyID: CompanyID,
            StatusArr: [ApprovalStatus.Submit],
          },
        },
      ],
    })
  }

  useEffect(() => {
    if (clickReject) {
      onSubmitReject()
    }
  }, [clickReject])

  useEffect(() => {
    if (
      (getRecurringJournalbyStatus &&
        getRecurringJournalbyStatus?.length > 0) ||
      getRecurringJournalbyStatus?.length === 0
    ) {
      setOriginalListing(
        getRecurringJournalbyStatus
        //   , v => {
        //   v['DocDateFormatted'] = formatDate(v.DocDate)
        // }
      )
    }
  }, [getRecurringJournalbyStatus])

  const GreyTooltip = withStyles({
    tooltip: {
      color: 'white',
      backgroundColor: '#9e9e9e',
    },
  })(Tooltip)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  return (
    <>
      {postRecurringJournalLoading && <Loading />}
      {listLoading && <Loading />}

      <ContentWrapper float style={{ marginTop: isDesktop ? '70px' : '-7px' }}>
        {listStatus === 'SUBMIT' && filteredList?.length !== 0 ? (
          <span className="mdDesc flex-space" style={{ paddingLeft: '7px' }}>
            <Checkbox
              onChange={handleSelectAll}
              color="primary"
              value={''}
              checked={postIDs?.size === filteredList?.length}
              style={{ marginLeft: '-5px', marginTop: '-5px' }}
              disabled={handlePermDisabled({
                companyID: CompanyID,
                permEnum:
                  AcctPermission.GeneralLedgerRecurringJournalApproveReject,
              })}
            />
            <span style={{ marginLeft: '9px' }}>Select All</span>
          </span>
        ) : null}

        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList)
              ?.filter(handleFilterDate)
              ?.sort((a, b) => {
                return a.createdTs.localeCompare(b.createdTs)
              })
              ?.map((el, index) => {
                return (
                  <CardExpansion
                    summary={
                      <ListItem key={index}>
                        {listStatus === 'SUBMIT' ? (
                          <FormControl
                            component="fieldset"
                            style={{ width: '16px' }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={el.RecurringJournalID}
                                  onChange={e => {
                                    handleCheckBoxChange(
                                      e,
                                      el?.RecurringJournalID,
                                      index
                                    )
                                  }}
                                  onClick={e => e.stopPropagation()}
                                  checked={postIDs?.has(el?.RecurringJournalID)}
                                  color="primary"
                                  style={{
                                    marginLeft: '10px',
                                    marginRight: '-5px',
                                  }}
                                  disabled={handlePermDisabled({
                                    companyID: CompanyID,
                                    permEnum:
                                      AcctPermission.GeneralLedgerRecurringJournalApproveReject,
                                  })}
                                />
                              }
                              label=""
                              name="check"
                            />
                          </FormControl>
                        ) : null}

                        <ListItemText
                          style={{
                            marginLeft: listStatus === 'SUBMIT' ? '25px' : null,
                          }}
                          primary={
                            <>
                              <span className="xsTitle ">
                                {formatMonth(el?.createdTs)}
                              </span>
                              <LibraryBooksIcon
                                style={{
                                  fontSize: 'small',
                                  width: '20px',
                                }}
                              />
                              <span
                                className="xsTitle flex-space"
                                style={{ fontSize: '12px' }}
                              >
                                {el?.NoOfRecurrance}
                              </span>
                              <span
                                className="desc flex-space text-overflow"
                                style={{ marginBottom: '-5px' }}
                              >
                                {/* {el?.Description} */}
                              </span>
                              <span className="xsTitle">
                                <span className="c-orange">
                                  {amtStr(
                                    el?.RecurringJournalItem?.filter(
                                      item => item?.DocAmt > 0
                                    )?.reduce((x, y) => x + y?.DocAmt, 0)
                                  )}
                                </span>
                              </span>
                            </>
                          }
                          secondary={
                            <>
                              <span
                                className="desc"
                                style={{
                                  whiteSpace: 'break-spaces',
                                  lineBreak: 'auto',
                                }}
                              >
                                <GreyTooltip
                                  onClick={e => e.stopPropagation()}
                                  disableFocusListener
                                  title={
                                    <React.Fragment>
                                      <Grid container wrap="nowrap" spacing={2}>
                                        <Grid item>
                                          <Avatar
                                            style={{
                                              width: '20px',
                                              height: '20px',
                                            }}
                                          >
                                            <PersonIcon
                                              style={{ fontSize: 'small' }}
                                            />
                                          </Avatar>
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                          <div style={{ fontSize: '12px' }}>
                                            {userList?.find(
                                              x => x?.ID === el?.submittedBy
                                            )?.name ??
                                              userList?.find(
                                                x =>
                                                  x?.ID === el?.submittedBy ??
                                                  el?.modBy
                                              )?.name}
                                          </div>
                                          <div style={{ fontSize: '10px' }}>
                                            {formatDate(el?.modTs)}
                                            &nbsp;
                                            {formatTime(el?.modTs)}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </React.Fragment>
                                  }
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <div>
                                    <IconText
                                      icon={
                                        <img
                                          src={SubmitterLog}
                                          style={{
                                            width: '12px',
                                            marginRight: '3px',
                                          }}
                                        />
                                      }
                                      font="desc"
                                    ></IconText>
                                  </div>
                                </GreyTooltip>
                              </span>
                              <span
                                className="desc flex-space text-overflow"
                                style={{ marginBottom: '-5px' }}
                              >
                                {el?.Description}
                              </span>
                            </>
                          }
                        />
                      </ListItem>
                    }
                  >
                    {
                      <>
                        <RecurringDetailContent
                          listEl={el}
                          documentListing={el?.Attachment}
                          listStatus={'SUBMIT'}
                          userList={userList}
                          mode={'expansion'}
                        />
                        <CardExpansionHeader title={'Journal Detail'} />
                        <RecurringDetailItemContent
                          listItem={el?.RecurringJournalItem}
                        />
                      </>
                    }
                  </CardExpansion>
                )
              })
          )}
        </List>
      </ContentWrapper>

      <SubmitterDialog
        data={menu2?.obj}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        moduleName={'Recurring Journal'}
      />
      <RejectDialog
        data={filteredList}
        openRejectDialog={openRejectDialog}
        setOpenRejectDialog={setOpenRejectDialog}
        setRejectDialogInput={setRejectDialogInput}
        setIsReject={setIsReject}
      />

      <TotalAmountFooter
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />
      {listStatus === 'SUBMIT' ? (
        <AccountFooter
          options={[
            {
              name: 'Reject',
              onClick: () => {
                setOpenRejectDialog(true)
              },
              color: 'secondary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.GeneralLedgerRecurringJournalApproveReject,
                  }),
              },
              // disabled: !!postIDs === false,
            },
            {
              name: 'Approve',
              onClick: () => {
                onSubmitApprove()
              },
              color: 'primary',
              props: {
                disabled:
                  postIDs.size === 0 ||
                  handlePermDisabled({
                    companyID: CompanyID,
                    permEnum:
                      AcctPermission.GeneralLedgerRecurringJournalApproveReject,
                  }),
              },
            },
          ]}
        />
      ) : null}
    </>
  )
}
