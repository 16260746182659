import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import React from 'react'

export const Table2GL = (props: any) => {
  // const { docDate, docNo, description, dueDate, reminderAmt } = props;
  const { data, docType, getCostCentre } = props
  // console.log(docType, 'dataa') not deleting this cause want to check the data 11/8/2023

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 10
  }

  ///// style border table
  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }

  const thamt = {
    borderBottom: '0.5px  solid',
    textAlign: 'center' as 'center',
    className: 'click-text',
    width: '10%',
    backgroundColor: '#e7e6e6',
  }

  const thleftcode = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    width: '15%',
    backgroundColor: '#e7e6e6',
  }

  const thcenter = {
    width: '15%',
    textAlign: 'center' as 'center',
  }

  const amt = {
    fontWeight: 'normal',
    textAlign: 'left',
    width: width < 769 ? '18%' : '10%',
  }

  const thright = {
    width: '10%',
    textAlign: 'right' as 'right',
  }

  let itemName

  switch (docType) {
    case 'journal-processing':
      itemName = 'JournalProcessingItem'
      break
    case 'recurring-journal':
      itemName = 'RecurringJournalItem'
      break
    case 'audit-adjustment':
      itemName = 'AuditAdjustmentItem'
      break
  }

  // ////// Line Under Amount //////
  let debitAmount = data?.[itemName]?.reduce((total, currentValue) => {
    if (parseFloat(currentValue?.DocAmt) > 0)
      total = total + parseFloat(currentValue?.DocAmt)
    else total = total + 0

    return total
  }, 0)

  let creditAmount = data?.[itemName]?.reduce((total, currentValue) => {
    if (parseFloat(currentValue?.DocAmt) < 0)
      total = total + parseFloat(currentValue?.DocAmt)
    else total = total + 0

    return total
  }, 0)

  return (
    <>
      <div>
        <table
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            width: '100%',
            fontSize,
            // borderCollapse: 'collapse',
            borderSpacing: '0 1em',
          }}
        >
          <tr>
            <th style={thleftcode}>Account Code</th>
            <th style={thleftcode}>Department</th>
            <th style={thleft}>Remark</th>
            {/* <th style={thcenter}>Department</th>
            <th style={thcenter}>Remark</th> */}
            <th style={thamt}>
              Debit Amt
              <br />
              <span>(RM)</span>
            </th>
            <th style={thamt}>
              Credit Amt
              <br />
              <span>(RM)</span>
            </th>
          </tr>
          {data?.[itemName]?.map((v, i) => (
            <>
              <tr>
                <td style={{ textAlign: 'left' }}>{v?.MasterCOA?.Code}</td>
                <td style={{ textAlign: 'left' }}>
                  {v?.CostCentre?.Code ??
                    getCostCentre
                      ?.filter(x => x?.CostCentreID === v?.CostCentreID)
                      ?.map(x => {
                        return x?.Code
                      })}
                </td>
                <td style={{ textAlign: 'left' }}>
                  <span>
                    {v?.MasterCOA?.Name}
                    <br />
                    {v?.Remark}
                  </span>
                </td>
                <td style={{ textAlign: 'right', width: '10%' }}>
                  {v?.DocAmt > 0 ? amtStr(v?.DocAmt) : null}
                </td>
                <td style={{ textAlign: 'right', width: '10%' }}>
                  {v?.DocAmt < 0 ? amtStr(v?.DocAmt).substring(1) : null}
                </td>
              </tr>
            </>
          ))}
        </table>
      </div>
      <div>
        <table
          style={{
            marginTop: '20px',
            width: '100%',
            fontSize,
            borderTop: '0.5px solid black',
            borderBottom: '0.5px solid black',
          }}
        >
          <tr>
            <th
              style={{ fontWeight: 'normal', textAlign: 'left', width: '15%' }}
            >
              Total Lines :
            </th>
            <th style={{ fontWeight: 'normal', textAlign: 'left' }}>
              {data?.[itemName]?.length}
            </th>
            <th style={thcenter}>Total : </th>
            <th style={thright}>{amtStr(debitAmount)}</th>
            <th style={thright}>{amtStr(creditAmount).substring(1)}</th>
          </tr>
        </table>
      </div>
    </>
  )
}

export default Table2GL
