import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { List, ListItem, ListItemText } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import { CopyrightFooter } from 'components/Footer/Copyright'
import {
  AcctPermission,
  useGetCreditorAccountQuery,
  useGetCompanyNameQuery,
  useGetTaxSchemeQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { toProperCase } from 'helpers/StringNumberFunction/StringConverter'
import React from 'react'
import { useHistory, useParams } from 'react-router'

export const CreditorAccountDetail = (props: any) => {
  let history = useHistory()
  const { CreditorAccountID, CompanyID }: any = useParams()

  const { mode }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    loading: TaxLoading,
    error: TaxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    onCompleted: () => {},
  })

  const {
    loading,
    error,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  const {
    loading: CreditorAccountLoading,
    error: CreditorAccountError,
    data: { getCreditorAccount } = { getCreditorAccount: [] },
  } = useGetCreditorAccountQuery({
    fetchPolicy: 'network-only',
    variables: { CreditorAccountID: CreditorAccountID },
  })

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */
  return (
    <>
      {loading && <Loading />}
      {TaxLoading && <Loading />}
      {CreditorAccountLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() =>
          history.push(
            `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}`
          )
        }
        title={curCompany[0]?.Name}
        smTitle={'Account Payable'}
        routeSegments={[
          { name: 'Account Payable' },
          { name: 'Main Menu' },
          { name: 'Creditor Accounts' },
          { name: 'Creditor Accounts', current: true },
        ]}
      />

      <ContentWrapper footer>
        <CardContents
          section={{
            header: {
              title: `${toProperCase(
                getCreditorAccount[0]?.CreditorTypeEnum
              )} Profile`,
              onClickAction: () =>
                history.push({
                  pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                  state: getCreditorAccount[0],
                }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
              }),
            },
          }}
        >
          <div className="content-wrap left">
            <div className="desc c-grey">Company Registration No.</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CompanyRegNo}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Company Name</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CompanyName}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Creditor Type</div>
            <div className="mdDesc">
              {`${toProperCase(getCreditorAccount[0]?.CreditorTypeEnum)} - ${
                getCreditorAccount[0]?.CreditorType?.Description
              }`}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Phone No.</div>
            <div className="mdDesc">+{getCreditorAccount[0]?.ContactNo}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Email Address</div>
            <div className="mdDesc">{getCreditorAccount[0]?.Email}</div>
          </div>

          <div className="content-wrap left">
            <div className="desc c-grey">Credit Terms</div>
            <div className="mdDesc">{getCreditorAccount[0]?.CreditTerm}</div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">Tax</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.TaxScheme?.Code}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc c-grey">Region</div>
            <div className="mdDesc">{getCreditorAccount[0]?.Region}</div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">GST Registration No.</div>
            <div className="mdDesc">{getCreditorAccount[0]?.GSTRegNo}</div>
          </div>

          <div className="content-wrap full">
            <div className="desc c-grey">Remark</div>
            <div className="mdDesc">{getCreditorAccount[0]?.Remark}</div>
          </div>

          <div className="content-wrap left">
            <div className="desc c-grey">Bank Account No.</div>
            <div className="mdDesc">{getCreditorAccount[0]?.BankAccountNo}</div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">Bank Name</div>
            <div className="mdDesc">{getCreditorAccount[0]?.BankName}</div>
          </div>
          <div className="content-wrap left">
            <div className="desc c-grey">SWIFT Code</div>
            <div className="mdDesc">{getCreditorAccount[0]?.SWIFTCode}</div>
          </div>

          <div className="content-wrap right">
            <div className="desc c-grey">PayeeName</div>
            <div className="mdDesc">{getCreditorAccount[0]?.PayeeName}</div>
          </div>
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Address Info',
              onClickAction: () =>
                history.push({
                  pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                  state: getCreditorAccount[0],
                }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
              }),
            },
          }}
        >
          <div className="content-wrap full">
            <div className="desc c-grey">Address</div>
            <div className="mdDesc">
              {getCreditorAccount[0]?.Address?.address},{' '}
              {getCreditorAccount[0]?.Address?.postcode}{' '}
              {getCreditorAccount[0]?.Address?.city},{' '}
              {getCreditorAccount[0]?.Address?.state},{' '}
              {getCreditorAccount[0]?.Address?.country}
            </div>
          </div>
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Contact Details',
              onClickAction: () =>
                history.push({
                  pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                  state: getCreditorAccount[0],
                }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
              }),
            },
          }}
        >
          <List style={{ width: '100%', paddingBottom: '0px' }}>
            {getCreditorAccount[0]?.CreditorContact?.map((el, index) => {
              return (
                <ListItem
                  key={index}
                  style={{ marginLeft: '-5px', marginTop: '-15px' }}
                >
                  <ListItemText
                    primary={
                      <>
                        <span className="desc">
                          <span className="xsTitle">
                            {el.Name} ({el.Designation})
                          </span>
                        </span>
                      </>
                    }
                    secondary={
                      <span className="desc">
                        <span className="desc">
                          <span>
                            <PhoneIcon
                              style={{
                                fontSize: '10px',
                                color: 'black',
                                marginBottom: '-1px',
                                marginRight: '3px',
                              }}
                            />

                            <span className={` primary-click-text`}>
                              +{el.ContactNo}
                            </span>
                          </span>
                          {` | `}
                          <span>
                            <EmailIcon
                              style={{
                                fontSize: '12px',
                                color: 'black',
                                marginBottom: '-3px',
                                marginRight: '3px',
                              }}
                            />
                            <span className={` primary-click-text`}>
                              {el.Email}
                            </span>
                          </span>
                        </span>
                      </span>
                    }
                  />
                </ListItem>
              )
            })}
          </List>
        </CardContents>
        <CardContents
          section={{
            header: {
              title: 'Director Details',
              onClickAction: () =>
                history.push({
                  pathname: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/edit`,
                  state: getCreditorAccount[0],
                }),
              icon: (
                <CreateIcon
                  style={{
                    color: handlePermDisabled({
                      permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
                    })
                      ? 'grey'
                      : '#FF9800',
                  }}
                />
              ),
              disabled: handlePermDisabled({
                permEnum: AcctPermission.AccPayableCreditorProfileUpdate,
              }),
            },
          }}
        >
          <List style={{ width: '100%', paddingBottom: '0px' }}>
            {getCreditorAccount[0]?.CreditorDirector?.map((el, index) => {
              return (
                <ListItem
                  key={index}
                  style={{ marginLeft: '-5px', marginTop: '-15px' }}
                >
                  <ListItemText
                    primary={
                      <>
                        <span className="desc">
                          <span className="xsTitle">{el.Name}</span>
                        </span>
                      </>
                    }
                    secondary={
                      <span className="desc">
                        <span className="desc">
                          <span>
                            <PhoneIcon
                              style={{
                                fontSize: '10px',
                                color: 'black',
                                marginBottom: '-1px',
                                marginRight: '3px',
                              }}
                            />

                            <span className={` primary-click-text`}>
                              +{el.ContactNo}
                            </span>
                          </span>
                          {` | `}
                          <span>
                            <EmailIcon
                              style={{
                                fontSize: '12px',
                                color: 'black',
                                marginBottom: '-3px',
                                marginRight: '3px',
                              }}
                            />
                            <span className={` primary-click-text`}>
                              {el.Email}
                            </span>
                          </span>
                        </span>
                      </span>
                    }
                  />
                </ListItem>
              )
            })}
          </List>
        </CardContents>
      </ContentWrapper>
    </>
  )
}
