import { AttachFile } from '@material-ui/icons'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { useHistory, useParams } from 'react-router'
import React from 'react'
import '../ReceiptForm/Receipt.scss'

interface DetailProps {
  listEl: any
  documentListing?: any
  listStatus: string
  mode?: string
  userList: any
  bankAccount: any
}

export const ReceiptDetailContent = (props: DetailProps) => {
  const {
    listEl,
    documentListing,
    listStatus,
    userList,
    mode,
    bankAccount,
  } = props

  let history = useHistory()
  const { CompanyID, BankAccountID }: any = useParams()

  return (
    <>
      {mode === 'detail' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: '#96938e' }}>
              Document Date
            </div>

            <div className="desc" style={{ marginTop: '2px' }}>
              {' '}
              {formatDate(listEl?.DocDate)}{' '}
            </div>
          </div>

          <div className="content-wrap right">
            <span>
              <div className="desc">
                <span style={{ color: '#96938e' }}> Transaction Date </span>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={() => {
                    history.push({
                      pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/${listEl?.ReceiptID}/detail/preview`,
                      state: {
                        ...listEl,
                        mode: 'detail',
                      },
                    })
                  }}
                >
                  <AttachFile
                    style={{
                      fontSize: 12,
                      color: 'orange',
                    }}
                  />
                  <span
                    className="mdDesc"
                    style={{ color: 'orange', textDecoration: 'underline' }}
                  >
                    {documentListing?.length}
                  </span>
                </span>
                <div className="desc" style={{ marginTop: '2px' }}>
                  {formatDate(listEl?.TransactionDate)}
                </div>
              </div>
            </span>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: '#96938e' }}>
              Document Number
            </div>
            <div className="desc text-noflow" style={{ marginTop: '2px' }}>
              {' '}
              {listEl?.DocNo}{' '}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              Reference No.
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.RefNo}
            </div>
          </div>

          {listEl?.PaymentMethod?.Name === 'Cash' ? (
            <div className="content-wrap left">
              <div className="desc" style={{ color: '#96938e' }}>
                Payment Method
              </div>
              <div className="desc" style={{ marginTop: '2px' }}>
                {listEl?.PaymentMethod?.Name}
              </div>
            </div>
          ) : (
            <div className="content-wrap full">
              <div className="desc" style={{ color: '#96938e' }}>
                Payment Method
              </div>
              <div className="desc" style={{ marginTop: '2px' }}>
                {listEl?.PaymentMethod?.Name}
              </div>
            </div>
          )}

          <div className="content-wrap left">
            <div className="desc" style={{ color: '#96938e' }}>
              Received From
            </div>
            <div className="desc" style={{ marginTop: '2px' }}>
              {listEl?.ReceivedFrom}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: '#96938e' }}>
              Description
            </div>
            <div className="desc text-noflow" style={{ marginTop: '2px' }}>
              {' '}
              {listEl?.Description ?? listEl?.Remark}{' '}
            </div>
          </div>

          {listEl?.PaymentMethod?.Name?.includes('Card') && (
            <div className="content-wrap right" style={{ marginTop: '2px' }}>
              <div className="desc" style={{ color: '#96938e' }}>
                Card Type
              </div>
              <div className="desc" style={{ marginTop: '2px' }}>
                {listEl?.CreditCardType?.Name}
              </div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <>
              <div className="content-wrap right">
                <div className="desc" style={{ color: '#96938e' }}>
                  Cheque No.
                </div>
                <div className="desc" style={{ marginTop: '2px' }}>
                  {listEl?.ChequeNo}
                </div>
              </div>
              <div className="content-wrap left">
                <div className="desc" style={{ color: '#96938e' }}>
                  Cheque Date
                </div>
                <div className="desc" style={{ marginTop: '2px' }}>
                  {formatDate(listEl?.ChequeDate)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ color: '#96938e' }}>
                  Cheque Expiry Date
                </div>
                <div className="desc" style={{ marginTop: '2px' }}>
                  {formatDate(listEl?.ChequeExpiryDate)}
                </div>
              </div>
            </>
          )}

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: '#96938e' }}>
              Approved Date
            </div>
            <div className="desc" style={{ marginTop: '2px' }}>
              {formatDate(listEl?.approvedTs)}
            </div>
          </div>

          <div className="content-wrap right">
            <div className="desc" style={{ color: '#96938e' }}>
              Approved By
            </div>
            <div className="desc" style={{ marginTop: '2px' }}>
              {userList?.filter(user => user.ID == listEl?.approvedBy)[0]?.name}
            </div>
          </div>
        </>
      )}

      {mode === 'expansion' && (
        <>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Transaction Date
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.TransactionDate)}
            </div>
          </div>

          <div className="content-wrap right">
            <span>
              <div className="desc">
                <span className="desc" style={{ color: 'grey' }}>
                  {' '}
                  Reference No.{' '}
                </span>
                <span
                  style={{ float: 'right', color: '#000' }}
                  onClick={() => {
                    history.push({
                      pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/receipt/${listEl?.ReceiptID}/detail/preview`,
                      state: {
                        ...listEl,
                        mode: 'detail',
                      },
                    })
                  }}
                >
                  <AttachFile
                    style={{
                      fontSize: 12,
                      color: 'orange',
                    }}
                  />
                  <span
                    className="mdDesc"
                    style={{ color: 'orange', textDecoration: 'underline' }}
                  >
                    {documentListing?.length}
                  </span>
                </span>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {listEl?.RefNo}
                </div>
              </div>
            </span>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Payment Method
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.PaymentMethod?.Name}
            </div>
          </div>

          {listEl?.PaymentMethod?.Name?.includes('Card') && (
            <div className="content-wrap right">
              <div className="desc" style={{ color: 'grey' }}>
                Card Type
              </div>
              <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
                {listEl?.CreditCardType?.Name}
              </div>
            </div>
          )}

          {listEl?.PaymentMethod?.Name === 'Cheque' && (
            <>
              <div className="content-wrap right">
                <div className="desc" style={{ color: 'grey' }}>
                  Cheque No.
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {listEl?.ChequeNo}
                </div>
              </div>
              <div className="content-wrap left">
                <div className="desc" style={{ color: 'grey' }}>
                  Cheque Date
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {formatDate(listEl?.ChequeDate)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ color: 'grey' }}>
                  Cheque Expiry Date
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {formatDate(listEl?.ChequeExpiryDate)}
                </div>
              </div>
            </>
          )}

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              Received From
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.ReceivedFrom ?? 'N/A'}
            </div>
          </div>

          <div className="content-wrap full">
            <div className="desc" style={{ color: 'grey' }}>
              Description
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {listEl?.Description ?? listEl?.Remark}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(listEl?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                userList?.filter(user => user.ID == listEl?.submittedBy)[0]
                  ?.name
              }
            </div>
          </div>

          {listStatus === 'COMPLETED' && (
            <>
              <div className="content-wrap left">
                <div className="desc" style={{ color: 'grey' }}>
                  {'Approved Date'}
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {formatDate(listEl?.approvedTs)}
                </div>
              </div>
              <div className="content-wrap right">
                <div className="desc" style={{ color: 'grey' }}>
                  {'Approved By'}
                </div>
                <div
                  className="mdDesc text-noflow"
                  style={{ fontSize: '11px' }}
                >
                  {
                    userList?.filter(user => user.ID == listEl?.approvedBy)[0]
                      ?.name
                  }
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
